import React, { Component } from "react";
import MatchsText from "./MatchsText";
import axios from "axios";
import { url_base } from "./../../../../api_url";
import auth from "./../../../../auth";
import { trackPromise } from "react-promise-tracker";

class Matchs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            options: [],
            divShow: false,
            talent_offer_status_message: "",
            cantPages: "",
            optionValue: [],
            optionSelected: "",
            pageSelected: ""
        };
        this.talentOfferStatus = this.talentOfferStatus.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.modalReset = this.modalReset.bind(this);
        this.handleChangeOptions = this.handleChangeOptions.bind(this);
        this.redirectToWithParams = this.redirectToWithParams.bind(this);
    }
    redirectToWithParams() {
        this.props.history.push(
            "/Matchs/" + this.state.pageSelected + "/" + this.state.optionSelected
        );
    }
    modalReset() {
        this.setState({ showModal: false });
    }

    talentOfferStatus(e, talent_offer_status) {
        let message = "";
        e.preventDefault();
        //  console.log("talent_offer_status" + talent_offer_status);

        if (talent_offer_status == 0) {
            //    console.log("entra la 0");
            this.setState({ talent_offer_status_message: "activa" });
            message = "activa";
        } else if (talent_offer_status == 1) {
            this.setState({ talent_offer_status_message: "postulado" });
            message = "postulado";
        } else if (talent_offer_status === 2) {
            this.setState({ talent_offer_status_message: "seleccionado" });
            message = "seleccionado";
        } else if (talent_offer_status === 3) {
            this.setState({ talent_offer_status_message: "contratado" });
            message = "contratado";
        } else if (talent_offer_status === 4) {
            this.setState({ talent_offer_status_message: "rechazado" });
            message = "rechazado";
        }
        return message;
    }

    handleChangePage(id, optionSelected) {
        const access_token_data = localStorage.getItem("token");

        this.props.history.push("/Matchs/" + id + "/" + this.state.optionSelected);

        axios
            .get(url_base + "/talents/jobOffers/matching" + "/" + id, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                },
                params: {
                    state: String(this.state.optionSelected)
                }
            })
            .then(
                response => {
                    const data = response.data.data.jobOffers;

                    const cantPages = response.data.data.cantPages;

                    this.setState({
                        data: data,
                        cantPages: cantPages,
                        pageSelected: id
                        //   optionSelected: optionSelected
                        //    bySkills: bySkills
                    });
                    //     console.log("data= " + JSON.stringify(response.data));
                },
                error => {
                    var status = error.response;
                    //      console.log(status);
                }
            );
    }

    handleChangeOptions(e) {
        if (
            auth.isAuthenticated() === true &&
            localStorage.getItem("userType") == 3
        ) {
            const access_token_data = localStorage.getItem("token");

            //console.log("optionValue" + JSON.parse({ optionValue }));

            let option = e.target[e.target.selectedIndex].value;
            // console.log("option value= " + option);

            axios
                .get(
                    url_base + "/talents/jobOffers/matching" + "?state=" + String(option),

                    {
                        headers: {
                            ["Authorization"]: "Bearer " + access_token_data,
                            "Content-Type": "application/json"
                        }
                    }
                )
                .then(response => {
                    const data = response.data.data.jobOffers;
                    const cantPages = response.data.data.cantPages;

                    if (data == "" || data == undefined) {
                        this.setState({
                            data: "",
                            cantPages: "",
                            divShow: true,
                            optionSelected: option
                        });
                    } else {
                        //console.log(data);
                        this.setState({
                            data: data,
                            cantPages: cantPages,
                            divShow: false,
                            optionSelected: option,
                            pageSelected: 1
                        });
                    }
                });
        } else {
            this.props.history.push("/Login");
        }
    }

    componentDidMount() {
        //  this.funcion();


        const key = this.props.location.pathname.split("/");

        let keyIdPage = "";
        if (key[2] != "" && key[2] !== undefined && key[2] !== null) {
            keyIdPage = key[2];
        } else {
            keyIdPage = 1;
        }

        let keyFilter = "";
        if (key[3] != "" && key[3] !== undefined && key[3] !== null) {
            keyFilter = key[3];
        } else {
            keyFilter = "";
        }

        const access_token_data = localStorage.getItem("token");

        //  console.log(access_token_data);
        //chequeo si el usuario fue habilitado
        axios.get(url_base + "/users/logged", {
            headers: {
                ["Authorization"]: "Bearer " + access_token_data
            }
        })
            .then(
                response => {
                    const userstate_id = response.data.data.user.userstate_id;
                    //     console.log("data " + JSON.stringify(userstate_id));
                    localStorage.setItem("accountState", userstate_id);
                    // console.log("userstate_id " + userstate_id)
                },

                // localStorage.setItem("accountState", 1)},
                error => {
                    var status = error.response;
                }

            );


        if (
            auth.isAuthenticated() === true &&
            localStorage.getItem("userType") == 3
        ) {
            //  const access_token_data = localStorage.getItem("token");



            axios
                .get(url_base + "/job-searches/application-states", {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    },
                    params: {
                        state: String(keyFilter)
                    }
                })
                .then(response => {
                    const options = response.data.data;
                    //console.log(options);
                    this.setState({
                        options: options
                    });
                });

            trackPromise(
                axios
                    .get(url_base + "/talents/jobOffers/matching" + "/" + keyIdPage, {
                        headers: {
                            ["Authorization"]: "Bearer " + access_token_data
                        },
                        params: {
                            state: String(keyFilter)
                        }
                    })
                    .then(
                        response => {
                            const data = response.data.data.jobOffers;
                            const cantPages = response.data.data.cantPages;

                            if (localStorage.getItem("accountState") == 1) {
                                //Chequeo que el usuario tenga Matchs
                                if (data == "" || data == undefined) {
                                    this.setState({
                                        divShow: true
                                    });
                                } else {
                                    this.setState({
                                        data: data,
                                        cantPages: cantPages,
                                        divShow: false,
                                        pageSelected: keyIdPage,
                                        optionSelected: keyFilter
                                    });
                                }
                            }
                            //Si el estado del usuario es pendiente de activación por el administrador
                            //abro un pop up para notificar dicha situación
                            else //if (localStorage.getItem("accountState") != 1) {
                            {
                                this.setState({
                                    divShow: true,
                                    showModal: true,
                                    modalTitle: "AVISO",
                                    modalText:
                                        "Tu entrevista esta pendiente. Nos vamos a comunicar para agendarla",
                                    modalBtn: "Aceptar",
                                    //  path: path,
                                    redirect: true,
                                    pageSelected: keyIdPage
                                    //   talent_offer_status
                                });
                            }
                        },
                        error => {
                            var status = error.response;
                            //   console.log(status);
                        }
                    )
            );
        } else {
            this.props.history.push("/Login");
        }
    }

    render() {
        return (
            <MatchsText
                data={this.state.data}
                divShow={this.state.divShow}
                talentOfferStatus={this.talentOfferStatus}
                talent_offer_status_message={this.state.talent_offer_status_message}
                cantPages={this.state.cantPages}
                pages={this.state.pages}
                showModal={this.state.showModal}
                modalTitle={this.state.modalTitle}
                modalText={this.state.modalText}
                modalBtn={this.state.modalBtn}
                modalReset={this.modalReset}
                redirect={this.state.redirect}
                path={this.state.path}
                handleChangePage={this.handleChangePage}
                handleChangeOptions={this.handleChangeOptions}
                options={this.state.options}
                pageSelected={this.state.pageSelected}
                optionSelected={this.state.optionSelected}
                redirectToWithParams={this.redirectToWithParams}
            />
        );
    }
}

export default Matchs;
