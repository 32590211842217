import React, { Component } from "react";

import axios from "axios";
import { url_base } from "./../../../../api_url";
import AgregarAptitudesScreen from "./AgregarAptitudesScreen.js";
import { object } from "prop-types";

class AgregarAptitudes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            skills: [],
            arrayHelpers: [],
            checks: [],
            result: "",
            idKey: ""
        };
    }

    componentWillMount() {
        const key = this.props.location.pathname.split("/");
        this.setState({ idKey: key[3] })
        const access_token_data = localStorage.getItem("token");
        const checks = JSON.parse(localStorage.getItem("checks"));

        let arraySkills = [];


        checks &&
            Object.keys(checks).map((keyName, i) => {
                checks[keyName].id && arraySkills.push(checks[keyName].id)
            });

        this.setState({ checks: arraySkills });

        axios
            .get(url_base + "/skills/" + key[2], {
                headers: { ["Authorization"]: "Bearer " + access_token_data }
            })
            .then(
                response => {
                    const skills = response.data.data.skills;
                    this.setState({
                        skills: skills
                    });
                },
                error => {
                    var status = error.response;
                }
            );
    }

    render() {
        return (
            <AgregarAptitudesScreen
                skills={this.state.skills}
                handleSubmitValue={this.handleSubmitValue}
                arrayHelpers={this.state.arrayHelpers}
                checkboxGroup={this.state.checks}
                checks={this.state.checks}
                idKey={this.state.idKey}
            />
        );
    }
}
export default AgregarAptitudes;
