import React, { Component } from "react";
import axios from "axios";
import { url_base } from "../../../../api_url";
import auth from "../../../../auth";
import DetalleTalentoOfertaScreen from "./DetalleTalentoOfertaScreen";

class DetalleTalentoOferta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idOffer: "",
            idTalent: "",
            studies: [],
            experiences: [],
            skills: [],
            languages: [],
            data: [],
            offer_end_date: [],
            checkMonotributoChecked: "",
            optionSelected: ""
        };
        this.selectTalent = this.selectTalent.bind(this);
        this.modalReset = this.modalReset.bind(this);
    }

    modalReset() {
        this.setState({ showModal: false, redirect: false });
    }

    selectTalent(event, idOffer, idTalent) {
        event.preventDefault();
        const access_token_data = localStorage.getItem("token");

        axios
            .patch(
                url_base +
                "/companies/job-searches/select-talent/" +
                idOffer +
                "/" +
                idTalent,
                "",
                {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                }
            )
            .then(
                response => {
                    const data = response.data;

                    let message = response.data.message;
                    let path = "/BusquedasActivas/";

                    this.setState({
                        showModal: true,
                        modalTitle: "",
                        modalText: message,
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                },
                error => {
                    var status = error.response;
                }
            );
    }


    componentDidMount() {
        const key = this.props.location.pathname.split("/");
        const idOffer = key[2];
        const idTalent = key[3];
        const selectionActive = key[4];
        const checkMonotributoChecked = key[5];
        const optionSelected = key[6]

        this.setState({
            idOffer: idOffer,
            idTalent: idTalent,
            selectionActive: selectionActive,
            optionSelected: optionSelected,
            checkMonotributoChecked: checkMonotributoChecked
        });
        const access_token_data = localStorage.getItem("token");
        //  console.log(idOffer + "/" + idTalent)
        axios
            .get(url_base + "/companies/talent/" + idOffer + "/" + idTalent, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = response.data.data;

                    this.setState({
                        data: data,
                        studies: data.studies,
                        skills: data.skills,
                        experiences: data.experiences,
                        languages: data.languages,
                        hirings: data.hirings
                    });


                },
                error => {
                    var status = error.response;
                }
            );

        const parameters = {
            "order_param": "",
            "order_direction": "",
            "filters": [
                {
                    "param": "",
                    "value": ""
                }
            ]
        }

        axios
            .post(url_base + "/companies/job-searches/" + key[2], parameters, {
                headers: { ["Authorization"]: "Bearer " + access_token_data }
            })

            .then(
                response => {

                    if (response.data.result === "1") {

                        const offer_end_date = response.data.data.jobOffer.offer_end_date;

                        this.setState({
                            offer_end_date: offer_end_date,

                        });
                    }
                },
                error => {
                    var status = error.response;
                    console.log("error: " + error);
                }
            );
    }

    render() {
        return (
            <DetalleTalentoOfertaScreen
                data={this.state.data}
                studies={this.state.studies}
                skills={this.state.skills}
                experiences={this.state.experiences}
                hirings={this.state.hirings}
                languages={this.state.languages}
                selectTalent={this.selectTalent}
                idOffer={this.state.idOffer}
                idTalent={this.state.idTalent}
                appliedTalents={this.state.appliedTalents}
                selectionActive={this.state.selectionActive}
                showModal={this.state.showModal}
                modalTitle={this.state.modalTitle}
                modalText={this.state.modalText}
                modalBtn={this.state.modalBtn}
                modalReset={this.modalReset}
                path={this.state.path}
                redirect={this.state.redirect}
                offer_end_date={this.state.offer_end_date}
                checkMonotributoChecked={this.state.checkMonotributoChecked}
                optionSelected={this.state.optionSelected}
            />
        );
    }
}
export default DetalleTalentoOferta;
