import React from "react";
import { Formik, Field, Form, withFormik } from "formik";
import * as Yup from "yup";
import { FormFeedback, Input, FormGroup, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { url_base } from "./../../api_url";

const formikWrapper = withFormik({
  mapPropsToValues: props => ({
    password: "",
    password_confirmation: ""
  }),

  initialValues: { password: "", password_confirmation: "" },
  validateOnChange: false,
  validateOnBlur: true,
  validationSchema: Yup.object().shape({
    password_confirmation: Yup.string()
      .required("requerido")

      .label("confirmar contraseña")
      .test("passwords-match", "la contraseña debe coincidir", function(value) {
        return this.parent.password === value;
      }),
    password: Yup.string()
      .oneOf([Yup.ref("password"), null], "la  contraseña debe coincidir")
      .min(8, "el password es demasiado corto!")
      .max(32, "el password es demasiado largo!")
      .required("requerido")
  }),

  // validationSchema: validationSchema,
  handleSubmit: (values, { props }) => {
    // console.log(values);
    const email = props.email;
    const password_token = props.password_token;
    const password = values.password;
    const password_confirmation = values.password_confirmation;
    // const
    const res =
      "password=" +
      password +
      "&password_confirmation=" +
      password_confirmation +
      "&email=" +
      email +
      "&password_token=" +
      password_token;
    //console.log(res);
    axios
      .patch(url_base + "/change-password", res)
      .then(response => {
        props.history.push("/Login");
        //   console.log(res);
        /*    let path = `/Login`;
        props.history.push(path);*/
      })
      .catch();
  }
});
const PasswordNoLogueado = props => {
  const { handleChange, errors, touched } = props;
  return (
    <div className="forgot_c">
      <div className="row justify-content-center m-3">
        <div className="col-xs-6 col-sm-6 col-md-5 col-lg-5  align-self-center">
          <div className="card">
            <article className="card-body">
              <br />
              <br />
              <h4 className="card-title text-center">Modificar contraseña</h4>

              <hr />
              <Form>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <FormGroup>
                      <input
                        name="password"
                        className="form-control"
                        type={"password"}
                        onChange={handleChange}
                        placeholder="Constraseña*"
                      />
                      {errors.password && touched.password ? (
                        <div className="frm_error_c">{errors.password}</div>
                      ) : null}
                    </FormGroup>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <FormGroup>
                      <input
                        name="password_confirmation"
                        className="form-control"
                        type={"password"}
                        onChange={handleChange}
                        placeholder="Repetir Contraseña*"
                      />
                      {errors.password_confirmation &&
                      touched.password_confirmation ? (
                        <div className="frm_error_c">
                          {errors.password_confirmation}
                        </div>
                      ) : null}
                    </FormGroup>
                  </div>
                </div>
                <div className="text-right">
                  <button
                    className="btn btn-blue btn-block"
                    style={{ width: "100%" }}
                    type="submit"
                  >
                    Enviar
                  </button>
                </div>
              </Form>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
};

const EnhancedForm = formikWrapper(PasswordNoLogueado);

export default withRouter(EnhancedForm);
