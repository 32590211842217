import React from "react";

//Dependencies

import { withFormik, Form } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// desde
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faMapMarkerAlt,
    faUserFriends,
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// hasta
import { FormFeedback, Input, FormGroup, Label, CustomInput } from "reactstrap";
import "./customDatePickerWidth.css";
import Modal from "./../../../common/Modal";
import moment from "moment";

library.add(faMapMarkerAlt, faUserFriends, faArrowLeft);

const numerics = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


const DatePickerField = ({ name, value, onChange }) => {
    return (
        <DatePicker
            name={name}
            //selected={(value && new Date(value)) || null}
            selected={value}
            className="form-control"
            placeholderText="Fecha de Nacimiento"
            dateFormat="dd-MM-yyyy"
            showMonthDropdown
            autoComplete="off"
            showYearDropdown
            onChange={val => {
                onChange(name, val);
            }}
        />
    );
};

const formikWrapper = withFormik({
    mapPropsToValues: props => ({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordRepeat: "",
        province: "",
        city: "",
        docType: "1",
        docNumber: "",
        birthDate: new Date("1980/01/01"),
        addressStreet: "",
        addressNumber: "",
        addressFloor: "",
        phoneAreaCode: "",
        phone: "",
        terms: "",
        gender: "",
        handleSubmit: props.handleSubmit,
        showModal: props.showModal,
        modalTitle: props.modalTitle,
        modalText: props.modalText,
        modalBtn: props.modalBtn,
        modalReset: props.modalReset
    }),

    handleSubmit: (values, bag) => bag.props.handleSubmit(values, bag),

    /* const payload = {
           ...values,
           topics: values.topics.map(t => t.value)
       }
       setTimeout(() => {
           alert(JSON.stringify(payload, null, 2))
           setSubmitting(false)
       }, 3000)*/

    validateOnChange: false,
    validateOnBlur: true,
    //validationSchema={RegisterSchema}
    //  ProvinceHandleChange={this.ProvinceHandleChange}
    /* onSubmit: (values, setFieldValue) =>
      this.props.Enviar(values, this.state.imagenTo, setFieldValue),*/
    validationSchema: Yup.object().shape({
        firstName: Yup.string().required("requerido"),
        lastName: Yup.string().required("requerido"),
        email: Yup.string()
            .email("email inválido")
            .required("requerido"),
        password: Yup.string().required("requerido"),
    //.label("password"),
    /* .test("passwords-match", "Passwords must match", function(value) {
        return this.parent.password === value;
      }),*/ passwordRepeat: Yup.string()
            .oneOf([Yup.ref("password"), null], "el password debe coincidir")
            .required("requerido"),
        phoneAreaCode: Yup.number()
            .integer()
            .required("requerido"),
        phone: Yup.number()
            .typeError("el campo debe ser númerico")
            .required("requerido"),
        /* docType: Yup.string().required("requerido"),*/
        docNumber: Yup.string()
            .matches(numerics, "documento inválido")
            .required("requerido"),
        province: Yup.string().required("requerido"),
        city: Yup.string().required("requerido"),
        addressStreet: Yup.string().required("requerido"),
        addressNumber: Yup.number()
            .typeError("el campo debe ser númerico")
            .required("requerido"),

        terms: Yup.boolean()
            .label("terms")
            .test(
                "is-true",
                "debe aceptar los término y condiciones",
                value => value === true
            ),
        birthDate: Yup.date()
            .test("DOB", "la fecha de nacimiento es incorrecta", value => {
                return moment().diff(moment(value), "years") >= 16;
            })
            .max(new Date(), "Fecha de nacimiento incorrecta")
            .required("requerido"),
        gender: Yup.string()
            .label("gender")
            .test("is-true", "debe seleccionar una opción", value =>
                value !== "M" && value !== "F" && value !== "O" ? false : true
            )
    })
});

const RegistroTalentoForm = props => {
    const {
        values,
        handleChange,
        errors,
        touched,
        setFieldValue,
        formSubmitting,
        dirty,
        province,
        docType,
        ProvinceHandleChange,
        cityDisabled,
        city,
        showModal,
        modalTitle,
        modalText,
        modalBtn,
        modalReset,
        path,
        redirect
        //city_id
    } = props;

    return (
        <div>
            {" "}
            <div className="row">
                <div className="decoration"></div>
                <div className="page_container">
                    <div className="card general pt-0" >
                        <article className="card-body" style={{ backgroundColor: "#FAFAFA" }}>
                            {showModal ? (
                                <Modal
                                    modal_title={modalTitle}
                                    modal_text={modalText}
                                    modal_btn={modalBtn}
                                    modalReset={modalReset}
                                    path={path}
                                    redirect={redirect}
                                />
                            ) : (
                                ""
                            )}
                            <h4 className="card-title text-center">
                                <Link to={"/Login"}>
                                    <FontAwesomeIcon
                                        className="arrow_back"
                                        icon={faArrowLeft}
                                        size="1x"
                                    />
                                </Link>
                                Crear Cuenta: Talento
                                <Link to={"/Login"}>
                                    <FontAwesomeIcon
                                        className="arrow_back"
                                        icon={faArrowLeft}
                                        size="1x"
                                        style={{ visibility: "hidden" }}
                                    />
                                </Link>
                            </h4>

                            <Form>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black" >Nombre</label>
                                            <input
                                                name="firstName"
                                                className="form-control"
                                                type={"firstName"}
                                                onChange={handleChange}
                                            />
                                            {errors.firstName && touched.firstName ? (
                                                <div class="frm_error_c">{errors.firstName}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Apellido</label>
                                            <input
                                                name="lastName"
                                                className="form-control"
                                                type={"lastName"}
                                                onChange={handleChange}
                                            />
                                            {errors.lastName && touched.lastName ? (
                                                <div class="frm_error_c">{errors.lastName}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Fecha de nacimiento</label>
                                            <div className="customDatePickerWidth">
                                                <DatePickerField
                                                    name="birthDate"
                                                    value={values.birthDate}
                                                    onChange={setFieldValue}
                                                    className="form-control"
                                                    dateFormat="dd-MM-yyyy"
                                                />
                                                {errors.birthDate && touched.birthDate ? (
                                                    <div class="frm_error_c">{errors.birthDate}</div>
                                                ) : null}
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 text-left radio_gruop_fix">
                                        <FormGroup>
                                            <label className="label_black">Género</label>
                                            <div class="custom-control custom-radio custom-control-inline fix">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    onChange={handleChange}
                                                    id="radioGender1"
                                                    name="gender"
                                                    value="M"
                                                />
                                                <label
                                                    class="custom-control-label"
                                                    for="radioGender1"
                                                >
                                                    M
                                                </label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline fix">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    onChange={handleChange}
                                                    id="radioGender2"
                                                    name="gender"
                                                    value="F"
                                                />
                                                <label
                                                    class="custom-control-label"
                                                    for="radioGender2"
                                                >
                                                    F
                                                </label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline fix">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    onChange={handleChange}
                                                    id="radioGender3"
                                                    name="gender"
                                                    value="O"
                                                />
                                                <label
                                                    class="custom-control-label blue_last"
                                                    for="radioGender3"
                                                >
                                                    O
                                                </label>
                                            </div>
                                        </FormGroup>
                                        {errors.gender && touched.gender ? (
                                            <div class="frm_error_c">{errors.gender}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row">
                                    <label
                                        className="tit_black"

                                    >
                                        Documento
                                    </label>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Tipo</label>
                                            <select
                                                name="docType"
                                                className="form-control"
                                                // component={customInputForm}
                                                onChange={handleChange}
                                                type="select"
                                            >
                                                {/* <option value="">Documento tipo</option>*/}
                                                {Object.keys(docType).map((keyName, i) => (
                                                    <option key={i} value={docType[keyName].id}>
                                                        {docType[keyName].doc_type}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.docType && touched.docType ? (
                                                <div class="frm_error_c">{errors.docType}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Número</label>
                                            <input
                                                name="docNumber"
                                                className="form-control"
                                                type={"docNumber"}
                                                //   component={customInputForm}
                                                onChange={handleChange}
                                            />
                                            {errors.docNumber && touched.docNumber ? (
                                                <div class="frm_error_c">{errors.docNumber}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <label
                                        className="tit_black"

                                    >
                                        Teléfono
                                    </label>
                                    <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                                        <FormGroup>
                                            <label className="label_black">Área</label>
                                            <input
                                                name="phoneAreaCode"
                                                className="form-control"
                                                type={"phoneAreaCode"}
                                                // component={customInputForm}
                                                onChange={handleChange}
                                            />
                                            {errors.phoneAreaCode && touched.phoneAreaCode ? (
                                                <div class="frm_error_c">{errors.phoneAreaCode}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-8 col-sm-8 col-md-4 col-lg-4">
                                        <FormGroup>
                                            <label className="label_black">Número</label>
                                            <input
                                                name="phone"
                                                className="form-control"
                                                type={"phone"}
                                                //  component={customInputForm}
                                                onChange={handleChange}
                                            />
                                            {errors.phone && touched.phone ? (
                                                <div class="frm_error_c">{errors.phone}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Email</label>
                                            <input
                                                name="email"
                                                className="form-control"
                                                type={"email"}
                                                //component={customInputForm}
                                                onChange={handleChange}
                                            />
                                            {errors.email && touched.email ? (
                                                <div class="frm_error_c">{errors.email}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Contraseña</label>
                                            <input
                                                name="password"
                                                className="form-control"
                                                type={"password"}
                                                //component={customInputForm}
                                                onChange={handleChange}
                                            />
                                            {errors.password && touched.password ? (
                                                <div class="frm_error_c">{errors.password}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Repetir contraseña</label>
                                            <input
                                                name="passwordRepeat"
                                                className="form-control"
                                                type={"password"}
                                                // component={customInputForm}
                                                onChange={handleChange}
                                            />
                                            {errors.passwordRepeat && touched.passwordRepeat ? (
                                                <div class="frm_error_c">{errors.passwordRepeat}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        class="tit_black"

                                    >
                                        Dirección
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Provincia</label>
                                            <select
                                                name="province"
                                                className="form-control"
                                                //  component={customInputForm}
                                                onChange={e => {
                                                    ProvinceHandleChange(e, setFieldValue);
                                                }}
                                                type="select"
                                            >
                                                <option value="">Provincia</option>
                                                {Object.keys(province).map((keyName, i) => (
                                                    <option key={i} value={province[keyName].id}>
                                                        {province[keyName].name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.province && touched.province ? (
                                                <div class="frm_error_c">{errors.province}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Ciudad / Barrio</label>
                                            <select
                                                name="city"
                                                className="form-control"
                                                type="select"
                                                disabled={cityDisabled}
                                                //component={customInputForm}
                                                onChange={handleChange}
                                                value={values.province == "" ? "" : values.city}
                                            >
                                                <option value="">Ciudad / Barrio</option>
                                                {Object.keys(city).map((keyName, i) => (
                                                    <option key={i} value={city[keyName].id}>
                                                        {city[keyName].name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.city && touched.city ? (
                                                <div class="frm_error_c">{errors.city}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Calle</label>
                                            <input
                                                name="addressStreet"
                                                className="form-control"
                                                type={"addressStreet"}
                                                //component={customInputForm}
                                                onChange={handleChange}
                                            />
                                            {errors.addressStreet && touched.addressStreet ? (
                                                <div class="frm_error_c">{errors.addressStreet}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Altura</label>
                                            <input
                                                name="addressNumber"
                                                className="form-control"
                                                type={"addressNumber"}
                                                //      component={customInputForm}
                                                onChange={handleChange}
                                            />
                                            {errors.addressNumber && touched.addressNumber ? (
                                                <div class="frm_error_c">{errors.addressNumber}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Piso/Depto</label>
                                            <input
                                                name="addressFloor"
                                                className="form-control"
                                                type={"text"}
                                                //    component={customInputForm}
                                                onChange={handleChange}
                                            />
                                            {errors.addressFloor && touched.addressFloor ? (
                                                <div class="frm_error_c">{errors.addressFloor}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <div
                                                class="custom-control custom-checkbox"
                                                style={{ paddingTop: "22px" }}
                                            >
                                                <input
                                                    name="terms"
                                                    className="custom-control-input"
                                                    type="checkbox"
                                                    // id="terms"
                                                    id="customCheck"
                                                    onChange={handleChange}
                                                // component={customInputForm}
                                                />
                                                <label
                                                    class="custom-control-label terms_c"
                                                    for="customCheck"
                                                >
                                                    <Link to="/Terms" target="_blank">
                                                        <small className="terms">
                                                            Acepto los Términos y Condiciones.
                                                        </small>
                                                    </Link>
                                                </label>
                                            </div>
                                            {errors.terms && touched.terms ? (
                                                <div class="frm_error_c">{errors.terms}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn btn-blue btn-block"
                                        type="submit"
                                        style={{ width: "100%" }}
                                        //   disabled={isSubmitting}
                                        disabled={formSubmitting || !dirty}
                                    >
                                        REGISTRARME
                                    </button>
                                </div>
                            </Form>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
};
const EnhancedForm = formikWrapper(RegistroTalentoForm);
export default EnhancedForm;
