import React, { Component } from "react";
import axios from "axios";
import { url_base } from "../../../../api_url";
import auth from "../../../../auth";
import HistorialMatcheosScreen from "./HistorialMatcheosScreen";
import { trackPromise } from "react-promise-tracker";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class HistorialMatcheos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idOffer: "",
            idTalent: "",
            studies: [],
            experiences: [],
            skills: [],
            languages: [],
            data: [],
            divShow: false,
            pageSelected: ""
        };
        this.selectTalent = this.selectTalent.bind(this);
        this.modalReset = this.modalReset.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.reloadPage = this.reloadPage.bind(this);
        this.qualifications = this.qualifications.bind(this);
    }

    qualifications(qualifications) {
        let result = "";
        let value_1 = "";
        let value_2 = "";
        let noQualifications = "";

        if (qualifications[0] !== undefined && qualifications[1] !== undefined) {
            let qualificationsResult =
                Number(qualifications[0].value) + Number(qualifications[1].value);
            // console.log("Calificación 1 " + qualifications[0].value + " Calificación 2 " + qualifications[1].value);
            if (Number(qualificationsResult) == 2) {
                value_1 = faThumbsUp;
                value_2 = faThumbsUp;
            } else if (Number(qualificationsResult) == 1) {
                value_1 = faThumbsUp;
                value_2 = faThumbsDown;
            } else if (Number(qualificationsResult) == 0) {
                value_1 = faThumbsDown;
                value_2 = faThumbsDown;
            }
        }
        else {
            noQualifications = 1;
        }

        result = (
            <>
                {Number(noQualifications) !== Number(1) ?
                    <>
                        {"Calificación: "}
                        < FontAwesomeIcon
                            icon={value_1}
                            className="text-primary"
                            size="1x"
                            style={{ paddingLeft: "3px" }}
                        />
                        <FontAwesomeIcon
                            icon={value_2}
                            className="text-primary"
                            size="1x"
                            style={{ paddingLeft: "3px" }}
                        />
                    </> : <> {""} </>}
            </>
        );

        return result;
    }

    reloadPage() {
        if (
            auth.isAuthenticated() === true &&
            localStorage.getItem("userType") == 3
        ) {
            const access_token_data = localStorage.getItem("token");

            const key = this.props.location.pathname.split("/");

            let keyIdPage = "";
            if (key[2] != "" && key[2] !== undefined && key[2] !== null) {
                keyIdPage = key[2];
            } else {
                keyIdPage = 1;
            }

            trackPromise(
                axios
                    .get(
                        url_base + "/talents/jobOffers/applications-history/" + keyIdPage,
                        {
                            headers: {
                                ["Authorization"]: "Bearer " + access_token_data
                            }
                        }
                    )
                    .then(
                        response => {
                            const data = response.data.data.jobApplications;
                            const cantPages = response.data.data.cantPages;
                            /*     console.log(data);
                               this.setState({
                                 data: data
                               });*/
                            if (localStorage.getItem("accountState") == 1) {
                                //Chequeo que el usuario tenga Matchs
                                if (data == "" || data == undefined) {
                                    this.setState({
                                        divShow: true
                                    });
                                } else {
                                    this.setState({
                                        data: data,
                                        /* cantPages: cantPages,
                                        divShow: false,*/
                                        pageSelected: keyIdPage
                                    });
                                }
                            } else if (localStorage.getItem("accountState") == 3) {
                                this.setState({
                                    divShow: true,
                                    showModal: true,
                                    modalTitle: "AVISO",
                                    modalText:
                                        "Tu entrevista esta pendiente. Nos vamos a comunicar para agendarla",
                                    modalBtn: "Aceptar",
                                    //  path: path,
                                    redirect: true
                                    //   talent_offer_status
                                });
                            }
                        },
                        error => {
                            var status = error.response;
                            //console.log(status);
                        }
                    )
            );
        } else {
            this.props.history.push("/Login");
        }
    }

    handleChangePage(id) {
        this.props.history.push("/HistorialMatcheos/" + id);
        const access_token_data = localStorage.getItem("token");

        axios
            .get(url_base + "/talents/jobOffers/applications-history/" + id, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = response.data.data.jobApplications;
                    const cantPages = response.data.data.cantPages;
                    //   console.log(data);
                    this.setState({
                        data: data,
                        cantPages: cantPages,
                        pageSelected: id
                    });
                },
                error => {
                    var status = error.response;
                }
            );
    }

    modalReset() {
        this.setState({ showModalInfo: false });
    }

    selectTalent(event, idOffer, idTalent) {
        event.preventDefault();
        const access_token_data = localStorage.getItem("token");
        //alert("idOffer" + idOffer + "idTAlent" + idTalent);
        axios
            .patch(
                url_base +
                "/companies/job-searches/select-talent/" +
                idOffer +
                "/" +
                idTalent,
                "",
                {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                }
            )
            .then(
                response => {
                    //console.log(response);

                    const data = response.data;
                },
                error => {
                    var status = error.response;
                    // console.log(status);
                }
            );
    }

    componentDidMount() {
        if (
            auth.isAuthenticated() === true &&
            localStorage.getItem("userType") == 3
        ) {
            const access_token_data = localStorage.getItem("token");

            const key = this.props.location.pathname.split("/");

            let keyIdPage = "";
            if (key[2] != "" && key[2] !== undefined && key[2] !== null) {
                keyIdPage = key[2];
            } else {
                keyIdPage = 1;
            }


            //  console.log(access_token_data);
            //chequeo si el usuario fue habilitado
            axios.get(url_base + "/users/logged", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
                .then(
                    response => {
                        const userstate_id = response.data.data.user.userstate_id;
                        //     console.log("data " + JSON.stringify(userstate_id));
                        localStorage.setItem("accountState", userstate_id)
                    },

                    // localStorage.setItem("accountState", 1)},
                    error => {
                        var status = error.response;
                    }

                );

            trackPromise(
                axios
                    .get(
                        url_base + "/talents/jobOffers/applications-history/" + keyIdPage,
                        {
                            headers: {
                                ["Authorization"]: "Bearer " + access_token_data
                            }
                        }
                    )
                    .then(
                        response => {
                            const data = response.data.data.jobApplications;
                            const cantPages = response.data.data.cantPages;

                            if (localStorage.getItem("accountState") == 1) {
                                //Chequeo que el usuario tenga Matchs
                                if (data == "" || data == undefined) {
                                    this.setState({
                                        divShow: true
                                    });
                                } else {
                                    this.setState({
                                        data: data,
                                        cantPages: cantPages,
                                        divShow: false,
                                        pageSelected: keyIdPage
                                    });
                                }
                            }// else if (localStorage.getItem("accountState") == 3) {
                            else {
                                this.setState({
                                    divShow: true,
                                    showModal: true,
                                    modalTitle: "AVISO",
                                    modalText:
                                        "Tu entrevista esta pendiente. Nos vamos a comunicar para agendarla",
                                    modalBtn: "Aceptar",
                                    //  path: path,
                                    redirect: true
                                    //   talent_offer_status
                                });
                            }
                        },
                        error => {
                            var status = error.response;
                            //console.log(status);
                        }
                    )
            );
        } else {
            this.props.history.push("/Login");
        }
    }

    render() {
        return (
            <HistorialMatcheosScreen
                data={this.state.data}
                showModal={this.state.showModal}
                modalTitle={this.state.modalTitle}
                modalText={this.state.modalText}
                modalBtn={this.state.modalBtn}
                modalReset={this.modalReset}
                redirect={this.state.redirect}
                path={this.state.path}
                divShow={this.state.divShow}
                pageSelected={this.state.pageSelected}
                data={this.state.data}
                cantPages={this.state.cantPages}
                handleChangePage={this.handleChangePage}
                reloadPage={this.reloadPage}
                qualifications={this.qualifications}
            />
        );
    }
}
export default HistorialMatcheos;
