import React from "react";
import ReactDOM from "react-dom";
//import "./index.css";
import App from "./App";
import { unregister as unregisterServiceWorker } from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/style.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import "./../node_modules/@fortawesome/free-solid-svg-icons";
//import "./../../../node_modules/@fortawesome/fontawesome-common-types";
import "./../node_modules/@fortawesome/fontawesome-svg-core";
//import { createStore, applyMiddleware } from "redux";
/*import { Provider } from "react-redux";
import store from './store';*/
/*import rootReducer from './rootReducers/index';
import { apiMiddleware } from 'redux-api-middleware';
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'*/
//import rootReducer from './reducer';
//import Reducer from './reducers/rootReducer'
/*const initialState = {
    pending: false,
    data: [],
    error: null,
    action:""
}*/

/*const initialState = {
    data: "", action: ""
};*/
/*
function reducer(state = initialState, action) {
    switch (action.type) {
        case "INCREMENT":
            return {
                data: action.data
            }
            console.log(state);
        default: return state;

    }
}
const store = createStore(reducer);*/
//store.dispatch({ type: "INCREMENT", data: "Bahia Blanca" });

//const thunkMiddleware = [thunk];

//const store = createStore(rootReducer, initialState, applyMiddleware(...middlewares));
/*const store = createStore(
    rootReducer,
    applyMiddleware(thunkMiddleware, logger)
  )*/
//const store = createStore(rootReducer);

//ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById("root"));4
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregisterServiceWorker();
