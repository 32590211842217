import React from "react";
import { Formik, Field, Form, withFormik } from "formik";
import * as Yup from "yup";
import { FormFeedback, Input, FormGroup, Button } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import { url_base } from "./../../api_url";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faMapMarkerAlt,
  faUserFriends,
  faMoneyBillWave,
  faCalendarAlt,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const formikWrapper = withFormik({
  mapPropsToValues: props => ({
    password: "",
    password_confirmation: ""
  }),

  initialValues: { password: "", password_confirmation: "" },
  validateOnChange: false,
  validateOnBlur: true,
  validationSchema: Yup.object().shape({
    password_confirmation: Yup.string()
      .required("requerido")

      .label("confirmar contraseña")
      .test("passwords-match", "la contraseña debe coincidir", function(value) {
        return this.parent.password === value;
      }),
    password: Yup.string()
      .oneOf([Yup.ref("password"), null], "la  contraseña debe coincidir")
      .min(8, "el password es demasiado corto!")
      .max(32, "el password es demasiado largo!")
      .required("requerido")
  }),

  // validationSchema: validationSchema,
  handleSubmit: (values, { props }) => {
    //  console.log(values);
    const access_token_data = localStorage.getItem("token");
    axios
      .patch(url_base + "/change-password-user", values, {
        headers: {
          ["Authorization"]: "Bearer " + access_token_data
        }
      })
      .then(response => {
        //console.log(response);
        /*let path = `/DatosPersonalesText/`;
        props.history.push(path);*/
        props.history.goBack();
      })
      .catch();
  }
});
const CambiarPassword = props => {
  const { handleChange, errors, touched } = props;
  return (
    <div className="row justify-content-center m-3">
      <div className="col-xs-6 col-sm-6 col-md-5 col-lg-5  align-self-center">
        <div className="card">
          <article className="card-body">
            <Link onClick={() => props.history.goBack()}>
              <FontAwesomeIcon
                className="arrow_back"
                icon={faArrowLeft}
                size="1x"
              />
            </Link>
            <br />
            <br />
            <h4 className="card-title text-center">Modificar contraseña</h4>

            <hr />
            <Form>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <FormGroup>
                    <input
                      name="password"
                      className="form-control"
                      type={"password"}
                      onChange={handleChange}
                      placeholder="Constraseña*"
                    />
                    {errors.password && touched.password ? (
                      <div className="frm_error_c">{errors.password}</div>
                    ) : null}
                  </FormGroup>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                  <FormGroup>
                    <input
                      name="password_confirmation"
                      className="form-control"
                      type={"password"}
                      onChange={handleChange}
                      placeholder="Repetir Contraseña*"
                    />
                    {errors.password_confirmation &&
                    touched.password_confirmation ? (
                      <div className="frm_error_c">
                        {errors.password_confirmation}
                      </div>
                    ) : null}
                  </FormGroup>
                </div>
              </div>
              <div className="text-right">
                <button
                  className="btn btn-primary btn-block"
                  type="submit"
                  style={{ width: "100%" }}
                >
                  Enviar
                </button>
              </div>
            </Form>
          </article>
        </div>
      </div>
    </div>
  );
};

const EnhancedForm = formikWrapper(CambiarPassword);

export default withRouter(EnhancedForm);
