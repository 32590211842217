import React from "react";
//import "./script";
import "./../../../style.css";
//import $ from "jquery";
import { Link, withRouter } from "react-router-dom";
import { withFormik, Form } from "formik";
import {
    faPlus,
    faChevronRight,
    faChevronDown,
    faCheck,
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormFeedback, Input, FormGroup, Label, CustomInput } from "reactstrap";
import axios from "axios";
import { url_base } from "./../../../../api_url";

const data = localStorage;

const formikWrapper = withFormik({
    mapPropsToValues: props => ({
        skillsCategory: data.skillsCategory || [],
        skillsSubcategory: data.skillsSubcategory || [],
        skills: props.skills || []
    }),
    handleSubmit: (values, { props }) => {
        const access_token_data = localStorage.getItem("token");

        // values = localStorage;

        axios
            .post(url_base + "/talents/skills/add", values, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {
                if (response.data.result == 1) {
                    /*      let path = `/PerfilText`;
                    console.log("path" + path);
                    props.history.push(path);*/
                } else {
                    // alert(response.data.message);
                }
            })
            .catch();
    }
});

const AgregarCategoriaScreen = props => {
    const {
        values,
        skillsCategory,
        skillsSubcategory,
        data,
        skills,
        handleClick,
        setFieldValue,
        saveformStorage,
        disabledSkillsbutton,
        skillsSubcategoryHandleChange,
        skillsSubcategoryID,
        deteleSkill
    } = props;

    return (
        <div>
            <div className="row justify-content-center">
                <div className="decoration"></div>
                <div className="page_container">
                    <div className="card">
                        <article className="card-body">
                            <h4 className="card-title text-center text-primary">
                                <Link to={"/PerfilText"}>
                                    <FontAwesomeIcon
                                        className="arrow_back"
                                        icon={faArrowLeft}
                                        size="1x"
                                    />
                                </Link>
                                Aptitudes
                            </h4>
                            <h6 className="card-title text-center text-primary">
                                Eilge hasta 5 subcategorías en total.
                            </h6>
                            <hr />
                            <Form>
                                <div className="row aptitudes_edit">
                                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <p className="text-left">
                                            <a
                                                className="btn btn-primary"
                                                data-toggle="collapse"
                                                href="#CollapseCategory1"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="CollapseCategory1"
                                            >
                                                Categoría
                                            </a>
                                        </p>
                                    </div>

                                    <div
                                        className="collapse show multi-collapse"
                                        id="CollapseCategory1"
                                    >
                                        <div className="row p-0 m-0">
                                            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        {skillsCategory &&
                                                            Object.keys(skillsCategory).map((keyName, i) => (
                                                                <button
                                                                    id={"category_" + skillsCategory[keyName].id}
                                                                    onClick={e =>
                                                                        handleClick(
                                                                            e,
                                                                            setFieldValue,
                                                                            skillsCategory[keyName].id
                                                                        )
                                                                    }
                                                                    className="list-group-item list-group-item-action list-group-item-light category"
                                                                >
                                                                    <div className="name">
                                                                        {skillsCategory[keyName].category}
                                                                    </div>

                                                                    <FontAwesomeIcon
                                                                        className="icon"
                                                                        icon={faCheck}
                                                                        size="1x"
                                                                    />
                                                                </button>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row aptitudes_edit">
                                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <p className="subcategoria_title">Sub-categorías</p>
                                    </div>

                                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="form-group">
                                            <div className="input-group">
                                                {skillsCategory &&
                                                    Object.keys(skillsSubcategory).map((keyName, i) => (
                                                        <button
                                                            id={
                                                                "subcategory_" + skillsSubcategory[keyName].id
                                                            }
                                                            onClick={e =>
                                                                skillsSubcategoryHandleChange(
                                                                    e,
                                                                    setFieldValue,
                                                                    skillsSubcategory[keyName].id,
                                                                    skillsSubcategory[keyName].category_id
                                                                )
                                                            }
                                                            // onBlur={e => e.preventDefault}
                                                            className="list-group-item list-group-item-action list-group-item-light add subcategory"
                                                        >
                                                            {" "}
                                                            {skillsSubcategory[keyName].subcategory}
                                                            <FontAwesomeIcon
                                                                className="icon"
                                                                icon={faCheck}
                                                                size="1x"
                                                            />
                                                        </button>
                                                    ))}
                                            </div>
                                        </div>
                                        <div className="aptitudes_requeridas">
                                            {skills &&
                                                Object.keys(skills).map((keyName, i) => (
                                                    <>
                                                        <span className="badge badge-pill badge-primary m-1 busqueda_activa_aptitud habilidades">
                                                            <a
                                                                className="close"
                                                                onClick={e =>
                                                                    deteleSkill(
                                                                        e,
                                                                        skills[keyName].id,
                                                                        setFieldValue,
                                                                        values
                                                                    )
                                                                }
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                &times;
                                                            </a>
                                                            <div
                                                                style={{
                                                                    marginTop: "5px",
                                                                    float: "left",
                                                                    marginRight: "5px"
                                                                }}
                                                            >
                                                                {" "}
                                                                {skills[keyName].skill}
                                                            </div>
                                                        </span>
                                                    </>
                                                ))}
                                        </div>
                                    </div>

                                    {values.skillsSubcategory > 0
                                        ? (
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <FormGroup className="agregar_habilidad">
                                                    <Link
                                                        style={{
                                                            pointerEvents: disabledSkillsbutton
                                                        }}
                                                        className="arrow_right"
                                                        // onClick={() => saveformStorage(values)}
                                                        to={"/AgregarAptitudesTalento/" + skillsSubcategoryID}
                                                    >
                                                        Agregar habilidades{" "}
                                                        <FontAwesomeIcon icon={faChevronRight} size="1x" />
                                                    </Link>
                                                </FormGroup>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                </div>
                            </Form>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
};
const EnhancedForm = formikWrapper(AgregarCategoriaScreen);
export default withRouter(EnhancedForm);
