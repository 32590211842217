import React, { Component } from "react";
import AgregarAptitudes from "./AgregarAptitudesTalento";
import { withFormik, ErrorMessage, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { FormFeedback, Input, FormGroup, Label, CustomInput } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { url_base } from "./../../../../api_url";

const InputFeedback = ({ error }) =>
    error ? <FormFeedback>{error}</FormFeedback> : null;

const formikWrapper = withFormik({
    // enableReinitialize: false,
    mapPropsToValues: props => ({ checkboxGroup: props.checkboxGroup || "" }),
    handleSubmit: (values, bag) => bag.props.handleSubmit(values),
    validationSchema: Yup.object().shape({
        checkboxGroup: Yup.array().required("debe seleccionar una opción.")
    })
});

const AgregarAptitudesTalentoScreen = props => {
    const { skills, handleSubmit, values, error, touched, errors } = props;
    return (
        <div>
            {" "}
            <Form onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                    <div className="decoration"></div>
                    <div className="page_container">
                        <div className="card">
                            <article className="card-body">
                                <h4 className="card-title text-center">
                                    <Link to={"/AgregarCategoria"}>
                                        <FontAwesomeIcon
                                            className="arrow_back"
                                            icon={faArrowLeft}
                                            size="1x"
                                        />
                                    </Link>
                                    APTITUDES
                                </h4>
                                <div className="row justify-content-end">
                                    <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 ">
                                        {touched && <InputFeedback error={error} />}
                                        <InputFeedback />
                                    </div>
                                </div>

                                <ul className="list-group aptitudes_add">
                                    {skills.map(item => (
                                        <label key={item.id}>
                                            <li className="list-group-item">
                                                <div className="checkbox">
                                                    <FieldArray
                                                        name="checkboxGroup"
                                                        render={checkboxGroup => (
                                                            <Input
                                                                // className="align-self-end"
                                                                name="checkboxGroup"
                                                                type="checkbox"
                                                                id={item.id}
                                                                // name="checkboxGroup"
                                                                //value={values.n}

                                                                value={item.id}
                                                                //checked={skills}//values.checkboxGroup.includes(item.id)}
                                                                onChange={e => {
                                                                    if (e.target.checked) {
                                                                        checkboxGroup.push(item.id);
                                                                        //setFieldValue("checkboxGroup", item.id);
                                                                    } else {
                                                                        const idx = values.checkboxGroup.indexOf(
                                                                            item.id
                                                                        );
                                                                        checkboxGroup.remove(idx);
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <label>{item.skill}</label>
                                                </div>
                                            </li>
                                        </label>
                                    ))}
                                </ul>
                                {errors.checkboxGroup && touched.checkboxGroup ? (
                                    <div class="frm_error_c" style={{ marginBottom: "1rem" }}>
                                        {errors.checkboxGroup}
                                    </div>
                                ) : null}
                                <div className="form-group">
                                    <button
                                        className="btn btn-primary btn-block"
                                        type="submit"
                                        //  disabled={isSubmitting}
                                        //onClick={values => handleSubmitValue(values)}
                                        style={{ width: "100%" }}
                                    >
                                        AGREGAR HABILIDADES
                                    </button>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

const EnhancedForm = formikWrapper(AgregarAptitudesTalentoScreen);
//export default EnhancedForm;
export default withRouter(EnhancedForm);
