import React, { Component } from "react";
import $ from "jquery";
import "./../../Styles/general.css";
import { withRouter } from "react-router-dom";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { classMsg: true };
    this.onModalBtnClick = this.onModalBtnClick.bind(this);
  }
  componentDidMount() {
    // $(".modal_msg").fadeIn(400);
    this.setState({ classMsg: true });
  }

  onModalBtnCancelClick(){
    this.props.modalReset();
  }

  onModalBtnClick() {
    
   if ( this.props.closeModal){
    this.props.closeModal();}
    this.setState({ classMsg: false });
    this.props.modalReset();
    
   
    if (this.props.redirect) {
      this.props.history.push(this.props.path);
    }
  }

  render() {
    return (
      <div>
        <div className={this.state.classMsg ? "modal_show" : "modal_hide"}>
          <div className="modal_background"></div>

          <div className="modal_content">
            <div className="modal_title">{this.props.modal_title}</div>
            <div className="modal_text">{this.props.modal_text}</div>
            <button
              className="modal_btn"
              onClick={() => this.onModalBtnClick()}
            >
              {this.props.modal_btn}
            </button>

           
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Modal);
