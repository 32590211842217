import React, { Component } from "react";
import DatosPersonalesText from "./DatosPersonalesText";
import axios from "axios";
import { url_base } from "./../../../../api_url";
import moment from "moment";
import auth from "../../../../auth";

class DatosPersonales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // data: []
            firstName: "",
            lastName: "",
            province: [],
            cityState: [],
            cityDisabled: "",
            phoneAreaCode: "",
            id: "",
            password: "",
            passwordRepeat: "",
            city: "",
            cityList: "",
            provinceList: "",
            userEmail: "",
            birthDate: "",
            docTypeList: "",
            docType: "",
            docNumber: "",
            address_street: "",
            address_floor: "",
            gender: ""
        };
        //  this.nameHandleChange = this.nameHandleChange.bind(this);
        this.ProvinceHandleChange = this.ProvinceHandleChange.bind(this);
        this.select = this.select.bind(this);
        this.cityHandleChange = this.cityHandleChange.bind(this);
        this.docTypeHandleChange = this.docTypeHandleChange.bind(this);
        this.datepickerHandleChange = this.datepickerHandleChange.bind(this);
    }

    select(idx) {
        if (this.state.id === idx) {
            let select = "selected";
            return select;
        }
    }

    datepickerHandleChange(e, setFieldValue) {
        setFieldValue(e.target.name, e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }

  /* nameHandleChange(e, setFieldValue) {
    console.log(e.target.name + " " + e.target.value);
    setFieldValue(e.target.name, e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  }

  */ cityHandleChange(
        e,
        setFieldValue
    ) {
        setFieldValue("city", e.target.value);
        this.setState({
            city: e.target[e.target.selectedIndex].value
        });
        // console.log("id ciudad: " + e.target[e.target.selectedIndex].value);
    }

    docTypeHandleChange(e, setFieldValue) {
        setFieldValue("docType", e.target.value);
        this.setState({
            docType: e.target[e.target.selectedIndex].value
        });
    }

    ProvinceHandleChange(e, setFieldValue) {
        //  console.log("id provincia: " + e.target[e.target.selectedIndex].value);

        setFieldValue("province", e.target.value);
        this.setState({
            province: e.target[e.target.selectedIndex].value
        });

        if (e.target[e.target.selectedIndex].value != "") {
            this.setState({
                cityDisabled: ""
            });
        } else {
            this.setState({
                cityDisabled: "disabled"
            });
        }
        const idProvince = e.target[e.target.selectedIndex].value;
        //  console.log("idProvince" + idProvince);

        axios
            .get(
                url_base + "/province-cities/" + e.target[e.target.selectedIndex].value
            )
            .then(response => {
                /*   console.log(response.data.data.cities);
                console.log("id ciudad: " + response.data.data.cities[0].id);*/

                this.setState({
                    cityList: response.data.data.cities,
                    city: response.data.data.cities[0].id
                });
            })
            .catch();
    }
    componentDidMount() {
        if (
            auth.isAuthenticated() === true &&
            localStorage.getItem("userType") == 3
        ) {
            const access_token_data = localStorage.getItem("token");

            axios
                .get(url_base + "/talents/profile", {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                })
                .then(
                    response => {
                        const data = response.data.data.talent;
                        /*   console.log("data: " + data);
                        console.log(data);*/
                        this.setState({
                            // data: data.first_name
                            talentImage: data.photo,
                            firstName: data.first_name,
                            province: data.provinceId,
                            lastName: data.last_name,
                            phoneAreaCode: data.phone_area_code,
                            phone: data.phone,
                            userEmail: data.userEmail,
                            city: data.city_id,
                            docType: data.doctype_id,
                            docNumber: data.doc_number,
                            password: data.password,
                            passwordRepeat: data.password_repeat,
                            addressStreet: data.address_street,
                            addressNumber: data.address_number,
                            addressFloor: data.address_floor,
                            birthDate: data.birthdate,
                            gender: data.gender

                            //province: data.provinceId
                        });

                        /*console.log("fecha nacimiento: " + data.birthdate);
            
                        console.log(data.provinceId);*/

                        axios
                            .get(url_base + "/province-cities/" + data.provinceId)
                            .then(response => {
                                //   console.log(response.data.data.cities);
                                this.setState({
                                    cityList: response.data.data.cities
                                });
                            })
                            .catch();
                    },
                    error => {
                        var status = error.response;
                        //  console.log(status);
                    }
                );

            //cargo los tipo de documento
            axios
                .get(url_base + "/document-types")
                .then(response => {
                    //   console.log(response.data.data.docTypes);
                    this.setState({
                        docTypeList: response.data.data.docTypes
                    });
                    /*    console.log(response.data.data.provinces);
                      this.props.dispatch({ type: "INCREMENT", data: response.data.data.provinces });*/
                })
                .catch();

            //cargo las provincias
            axios
                .get(url_base + "/provinces")
                .then(response => {
                    // console.log(response.data.data.provinces);
                    this.setState({
                        provinceList: response.data.data.provinces
                    });
                })
                .catch();

        } else {
            this.props.history.push("/Login");
        }
    }
    render() {
        return (
            <DatosPersonalesText
                ProvinceHandleChange={this.ProvinceHandleChange}
                categoryHandleChange={this.categoryHandleChange}
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                phoneAreaCode={this.state.phoneAreaCode}
                //  nameHandleChange={this.nameHandleChange}
                talentImage={this.state.talentImage}
                phone={this.state.phone}
                provinceList={this.state.provinceList}
                // city={this.state.cityState}
                province={this.state.province}
                select={this.select}
                cityList={this.state.cityList}
                city={this.state.city}
                cityHandleChange={this.cityHandleChange}
                password={this.state.password}
                passwordRepeat={this.state.passwordRepeat}
                userEmail={this.state.userEmail}
                birthDate={this.state.birthDate}
                docType={this.state.docType}
                docTypeList={this.state.docTypeList}
                docTypeHandleChange={this.docTypeHandleChange}
                docNumber={this.state.docNumber}
                addressStreet={this.state.addressStreet}
                addressNumber={this.state.addressNumber}
                addressFloor={this.state.addressFloor}
                gender={this.state.gender}
                datepickerHandleChange={this.datepickerHandleChange}
            />
        );
    }
}

export default DatosPersonales;
