import React, { Component } from "react";
import { Link } from "react-router-dom";
import { NavBarBase, NavBarOptions, NavBarIcons, NavBarOptionsContent, NavBarContainerLogoOptions } from "./common/Navbar/style";
import Hamburger from "./common/Hamburger";
import "../Styles/general.css";



/*
  Recibe:
  - Array de objetos, siendo cada objeto un item del nav, con su sección correspondiente a scrollear (o la ruta a la cual redirigir)
*/

export default class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrolled: false,
            hide: false,
            mobile: false,
            isActive: false
        };
    }

    componentWillUnmount() {
        // Remover event listeners
        window.removeEventListener("scroll", this.scrollEventHandler);
    }

    componentDidMount() {
        // Scroll setup
        this.prev = window.scrollY;
        this.scrollEventHandler = e => this.handleScroll(e);
        window.addEventListener("scroll", this.scrollEventHandler);
        window.scroll();
    }

    handleScroll(e) {
        const { scrolled } = this.state;
        const window = e.currentTarget;

        // Detect if scrolling up or down
        /*   if (this.prev > window.scrollY && hide) {
               // Scrolling up...
               this.setState({ hide: false });
           } else if (this.prev < window.scrollY && !hide) {
               // Scrolling down...
               this.setState({ hide: true });
           }*/

        this.prev = window.scrollY;

        // Detect if on top of the webpage
        if (window.scrollY === 0 && scrolled) {
            // On top of webpage...
            this.setState({ scrolled: false });
        } else if (!scrolled) {
            this.setState({ scrolled: true });
        }
    }

    setLottieRef(node) {
        this.lottieRef = node;
    }


    renderOptions() {
        const { options } = this.props;

        if (!Array.isArray(options) || options.length === 0) return [];

        return options.map((option, index) => {
            const { name, link } = option;
            const linkToRender =
                link[0] === "#" ? (

                    <Link
                        to={link.substr(1)}
                        activeClass="navigation-active"
                        spy={true}
                        smooth={true}
                        offset={-10}
                        duration={900}
                        onClick={() => this.setState({ isActive: false })}
                    >
                        {name}

                    </Link>




                ) : (
                    // <a href={link}>{name}</a>

                    <Link
                        to={{ pathname: "/" + link }}
                        // onClick={this.collapseMenu}
                        //className="nav-link"
                        onClick={() => this.setState({ isActive: false })}
                        className="ul-list-menu"
                    >
                        {name}
                    </Link>
                );

            return <li key={index}>{linkToRender}</li>;
        });
    }

    render() {
        const { scrolled, hide, mobile, isActive } = this.state;
        const shouldHide = !isActive && hide;

        return (
            <NavBarBase scrolled={scrolled} hide={shouldHide} mobile={mobile} className="shadow-sm mb-2">
                <NavBarOptionsContent>
                    <NavBarIcons>
                        <img
                            src={scrolled ? this.props.logoImg : this.props.logoImg}
                            alt="Credicuotas" />
                    </NavBarIcons>
                    <NavBarContainerLogoOptions>
                        <NavBarOptions scrolled={scrolled} isActive={isActive} >

                            {/*   <ul id="navigation-options">{this.renderOptions()} <li><a href="https://clientes.credicuotas.com.ar/#/adelantos/thirdStep/OTHER" onClick={() => this.setState({ isActive: false })} target="_blank" style={{ textDecoration: "none" }}><ButtonNavbarMenu isActive={isActive} onClicked="">Simulá tu préstamo</ButtonNavbarMenu></a></li></ul> */}

                            <ul id="navigation-options" className="container-list-menu" >{this.renderOptions()}  <li><a onClick={() => this.setState({ isActive: false })} style={{ textDecoration: "none" }}></a></li></ul>
                        </NavBarOptions>
                        <NavBarIcons>
                            {/*   <ButtonNavbar>¡Pedir ya!</ButtonNavbar> */}
                            <a href={process.env.REACT_APP_PATH_HOME_LOGIN} rel="noopener noreferrer" style={{ textDecoration: "none" }}></a>
                        </NavBarIcons>
                        <NavBarOptions scrolled={scrolled} isActive={isActive} >
                            <Hamburger
                                onClick={() =>
                                    this.setState(prevState => ({ isActive: !prevState.isActive }))
                                }
                                scrolled={scrolled}
                                isActive={isActive}

                            />

                        </NavBarOptions>
                    </NavBarContainerLogoOptions>
                </NavBarOptionsContent>
            </NavBarBase >
        );
    }
}