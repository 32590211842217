import React, { Component } from "react";

import axios from "axios";
import { url_base } from "../../../../api_url";
import AgregarAptitudesTalentoScreen from "./AgregarAptitudesTalentoScreen";

class AgregarAptitudesTalento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            skills: [],
            arrayHelpers: [],
            checks: [],
            skillsProfile: "",
            arraySkills: []
        };
        // this.handleSubmitValue = this.handleSubmitValue.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(values, props) {
        // console.log("data: "  + values.checkboxGroup);

        const access_token_data = localStorage.getItem("token");

        let data = values.checkboxGroup;

        // values = localStorage;

        const val = { skills: data };

        //  console.log(JSON.stringify(val));

        axios
            .post(url_base + "/talents/skills/add", val, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    //  if (response.data.result == 1) {

                    let path = `/AgregarCategoria`;
                    this.props.history.push(path);

                    this.setState({ error: response.data.result });
                },
                error => {
                    this.setState({ error: error.response.data.message });
                }
            );
    }

    componentDidMount() {
        const key = this.props.location.pathname.split("/");
        const access_token_data = localStorage.getItem("token");
        const checks = localStorage.getItem("skills");

        this.setState({ checks: checks });

        axios
            .get(url_base + "/talents/skills/not-assigned/" + key[2], {
                headers: { ["Authorization"]: "Bearer " + access_token_data }
            })
            .then(
                response => {
                    const skills = response.data.data.skills;
                    // console.log("skills " + JSON.stringify(skills));
                    this.setState({
                        skills: skills
                    });
                },
                error => {
                    var status = error.response;
                }
            );

        axios
            .get(url_base + "/talents/profile", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = response.data.data.talent.skills;

                    //skillsProfile = skillsProfile.objec;

                    Object.keys(data).map((keyName, i) => {
                        this.setState({
                            arraySkills: this.state.arraySkills.concat(data[keyName].id)
                        });
                        //   console.log(" arraySkills" + data[keyName].id);
                    });

                    this.setState({
                        skillsProfile: data
                    });
                },
                error => {
                    var status = error.response;
                    //  console.log(status);
                }
            );
    }

    render() {
        return (
            <AgregarAptitudesTalentoScreen
                skills={this.state.skills}
                handleSubmitValue={this.handleSubmitValue}
                arrayHelpers={this.state.arrayHelpers}
                checkboxGroup={this.state.checks}
                skillsProfile={this.state.skillsProfile}
                arraySkills={this.state.arraySkills}
                handleSubmit={this.handleSubmit}
                error={this.state.error}
            />
        );
    }
}
export default AgregarAptitudesTalento;
