import React, { useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { withRouter } from "react-router-dom";
import "./../../../style.css";

//Assets
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faMapMarkerAlt,
    faUserFriends,
    faMoneyBillWave,
    faCalendarAlt,
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Modal from "./../../../common/Modal";

library.add(faMapMarkerAlt, faUserFriends);

function DifferenceInDaysEndingNumber(firstDate) {
    var today = new Date();
    var todayDate = moment(today).format("YYYY-MM-DD");
    var endDate = moment(firstDate).format("YYYY-MM-DD");
    // var difference = moment(endDate).diff(todayDate, "days");
    var difference = moment(todayDate).diff(endDate, "days");
    ///var day = difference > "1" || difference < "-1" ? "días" : "día";

    return difference;
}

const MatchsText = props => {
    const {
        min,
        data,
        company,
        handleSubmitValue,
        handleSubmitReject,
        handleSubmitAcceptSelection,
        handleSubmitRejectSelection,
        showModal,
        modalTitle,
        modalText,
        modalBtn,
        modalReset,
        path,
        redirect,
        offerState,
        application,
        accountState,
        valueMinPayment,
        pageRedirect,
        keyPage
    } = props;
    const [value = Math.round(valueMinPayment * data.duration_unit_quantity), setValue] = useState(min);
    return (
        <div>
            <div className="row mr-0 ml-0 h-100">
                <div className="decoration"></div>
                <div className="page_container  h-100">
                    <div className="card h-100" style={{ backgroundColor: "#FAFAFA", minHeight: "100vh" }} >
                        <article className="card-body general  h-100" >
                            <h4 className="card-title text-center">
                                <a onClick={pageRedirect}>
                                    <FontAwesomeIcon
                                        className="arrow_back"
                                        icon={faArrowLeft}
                                        size="1x"
                                    />
                                </a>
                                Detalle
                                <FontAwesomeIcon
                                    className="arrow_back"
                                    icon={faArrowLeft}
                                    size="1x"
                                    style={{ visibility: "hidden" }}
                                />
                            </h4>

                            {showModal ? (
                                <Modal
                                    modal_title={modalTitle}
                                    modal_text={modalText}
                                    modal_btn={modalBtn}
                                    modalReset={modalReset}
                                    path={path}
                                    redirect={redirect}
                                />
                            ) : (
                                ""
                            )}
                            <form>
                                <div className="row match_detalle " >
                                    <div className="match_detalle_top bg-white shadow-sm border border-light p-3" >
                                        <div className="match_detalle_date">
                                            {moment(data.job_end_date).format("DD/MM/YYYY")}
                                        </div>

                                        <div className="match_detalle_status">
                                            Estado:
                                            {offerState === "activa" ? (
                                                <span className="activa"> Activa</span>
                                            ) : offerState === "postulado" ? (
                                                <span className="postulado"> Postulado</span>
                                            ) : offerState === "seleccionado" ? (
                                                <span className="seleccionado"> Seleccionado</span>
                                            ) : offerState === "contratado" ? (
                                                <span className="contratado"> Contratado</span>
                                            ) : offerState === "rechazado" ? (
                                                <span className="rechazado"> Rechazado</span>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    <div className="match_detalle_info  w-100">
                                        <div className="border bg-white  border-light shadow-sm p-3 ">
                                            <div className="company_name mb-2">
                                                <strong>{company.fantasy_name}</strong> Busca:
                                            </div>

                                            <div className="match_detalle_job_name">{data.title}</div>

                                            <div className="match_detalle_description_title mb-1">
                                                Detalle:
                                            </div>
                                            <div className="match_detalle_description" dangerouslySetInnerHTML={{ __html: data.description }} />


                                            {offerState !== "cerrado" && offerState !== "contratado" ? (
                                                <div className="match_detalle_description_title mt-0">
                                                    {data.vacancies ?
                                                        parseInt(data.vacancies) >= 2 ? data.vacancies + " " + "vacantes"
                                                            : data.vacancies + " " + "vacante" : ""}
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                            <div className="match_detalle_description_title">
                                                Ubicación:
                                            </div>
                                            <div className="match_detalle_location">
                                                {data.city +
                                                    ", " +
                                                    data.province +
                                                    "/" +
                                                    data.address_street +
                                                    " " +
                                                    data.address_number}{" "}
                                            </div>
                                        </div>

                                        <div className="border border-light bg-white shadow-sm p-3 mt-3">
                                            <div className="match_detalle_description_title mt-0">

                                                Remuneración:
                                            </div>
                                            <div>
                                                {offerState === "activa" ? (
                                                    <div>
                                                        <div className="match_detalle_txt">
                                                            El rango de dinero ofrecido para este trabajo es el
                                                            siguiente. Desliza el botón para seleccionar por
                                                            cuánto dinero lo harías:
                                                        </div>

                                                        <div className="row flex-nowrap" style={{ clear: "left" }}>
                                                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-left">
                                                                <small>
                                                                    <span className="indigo-text mr-0 mt-1 text-left text-primary">
                                                                        {data.min_payment * data.duration_unit_quantity}
                                                                    </span>
                                                                </small>
                                                            </div>
                                                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right">
                                                                <small>
                                                                    <span className=" indigo-text ml-0 mt-1 text-primary">

                                                                        {Math.floor(
                                                                            (data.min_payment +
                                                                                (data.min_payment *
                                                                                    data.min_payment_percentage) /
                                                                                100) * data.duration_unit_quantity
                                                                        )}
                                                                    </span>
                                                                </small>
                                                            </div>
                                                        </div>

                                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                            <Slider
                                                                name="slider"
                                                                onChange={value => {
                                                                    setValue(value);
                                                                }}
                                                                value={value}
                                                                step={1}
                                                                min={(data.min_payment) * data.duration_unit_quantity}
                                                                max={Math.floor(
                                                                    (data.min_payment +
                                                                        (data.min_payment *
                                                                            data.min_payment_percentage) /
                                                                        100) * data.duration_unit_quantity
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            {application && application.payment_required ?
                                                <div className="match_detalle_amount">
                                                    Remuneración escogida:{" "}
                                                    <strong>
                                                        {offerState === "activa"
                                                            ? value
                                                            : application.payment_required}
                                                    </strong>
                                                </div>
                                                : ""}
                                        </div>
                                    </div>

                                    <div className="border border-light bg-white shadow-sm p-3 mt-3 w-100">
                                        <div className="match_detalle_info ">
                                            <div className="match_detalle_description_title mt-0">
                                                Fechas y horarios:
                                            </div>

                                            <div className=" match_detalle_txt  mb-0 mt-2">
                                                Fecha de inicio:{" "}
                                                <strong>
                                                    {moment(data.job_start_date).format("DD/MM/YYYY")}
                                                </strong>
                                            </div>
                                            <div className="match_detalle_txt mb-0 mt-0">
                                                Fecha de finalización:{" "}
                                                <strong>
                                                    {moment(data.job_end_date).format("DD/MM/YYYY")}
                                                </strong>
                                            </div>
                                            {data && data.total_hours ?
                                                <div className="match_detalle_txt mb-0 mt-0 pb-0">
                                                    Cantidad de horas: <strong>{data.total_hours}</strong>
                                                </div>
                                                : ""}

                                        </div>

                                        {/*end iteration */}
                                    </div>
                                </div>

                                {offerState === "activa" && accountState !== "3" && DifferenceInDaysEndingNumber(data.job_end_date) <= 0 ? (
                                    <div className="w-100 m-0">
                                        <button
                                            // type="submit"
                                            className="btn btn-blue btn-block w-100 m-0"
                                            onClick={e => handleSubmitValue(e, value, data.id)}
                                        >
                                            {" "}
                                            Postularme{" "}
                                        </button>

                                        <button
                                            // type="submit"
                                            className="btn btn-red btn-block bg_red w-100 mt-2"
                                            onClick={e => handleSubmitReject(e, value, data.id)}
                                        >
                                            Descartar{" "}
                                        </button>
                                    </div>
                                ) : null}

                                {offerState === "seleccionado" && accountState !== "3" && DifferenceInDaysEndingNumber(data.job_end_date) <= 0 ? (

                                    <div className="w-100 m-0">

                                        <button
                                            //type="submit"
                                            className="btn btn-block btn-blue w-100"
                                            style={{ backgroundColor: "#0D71B9", color: "#FFFFFF" }}
                                            onClick={e =>
                                                handleSubmitAcceptSelection(e, value, data.id)
                                            }
                                        >
                                            {" "}
                                            Aceptar{" "}
                                        </button>

                                        <button
                                            // type="submit"
                                            className="btn btn-red btn-block bg_red w-100"
                                            onClick={e =>
                                                handleSubmitRejectSelection(e, value, data.id)
                                            }
                                        >
                                            Rechazar{" "}
                                        </button>
                                    </div>
                                ) : null}
                            </form>
                        </article>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default withRouter(MatchsText);
