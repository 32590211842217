import React, { Component } from "react";

class auth extends Component {
  constructor() {
    super();
    this.authenticated = false;
  }
  login(token) {
    localStorage.setItem("token", token);
  }
  logout() {
    localStorage.removeItem("token");
  }
  isAuthenticated() {
    let logged = false;
    // console.log(localStorage.getItem("token"));

    if (localStorage.getItem("token") != null) {
      /*   setTimeout(() => {
        this.logout();
      }, 30000);*/
      //console.log("logged adentro de token" + logged);
      logged = true;
      //console.log("logged adentro de token" + logged);
    }

    return logged;
  }
}
export default new auth();
