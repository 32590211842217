import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { url_base } from "../../api_url";
import PasswordNoLogueado from "./PasswordNoLogueado";

class RestaurarPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOk: false,
      promiseSolved: false,
      email: "",
      password_token: "",
      message: ""
    };
  }
  componentDidMount() {
    const key = this.props.location.pathname.split("/");

    axios
      .patch(url_base + "/restore-password/" + key[2])

      .then(
        response => {
          const result = response.data.result;

          if (result == 1) {
            const email = response.data.data.email;
            const token = response.data.data.password_token;

            this.setState({
              isOk: true,
              promiseSolved: true,
              password_token: token,
              email: email
            });
          } else {
            const message = response.data.message;
            this.setState({
              promiseSolved: true,
              message: message
            });
          }
        },
        error => {
          var status = error.response;
          this.setState({ promiseSolved: true });
          //     console.log("error status" + status);
          let path = `/Login/`;
          this.props.history.push(path);
        }
      );
  }
  render() {
    return (
      <div>
        {this.state.promiseSolved ? (
          this.state.isOk ? (
            <PasswordNoLogueado
              email={this.state.email}
              password_token={this.state.password_token}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withRouter(RestaurarPassword);
