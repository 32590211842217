export const url_base = "https://asignate.com.ar/admin/api";

//PRODUCCIÓN
export const imageCompanyPath = "https://asignate.com.ar/admin/storage/companies/";
export const imageTalentPath = "https://asignate.com.ar/admin/storage/talents/";

//DEVELOPMENT
//export const imageCompanyPath = "https://asignate.com.ar/development/storage/companies/";
//export const imageTalentPath = "https://asignate.com.ar/development/storage/talents/";

//LOCAL GUILLE
// export const imageCompanyPath = "http://asignate-admin.local/storage/companies/";
// export const imageTalentPath = "http://asignate-admin.local/storage/talents/";