import React from "react";
import { promiseTrackerHoc } from "react-promise-tracker";
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { usePromiseTracker } from "react-promise-tracker";

const InnerSpinner = props => {
    const promiseInProgress = usePromiseTracker();

    return (
        <div>
            {promiseInProgress.promiseInProgress ? (
                <div
                    className="spinner"
                    style={{ marginLeft: "30rem", marginTop: "15rem" }}
                >
                    <Loader
                        type="Puff"
                        color="#2BAD60"
                        height="100"
                        width="100"
                        timeout={1000000}
                        color="#0D71B9"
                    />
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

export const Spinner = promiseTrackerHoc(InnerSpinner);
