import React from "react";

import { withFormik, ErrorMessage, Form, Field } from "formik";
import { FormFeedback, Input, FormGroup, Label, CustomInput } from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { url_base } from "./../../../../api_url";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";
import "./../../../../Styles/general.css";
import AddItem from "./AddItem";
import { faArrowLeft, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DatePickerField = ({ name, value, onChange, disable, placeholder }) => {
    return (
        <DatePicker
            name={name}
            selected={(value && new Date(value)) || null}
            //   selected={moment(value)}
            // selected={value}
            readOnly={!!disable}
            autoComplete="off"
            className="form-control"
            placeholderText={placeholder}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            onChange={val => {
                onChange(name, val);
            }}
        />
    );
};

const formikWrapper = withFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,

    mapPropsToValues: props => ({
        validationShapeVar: props.validationShapeVar || "",
        title_1: props.primary.title || "",
        institution_1: props.primary.institution || "",
        startDate_1: props.primary.start_date
            ? moment(props.primary.start_date) //.format("DD-MM-YYYY")
            : "",
        finishDate_1: props.primary.finish_date
            ? moment(props.primary.finish_date) //.format("DD-MM-YYYY")
            : "",
        state_1: "f",
        disablePrimary: props.disablePrimary || "",
        //    rOnlyPrimary: props.title_1 != "" ? "readOnly" : "",

        title_2: props.highSchool.title || "",
        institution_2: props.highSchool.institution || "",
        startDate_2: props.highSchool.start_date
            ? moment(props.highSchool.start_date) //.format("DD-MM-YYYY")
            : "",
        finishDate_2: props.highSchool.finish_date
            ? moment(props.highSchool.finish_date) //.format("DD-MM-YYYY")
            : "",
        state_2: "f",
        disableHighschool: props.disableHighschool || "",
        title_3: props.university.title || "",
        institution_3: props.university.institution || "",
        startDate_3: props.university.start_date
            ? moment(props.university.start_date) //.format("DD-MM-YYYY")
            : "",

        finishDate_3: props.university.finish_date
            ? moment(props.university.finish_date) //.format("DD-MM-YYYY")
            : "",
        state_3: "f",
        disableUniversity: props.disableUniversity || "",
        collapsePrimary: ""
    }),
    handleSubmit: (values, bag, val1, val2, val3) =>
        bag.props.handleSubmitForm(values, val1, val2, val3)

    //  validationSchema: props => props.validationShapeVar
});

const EducacionText = props => {
    // const validationShapeVar = validationShapeVar;

    const {
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        languages,
        loadedLanguages,
        numRows,
        handleChange,
        validateFieldsPrimaria,
        collapsePrimary,
        validateFieldsSecundaria,
        validateFieldsSuperior,
        errorTitle_1,
        errorInstitution_1,
        errorStartDate_1,
        errorFinishDate_1,

        errorTitle_2,
        errorInstitution_2,
        errorStartDate_2,
        errorFinishDate_2,

        errorTitle_3,
        errorInstitution_3,
        errorStartDate_3,
        errorFinishDate_3,

        handleSubmitForm
    } = props;

    return (
        <div>
            <div className="row justify-content-center  mr-0 ml-0 h-100">
                <div className="decoration"></div>
                <div className="page_container">
                    <div className="card" style={{ backgroundColor: "#FAFAFA", padding: "1rem", minHeight: "100vh" }} >
                        <article className="card-body general">
                            <h4 className="card-title text-center">
                                <Link to={"/PerfilText"} className="d-flex align-items-center">
                                    <FontAwesomeIcon
                                        className="arrow_back"
                                        icon={faArrowLeft}
                                        size="1x"
                                    />
                                </Link>
                                Educación
                                <FontAwesomeIcon
                                    className="arrow_back"
                                    icon={faArrowLeft}
                                    size="1x"
                                    style={{ visibility: "hidden" }}
                                />
                            </h4>

                            <Form >
                                <div
                                    type="hidden"
                                    value={validateFieldsSuperior}
                                    name="validateFieldsSuperior p-2"
                                />

                                <div className="row educacion_title ">
                                    <a
                                        id="superior"
                                        className="btn btn-primary education p-3 border-light shadow-sm rounded-0"
                                        data-toggle="collapse"
                                        href="#CollapseSuperior"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="CollapseSuperior"
                                        onClick={() =>
                                            props.conditionalValidation(
                                                document.getElementById("primary"),
                                                document.getElementById("highSchool"),
                                                document.getElementById("superior"),
                                                setFieldValue
                                            )
                                        }
                                        value="true"
                                    >
                                        {" "}
                                        <div className="row ml-0 mr-0">
                                            <div className="col-10 tit_black m-0 p-0 d-flex  align-items-center justify-content-start">
                                                Superior</div>

                                            <div
                                                className="col-2 pr-0 pl-0"
                                                style={{ textAlign: "right" }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faAngleDown}
                                                    size="1x"
                                                    className="icono_right"
                                                />
                                            </div>
                                        </div>
                                    </a>

                                    <div
                                        className="collapse multi-collapse"
                                        id="CollapseSuperior"
                                    >
                                        <div className="row p-0 m-0">
                                            <div className="col-12  mb-3" style={{ textAlign: "center" }}>
                                                {"Cargá el último título superior finalizado o en curso por nivel"}
                                            </div>
                                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                                                <div className="form-group">
                                                    <input
                                                        // readOnly={!!values.institution_3}
                                                        readOnly={!!values.disableUniversity}
                                                        id="institution_3"
                                                        name="institution_3"
                                                        className="form-control text-primary"
                                                        placeholder="Institución"
                                                        type="text"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.institution_3}
                                                    />
                                                    {errorInstitution_3 ? (
                                                        <div>
                                                            <div className="frm_error_c">
                                                                {errorInstitution_3}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <input
                                                        readOnly={!!values.disableUniversity}
                                                        name="title_3"
                                                        className="form-control text-primary"
                                                        placeholder="Título"
                                                        type="text"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.title_3}
                                                    />
                                                    {errorTitle_3 !== "" ? (
                                                        <div>
                                                            <div className="frm_error_c">{errorTitle_3}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row p-0 m-0">
                                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <FormGroup>
                                                        <div className="customDatePickerWidth" autoComplete="off">
                                                            <DatePickerField
                                                                name="startDate_3"
                                                                value={values.startDate_3}
                                                                onChange={setFieldValue}
                                                                className="form-control"
                                                                dateFormat="MM/yyyy"
                                                                placeholder="fecha de inicio"
                                                                autoComplete="off"
                                                                disable={values.disableUniversity}
                                                            />
                                                            {errorStartDate_3 ? (
                                                                <div>
                                                                    <div className="frm_error_c">
                                                                        {errorStartDate_3}
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <FormGroup>
                                                        <div className="customDatePickerWidth" autoComplete="off">
                                                            <DatePickerField
                                                                name="finishDate_3"
                                                                value={values.finishDate_3}
                                                                onChange={setFieldValue}
                                                                className="form-control"
                                                                dateFormat="dd-MM-yyyy"
                                                                autoComplete="off"
                                                                placeholder="Fecha de finalización (o estimada)"
                                                                disable={values.disableUniversity}
                                                            />
                                                            {errorFinishDate_3 ? (
                                                                <div>
                                                                    <div className="frm_error_c">
                                                                        {errorFinishDate_3}
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input
                                        name="state_1"
                                        value="f"
                                        type="hidden"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div
                                    type="hidden"
                                    value={validateFieldsSecundaria}
                                    name="validateFieldsSecundaria"
                                />
                                <div className="row educacion_title">
                                    <a
                                        id="highSchool"
                                        className="btn btn-primary education p-3 border-light shadow-sm rounded-0"
                                        data-toggle="collapse"
                                        href="#CollapseSecundaria"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="CollapseSecundaria"
                                        onClick={() =>
                                            props.conditionalValidation(
                                                document.getElementById("primary"),
                                                document.getElementById("highSchool"),
                                                document.getElementById("superior"),
                                                setFieldValue
                                            )
                                        }
                                        value="true"
                                    >
                                        <div className="row  ml-0 mr-0">
                                            <div className="col-10 tit_black m-0 p-0 d-flex  align-items-center justify-content-start">
                                                Secundaria</div>

                                            <div
                                                className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pr-0 pl-0"
                                                style={{ textAlign: "right" }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faAngleDown}
                                                    size="1x"
                                                    className="icono_right pr-0 pl-0"
                                                />
                                            </div>
                                        </div>
                                    </a>

                                    <div
                                        className="collapse multi-collapse"
                                        id="CollapseSecundaria"
                                    >
                                        <div className="row p-0 m-0">
                                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <input
                                                        readOnly={!!values.disableHighschool}
                                                        name="institution_2"
                                                        className="form-control text-primary"
                                                        placeholder="Institución"
                                                        type="text"
                                                        onChange={handleChange}
                                                        value={values.institution_2}
                                                    />
                                                    {errorInstitution_2 ? (
                                                        <div>
                                                            <div className="frm_error_c">
                                                                {errorInstitution_2}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <input
                                                        readOnly={!!values.disableHighschool}
                                                        name="title_2"
                                                        className="form-control text-primary"
                                                        placeholder="Título"
                                                        type="text"
                                                        onChange={handleChange}
                                                        value={values.title_2}
                                                    />
                                                    {errorTitle_2 ? (
                                                        <div>
                                                            <div className="frm_error_c">{errorTitle_2}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row p-0 m-0">
                                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <FormGroup>
                                                        <div className="customDatePickerWidth" autoComplete="off">
                                                            <DatePickerField
                                                                name="startDate_2"
                                                                value={values.startDate_2}
                                                                onChange={setFieldValue}
                                                                className="form-control"
                                                                dateFormat="dd-MM-yyyy"
                                                                placeholder="fecha de inicio"
                                                                autoComplete="off"
                                                                disable={values.disableHighschool}
                                                            />
                                                            {errorStartDate_2 ? (
                                                                <div>
                                                                    <div className="frm_error_c">
                                                                        {errorStartDate_2}
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <FormGroup>
                                                        <div className="customDatePickerWidth" autoComplete="off">
                                                            <DatePickerField
                                                                rOnlyHighSchool
                                                                name="finishDate_2"
                                                                value={values.finishDate_2}
                                                                onChange={setFieldValue}
                                                                className="form-control"
                                                                dateFormat="dd-MM-yyyy"
                                                                autoComplete="off"
                                                                placeholder="Fecha de finalización (o estimada)"
                                                                disable={values.disableHighschool}
                                                            />
                                                            {errorFinishDate_2 ? (
                                                                <div>
                                                                    <div className="frm_error_c">
                                                                        {errorFinishDate_2}
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <input
                                    name="state_2"
                                    value="f"
                                    type="hidden"
                                    onChange={handleChange}
                                />
                                <div
                                    type="hidden"
                                    value={validateFieldsPrimaria}
                                    name="validateFieldsPrimaria"
                                />
                                <div className="row educacion_title">
                                    <a
                                        id="primary"
                                        className="btn btn-primary education p-3 border-light shadow-sm rounded-0"
                                        data-toggle="collapse"
                                        href="#CollapsePrimaria"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="CollapsePrimaria"
                                        onClick={() =>
                                            props.conditionalValidation(
                                                document.getElementById("primary"),
                                                document.getElementById("highSchool"),
                                                document.getElementById("superior"),
                                                setFieldValue
                                            )
                                        }
                                        value="true"
                                    >
                                        <div className="row ml-0 mr-0">
                                            <div className="col-10 tit_black m-0 p-0 d-flex  align-items-center justify-content-start">
                                                Primaria</div>

                                            <div
                                                className="col-2 pr-0 pl-0"
                                                style={{ textAlign: "right" }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faAngleDown}
                                                    size="1x"
                                                    className="icono_right"
                                                />
                                            </div>
                                        </div>
                                    </a>

                                    <div
                                        className="collapse multi-collapse"
                                        id="CollapsePrimaria"
                                    >
                                        <div className="row p-0 m-0">
                                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <input
                                                        readOnly={!!values.disablePrimary}
                                                        name="institution_1"
                                                        className="form-control text-primary"
                                                        placeholder="Institución"
                                                        type="text"
                                                        onChange={handleChange}
                                                        value={values.institution_1}
                                                    />
                                                    {errorInstitution_1 ? (
                                                        <div>
                                                            <div className="frm_error_c">
                                                                {errorInstitution_1}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <input
                                                        readOnly={!!values.disablePrimary}
                                                        id="title_1"
                                                        name="title_1"
                                                        className="form-control text-primary"
                                                        placeholder="titulo"
                                                        type="text"
                                                        onChange={handleChange}
                                                        value={values.title_1}
                                                    />
                                                    {errorTitle_1 !== "" ? (
                                                        <div>
                                                            <div className="frm_error_c">{errorTitle_1}</div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row p-0 m-0">
                                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <FormGroup>
                                                        <div className="customDatePickerWidth" autoComplete="off">
                                                            <DatePickerField
                                                                name="startDate_1"
                                                                value={values.startDate_1}
                                                                onChange={setFieldValue}
                                                                className="form-control"
                                                                dateFormat="dd-MM-yyyy"
                                                                autoComplete="off"
                                                                placeholder="fecha de inicio"
                                                                disable={values.disablePrimary}
                                                            />
                                                            {errorStartDate_1 !== "" ||
                                                                errorStartDate_1 !== undefined ? (
                                                                <div>
                                                                    <div className="frm_error_c">
                                                                        {errorStartDate_1}
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" autoComplete="nope">
                                                <div className="form-group" autoComplete="nope">
                                                    <FormGroup autoComplete="nope">
                                                        <div className="customDatePickerWidth" autoComplete="nope">
                                                            <DatePickerField
                                                                name="finishDate_1"
                                                                value={values.finishDate_1}
                                                                onChange={setFieldValue}
                                                                className="form-control"
                                                                dateFormat="dd-MM-yyyy"
                                                                autoComplete="nope"
                                                                placeholder="Fecha de finalización (o estimada)"
                                                                disable={values.disablePrimary}
                                                            />
                                                            {errorFinishDate_1 ? (
                                                                <div>
                                                                    <div className="frm_error_c">
                                                                        {errorFinishDate_1}
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Field
                                    name="state_3"
                                    value="f"
                                    type="hidden"
                                    //component={customInputForm}
                                    onChange={handleChange}
                                />

                                <div className="row educacion_title">
                                    <a
                                        className="btn btn-primary education p-3 border-light shadow-sm rounded-0"
                                        data-toggle="collapse"
                                        href="#CollapseIdiomas"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="CollapseIdiomas"
                                    >
                                        <div className="row ml-0 mr-0">
                                            <div className="col-10 tit_black m-0 p-0 d-flex  align-items-center justify-content-start">
                                                Idiomas</div>

                                            <div
                                                className="col-2 pr-0 pl-0"
                                                style={{ textAlign: "right" }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faAngleDown}
                                                    size="1x"
                                                    className="icono_right"
                                                />
                                            </div>
                                        </div>
                                    </a>
                                </div>

                                <div className="collapse multi-collapse" id="CollapseIdiomas">
                                    <div className="row p-0 m-0">
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" >
                                            <FormGroup>
                                                {Object.keys(loadedLanguages).map((keyName, i) => (
                                                    <div className="idioma_set" key={i}>
                                                        <select
                                                            id="languages"
                                                            readOnly={true}
                                                            name="languages"
                                                            className="form-control"
                                                            //component={customInputForm}
                                                            onChange={handleChange}
                                                            type="select"
                                                            value={loadedLanguages[keyName].language_id}

                                                        >
                                                            <option value="">Seleccionar</option>
                                                            {Object.keys(languages).map((keyName, i) => (
                                                                <option key={i} value={languages[keyName].id} >
                                                                    {languages[keyName].language}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div>
                                                            {" "}
                                                            <label className="form-check-label align-self-end ">
                                                                Nivel
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline ">
                                                            <input
                                                                readOnly={true}
                                                                id="radioGender1"
                                                                name={
                                                                    "level_" +
                                                                    loadedLanguages[keyName].language_id
                                                                }
                                                                className="form-check-input radio-inline radio align-items-center blue"
                                                                //hecked={gender === "M" ? console.log("true") : ""}
                                                                //checked={values.gender === "M"}

                                                                checked={
                                                                    loadedLanguages[keyName].languageLevel_id ===
                                                                        1
                                                                        ? true
                                                                        : ""
                                                                }
                                                                type="radio"
                                                                value="b"
                                                                //  checked={gender === "M" ? "checked" : ""}
                                                                onChange={handleChange}
                                                                style={{ backgroundColor: "#0D71B9", color: "#0D71B9" }}
                                                            />
                                                            <label className="form-check-label">básico</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                readOnly={true}
                                                                id="radioGender2"
                                                                name={
                                                                    "level_" +
                                                                    loadedLanguages[keyName].language_id
                                                                }
                                                                className="form-check-input radio-inline radio align-items-center blue"
                                                                // checked={gender === "F"}
                                                                type="radio"
                                                                value="i"
                                                                //checked={values.gender === "F"}
                                                                checked={
                                                                    loadedLanguages[keyName].languageLevel_id ===
                                                                        2
                                                                        ? true
                                                                        : ""
                                                                }
                                                                //checked={gender === "F" ? console.log("true") : ""}
                                                                onChange={handleChange}
                                                                style={{ backgroundColor: "#0D71B9", color: "#0D71B9" }}
                                                            />
                                                            <label className="form-check-label">
                                                                intermedio
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                readOnly={true}
                                                                id="radioGender3"
                                                                name={
                                                                    "level_" +
                                                                    loadedLanguages[keyName].language_id
                                                                }
                                                                className="form-check-input radio-inline radio align-items-center blue"
                                                                //checked={gender === "O" ? console.log("true") : ""}
                                                                //checked={values.gender === "O"}
                                                                checked={
                                                                    loadedLanguages[keyName].languageLevel_id ===
                                                                        3
                                                                        ? true
                                                                        : ""
                                                                }
                                                                type="radio"
                                                                value="a"
                                                                onChange={handleChange}
                                                                style={{ backgroundColor: "#0D71B9", color: "#0D71B9" }}
                                                            />
                                                            <label className="form-check-label">
                                                                avanzado
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline" >
                                                            <input
                                                                readOnly={true}
                                                                id="radioGender4"
                                                                name={
                                                                    "level_" +
                                                                    loadedLanguages[keyName].language_id
                                                                }
                                                                className="form-check-input radio-inline radio align-items-center blue"
                                                                //checked={gender === "O" ? console.log("true") : ""}
                                                                //checked={values.gender === "O"}
                                                                checked={
                                                                    loadedLanguages[keyName].languageLevel_id ===
                                                                        4
                                                                        ? true
                                                                        : ""
                                                                }
                                                                type="radio"
                                                                value="n"
                                                                onChange={handleChange}
                                                                style={{ backgroundColor: "#0D71B9", color: "#0D71B9" }}
                                                            />
                                                            <label className="form-check-label">
                                                                nativo / bilingue
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </FormGroup>
                                        </div>
                                    </div>

                                    <AddItem languages={languages} numRows={numRows} />
                                </div>

                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-blue btn-block"
                                        onSubmit={() =>
                                            handleSubmitForm(
                                                values,
                                                document.getElementById("primary"),
                                                document.getElementById("highSchool"),
                                                document.getElementById("superior")
                                            )
                                        }
                                    >
                                        {" "}
                                        Guardar{" "}
                                    </button>
                                </div>
                            </Form>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
};
const EnhancedForm = formikWrapper(EducacionText);
//export default EnhancedForm;

export default withRouter(EnhancedForm);
