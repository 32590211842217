import React from "react";
import { Link } from "react-router-dom";
import "../../script";
import "./../../../style.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
    faUser,
    faUserGraduate,
    faPen,
    faChevronRight,
    faArrowLeft,
    faSuitcase,
    faHandPaper,
    faTimes,
    faPlus,
    faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "./../../../common/Modal";
import { imageTalentPath } from "./../../../common/constants";
import moment from "moment";

const todayButton = new Date();
const todayButtonDate = moment(todayButton).format("YYYY-MM-DD");

library.add(
    faUser,
    faUserGraduate,
    faPen,
    faChevronRight,
    faArrowLeft,
    faSuitcase,
    faHandPaper,
    faTimes,
    faPlus,
    faMapMarkerAlt
);
function DifferenceInDaysEndingNumber(firstDate) {
    var today = new Date();
    var todayDate = moment(today).format("YYYY-MM-DD");
    var endDate = moment(firstDate).format("YYYY-MM-DD");
    // var difference = moment(endDate).diff(todayDate, "days");
    var difference = moment(endDate).diff(todayDate, "days");
    ///var day = difference > "1" || difference < "-1" ? "días" : "día";

    return difference;
}

const DetalleTalentoOfertaScreen = ({
    data,
    studies,
    experiences,
    hirings,
    skills,
    idOffer,
    idTalent,
    selectTalent,
    selectionActive,
    languages,
    showModal,
    modalTitle,
    modalText,
    modalBtn,
    path,
    modalReset,
    redirect,
    offer_end_date,
    checkMonotributoChecked,
    optionSelected
}) => (
    <div>
        <div className="row justify-content-center mr-0 ml-0">
            <div className="decoration"></div>
            <div className="page_container">
                <div className="card">
                    <article className="card-body general">
                        <h4 className="card-title text-center">
                            <Link to={"/BusquedasDetalle/" + idOffer + "/" + selectionActive + "/" + checkMonotributoChecked + "/" + optionSelected} replace>
                                <FontAwesomeIcon
                                    className="arrow_back"
                                    icon={faArrowLeft}
                                    size="1x"
                                />
                            </Link>
                            Perfil
                            <FontAwesomeIcon
                                className="arrow_back"
                                icon={faArrowLeft}
                                size="1x"
                                style={{ visibility: "hidden" }}
                            />
                        </h4>

                        <div className="row clean">
                            <div className="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 basura">
                                <div className="browse_c_noImage">
                                    <img
                                        className="thumb_user profile"
                                        src={imageTalentPath + "/" + data.photo}
                                        width="30%"
                                    />
                                </div>
                                <div className="user_name">
                                    {data.first_name +
                                        " " +
                                        (data.last_name &&
                                            data.last_name != undefined &&
                                            selectionActive != "hired"
                                            ? data.last_name.substring(0, 1)
                                            : data.last_name)}
                                </div>
                            </div>
                        </div>

                        <div className="row clean"></div>

                        <form>
                            {showModal ? (
                                <Modal
                                    modal_title={modalTitle}
                                    modal_text={modalText}
                                    modal_btn={modalBtn}
                                    modalReset={modalReset}
                                    path={path}
                                    redirect={redirect}
                                />
                            ) : (
                                ""
                            )}

                            <div className="row clean">
                                <div className="user_info">
                                    <div className="tit">
                                        {" "}

                                        Usuario
                                    </div>
                                    <p className="card-text m-0">
                                        <small> Email </small>
                                        <br />
                                        {data.userEmail ? data.userEmail : "*********"}
                                    </p>

                                    <p className="card-text m-0">
                                        <small>Telef.</small>
                                        <br />
                                        {data.phone_area_code && data.phone
                                            ? data.phone_area_code + "-" + data.phone
                                            : "*********"}
                                    </p>
                                </div>
                                <div className="user_info">
                                    <div className="tit mt_20">
                                        {" "}

                                        Puntaje
                                    </div>

                                    <p className="card-text m-0">
                                        {(data.qualificationsSum != undefined && data.qualificationsQuant != undefined) && (Number(data.qualificationsSum) != 0 && Number(data.qualificationsQuant) != 0) ?

                                            ((data.qualificationsSum * 100
                                            ) / data.qualificationsQuant) + "%"
                                            : "aún no tiene puntaje"}
                                    </p>
                                </div>

                                <div className="user_info">
                                    <div className="tit mt_20">
                                        {" "}

                                        Dirección
                                    </div>

                                    <p className="card-text m-0">
                                        {data.address_street +
                                            " " +
                                            data.address_number +
                                            ", " +
                                            data.city +
                                            " (" +
                                            data.address_floor +
                                            ")"}
                                    </p>
                                </div>

                                <div className="user_info">
                                    <div className="tit mt_20">
                                        {" "}

                                        Educación
                                    </div>

                                    {studies &&
                                        Object.keys(studies).map((keyName, i) => (
                                            <div className="card-text m-0 underline">
                                                <div className="name">
                                                    {" "}
                                                    {studies[keyName].title} -{" "}
                                                    <span className="grey">
                                                        {studies[keyName].institution}
                                                    </span>
                                                </div>
                                                <div className="fecha">
                                                    {moment(studies[keyName].start_date).format(
                                                        "DD/MM/YYYY"
                                                    ) +
                                                        " - " +
                                                        (studies[keyName].finish_date
                                                            ? moment(studies[keyName].finish_date).format(
                                                                "DD/MM/YYYY"
                                                            )
                                                            : studies[keyName].state)}
                                                </div>
                                            </div>
                                        ))}

                                    <div className="tit mt_20">Idiomas</div>

                                    {languages &&
                                        Object.keys(languages).map((keyName, i) => (
                                            <div className="card-text m-0 underline">
                                                <div className="name">
                                                    {" "}
                                                    {languages[keyName].language} -{" "}
                                                    <span className="grey">
                                                        {languages[keyName].level}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                </div>

                                <div className="user_info">
                                    {experiences && experiences[0] ? (
                                        <div className="tit mt_20">
                                            {" "}

                                            Experiencia
                                        </div>) : ("")}

                                    {experiences &&
                                        Object.keys(experiences).map((keyName, i) => (
                                            <div className="card-text m-0 underline">
                                                <div className="name">
                                                    {" "}
                                                    {experiences[keyName].position} -{" "}
                                                    <span className="grey">
                                                        {experiences[keyName].company}
                                                    </span>
                                                </div>
                                                <div className="fecha">
                                                    {moment(experiences[keyName].start_date).format(
                                                        "DD/MM/YYYY"
                                                    ) +
                                                        " - " +
                                                        (experiences[keyName].finish_date &&
                                                            experiences[keyName].finish_date != undefined
                                                            ? moment(experiences[keyName].finish_date).format(
                                                                "DD/MM/YYYY"
                                                            )
                                                            : "acutalmente")}
                                                </div>
                                            </div>
                                        ))}
                                </div>

                                <div className="user_info">

                                    {hirings && hirings[0] ? (
                                        <div className="tit mt_20">
                                            {" "}

                                            Experiencia Asignate
                                        </div>) : ("")}

                                    {hirings &&
                                        Object.keys(hirings).map((keyName, i) => (
                                            <div className="card-text m-0 underline">
                                                <div className="name">
                                                    {" "}
                                                    {hirings[keyName].title} -{" "}
                                                    <span className="grey">
                                                        {hirings[keyName].fantasy_name}
                                                    </span>
                                                </div>
                                                <div className="fecha">
                                                    {moment(hirings[keyName].job_start_date).format(
                                                        "DD/MM/YYYY"
                                                    ) +
                                                        " - " +
                                                        (hirings[keyName].job_end_date &&
                                                            hirings[keyName].job_end_date != undefined
                                                            ? moment(hirings[keyName].job_end_date).format(
                                                                "DD/MM/YYYY"
                                                            )
                                                            : "acutalmente")}
                                                </div>
                                            </div>
                                        ))}
                                </div>

                                <div className="user_info">

                                    <div className="tit mt_20">Aptitudes</div>
                                    {skills &&
                                        Object.keys(skills).map((keyName, i) => (
                                            <div className="card-text m-0 underline">
                                                <div className="name">
                                                    {" "}
                                                    {skills[keyName].skill} -{" "}
                                                    <span className="grey">
                                                        {skills[keyName].category +
                                                            "/" +
                                                            skills[keyName].subcategory}
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-12 col-xs-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 text-left text-primary ">
                                    <div className="form-group">

                                        {(selectionActive == 1) &&
                                            /* (Number(moment(offer_end_date).diff(moment(todayButtonDate), "days"))
                                             ) >= Number(0)*/

                                            Number(DifferenceInDaysEndingNumber(offer_end_date)) >= 0 ?
                                            <button
                                                // type="submit"
                                                className="btn btn-blue btn-block"
                                                style={{ width: "100%" }}
                                                onClick={e => selectTalent(e, idOffer, idTalent)}
                                            >

                                                {" "}
                                                Seleccionar{" "}
                                            </button>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </article>
                </div>
            </div>
        </div>
    </div>
);
export default DetalleTalentoOfertaScreen;
