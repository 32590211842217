import styled from "styled-components";
import media from "../../media";

//const navContentHeight = "2.5rem";


const navContentHeight = "2rem";

// scrolled -> Window has been scrolled down
// hide -> User is scrolling down
// !hide -> User is scrolling up

export const NavBarOptionsContent = styled.div`
display:flex;
width:100%;
height: 100%;`;


export const NavBarContainerLogoOptions = styled.div`
display:flex;
height: 100%;`;

export const NavBarBase = styled.nav`

display:flex;
diplay-content:space-between;
justify-content:center;
align-items:center;
text-align:center;
  background-color:#FFFFFF;
  position: fixed;
  top: -1px;
  left: 0;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
opacity: 1;
  
  z-index: 2000;

  transition: 0.4s;

  ${({ hide }) =>
        hide &&
        `
    top: -91px;
  `}
  ${({ scrolled }) =>
        scrolled &&
        `
    background-color: #ffffff;
    color: #1766A9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  `}

  & > div {
    display: flex;
    justify-content: space-between;
 /*max-width: 164rem;*/
    margin: 0 auto;
    padding: 0.5rem 1.5rem;
 
    ${media.sm`
        padding:0.5rem;
       
    `}
    ${media.xs`
        padding-right:0.8rem;
        padding-left:1rem;
       
    `} 
    @media screen and (orientation:landscape){
        padding:0.7rem 1.8rem;
    }
  }
`;

export const NavBarIcons = styled.div`
 display:flex;
 justify-content:center;
align-items:center;
text-align: center;
 
  height: ${navContentHeight};
  
  & img {
    height: ${navContentHeight};

    &:not(:last-child) {
      margin-right: 2rem;
    }
    ${media.sm`
    height: 2.5rem !important;
  `}${media.xs`
  height: 2.1rem !important;
`}
  }
  
 
`;

export const NavBarOptions = styled.div`
  height: ${navContentHeight};
  
  display:flex;
  justify-content:center;
  align-items:center;
  & button {
    display: block;
    position: relative;
    z-index: 5000;
  }
  

  & ul {
    list-style: none;
   
  }

  & li {
    display: none;
  }

  & a,
  & a:link,
  & a:visited {
    cursor: pointer;
    display: block;
    padding: 0 1.5rem;
    text-decoration: none;
    font-family: Poppins;
    font-size: 1.8rem;
    font-weight: 900;
    color: #413E3E;
    line-height: ${navContentHeight};

    transition: color 0.15s;

    &:hover,
    &:active {
      text-decoration: none;
      /*color: #084572;*/
      color:#F2025D;
    

    }

    ${({ scrolled }) =>
        scrolled &&
        `
        color: #413E3E;
      `}

    &.navigation-active {
      color: ${({ theme }) => theme.main} !important;
     
    }
  }

  
    & button {
      display: block;
    }

    & ul {
      position: fixed;
      bottom: 0;
      left: 0;
      overflow:hidden;
      overflow-y:hidden;
    overflow-x:hidden;
      width: 100%;
      height: 100vh;
      transform: ${({ isActive }) =>
        isActive ? "translateY(0)" : "translateY(-100vh)"};
      
      background-color: #FFFFFF;
      padding: 12rem 4rem 1rem 4rem;

      transition: transform .4s ease-in-out;
    }

    & li {
      display: block;

      opacity: ${({ isActive }) => (isActive ? "1" : "0")} ;
      transition: opacity 0.4s ease ${({ isActive }) =>
        isActive ? "400ms" : "0ms"} ;
    }

    & a,
    & a:link,
    & a:visited {
      position: relative;
      display: block;
      font-size: 2.2rem;
      color: #413E3E;
      padding: 2rem 0;
      text-align:center;
    /*  border-bottom: solid 1px rgb(154, 145, 140, 0.2);*/

      &::after {
        display: none;
        content: '';
      
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    /*    background-image: url('${({ theme }) => theme.navArrow}');*/
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        width: 2rem;
        height: 2rem;
        
      }
    }
  
`;
