import React, { Component } from "react";

import { withRouter, Link } from "react-router-dom";

import "../../script";
import "./../../../style.css";
import moment from "moment";
import { withFormik, ErrorMessage, Form, Field, Formik } from "formik";
import * as Yup from "yup";
import DatePicker, { registerLocale } from "react-datepicker";

class Paginator extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let data = [];
        let active = "";
        let arrowActiveForward = "";
        let arrowActiveBackward = "";
        //console.log("cantPages" + this.props.cantPages);
        this.props.optionSelected <= 1
            ? (arrowActiveForward = "page-item disabled")
            : (arrowActiveForward = "page-item");
        this.props.optionSelected >= this.props.cantPages
            ? (arrowActiveBackward = "page-item disabled")
            : (arrowActiveBackward = "page-item");

        for (let i = 1; i <= this.props.cantPages; i++) {
            if (this.props.cantPages > 1) {
                Number(this.props.optionSelected) === Number(i)
                    ? (active = "page-item active")
                    : (active = "page-item");

                data.push(
                    <li className={active}>
                        <Link
                            className="page-link"
                            onClick={() => this.props.handleChangePage(i)}
                        >
                            {i}
                        </Link>
                    </li>
                );
            }
        }
        return this.props.cantPages > 1 ? (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    {
                        <li className={arrowActiveForward}>
                            <a
                                className="page-link text-primary"
                                style={{ cursor: "pointer" }}
                                /// href="javascript:void();"
                                //  onClick={() => this.props.handleChangePage()}
                                onClick={() =>
                                    this.props.handleChangePage(
                                        Number(this.props.optionSelected) - 1
                                    )
                                }
                                aria-label="Previous"
                            >
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Anterior</span>
                            </a>
                        </li>
                    }
                    {data}
                    {
                        <li className={arrowActiveBackward}>
                            <a
                                className={"page-link text-primary"}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    this.props.handleChangePage(
                                        Number(this.props.optionSelected) + 1
                                    )
                                }
                                aria-label="Next"
                            >
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Siguiente</span>
                            </a>
                        </li>
                    }
                </ul>
            </nav>
        ) : (
            ""
        );
    }
}

const DatePickerField = ({ name, value, onChange, placeholder }) => {
    return (
        <DatePicker
            name={name}
            selected={(value && new Date(value)) || null}
            // selected={value}
            //   selected={value ? moment(value, "DD-MM-YYYY") : moment()}
            className="form-control"
            placeholderText={placeholder}
            dateFormat="dd-MM-yyyy"
            autoComplete="off"
            showMonthDropdown
            showYearDropdown
            onChange={val => {
                onChange(name, val);
            }}
        />
    );
};

const formikWrapper = withFormik({
    mapPropsToValues: props => ({
        dateFrom: "",
        dateUntil: ""
    }),

    handleSubmit: (values, bag) => bag.props.handleSubmit(values),

    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
        dateUntil: Yup.string().required("requerido"),
        dateFrom: Yup.string().required("requerido")
    })
});

const DivMessage = (
    <text>
        <strong>Aún no tenes búsquedas finalizadas</strong>
    </text>
);

const HistorialBusquedasScreen = props => {
    const { data, divShow, cantPages, handleChangePage, pageSelected } = props;

    return (
        <div>
            <div className="row">
                <div
                    className="decoration"
                    style={{ height: window.innerHeight + "px" }}
                ></div>
                <div className="page_container">
                    <div className="card">
                        <article className="card-body">
                            <h4 className="card-title text-center text-primary">Historial</h4>
                            <hr />
                            <div className={divShow === true ? "div_show" : "div_hide"}>
                                {DivMessage}
                            </div>

                            {data &&
                                Object.keys(data).map((keyName, i) => (
                                    <div key={i} className="card h-10 match_preview">
                                        <Form>
                                            <div className="card-body p-30 m-30">
                                                <div className="row historial">
                                                    <a
                                                        className="h_titulo"
                                                        href={
                                                            "#/BusquedasDetalle/" +
                                                            data[keyName].id +
                                                            "/" +
                                                            pageSelected
                                                        }
                                                    // className="list-group-item list-group-item-action list-group-item-light"
                                                    >
                                                        <strong> {data[keyName].title}</strong>
                                                    </a>

                                                    <div className="h_contratados">
                                                        {"Contratados: "} <br />
                                                        {data[keyName].talentsHired}
                                                    </div>

                                                    <div className="h_fecha">
                                                        {"Vence: "} <br />
                                                        {moment(data[keyName].offer_end_date).format(
                                                            "DD/MM/YYYY"
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                ))}
                        </article>
                        <>
                            <Paginator
                                cantPages={cantPages}
                                handleChangePage={handleChangePage}
                                optionSelected={pageSelected}
                            />
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
};
const EnhancedForm = formikWrapper(HistorialBusquedasScreen);
export default EnhancedForm;
