import React, { Component } from "react";
import "./css/index.css";
// import "./js/index";
import { Link } from "react-router-dom";
import RegistroEmpresa from "../privateRoutes/empresa/formRegistroEmpresa/RegistroEmpresaForm";
import RegistroTalento from "../privateRoutes/talento/formRegistroTalento/RegistroTalentoForm";
import Login from "./../privateRoutes/LoginForm";
import axios from "axios";
import { url_base } from "./../../api_url";

import $ from 'jquery';

$(window).on('load', function () {
    fitScreen();
});

$(window).on('resize', function () {
    fitScreen();
});

function androidRedirect() {
    // external url
    var ref = window.open('https://play.google.com/store/apps/details?id=io.cordova.Asignate_v1', '_blank');

}

function IOSRedirect() {
    // external url
    var ref = window.open('https://apps.apple.com/ar/app/asignate/id1520253439?l=en', '_blank');

}



function monotributoRedirect() {
    // external url
    var ref = window.open('https://asignate.com.ar/guia/', '_blank');

}

function fitScreen() {

    var modulo_0_height = $('#modulo_0 .col .pic').height();
    var modulo_0_text_height = $('#modulo_0 .col .text').height();
    var modulo_0_text_mt = (modulo_0_height - modulo_0_text_height) / 2;
    $('#modulo_0 .col .text').css('margin-top', modulo_0_text_mt + 'px');

    //

    var modulo_1_height = $('#modulo_1 .col .pic').height();
    var modulo_1_text_height = $('#modulo_1 .col .text').height();
    var modulo_1_text_mt = (modulo_1_height - modulo_1_text_height) / 2;
    $('#modulo_1 .col .text').css('margin-top', modulo_1_text_mt + 'px');

    //

    var modulo_2_height = $('#modulo_2 .col .pic').height();
    var modulo_2_text_height = $('#modulo_2 .col .text').height();
    var modulo_2_text_mt = (modulo_2_height - modulo_2_text_height) / 2;
    $('#modulo_2 .col .text').css('margin-top', modulo_2_text_mt + 'px');

    //

    var modulo_3_height = $('#modulo_3 .col .pic').height();
    var modulo_3_text_height = $('#modulo_3 .col .text').height();
    var modulo_3_text_mt = (modulo_3_height - modulo_3_text_height) / 2;
    $('#modulo_3 .col .text').css('margin-top', modulo_3_text_mt + 'px');

    //

    var area_height = $('.container #modulo_4 .area').width();
    $('.container #modulo_4 .area').css('height', area_height + 'px');

    var area_1_txt_height = $('.container #modulo_4 .area.n1 .area_text').height();
    var area_1_txt_mt = (area_height - area_1_txt_height) / 2;
    $('.container #modulo_4 .area.n1 .area_text').css('margin-top', area_1_txt_mt + 'px');

    var area_2_txt_height = $('.container #modulo_4 .area.n2 .area_text').height();
    var area_2_txt_mt = (area_height - area_2_txt_height) / 2;
    $('.container #modulo_4 .area.n2 .area_text').css('margin-top', area_2_txt_mt + 'px');

    var area_3_txt_height = $('.container #modulo_4 .area.n3 .area_text').height();
    var area_3_txt_mt = (area_height - area_3_txt_height) / 2;
    $('.container #modulo_4 .area.n3 .area_text').css('margin-top', area_3_txt_mt + 'px');

    /*
     
     var modulo_header_height = $('.container .modulo.header').height();
     $('.container .modulo.header').css('height', modulo_header_height+'px');
     console.log('alto header: '+modulo_header_height);
     
     */


}

class Landing extends Component {
    constructor() {
        super();
        this.state = {
            logged: "hidden"
        }
    }

    componentDidMount() {
        fitScreen();
        const access_token_data = localStorage.getItem("token");

        if (access_token_data) {
            axios
                .get(url_base + "/users/logged", {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                })
                .then(

                    response => {
                        this.props.history.push("/Login");

                    }
                    ,
                    error => {
                        let path = `/Login`;
                        this.props.history.push(path);
                    }

                );

        }
        else {
            this.setState({ logged: "show" })
        }
    }

    render() {

        return (
            this.state.logged != "hidden" ?

                <div>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <title>Asignate</title>
                    <link
                        href="https://fonts.googleapis.com/css?family=Red+Hat+Display:500&display=swap"
                        rel="stylesheet"
                    />
                    <link rel="shortcut icon" href="favicon.ico" />
                    <link rel="stylesheet" href="css/index.css" />
                    <div className="container" style={{ maxWidth: "none" }}>
                        <div className="row headerr">
                            <div className="col-6 text-left">
                                <img
                                    className="img"
                                    height={40}
                                    width={130}
                                    style={{ marginLeft: "80px", marginTop: "21px" }}
                                    src="images/asignate.png"
                                />
                            </div>
                            <div className="col-6 text-right">
                                <div
                                    className="btn"
                                    onClick={() => this.props.history.push("/Login")}
                                >
                                    Iniciar Sesión
                                </div>
                            </div>
                        </div>
                        <div id="modulo_header" className="row modulo headerr">
                            <div className="col-12">
                                <img className="isologo" src="images/logo.png" />
                            </div>
                            <div className="col-12">
                                <div className="area_text box2">
                                    Conectamos Talentos con Empresas <br />
                                    de manera 100% digital{" "}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="boxes">
                                    <div className="box n1">
                                        <div className="area_text box1">
                                            Busco Talento <br />
                                            temporal{" "}
                                        </div>
                                        <div
                                            className="btn"
                                            onClick={() => this.props.history.push("/RegistroEmpresa")}
                                        >
                                            REGISTRO EMPRESA
                                        </div>
                                    </div>
                                    <div className="box n2">
                                        <div className="area_text box1">
                                            Busco ingresos <br />
                                            extra
                                        </div>
                                        <div
                                            className="btn"
                                            onClick={() => this.props.history.push("/RegistroTalento")}
                                        >
                                            REGISTRO TALENTO
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div id="modulo_0" className="modulo">
                            <div className="col left">
                                <div className="text" ><h1>¡Descargá la App<br /> y comenzá a usarla!</h1>
                                </div>
                                <div className="download">
                                    <Link onClick={IOSRedirect}>

                                        <img className="store" height="47" width="150" style={{ float: "left" }} src="images/applestore.png" />
                                    </Link>

                                    <Link onClick={androidRedirect}>

                                        <img className="store" height="47" width="150" style={{ float: "left" }} src="images/googleplay.png" />
                                    </Link>


                                </div>
                            </div>
                            <div class="col right">
                                <img class="pic cel" src="images/celulares.png" />
                            </div>
                        </div>

                        <div id="modulo_0" className="row modulo" hidden>
                            <div className="col-md-6 col-12 col text-md-left ">
                                <div className="row">

                                    <h1 className="h1 col-md-10 offset-md-1 col-12 text text-md-left">
                                        Entrá al nuevo mundo de las
                                        <br />
                                        oportunidades temporales
                                    </h1>{" "}
                                    <div className="col-md-10 offset-md-1 col-12">
                                        <p>¡Descargá la App y comenzá a usarla!</p>
                                    </div>
                                    <div className="col-md-10 offset-md-1 col-12 download text-md-left">
                                        <img
                                            className="store"
                                            style={{}}
                                            src="images/applestore.png"
                                        />
                                        <img
                                            className="store"
                                            style={{ marginLeft: "15px" }}
                                            src="images/googleplay.png"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 col text-md-left">
                                <img className="pic cel" src="images/celulares.png" />
                            </div>
                        </div>
                        <div id="modulo_1" className="row modulo gris">
                            <h2 className="h2 col-12">Nuestros principios</h2>
                            <div className="col-10 offset-1 mb-1">
                                <div className="row">
                                    <div className="col-6 col-md-3 text-center mb-4">
                                        <div
                                            className="col-12 "
                                            style={{
                                                backgroundColor: "white",
                                                height: "300px",
                                                boxShadow: "0px 2px 4px 0px rgba(193, 193, 193, 1)",
                                                fontSize: "12px"
                                            }}
                                        >
                                            <img
                                                className="icon"
                                                src="images/talento.png"
                                                style={{ marginTop: "1rem" }}
                                            />
                                            <h3 className="h3">Talento verificado</h3>
                                            <p className="text">
                                                Más y mejor talento con
                                                <br />
                                                caliﬁcación transparente.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3 text-center mb-4">
                                        <div
                                            className="col-12 "
                                            style={{
                                                backgroundColor: "white",
                                                height: "300px",
                                                boxShadow: "0px 2px 4px 0px rgba(193, 193, 193, 1)",
                                                fontSize: "12px"
                                            }}
                                        >
                                            <img
                                                className="icon"
                                                src="images/digitales.png"
                                                style={{ marginTop: "1rem" }}
                                            />
                                            <h3 className="h3">Digitales</h3>
                                            <p className="text">
                                                Procesos ágiles y 100% <br />
                                                digitales.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3 text-center mb-4">
                                        <div
                                            className="col-12 "
                                            style={{
                                                backgroundColor: "white",
                                                height: "300px",
                                                boxShadow: "0px 2px 4px 0px rgba(193, 193, 193, 1)",
                                                fontSize: "12px"
                                            }}
                                        >
                                            <img
                                                className="icon"
                                                src="images/formales.png"
                                                style={{ marginTop: "1rem" }}
                                            />
                                            <h3 className="h3">Formales</h3>
                                            <p className="text">
                                                Prestadores formales y<br />
                                                asegurado.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3 text-center mb-4">
                                        <div
                                            className="col-12 "
                                            style={{
                                                backgroundColor: "white",
                                                height: "300px",
                                                boxShadow: "0px 2px 4px 0px rgba(193, 193, 193, 1)",
                                                fontSize: "12px"
                                            }}
                                        >
                                            <img
                                                className="icon"
                                                src="images/costo.png"
                                                style={{ marginTop: "1rem" }}
                                            />
                                            <h3 className="h3">sin intermediarios</h3>
                                            <p className="text">
                                                Bajo costo extra, sin
                                                <br />
                                                consultoras ni agencias
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="modulo_2" className="row modulo">
                            <div className="col-md-6 col-12 col order-md-2">
                                <div className="row">
                                    <div className="text" style={{ width: "100%" }}>
                                        <h1 className="col-12 text-md-left h1">¿Sos una empresa?</h1>{" "}
                                        <p className="col-12 text-md-left">
                                            ¡En Asignate encontrá el talento que
                                            <br /> necesitas, en el momento que lo necesitás
                                            <br /> y sin intermediarios!
                                            <br />
                                            <br /> Con proveedores formales y asegurados
                                            <br />
                                        </p>
                                    </div>
                                    <div className="col-12 text-md-left">
                                        <div
                                            className="btn"
                                            onClick={() => this.props.history.push("/RegistroEmpresa")}
                                        >
                                            REGISTRARME
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 offset-md-1 col-12 col text-md-left order-md-1">
                                <img className="pic" src="images/sosunaempresa.png" />
                            </div>
                        </div>
                        <div id="modulo_3" className="row modulo gris">
                            <div className="col-md-5 offset-md-1 col-12 col">
                                <div className="row">
                                    <div className="text" style={{ width: "100%" }}>
                                        <h1 className="col-12 text-md-left h1">¿Sos un talento?</h1>{" "}
                                        <p className="col-12 text-md-left">
                                            ¡Ofrecé tus servicios de manera
                                            <br />
                                            independiente y 100% flexible!
                                            <br />
                                            <br />
                                            Ingresá sin costo a nuestra plataforma
                                        </p>
                                    </div>
                                    <div className="col-12 text-md-left">
                                        <div
                                            className="btn"
                                            onClick={() => this.props.history.push("/RegistroTalento")}
                                        >
                                            REGISTRARME
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-12 col">
                                <img className="pic" src="images/sosuntalento.png" />
                            </div>
                        </div>
                        <div id="modulo_4" className="row modulo pb-3">
                            <h2 className="col-12 h2">Nuestras oportunidades</h2>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="col-12 area n1">
                                            <div className="row">
                                                <div className="col-12 area_text">VENTAS</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="col-12 area n2">
                                            <div className="row">
                                                <div className="col-12 area_text">EVENTOS</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="col-12 area n3">
                                            <div className="row">
                                                <div className="col-12 area_text">TALLER</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row footerr pb-md-0 pb-1">
                            <div className="txt n1" hidden> Bajate la app</div>
                            <div className="download" hidden>
                                <img
                                    className="store"
                                    height={40}
                                    width={130}
                                    style={{ margin: "15px" }}
                                    src="images/footer1.png"
                                />
                                <img
                                    className="store"
                                    height={40}
                                    width={130}
                                    style={{ margin: "15px" }}
                                    src="images/footer2.png"
                                />
                            </div>
                            <div className="col-12 txt n2">
                                {" "}
                                ⒸAsignate 2020 &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                <a
                                    style={{ textDecoration: "none", color: "#fff" }}
                                    href="mailto:hola@asignate.com"
                                >
                                    Contacto
                                </a>
                                <Link
                                    style={{ textDecoration: "none", color: "#fff" }}
                                    onClick={monotributoRedirect}
                                >
                                    &nbsp;&nbsp;{" "} - &nbsp; Guía Monotributo
                                </Link>
                            </div>
                        </div>
                    </div>
                </div> : <></>
        );
    }
}
export default Landing;
