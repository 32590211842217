import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FormGroup } from "reactstrap";
import { animateScroll as scroll } from 'react-scroll'


import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

import "./../../../style.css";
import "./style.css";


import { url_base } from "../../../../api_url";
import axios from "axios";

import "./../../../../Styles/general.css";
import "./styleQuill.css";

const ModalUpdateFields = props => {
    const {
        showModalInfo,
        modalTitle,
        closeModal,
        modalText,
        modalBtn,
        error,
        updateDescription
    } = props;

    const [description, setDescription] = useState();
    const [vacancies, setVacancies] = useState();
    const [initialVacancies, setInitialVacancies] = useState();
    const [street, setStreet] = useState();
    const [streetNumber, setStreetNumber] = useState();
    //const [title, setTitle] = useState(false);
    const location = useLocation();
    const [errors, setErrors] = useState([]);
    var err = {};


    useEffect(() => {
        const key = location.pathname.split("/");
        const access_token_data = localStorage.getItem("token");

        scroll.scrollTo(200);
        const parameters = {
            "order_param": "1",
            "order_direction": "a",
            "filters": [
                {
                    "param": "monotributista",
                    "value": "0"
                }
            ]
        }

        axios
            .post(url_base + "/companies/job-searches/" + key[2], parameters, {
                headers: { ["Authorization"]: "Bearer " + access_token_data }

            })

            .then(
                response => {

                    if (response.data.result === "1") {

                        setDescription(response.data.data.jobOffer.description);
                        setVacancies(response.data.data.jobOffer.vacancies);
                        setStreet(response.data.data.jobOffer.address_street);
                        setStreetNumber(response.data.data.jobOffer.address_number);
                        setInitialVacancies(response.data.data.jobOffer.vacancies);

                    }
                },
                error => {
                    var status = error.response;
                }
            );
        return () => {

        }
    }, [])
    function handleSubmit(e, closeModal, errors) {
        e.preventDefault();

        var isError = false;
        var errorsMsgTitle = {};
        var errorsMsgCity = {};
        var errorsMsgSubCategory = {};

        if (description === "<p><br></p>") {
            err.description = "requerido";
            isError = true;
        }
        if (vacancies === "") {
            err.vacancies = "requerido";
            isError = true;
        } else {

            if (initialVacancies > vacancies) {
                err.vacancies = "el número de vacantes actual debe ser mayor o igual que el de la última publicación";
                isError = true;
            }
        }
        if (street === "") {
            err.street = "requerido";
            isError = true;
        }
        if (streetNumber === "") {
            err.streetNumber = "requerido";
            isError = true;
        }
        if (isError === false) {
            setErrors({});
            const key = location.pathname.split("/");
            //  if (description === "<p><br></p>") { description.replace("<p><br></p>", "") }
            const values = {
                "description": description,
                "addressStreet": street,
                "addressNumber": streetNumber,
                "vacancies": vacancies
            }
            const access_token_data = localStorage.getItem("token");
            const valuesToApi = JSON.stringify(values).replace("null", "");

            axios
                .patch(url_base + "/companies/job-searches/edit/" + key[2], values, {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                })
                .then(response => {

                    let message = "";
                    closeModal();
                    updateDescription();
                })
                .catch(error => {

                }
                );


        } else {
            setErrors(err);

        }
    }

    return (
        <>

            {/*  <div className={isModalOpen ? "modal_show" : "modal_hide"}> */}

            <div className="modal_background_updates" style={{ pointerEvents: "auto" }}>
                <div className="modal_content_updates"  >
                    {!showModalInfo ? (
                        <form method="POST" name={"form"} onSubmit={e => handleSubmit(e, closeModal, errors)} className="col-12">
                            <>
                                <div className="row">
                                    <div
                                        className="col-12"
                                        style={{ textAlign: "right", marginBottom: "5px" }}
                                    >
                                        <a
                                            className="close"
                                            onClick={e => closeModal(e)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            &times;
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" style={{ pointerEvents: "auto" }}>
                                        <label className="label_black">Descripción</label>
                                        <div >
                                            <div className="customDatePickerWidth" style={{ height: "8.5rem", color: "#000000" }}>
                                                <FormGroup>
                                                    <div className="customDatePickerWidth" style={{ height: "6.5rem", color: "#000000" }}>
                                                        <ReactQuill value={description || ""}
                                                            style={{ height: "4.7rem" }}
                                                            modules={{
                                                                toolbar: {
                                                                    container: [
                                                                        ["bold", "italic", "underline"],

                                                                        [
                                                                            { list: "bullet" },

                                                                        ],

                                                                    ],

                                                                },
                                                            }}
                                                            onChange={(e) => {
                                                                { setDescription(e) }
                                                            }
                                                            }

                                                        />
                                                    </div>


                                                </FormGroup>

                                            </div>
                                            {errors.description && errors.description ? (
                                                <div className="frm_error_c_modal">{errors.description}</div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </div>
                                        <div>
                                            <div className="customDatePickerWidth" style={{ height: "2rem", }}>
                                                <FormGroup>
                                                    <label className="label_black">Vacantes</label>
                                                    <input
                                                        name="vacancies"
                                                        className="form-control"
                                                        //type={"vacancies"}
                                                        type="number"
                                                        onChange={(e) => { setVacancies(e.target.value) }}
                                                        /*render={setInitialVacancies(vacancies)}*/
                                                        placeholder="vacantes"
                                                        value={vacancies}
                                                    />
                                                    {errors.vacancies ? (
                                                        <div className="frm_error_c_modal">{errors.vacancies}</div>
                                                    ) : <div style={{ height: "15px" }} />}
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="tit"
                                                style={{
                                                    fontSize: "18px",
                                                    fontWeight: "bold",
                                                    marginLeft: "0",
                                                    marginTop: "1rem"

                                                }}
                                            >
                                                <strong>Dirección</strong>
                                            </div>
                                            <div className="customDatePickerWidth" style={{ height: "4rem", marginTop: "2rem", marginBottom: "2rem" }}>
                                                <label className="label_black">Calle</label>
                                                <FormGroup>

                                                    <input
                                                        name="street"
                                                        className="form-control"
                                                        //type={"vacancies"}
                                                        type="text"
                                                        onChange={(e) => { setStreet(e.target.value) }}
                                                        placeholder="calle"
                                                        value={street}
                                                    />
                                                    {errors.street ? (
                                                        <div className="frm_error_c_modal">{errors.street}</div>
                                                    ) : <div style={{ height: "15px" }} />}
                                                </FormGroup>
                                            </div>

                                            <div>
                                                <div className="customDatePickerWidth" style={{ height: "5rem", marginTop: "3rem", marginBottom: "1rem" }}>
                                                    <label className="label_black">Número</label>
                                                    <FormGroup>

                                                        <input
                                                            name="streetNumber"
                                                            className="form-control"
                                                            //type={"vacancies"}
                                                            type="text"
                                                            onChange={(e) => { setStreetNumber(e.target.value) }}
                                                            placeholder="número"
                                                            value={streetNumber}
                                                        />
                                                        {errors.streetNumber ? (
                                                            <div className="frm_error_c_modal">{errors.streetNumber}</div>
                                                        ) : <div style={{ height: "15px" }} />}
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button
                                            className="btn btn-blue btn-block"
                                            //type="submit"
                                            style={{ width: "100%" }}
                                        >
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                            </>
                        </form>
                    ) : (
                        <>
                            {" "}
                            <div className="col-12">
                                <div className="row">
                                    <div className="modal_title">{modalTitle}</div>
                                    <div className="modal_text">{modalText}</div>
                                    <button className="modal_btn" onClick={() => closeModal()}>
                                        {modalBtn}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

        </>
    );
};

/*const EnhancedForm = formikWrapper(ModalPreguntasScreen);
export default EnhancedForm;*/
export default ModalUpdateFields;