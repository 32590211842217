import React, { Component } from "react";

import { Formik, Field, Form, withFormik } from "formik";
import { FormFeedback, Input, FormGroup, Button } from "reactstrap";
import "./Styles/general.css";
import { withRouter } from "react-router-dom";
import RenderToLayer from "material-ui/internal/RenderToLayer";
import Modal from "./components/common/Modal";

const RegistryVerificationScreen = props => {
  const {
    handleChange,
    errors,
    touched,
    showModal,
    modalTitle,
    modalText,
    modalBtn,
    modalReset,
    path,
    redirect
  } = props;
  return (
    <div>
      <div className="row justify-content-center login_screen">
        <div className="card login">
          <article className="card-body">
            {showModal ? (
              <Modal
                modal_title={modalTitle}
                modal_text={modalText}
                modal_btn={modalBtn}
                modalReset={modalReset}
                path={path}
                redirect={redirect}
              />
            ) : (
              ""
            )}
          </article>
        </div>
      </div>
    </div>
  );
};

export default withRouter(RegistryVerificationScreen);
