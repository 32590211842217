import React, { useState, useEffect } from "react";
import AgregarAptitudes from "./AgregarAptitudes";
import { withFormik, ErrorMessage, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { FormFeedback, Input, FormGroup, Label, CustomInput } from "reactstrap";

import { withRouter, Link } from "react-router-dom";
import {

    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const formikWrapper = withFormik({
    // enableReinitialize: false,
    mapPropsToValues: props => ({ checkboxGroup: props.checkboxGroup || "" }),
    handleSubmit: (values, { props }, isValidating) => {

        if (values.checkboxGroup !== "" && values.checkboxGroup !== undefined) {
            let arraySkills = [];

            values.checkboxGroup !== "" &&
                Object.keys(values.checkboxGroup).map((keyName, i) => {
                    arraySkills.push(values.checkboxGroup[keyName].id);
                });


            localStorage.setItem("skills", arraySkills);

            let path = `/NuevaBusqueda/`;

            props.history.push({ pathname: "/NuevaBusqueda/" + props.idKey });
            //  props.history.push(path);
            //   props.history.push({ pathname: path, state: { referedData: "1" } });
        } else {
            props.functionSetError();
        }
    },
    validationSchema: Yup.object().shape({
        checkboxGroup: Yup.array().required("debe seleccionar una opción.")
    })
});

const AgregarAptitudesScreen = props => {
    let r = "";

    const [count, setCount] = useState(0);
    const {
        skills,
        handleSubmit,
        isSubmitting,
        values,
        checks,
        errors,
        arrayHelpers,
        touched,
        checkboxGroup,
        result,
        skillsData,
        idKey
    } = props;

    return (
        <div>
            {" "}
            <Form onSubmit={handleSubmit}>
                <div className="row justify-content-center mr-0 ml-0">
                    <div className="decoration"></div>
                    <div className="page_container">
                        <div className="card">
                            <article className="card-body">
                                <h4 className="card-title text-center">
                                    <Link to={"/NuevaBusqueda/" + idKey}>
                                        <FontAwesomeIcon
                                            className="arrow_back"
                                            icon={faArrowLeft}
                                            size="1x"
                                        />
                                    </Link>
                                    APTITUDES
                                </h4>

                                <ul className="list-group aptitudes_add">
                                    {skills.map(item => (
                                        <label key={item.id}>
                                            <li className="list-group-item">
                                                <div className="checkbox">
                                                    <FieldArray
                                                        name="checkboxGroup"
                                                        render={checkboxGroup => (
                                                            <Input
                                                                name="checkboxGroup"
                                                                className="blue"
                                                                type="checkbox"
                                                                id={item.id}
                                                                //  checked={values.gender === "m" ? true : ""}
                                                                value={item.id}
                                                                /*checked={checks.map((check, index) => {
                                                                  console.log(item.id);
                                                                })}*/

                                                                render={checks.find(ch =>
                                                                    ch === item.id
                                                                        ? ((r = "checked"),

                                                                            (values.checkboxGroup = values.checkboxGroup.filter(
                                                                                function (obj) {
                                                                                    // console.log("obj.id " + obj.id)
                                                                                    if (obj !== undefined) {
                                                                                        //  obj = obj.substring(1)
                                                                                        return obj.id !== item.id;
                                                                                    }
                                                                                }
                                                                            )),
                                                                            //    item.skill !== undefined ?
                                                                            //  console.log("checks " + checks),
                                                                            values.checkboxGroup.push({
                                                                                id: item.id,
                                                                                description: item.skill
                                                                            })// : ""
                                                                        )
                                                                        : (r = "")
                                                                )}
                                                                defaultChecked={r}
                                                                onChange={e => {
                                                                    if (e.target.checked) {
                                                                        values.checkboxGroup.push({
                                                                            id: item.id,
                                                                            description: item.skill
                                                                        })

                                                                    } else {
                                                                        values.checkboxGroup = values.checkboxGroup.filter(
                                                                            function (obj) {

                                                                                return obj.id !== item.id;

                                                                            })

                                                                    }
                                                                    {

                                                                        localStorage.setItem(
                                                                            "checks",
                                                                            JSON.stringify(values.checkboxGroup)
                                                                        );

                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <label>{item.skill}</label>
                                                </div>
                                            </li>
                                        </label>
                                    ))}
                                </ul>
                                {errors.checkboxGroup && touched.checkboxGroup ? (
                                    <div class="frm_error_c" style={{ marginBottom: "1rem" }}>
                                        {errors.checkboxGroup}
                                    </div>
                                ) : null}
                                <div className="row justify-content-end">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <button
                                                className="btn btn-blue btn-block"
                                                type="submit"
                                            //  disabled={isSubmitting}
                                            //onClick={values => handleSubmitValue(values)}
                                            >
                                                Guardar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};

const EnhancedForm = formikWrapper(AgregarAptitudesScreen);
//export default EnhancedForm;
export default withRouter(EnhancedForm);
