import React,{ Component } from "react"

class Terms extends Component{
	render(){
	  return (
		<div >
		  <meta charSet="utf-8" />
		  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
		  <title>Asignate</title>
		  <link rel="shortcut icon" href="favicon.ico" />
		  <div style={{float: 'left', width: '80%', marginLeft: '10%', marginRight: '10%', marginTop:"2%"}}>
			<p className="p1"><span className="s1"><strong>Términos y Condiciones Generales de los Servicios brindados en el Sitio y/o la aplicación móvil ASIGNATE</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Versión vigente: Enero de 2020</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Este contrato describe los términos y condiciones generales (los "Términos y Condiciones Generales") aplicables al uso de los servicios ofrecidos por WCL S.A., CUIT 33-71659725-9, ("los Servicios") dentro del sitio www.ASIGNATE.com.ar<span className="Apple-converted-space">&nbsp; </span>o www.ASIGNATE.com ("ASIGNATE" o el "Sitio").&nbsp;</span></p>
			<p className="p3"><span className="s2">Cualquier persona (en adelante "Contratantes" o "Proveedores de Servicios" y en su conjunto “Usuarios”) que desee acceder y/o usar el sitio o los Servicios, podrá hacerlo sujetándose a los Términos y Condiciones Generales respectivos, junto con todas las demás políticas y principios que rigen ASIGNATE y que son incorporados al presente por referencia.</span></p>
			<p className="p3"><span className="s2"><strong>&nbsp;</strong></span></p>
			<p className="p3"><span className="s2"><strong>CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES GENERALES, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR EL SITIO Y/O LOS SERVICIOS.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">El Usuario debe leer, entender y aceptar todas las condiciones establecidas en los Términos y Condiciones Generales, Reglas de Uso del Sitio previstas en el punto 5 del presente Acuerdo y en las Políticas de Privacidad, así como en los demás documentos incorporados a los mismos por referencia, previo a su registración como Usuario de ASIGNATE, ya sea como Proveedor de Servicios o como Contratante, optando de forma expresa por recibir los mismos y toda otra información por medios digitales.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>1. Capacidad para ser miembro del Sitio.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Para ser miembro de ASIGNATE y utilizar los Servicios que se prestan en el Sitio deberás tener capacidad legal para contratar. Además, en el caso de te registres como Prestador de Servicios deberás contar con tu inscripción de monotributista ante la AFIP vigente y sin deuda impositiva. No podrán utilizar los servicios las personas que no tengan esa capacidad, los menores de edad o Usuarios de ASIGNATE que hayan sido suspendidos temporalmente o inhabilitados definitivamente. Si estás registrando un Usuario como Empresa Contratante, debes tener capacidad para contratar a nombre de tal entidad y de obligar a la misma en los términos aquí establecidos.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>2. Alcance de los servicios de ASIGNATE.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">El Sitio brinda un espacio donde Contratantes y Proveedores de Servicios independientes pueden identificarse fácilmente y contratar u ofrecer servicios prestados por personas que se desempeñan de forma autónoma e independiente y/o realizar propuestas de contrataciones temporales en línea en los términos y alcances señalados en la sección 3 de estos términos y condiciones.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Mediante el Sitio, ASIGNATE provee a sus Usuarios (Contratantes y Proveedores de Servicios) el alojamiento y mantenimiento del Sitio, lo que permite la formación del Servicio de Contratos entre los Usuarios.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">A partir del momento en que un Contratante y un Prestador de Servicios acuerdan los términos de los Servicios, un Contrato de Servicios se forma directamente entre el Contratante y el Prestador de Servicios, el cual queda sujeto a las disposiciones establecidas en la Sección 3 (Relación contractual entre el Contratante y el Proveedor de Servicios), independientemente de las otras condiciones especiales que las partes de la contratación acuerden.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Cuando un usuario celebra un Contrato de Servicio, el usuario utiliza el Sitio para participar, comunicarse, y hacer valoraciones sobre los desempeños mutuos de las partes contratantes al finalizar la contratación del servicio, en los términos establecidos en el presente Acuerdo.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios reconocen y aceptan que ASIGNATE puede proporcionar, como otro de sus servicios, información en el Sitio sobre un Proveedor de Servicios o un Contratante, tales como comentarios, incluyendo una calificación sobre su capacidad, eficacia, desempeño, ubicación geográfica, o verificación de identidad o credenciales. Sin embargo, dicha información se basa únicamente en los datos que los Proveedores de Servicios o Contratantes envían voluntariamente a ASIGNATE, lo cual no constituye ni se podrá interpretar como una postulación, respaldo o recomendación efectuada por ASIGNATE, ni que ASIGNATE garantice la veracidad ni exactitud de tal información, la cual es de exclusiva responsabilidad de los Usuarios.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">En tal sentido, los Usuarios saben y aceptan que la información mencionada en el párrafo precedente, la proporciona ASIGNATE en su Sitio al sólo efecto de la conveniencia de los Usuarios.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>3. Relación contractual entre el Contratante y el Proveedor de Servicios.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>3.A. Alcance de los contratos de servicios efectuados entre los Usuarios.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios de este Sitio reconocen y aceptan que cuando los Proveedores de Servicios aplican para un Contrato ofertado por cualquier Contratante que opere en el Sitio, se considerará que ambas partes tienen celebrado un "Contrato de Servicio". En este sentido, el Proveedor de Servicios está obligado a estar disponible para ser contactado y para prestar el servicio objeto de la contratación.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios del Sitio, como asimismo, las partes del Contrato de Servicios, reconocen y aceptan que ASIGNATE no es parte de dicho contrato de servicios, y que la celebración de un contrato de servicio entre un Contratante y un Prestador de Servicios independiente no creará una relación laboral u otra relación de servicio entre ASIGNATE y el Prestador de Servicios, ni implicará un contrato de sociedad, joint venture o franquicia o cualquier otra figura societaria entre ASIGNATE&nbsp; y el Contratante.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Por otro lado, los Usuarios declaran conocer y aceptar que ASIGNATE de ninguna manera supervisa, dirige o controla a los Proveedores de Servicios ni la prestación de sus servicios, como tampoco establece las horas en que se prestará el servicio ni la ubicación, ni está involucrado en determinar otras condiciones de la contratación</span><span className="s3"><strong>.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios también saben y aceptan que ASIGNATE no garantiza la calidad, seguridad o legalidad de los Servicios que brindan los Proveedores de Servicios; la verdad o exactitud de los listados de Proveedores de Servicios incluidos en el Sitio; las calificaciones, antecedentes o identidades de los Usuarios; la capacidad de los Proveedores de Servicios; la capacidad económica de los Contratantes para pagar los Servicios brindados por los Proveedores de Servicios; o que un Contratante o Proveedor de Servicios pueda completar o completará una contratación.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Sin perjuicio de las sanciones que ASIGNATE pueda aplicar -que<span className="Apple-converted-space">&nbsp; </span>se encuentran previstas en las sección 6- a partir de que le sea reportada por parte de cualquier usuario una violación a los términos de este Acuerdo y/o la reglas mencionadas, u otros términos que exija el Sitio, ASIGNATE no está obligado ni puede verificar ningún comentario o información ingresada al Sitio por Proveedores de Servicios o Contratantes, como tampoco ASIGNATE garantiza los antecedentes de los Proveedores de Servicios o de los Contratantes.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>3.B. Responsabilidad emergente a partir<span className="Apple-converted-space">&nbsp; </span>de los Contratos de Servicios celebrados entre los Usuarios.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios aceptan y reconocen que ASIGNATE sólo ha creado y opera el Sitio&nbsp; con el fin de que Contratantes y Proveedores de Servicios puedan identificarse mutuamente de forma práctica, eficiente y rápida, y contratar servicios prestados por Proveedores de Servicios independientes que poseen cierto perfil, capacidades y confiabilidad, las cuales se desprenden a partir de la información que sobre los mismos figure en el Sitio y que, como ha sido señalado, es incorporada en base a los comentarios que otros Usuarios han realizado del Proveedor de Servicios.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">En este sentido, los Usuarios reconocen y aceptan que ASIGNATE no tendrá ninguna responsabilidad por pérdidas, gastos, daños o demoras que surjan de cualquier falla en la provisión de servicios por parte del Proveedor de Servicios, ya sea el incumplimiento por dolo o negligencia, mala conducta o falta de habilidad del Proveedor de Servicios, como tampoco por cualquier incumplimiento en que incurra el Contratante en el marco de la contratación.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>3.C. Deber de cuidado y de adoptar medidas de seguridad.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Proveedores de Servicios independientes contratados a partir de su identificación en el Sitio que opera ASIGNATE se consideran responsabilidad del Contratante desde el momento en que el Proveedor de Servicios informa su aceptación para comenzar con la contratación y hasta que ésta concluye.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">El Contratante conoce y acepta ser responsable ante terceros por todos los actos, errores y omisiones, en que el Proveedor de Servicios incurra, ya sea de forma intencional, negligente o de otra manera, en el desempeño del servicio para el que fue contratado.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Además, el Contratante será responsable de proporcionar al Proveedor de Servicios un ambiente seguro para que éste pueda prestar de forma segura sus servicios durante la contratación. El Contratante deberá cumplir con legislación en materia de seguridad e higiene, y deberá proporcionar al Proveedor de Servicios toda la información para que esté al tanto de los riesgos particulares que podría implicar la contratación.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>3.D. Seguros.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">El Contratante se compromete a asegurar contra riesgos a terceros, derivado de cualquier acto o incumplimiento del Proveedor de Servicios durante todo el término que dure la prestación del servicio contratado y mantener indemne a ASIGNATE respecto de todos los reclamos, costos y daños que surjan a partir del Servicio brindado por el Proveedor de Servicios. El Contratante además, se compromete a asegurar al Proveedor de Servicios respecto de todo tipo de riesgos con respecto a reclamos de terceros.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">En cualquier caso, ningún incumplimiento por parte del Contratante en sus obligaciones de contratar los seguros correspondientes reducirá ni eliminará sus obligaciones de mantener indemne a ASIGNATE.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>3.E. Obligación de pago por la Contratación de Servicios.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>1. Tarifa de servicio brindado por el Proveedor de Servicios.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">El Contratante será el único&nbsp; responsable de pagar al Proveedor de Servicios por los servicios realizados en el marco de la contratación (la "Tarifa&nbsp; de Servicio").</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">En tal sentido, los Usuarios conocen y aceptan que ASIGNATE no es una agencia que selecciona personal para presentarlo a terceros sino que brinda su Sitio para que Proveedores de Servicios independientes oferten sus servicios y acepten por su cuenta las propuestas de contrataciones de servicios publicadas a su vez por los Contratantes que crean más convenientes y que se ajustan a su perfil.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">ASIGNATE no cobra al Proveedor de Servicios comisión ni cargo alguno por concretar la contratación, sino que cobra una tarifa al Contratante por los servicios que brinda el Sitio y por su mantenimiento, la cual se encuentra estipulada en el punto 4 del presente.&nbsp;</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>2. Falta de pago.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Proveedores de Servicios aceptan que ante cualquier incumplimiento en materia de pago u otro relativo a la contratación que hayan efectuado con el Contratante, ASIGNATE no será responsable por los mismos, debiendo dirimir entre las partes de la contratación sus reclamos.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Sin perjuicio de lo anterior, ante el caso de que el Contratante no pague los honorarios del Proveedor de Servicios&nbsp; o cualquier otro monto adeudado bajo este Acuerdo, ASIGNATE, a su sola discreción,<span className="Apple-converted-space">&nbsp; </span>podrá suspender o cerrar su cuenta y revocarle el acceso al Sitio hasta tanto las partes no solucionen el conflicto. </span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>3.F. Deber de mantener registros de documentación.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Contratantes y los Proveedores de Servicios deberán crear y mantener registros para documentar las contrataciones efectuadas en el marco de este Acuerdo; sus respectivas obligaciones de pago y el cumplimiento de las leyes fiscales.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Asimismo, las partes mencionadas deberán proporcionar copias de tales registros a ASIGNATE, si ésta se los solicitara, sin que ello implique que ASIGNATE supervise el cumplimiento de un usuario con este Acuerdo, los otros Términos de servicio o un Contrato de servicio.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>3.G. Deber de indemnidad.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios del Sitio, además de reconocer que ASIGNATE no es parte de ningún contrato que celebren entre los Proveedores de Servicios y los Contratantes, aceptan mantener indemne a ASIGNATE, sus respectivos funcionarios, directores, agentes, subsidiarias, empresas conjuntas y empleados (cada uno "Parte indemnizada") de todos y cada uno de los reclamos, daños, responsabilidades, costos, pérdidas, y gastos (incluidos, entre otros, honorarios razonables de abogados y todo costo y gasto relacionado) derivados de o relacionados con cualquier reclamo, demanda, procedimiento, demanda o acción presentada por un Usuario, o un tercero u otro Usuario contra una Parte indemnizada relacionada con:</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">(a) el uso del Sitio y los Servicios del sitio efectuado por cualquier Usuario o sus agentes, incluidas las obligaciones de pago incurridas a través del uso de los servicios del Sitio;&nbsp;</span></p>
			<p className="p3"><span className="s2">(b) cualquier Contrato de servicio celebrado entre los Usuarios o sus agentes, incluyendo, pero no limitado a, cualquier reclamo en materia laboral, etc;&nbsp;</span></p>
			<p className="p3"><span className="s2">(c) incumplimiento de este Acuerdo por los Usuarios o sus agentes;&nbsp;</span></p>
			<p className="p3"><span className="s2">(d) falta de cumplimiento de la ley aplicable incurrida por los Usuarios o sus agentes;&nbsp;</span></p>
			<p className="p3"><span className="s2">(e) negligencia, intencional mala conducta o fraude incurrida por los Usuarios o sus agentes; y&nbsp;</span></p>
			<p className="p3"><span className="s2">(f) difamación, violación de derechos de privacidad, competencia desleal o infracción de los derechos de propiedad intelectual en la medida en que los Usuarios o sus agentes lo hayan causado.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>4. Pago por parte de los Contratantes por uso de Sitio. Métodos de pago.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Para utilizar ciertos Servicios del sitio, el Contratante deberá abonar a ASIGNATE, conforme al método de pago que ASIGNATE posteriormente le indique,<span className="Apple-converted-space">&nbsp; </span>un porcentaje</span> <span className="s2"> sobre la tarifa total que deba abonar al Proveedor de Servicios al momento de que la propuesta de contratación es aceptada por éste último,. Dicha suma ASIGNATE la destinará al mantenimiento del Sitio.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>5. Reglas de acceso y uso del Sitio.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>5.A. Firma digital.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Al registrarse para obtener una cuenta en el Sitio de ASIGNATE (una "Cuenta"), se considera que usted ha ejecutado este Acuerdo electrónicamente y será efectivo desde la&nbsp; fecha de registro de su cuenta. El registro de su cuenta constituye un reconocimiento de que puede recibir, descargar e imprimir electrónicamente este Acuerdo, y cualquier enmienda.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>5.B. Consentimiento para utilizar registros electrónicos.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios aceptan y autorizan a ASIGNATE a proporcionarles estos registros electrónicamente en lugar de en papel.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Asimismo, al registrarse para obtener una cuenta, los Usuarios aceptan recibir y acceder, por correo electrónico o a través del Sitio.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Sin perjuicio de lo anterior, en todo momento los Usuarios tendrán la posibilidad de retirar su consentimiento para recibir registros y notificaciones electrónicamente para lo cual deberán hacerlo saber a ASIGNATE contactando Atención al cliente hola@asignate.com.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios conocen y aceptan que si retiran su consentimiento para recibir dichos registros y avisos electrónicamente, ASIGNATE podrá revocar su acceso al Sitio y ya no se le permitirá usar el mismo.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>5.C. Deber de mantener dirección de correo electrónico actualizada.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios deberán mantener actualizada su dirección de correo electrónico a los fines de que ASIGNATE pueda proporcionarle registros y notificaciones electrónicamente.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Para el caso de que el Usuario cambie dicha dirección, deberá notificar a ASIGNATE inmediatamente el cambio ya sea actualizando la información de su Cuenta en el Sitio o contactando al sector de Atención al cliente.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>5.D. Obtención de una cuenta de ASIGNATE.&nbsp;</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Para usar los Servicios del Sitio y adquirir la calidad de Usuario, Ud. deberá registrarse para obtener una Cuenta.&nbsp;</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">A tal fin, deberá cumplir con los requisitos establecidos en el punto 1 de este Acuerdo y tener en claro que los Servicios de ASIGNATE que ofrece en el Sitio, se brindan para&nbsp; fines comerciales y no para fines personales, uso doméstico o de consumo.&nbsp;</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Además, deberá (a) cumplir con este Acuerdo y los procesos, procedimientos y pautas descrito en el Sitio; (b) ser financieramente responsable ya sea para contratación y/o la prestación de Servicios; y (c) realizar sus obligaciones según lo especificado por cualquier Contrato de servicio que acepte, a menos que tales obligaciones están prohibidas por la ley aplicable o este Acuerdo.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Al postularse para la obtención de una cuenta en el Sitio, Ud. acepta que deberá brindar información verdadera, precisa y completa en todos los registros y formularios a los que accede en el Sitio y asume el compromiso de mantenerla actualizada. No debe proporcionar información falsa o engañosa sobre tu ubicación. No debe proporcionar información falsa o engañosa sobre su negocio, sus habilidades o los servicios que brinda su negocio. Queda prohibido registrarse para más de una cuenta como Contratante como así también cuenta de PROVEEDOR DE SERVICIOS sin nuestro permiso expreso por escrito (excepto que puede registrarse como un miembro de la agencia de otras cuentas como se detalla a continuación). No debes preguntar o permitir que otra persona cree una cuenta en su nombre, para su uso, o para tu beneficio.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Asimismo, para el caso de que Ud. desee obtener una cuenta en carácter de Proveedor de Servicios deberá proporcionar a ASIGNATE&nbsp; la siguiente información:</span></p>
			<p className="p2">&nbsp;</p>
			<ol className="ol1">
			  <li className="li3"><span className="s2">Documento nacional de identidad.</span></li>
			  <li className="li3"><span className="s2">Constancia de domicilio.</span></li>
			  <li className="li3"><span className="s2">Constancia de alta fiscal como monotributista.</span></li>
			  <li className="li3"><span className="s2">Certificado de reincidencia, con una antigüedad no mayor a seis (6) meses.</span></li>
			</ol>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Toda otra documentación complementaria que ASIGNATE requiera.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Además, el postulante deberá realizar una entrevista personal con el profesional que ASIGNATE le indique.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>5.E. Derecho de admisión.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">ASIGNATE se reserva el derecho, a su exclusivo criterio, de rechazar, suspender o revocar su acceso al Sitio y a los Servicios del sitio al descubrir que la información que proporcionó en cualquier formulario o publicada en el Sitio no es verdadera, precisa o completa, o dicha información u otra conducta de otra manera viola este Acuerdo, o por cualquier otro motivo o sin motivo.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>6. Uso permitido del Sitio.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>6.A. Reglas generales.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios tienen prohibido utilizar el Sitio para transmitir, distribuir, almacenar o destruir material (i) violando la normativa vigente, (ii) de forma que se infrinjan derechos de terceros o se viole la confidencialidad, honor, privacidad, imagen u otros derechos personales de otras personas o Usuarios.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios tienen prohibido violar o intentar violar la seguridad del Sitio Web.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Las violaciones de la seguridad del sistema o de la red constituyen delitos penales y pueden derivar en responsabilidades civiles. ASIGNATE investigará los casos de violaciones a la seguridad del sistema, pudiendo dirigirse a la autoridad judicial o administrativa competente a los efectos de perseguir a los Usuarios involucrados en tales violaciones. </span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>6.B. Usos prohibidos:</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">El Sitio sólo podrá ser utilizado con fines lícitos, para acceder a información referida a los Servicios disponibles a través del mismo. Se prohíbe específicamente cualquier utilización del Sitio para:</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">- Anunciar datos biográficos incompletos, falsos o inexactos.</span></p>
			<p className="p3"><span className="s2">- Usar cualquier mecanismo para impedir o intentar impedir el adecuado funcionamiento de este Sitio o cualquier actividad que se esté realizando en este Sitio .</span></p>
			<p className="p3"><span className="s2">- Revelar o compartir su contraseña con terceras personas, o usar su contraseña para propósitos no autorizados.</span></p>
			<p className="p3"><span className="s2">- El uso o intento de uso de cualquier máquina, software, herramienta, agente u otro mecanismo para navegar o buscar en este Sitio Web que sean distintos a las herramientas de búsqueda provistos en este Sitio Web.</span></p>
			<p className="p3"><span className="s2">- Intentar descifrar, descompilar u obtener el código fuente de cualquier programa de software de este Sitio Web. </span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Asimismo, los Usuarios reconocen y aceptan que las siguientes conductas se encuentran terminantemente prohibidas:</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">a) utilizar lenguaje vulgar /obsceno, discriminatorio y/u ofensivo;</span></p>
			<p className="p3"><span className="s2">b) todo tipo de ataque personal contra Usuarios y/o terceros, mediante acoso, amenazas, insultos;</span></p>
			<p className="p3"><span className="s2">c) todo acto contrario a las leyes, la moral y las buenas costumbres;</span></p>
			<p className="p3"><span className="s2">d) publicar mensajes, imágenes e hipervínculos agraviantes, difamatorios, calumniosos, injuriosos, falsos, discriminatorios, pornográficos, de contenido violento, insultantes, amenazantes, incitantes a conductas ilícitas o peligrosas para la salud, y/o que vulneren de cualquier forma la privacidad de cualquier tercero como así también la violación directa o indirecta de los derechos de propiedad intelectual de ASIGNATE y/o de cualquier tercero;</span></p>
			<p className="p3"><span className="s2">e) publicar mensajes que puedan herir y/o afectar la sensibilidad del resto de los Usuarios y/o de cualquier tercero;</span></p>
			<p className="p3"><span className="s2">f) publicar mensajes que de cualquier forma contengan publicidad;</span></p>
			<p className="p3"><span className="s2">g) el uso o envío de virus informáticos, malware, spyware, ransomware y/o la realización de todo acto que cause o pudiera causar daños o perjuicios al normal funcionamiento de los Servicios o de los equipos informáticos o software de ASIGNATE y/o de cualquier tercero;</span></p>
			<p className="p3"><span className="s2">h) todo acto dirigido a enmascarar y/o falsificar o disimular direcciones IP, correos electrónicos y/o cualquier otro medio técnico de identificación de los Usuarios o sus equipos informáticos;</span></p>
			<p className="p3"><span className="s2">i) todo acto que viole la privacidad de otros Usuarios, o que viole cualquiera de sus derechos bajo la Ley N° 25.326;</span></p>
			<p className="p3"><span className="s2">j) la publicación de datos personales sin el consentimiento expreso del titular de los mismos;</span></p>
			<p className="p3"><span className="s2">k) la transmisión o divulgación de material que viole la legislación en vigor en el país y/o que pudiera herir la sensibilidad del resto de los Usuarios y/o de cualquier tercero;</span></p>
			<p className="p3"><span className="s2">l) la publicación de cualquier tipo de contenido en violación de derechos de terceros, incluyendo sin limitación los derechos de propiedad intelectual y/o industrial.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios entienden y aceptan que el sitio ASIGNATE podrá advertir, suspender en forma temporal o inhabilitar definitivamente una Cuenta o una publicación e iniciar las acciones que estime pertinentes y/o suspender el acceso al sitio de forma temporal o permanente:</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">(a) Si se acreditara que el Usuario ha incurrido en contravención de alguna ley, o cualquiera de las estipulaciones establecidas en este punto 6 o de este Acuerdo u otra política establecida por ASIGNATE; (b) si un Usuario incurrió, a criterio de ASIGNATE en conductas o actos dolosos o fraudulentos; (c) si no pudiera verificarse la identidad del Usuario o cualquier información proporcionada por el mismo fuere errónea o desactualizada; o si ASIGNATE verificara que el Usuario utiliza la identidad de otra persona para registrar una cuenta. </span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">En el caso de la suspensión o cancelación de su cuenta, todos los pedidos de trabajo serán removidos del sistema y en ningún caso se devolverán o bonificarán los pagos abonados. </span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>7. Modificaciones del Acuerdo.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">ASIGNATE podrá en cualquier momento actualizar el presente Acuerdo y publicará la nueva versión del mismo en el Sitio.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">A tal fin ASIGNATE le enviará un correo electrónico a los Usuarios notificando la entrada en vigencia de la nueva versión del Acuerdo que se considerará aceptado al momento en que los Usuarios continúen operando en el Sitio. En caso que el Usuario no esté de acuerdo con la actualización de los términos y condiciones del Acuerdo, deberá no utilizar más el Sitio y podrá ejercer el derecho del art. 6 de la Ley 25.326 inc e) y solicitar a ASIGNATE la cancelación de sus datos de registro como Usuario del Sitio. A tal fin consulte el procedimiento para solicitar la cancelación de sus datos escribiendo a hola@asignate.com</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>8. Prohibición de cesión para los Usuarios.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios no pueden ceder este Acuerdo, ni ninguno de sus derechos u obligaciones sin el consentimiento previo por escrito de ASIGNATE.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">En cambio, ASIGNATE podrá ceder libremente este Acuerdo sin el consentimiento de los Usuarios.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Cualquier intento de cesión en violación de este Acuerdo será nula y sin efecto.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>9. Propiedad intelectual. Marcas. Enlaces.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios aceptan y reconocen que el sitio ASIGNATE, bases de datos, redes, archivos que le permiten acceder y usar su Cuenta, son de propiedad exclusiva de ASIGNATE y están protegidas por las leyes y los tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos, marcas y logos quedan prohibidos, salvo autorización expresa y por escrito de ASIGNATE.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">El sitio ASIGNATE podrá contener enlaces a otros sitios web lo cual no indica que sean propiedad u operados por ASIGNATE. En virtud que ASIGNATE no tiene control sobre tales sitios, no será responsable por los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de los mismos, sean causadas directa o indirectamente. La presencia de enlaces a otros sitios web no implica una sociedad, relación, aprobación, respaldo de ASIGNATE a dichos sitios y sus contenidos. </span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>10. Divisibilidad de las disposiciones. </strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los términos y condiciones del presente Acuerdo son divisibles y la invalidez de alguno de ellos no afectará la validez de los demás términos y condiciones de ésta. </span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>11. Encabezados. </strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los encabezados de las cláusulas son sólo para referencia, y no tendrán efecto legal alguno. </span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>12. Fuerza mayor.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Las partes de este Acuerdo no serán responsables por el incumplimiento de cualquier de sus obligaciones o cualquier retraso en virtud del presente debido a disturbios laborales, accidentes, incendios, inundaciones, fallas de Internet, huelgas, guerras, disturbios, rebeliones, bloqueos, actos de gobierno, requisitos gubernamentales y regulaciones o restricciones impuestas por la ley o cualquier otra condición similar más allá del control razonable de la parte afectada.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2"><strong>13. Jurisdicción y Ley Aplicable.</strong></span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Este Acuerdo estará regido en todos sus puntos por las leyes vigentes en la República Argentina.</span></p>
			<p className="p2">&nbsp;</p>
			<p className="p3"><span className="s2">Los Usuarios aceptan que cualquier controversia derivada del presente Acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será sometida a la jurisdicción de los tribunales ordinarios de la Ciudad Autónoma de Buenos Aires. </span></p>
			<p className="p2">&nbsp;</p>
		  </div>
		</div>
	  );
	}
}
export default Terms;