import React, { Component } from "react";

import "./../../Styles/general.css";
import { withRouter } from "react-router-dom";

class ModalNoSaving extends Component {
    constructor(props) {
        super(props);
        this.state = { classMsg: true };
        this.onModalBtnClick = this.onModalBtnClick.bind(this);
    }
    componentDidMount() {
        // $(".modal_msg").fadeIn(400);
        this.setState({ classMsg: true });
    }

    onModalBtnClick() {
        this.setState({ classMsg: false });
        this.props.modalResetNoSaving();
        if (this.props.redirect) {
            this.props.history.push(this.props.path);
        }
    }

    render() {
        return (
            <div>
                <div className={this.state.classMsg ? "modal_show" : "modal_hide"}>
                    <div className="modal_background"></div>

                    <div className="modal_content">
                        <div className="modal_title">{this.props.modal_title}</div>
                        <div className="modal_text">
                            <div className="row">
                                <div className="col-12" style={{ textAlign: "center" }}>
                                    {this.props.modal_text}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12" style={{ textAlign: "center" }}>
                                    <button
                                        className="modal_btn m-1"
                                        onClick={() => this.onModalBtnClick()}
                                    >
                                        {this.props.modal_btn}
                                    </button>

                                    <button
                                        className="modal_btn m-1"
                                        style={{ backgroundColor: "red" }}
                                        onClick={() => this.props.modalCancel()}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModalNoSaving);
