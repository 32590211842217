import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Logout extends Component{
    constructor(props){
        super(props);
      
    }
    componentDidMount(){
        localStorage.clear();
        this.props.history.push("/Login");
      
    }
    
    render(){
       
        return(
            <div></div>
        )
    }
}

export default withRouter(Logout);