import React, { Component } from "react";
import Popup from "reactjs-popup";
import { FormFeedback, Input, FormGroup, Label, CustomInput } from "reactstrap";
import { withFormik, ErrorMessage, Form, Field, Formik } from "formik";
import * as Yup from "yup";
import ModalInfo from "./../../../common/Modal";

const customInputForm = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <Input
      invalid={!!(touched[field.name] && errors[field.name])}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && (
      <FormFeedback>{errors[field.name]}</FormFeedback>
    )}
  </div>
);

/*const formikWrapper = withFormik({
  mapPropsToValues: props => ({
    idname: props.idname
  }),
  validationSchema: Yup.object().shape({
    /*  valueQuestion_1: Yup.string()
     .label("gender")
     .test("is-true", "debe seleccionar una opción", value =>
       value !== "1" && value !== "0" ? false : true
     ),
     valueQuestion_2: Yup.string()
     .label("gender")
     .test("is-true", "debe seleccionar una opción", value =>
       value !== "1" && value !== "0" ? false : true
     ),*/
/*   city: Yup.string().required("requerido"),
     city: Yup.string().required("requerido"),*/
/* }),
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
  handleSubmit: (values, bag) => bag.props.handleSubmit(values)
});*/

const ModalPreguntasScreen = props => {
  const {
    handleChangeRadio,
    data,
    idname,
    setFieldValue,
    handleChange,
    errors,
    touched,
    showModalInfo,
    handleSubmit,
    showModalPreguntas,
    onModalBtnClick,
    modalTitle,
    closeModal,
    modalText,
    modalBtn,
    modalReset,
    error,
    close,
    path,
    Modal,
    redirect,
    modalOpen,
    isModalOpen,
    values
  } = props;

  return (
    <>
      {" "}
      <button className="h_btn" onClick={e => modalOpen(e)}>
        {" "}
        CALIFICAR{" "}
      </button>
      <div className={isModalOpen ? "modal_show" : "modal_hide"}>
        <div className="modal_background"></div>
        <div className="modal_content">
          {!showModalInfo ? (
            <form
              method="POST"
              name={"oForm_" + idname}
              onSubmit={e => handleSubmit(e, values, props)}
              className="col-12"
            >
              <>
                <div className="row">
                  <div
                    className="col-12"
                    style={{ textAlign: "right", marginBottom: "5px" }}
                  >
                    <a
                      className="close"
                      onClick={e => closeModal(e, setFieldValue, values)}
                      style={{ cursor: "pointer" }}
                    >
                      &times;
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {data &&
                      Object.keys(data).map((keyName, i) => (
                        <div className="row">
                          <div className="col-md-8 col-12">
                            {data[keyName].question}
                          </div>
                          <div
                            className="col-md-4 col-12"
                            style={{ textAlign: "right", marginTop: "5px" }}
                          >
                            <FormGroup tag="fieldset">
                              <div
                                className="form-check form-check-inline"
                                style={{
                                  margin: "0 0 0 0.75rem",
                                  float: "left"
                                }}
                              >
                                <input
                                  id={"radiouna_" + props.idname}
                                  name={
                                    "valueQuestion_" +
                                    data[keyName].id +
                                    "_" +
                                    props.idname
                                  }
                                  className="form-check-input radio-inline radio align-items-center "
                                  type="radio"
                                  value="1"
                                  onChange={e =>
                                    handleChangeRadio(
                                      e,
                                      document.getElementById(
                                        "descriptionQuestion_" +
                                          data[keyName].id +
                                          "_" +
                                          props.idname
                                      ),
                                      setFieldValue
                                    )
                                  }
                                />
                                <label className="form-check-label">Si</label>
                              </div>
                              <div
                                className="form-check form-check-inline"
                                style={{
                                  margin: "0 0 0 0.75rem",
                                  float: "left"
                                }}
                              >
                                <input
                                  id={"radiodos_" + props.idname}
                                  name={
                                    "valueQuestion_" +
                                    data[keyName].id +
                                    "_" +
                                    props.idname
                                  }
                                  className="form-check-input radio-inline radio align-items-center"
                                  type="radio"
                                  value="0"
                                  onChange={e =>
                                    handleChangeRadio(
                                      e,
                                      document.getElementById(
                                        "descriptionQuestion_" +
                                          data[keyName].id +
                                          "_" +
                                          props.idname
                                      ),
                                      setFieldValue
                                    )
                                  }
                                />
                                <label className="form-check-label">No</label>
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-12">
                            <FormGroup>
                              <textarea
                                id={
                                  "descriptionQuestion_" +
                                  data[keyName].id +
                                  "_" +
                                  props.idname
                                }
                                name={
                                  "descriptionQuestion_" +
                                  data[keyName].id +
                                  "_" +
                                  props.idname
                                }
                                className="form-control"
                                style={{ display: "none", resize: "none" }}
                                type="textarea"
                                rows="3"
                                placeholder="Descripción"
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </div>
                          <input
                            type="hidden"
                            name="idname"
                            value={props.idname}
                          />
                        </div>
                      ))}
                  </div>
                  {
                    <div className="frm_error_c" style={{ margin: "1rem" }}>
                      {error}
                    </div>
                  }
                  <div className="col-12">
                    <button
                      className="btn btn-primary btn-block"
                      // type="submit"
                      style={{ width: "100%" }}
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </>
            </form>
          ) : (
            <>
              {" "}
              <div className="col-12">
                <div className="row">
                  <div className="modal_title">{modalTitle}</div>
                  <div className="modal_text">{modalText}</div>
                  <button className="modal_btn" onClick={() => closeModal()}>
                    {modalBtn}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

/*const EnhancedForm = formikWrapper(ModalPreguntasScreen);
export default EnhancedForm;*/

export default ModalPreguntasScreen;
