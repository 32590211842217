import React, { Component } from "react";
import AgregarHabilidadesScreen from "./AgregarHabilidadesScreen";
import auth from "../../../../auth";

class AgregarHabilidades extends Component {
  constructor() {
    this.state = {
      Categorias: ""
    };
  }

  render() {
    if (!auth.isAuthenticated() === true || localStorage.getItem("userType") != 3) {

      this.props.history.push("/Login")
     }
    return <AgregarHabilidadesScreen />;
  }
}

export default AgregarHabilidades;
