import React, { Component } from "react";
import AgregarCategoriaScreen from "./AgregarCategoriaScreen";
import axios from "axios";
import { url_base } from "./../../../../api_url";

class AgregarCategoria extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: new Date(),
            skillsCategory: [],
            skillsSubcategory: "",
            skillsSubcategoryDisabled: "disabled",
            skillsSubcategoryID: "",
            skillsCategoryID: "",
            data: "",
            skills: ""
        };
        this.handleClick = this.handleClick.bind(this);
        this.saveformStorage = this.saveformStorage.bind(this);
        this.skillsSubcategoryHandleChange = this.skillsSubcategoryHandleChange.bind(
            this
        );
        this.deteleSkill = this.deteleSkill.bind(this);
    }

    saveformStorage(values) {
        //console.log(localStorage);
        localStorage.setItem("skillsCategory", values.skillsCategory);
        localStorage.setItem("skillsSubcategory", values.skillsSubcategory);
    }

    categoryHandleChange(e, setFieldValue) {
        setFieldValue("skillsCategory", e.target.value);
        setFieldValue("skillsSubcategory", "");
        this.setState({
            skillsCategoryID: e.target[e.target.selectedIndex].value
        });

        if (e.target[e.target.selectedIndex].value != "") {
            this.setState({
                skillsSubcategoryDisabled: ""
            });
        } else {
            this.setState({
                skillsSubcategoryDisabled: "disabled"
            });
        }

        let skillsCategoryID = e.target[e.target.selectedIndex].value;

        const access_token_data = localStorage.getItem("token");

        axios

            .get(url_base + "/skills/subcategories-with-skills/" + skillsCategoryID, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {
                // console.log(response.data.data.subcategories);
                this.setState({
                    skillsSubcategory: response.data.data.subcategories
                });
            })
            .catch();
    }

    skillsSubcategoryHandleChange(e, setFieldValue, id, categoryId) {
        setFieldValue("skillsSubcategory", id);
        //console.log("subcategory" + id);
        this.setState({
            skillsSubcategoryID: id
        });
        document.querySelector("#category_" + categoryId + " svg").style.display =
            "block";
        var subcategoriesSvg = document.querySelectorAll(".subcategory svg");
        if (subcategoriesSvg.length > 0) {
            for (var i = 0; i < subcategoriesSvg.length; i++) {
                subcategoriesSvg[i].removeAttribute("style");
            }
        }
        document.querySelector("#subcategory_" + id + " svg").style.display =
            "block";
    }

    handleClick(e, setFieldValue, id) {
        var subcategoriesSvg = document.querySelectorAll(".subcategory svg");
        if (subcategoriesSvg.length > 0) {
            for (var i = 0; i < subcategoriesSvg.length; i++) {
                subcategoriesSvg[i].removeAttribute("style");
            }
        }
        var categoriesSvg = document.querySelectorAll(".category svg");
        if (categoriesSvg.length > 0) {
            for (var i = 0; i < categoriesSvg.length; i++) {
                categoriesSvg[i].removeAttribute("style");
            }
        }

        // e.stopPropagation();
        e.preventDefault();
        //console.log("e.target.value: " + id);
        setFieldValue("skillsCategory", id);
        setFieldValue("skillsSubcategory", "");

        let skillsCategoryID = id;

        const access_token_data = localStorage.getItem("token");

        axios

            .get(url_base + "/skills/subcategories-with-skills/" + id, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {

                this.setState({
                    skillsSubcategory: response.data.data.subcategories
                });
            })
            .catch();
    }

    deteleSkill(e, id) {
        const access_token_data = localStorage.getItem("token");
        axios
            .post(url_base + "/talents/skills/delete/" + id, "", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = this.state.skills.filter(i => i.id !== id);
                    this.setState({ skills: data });
                    //    const data = response.data.result;
                    //console.log(data);
                },
                error => {
                    var status = error.response;
                    // console.log(status);
                }
            );
    }

    componentDidMount() {
        //cargo las Categorias
        const access_token_data = localStorage.getItem("token");
        axios
            .get(url_base + "/skills/categories-with-skills", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {
                //console.log("categorias recarga:" + response.data.data.categories);
                this.setState({
                    skillsCategory: response.data.data.categories
                });
            })
            .catch();

        /*  const skills = JSON.parse(localStorage.getItem("skills"));
    
        this.setState({
          skills: skills
        });*/

        //cargo las subcategorias si ya fueron cargadas

        const subID = localStorage.getItem("skillsCategory");

        if (subID) {
            this.setState({
                skillsSubcategoryDisabled: ""
            });
        } else {
            this.setState({
                skillsSubcategoryDisabled: "disabled"
            });
        }

        axios
            .get(url_base + "/skills/subcategories-with-skills/" + subID, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {
                // console.log(response.data.data.subcategories);
                this.setState({
                    skillsSubcategory: response.data.data.subcategories
                });
            })
            .catch();

        axios
            .get(url_base + "/talents/profile", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = response.data.data.talent.skills;

                    this.setState({
                        skills: data
                    });
                },
                error => {
                    var status = error.response;
                }
            );
    }

    render() {
        return (
            <AgregarCategoriaScreen
                skillsCategory={this.state.skillsCategory}
                skillsSubcategory={this.state.skillsSubcategory}
                data={this.state.data}
                handleClick={this.handleClick}
                saveformStorage={this.saveformStorage}
                skillsSubcategoryHandleChange={this.skillsSubcategoryHandleChange}
                skillsSubcategoryID={this.state.skillsSubcategoryID}
                skills={this.state.skills}
                deteleSkill={this.deteleSkill}
            />
        );
    }
}
export default AgregarCategoria;
