import React, { Component } from "react";
import logo from "./logo.svg";

import AppRoutes from "./routes";

import "./App.css";
import "./Styles/general.css";
//import NavBar from "./NavBar/NavBar";
import { Spinner } from './components/common/Spinner';



class App extends Component {
  render() {
   
    return(
     <div>
     

    <Spinner/> 
    <AppRoutes />
    
    </div>

    )}
}
export default App;
