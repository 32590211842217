import React, { Component } from "react";
import axios from "axios";
import { url_base } from "./api_url";
import RegistryVerificationScreen from "./RegistryVerificationScreen";

class RegistryVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalTitle: "",
            modalText: "",
            modalBtn: "",
            path: "",
            redirect: ""
        };

        this.modalReset = this.modalReset.bind(this);
    }

    modalReset() {
        this.setState({ showModal: false, redirect: false });
    }

    componentDidMount() {
        //   this.props.location.search;
        const key = this.props.location.pathname.split("/");



        axios.get(url_base + "/registry-verification/" + key[2]).then(
            response => {
                if (response.data.result == 1) {

                    let path = "/Login";
                    // props.history.push(path);
                    this.setState({
                        showModal: true,
                        modalTitle: "",
                        modalText: (
                            <text>
                                La cuenta fue activada exitosamente <br /> Ya puedes iniciar sesión
                            </text>
                        ),
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                } else {

                    let path = "/Login";
                    this.setState({
                        showModal: true,
                        modalTitle: "",
                        modalText: "Ocurrió un error, comunicate con nosotros",
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                }
            },
            error => {
                var message = error.response.data.message;


                let path = "/Matchs/";
                this.setState({
                    showModal: true,
                    modalTitle: "HA OCURRIDO UN ERROR",
                    modalText: message,
                    modalBtn: "Aceptar",
                    path: path,
                    redirect: true
                });
            }
        );
    }

    render() {
        return (
            <div>
                <RegistryVerificationScreen
                    showModal={this.state.showModal}
                    modalTitle={this.state.modalTitle}
                    modalText={this.state.modalText}
                    modalBtn={this.state.modalBtn}
                    modalReset={this.modalReset}
                    redirect={this.state.redirect}
                    path={this.state.path}
                />
            </div>
        );
    }
}
export default RegistryVerification;
