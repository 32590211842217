import React, { Component } from "react";
import ReactCrop, { makeAspectCrop } from "react-image-crop";
import { FlatButton, Dialog } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import "react-image-crop/dist/ReactCrop.css";
import axios from "axios";
import { url_base } from "../../../../api_url";

import auth from "../../../../auth";

class ImageCrop extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    showImageCropper: false,
    selectedImageURL: "",
    src: null,
    crop: {
      unit: "px",
      width: 30,
      aspect: 1 / 1
    },
    selectedFile: null,
    croppedImage: "",
    fileURl: "",
    imagenTo: "",
    croppedImageUrl: ""
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        //this.setState({ src: reader.result, selectedImageURL: reader.result })
        this.setState({ src: reader.result });
      });
      reader.readAsDataURL(e.target.files[0]);
    }

    this.setState({ showImageCropper: true });
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    // var contImage=document.getElementsByClassName("ReactCrop__image");
    // if (image.width >= image.height){
    //   //alert("width: " + image.width);
    //   contImage[0].style.maxWidth="100%";
    // }else{
    //   // alert("height: " + image.height);
    //   contImage[0].style.maxHeight="300px";
    // }
    this.imageRef = image;
  };

  onCropComplete = (crop, percentCrop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    let ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    ctx = "";

    let imagenToPost = canvas.toDataURL();
    //console.log(imagenToPost);
    this.props.saveImage(imagenToPost, this.props.setFieldValue);

    this.setState({ imagenToPost: imagenToPost });

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          //   console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
    canvas.toDataURL("image/jpeg");
  }

  handleOpen = () => {
    this.setState({ showImageCropper: true });
  };

  handleCancel = () => {
    this.setState({ showImageCropper: false });
  };

  handleSave = () => {
    //    console.log("crop " + JSON.stringify(this.state.imagenToPost));

    const values = this.state.imagenToPost;

    const access_token_data = localStorage.getItem("token");
    const dataSent = { photo: values };

    fetch(url_base + "/talents/profile/update-photo", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        ["Authorization"]: "Bearer " + access_token_data
      },
      body: JSON.stringify(dataSent)
    })
      .then(res => res.json())
      .then(res => {
        //       console.log(res);

        const talentImage = res.data.photo;
        this.props.updateKey(talentImage);
      })
      .catch();

    this.setState({
      showImageCropper: false,
      crop: { unit: "px", width: 30, aspect: 1 / 1 },
      croppedImage: "",
      croppedImageUrl: "",
      fileURl: "",
      selectedImageURL: "",
      selectedFile: "",
      imagenToPost: ""
    });
    this.props.updateKey();
  };

  showCropImageModal() {
    const actions = [
      <FlatButton label="Cerrar" primary={true} onClick={this.handleCancel} />,
      <FlatButton label="Recortar" primary={true} onClick={this.handleSave} />
    ];

    if (this.state.showImageCropper) {
      return (
        <>
          <Dialog
            title="Recortar la imagen"
            actions={actions}
            modal={true}
            open={this.state.showImageCropper}
            autoScrollBodyContent={true}
          >
            <div className="row">
              <div className="col-md-8 col-12" style={{ marginBottom: "20px" }}>
                {this.state.src && (
                  <ReactCrop
                    src={this.state.src}
                    crop={this.state.crop}
                    // ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                )}
              </div>
              <div className="col-md-4 col-12" style={{ textAlign: "center" }}>
                {!this.state.croppedImageUrl ? (
                  <img
                    alt="Crop"
                    style={{ maxWidth: "100%" }}
                    src={this.state.src}
                  />
                ) : (
                  this.state.croppedImageUrl && (
                    <img
                      alt="Crop"
                      style={{ maxWidth: "100%" }}
                      src={this.state.croppedImageUrl}
                    />
                  )
                )}
              </div>
            </div>
          </Dialog>
        </>
      );
    }
  }

  render() {
    return (
      <MuiThemeProvider>
        <div className="App">
          <input
            id="photo"
            name="photo"
            className="form-control transparent" //transparent_profile
            type="file"
            accept="image/*"
            onClick={e => (e.target.value != "" ? (e.target.value = "") : null)}
            onChange={this.onSelectFile}
            style={{
              width: "150px",
              height: "150px",
              left: "15px",
              top: "0",
              position: "absolute",
              cursor: "pointer"
            }}
            title="Toque aquí para cambiar la imagen de perfil"
          />
          {this.showCropImageModal()}
          <img
            src={this.state.selectedImageURL}
            style={{ display: "none" }}
            ref={img => {
              this.refImageCrop = img;
            }}
            // onClick={() => showImage}
            // src={imageTalentPath + "/" + talentImage}
            //            onChange={this.onSelectFile}
          />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default ImageCrop;
