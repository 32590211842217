//Depenencies
import React, { Component } from "react";

import { Form, withFormik } from "formik";
import * as Yup from "yup";
import { FormGroup } from "reactstrap";
import logo from "./../../images/logo.png";
import "./../../Styles/general.css";
import { withRouter } from "react-router-dom";
import Modal from "./../common/Modal";

//Assets

const formikWrapper = withFormik({
    mapPropsToValues: props => ({
        email: "",
        password: "",
        remember_me: "0",
        handleSubmit: props.handleSubmit,
        showModal: props.showModal,
        modalTitle: props.modalTitle,
        modalText: props.modalText,
        modalBtn: props.modalBtn,
        modalReset: props.modalReset
        //onSubmit: props.onSubmit
    }),

    validateOnChange: true,
    validateOnBlur: true,
    initialValues: { email: "", password: "", remember_me: "0" },
    // validationSchema: validationSchema,
    handleSubmit: (values, bag) => bag.props.handleSubmit(values),
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email("email inválido")
            .min(8, "el email es demasiado corto")
            .required("requerido"),
        password: Yup.string()
            .min(2, "el password es demasiado corto!")
            .max(32, "el password es demasiado largo!")
            .required("requerido"),
        remember_me: Yup.string()
    })
});

const LoginForm = props => {
    const {
        handleChange,
        errors,
        touched,
        showModal,
        modalTitle,
        modalText,
        modalBtn,
        modalReset,
        path,
        redirect
    } = props;
    return (
        <div >
            <div className="row justify-content-center login_screen"   >
                <div className="card login d-flex flex-column" style={{ backgroundColor: "#FAFAFA !important" }}>
                    <article className="card-body d-flex flex-column" style={{ backgroundColor: "#FAFAFA" }}>
                        <img src={logo} className="logo" />
                        {showModal ? (
                            <Modal
                                modal_title={modalTitle}
                                modal_text={modalText}
                                modal_btn={modalBtn}
                                modalReset={modalReset}
                                path={path}
                                redirect={redirect}
                            />
                        ) : (
                            ""
                        )}

                        <Form className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <FormGroup>
                                            <label className="label_black">Email</label>
                                            <input name="email" type={"mail"} onChange={handleChange} />
                                            {errors.email && touched.email ? (
                                                <div className="frm_error_c">{errors.email}</div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>

                                        <FormGroup>
                                            <label className="label_black">Contraseña</label>
                                            <input
                                                name="password"
                                                type={"password"}
                                                onChange={handleChange}
                                            />
                                            {errors.password && touched.password ? (
                                                <div className="frm_error_c">{errors.password}</div>
                                            ) : <div style={{ height: "15px" }} />}
                                            <input
                                                type="hidden"
                                                name="remember_me"
                                                onChange={handleChange}
                                                value="0"
                                            />
                                        </FormGroup>

                                        <div className="text-right">
                                            <button className="btn btn-blue btn-block" type="submit">
                                                Ingresar
                                            </button>
                                        </div>
                                        <p className="text-center">
                                            <a
                                                href="#/RecuperarPassword"
                                                className="text_link forgot"
                                                id="login_fix_1"
                                            >
                                                Olvidaste tu contraseña?
                                            </a>
                                        </p>


                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col d-flex justify-content-center">
                                        <a
                                            href="#/RegistroTalento"
                                            className="text_link forgot mt-0"
                                            id="login_fix_2"
                                        >
                                            Quiero registrarme como talento
                                        </a>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col d-flex justify-content-center">

                                        <a
                                            href="#/RegistroEmpresa"
                                            className="text_link forgot mt-0"
                                            id="login_fix_3"
                                        >
                                            Quiero registrarme como empresa
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </Form>

                    </article>
                </div>
            </div>
        </div >
    );
};

const EnhancedForm = formikWrapper(LoginForm);
//export default EnhancedForm;

export default withRouter(EnhancedForm);
