import React from "react";

//Dependencies
import { Form, withFormik } from "formik";
import * as Yup from "yup";
import { FormFeedback, Input, FormGroup } from "reactstrap";

import "./../../../../../node_modules/react-image-crop/dist/ReactCrop.css";


import { Link } from "react-router-dom";

import {
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "./../../../common/Modal";

import ImageCrop from "./ImageCrop";

//Assets
const numerics = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const formikWrapper = withFormik({
    mapPropsToValues: props => ({
        fantasyName: "",
        legalName: "",
        logo: "",
        cuit: "",
        imageLogo: "",
        responsableFirstName: "",
        responsableLastName: "",
        email: "",
        password: "",
        passwordRepeat: "",
        phoneAreaCode: "",
        phone: "",
        documentNumber: "",
        province: props.provinceID,
        ProvinceHandleChange: props.ProvinceHandleChange,
        handleChange: props.handleChange,
        city_id: "",
        city: props.city,
        addressStreet: "",
        addressNumber: "",
        addressFloor: "",
        terms: "",
        showModal: props.showModal,
        modalTitle: props.modalTitle,
        modalText: props.modalText,
        modalBtn: props.modalBtn,
        modalReset: props.modalReset,
        saveImage: props.saveImage,
        handleSubmit: props.handleSubmit
    }),

    handleSubmit: (values, bag) => bag.props.handleSubmit(values),

    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
        fantasyName: Yup.string().required("requerido"),
        legalName: Yup.string().required("requerido"),
        responsableFirstName: Yup.string().required("requerido"),
        responsableLastName: Yup.string().required("requerido"),
        //logo: Yup.string().required("requerido"),
        email: Yup.string()
            .email("email inválido")
            .required("requerido"),
        passwordRepeat: Yup.string()
            .required("requerido")
            .label("password")
            .test("passwords-match", "la contraseña debe coincidir", function (value) {
                return this.parent.password === value;
            }),
        password: Yup.string()
            .oneOf([Yup.ref("password"), null], "la contraseña debe coincidir")
            .required("requerido"),
        phoneAreaCode: Yup.number()
            .integer()
            .required("requerido"),
        phone: Yup.number().required("requerido"),
        cuit: Yup.string()
            .matches(numerics, "cuit inválido")
            .required("requerido"),
        province: Yup.string().required("requerido"),
        city: Yup.string().required("requerido"),
        addressStreet: Yup.string().required("requerido"),
        addressNumber: Yup.number().required("requerido"),
        imageLogo: Yup.mixed(),
    /*.test(
        "fileSize",
        "el tamaño del archivo es incorrecto",
            value =>
          (value !== "" || value != undefined) &&
          Number(value.size) < Number(FILE_SIZE)
        value =>
          (value !== "" || value !== undefined) && value.size <= FILE_SIZE
      )*/
    /*  .test(
        "fileFormat",
        "el formato del archivo es incorrecto",
        value =>
          (value !== "" || value != undefined) &&
          SUPPORTED_FORMATS.includes(value.type)
      )*/ terms: Yup.boolean()
            .label("terms")
            .test(
                "is-true",
                "debe aceptar los término y condiciones",
                value => value === true
            )
    })
});

const customInputForm = ({ field, form: { touched, errors }, ...props }) => (
    <div>
        <Input
            invalid={!!(touched[field.name] && errors[field.name])}
            {...field}
            {...props}
        />
        {touched[field.name] && errors[field.name] && (
            <FormFeedback>{errors[field.name]}</FormFeedback>
        )}
    </div>
);

const RegistroEmpresaForm = props => {
    const {
        handleChange,
        errors,
        touched,
        setFieldValue,
        saveImage,
        province,
        cityDisabled,
        city,
        ProvinceHandleChange,
        showModal,
        modalTitle,
        modalText,
        modalBtn,
        modalReset,
        path,
        redirect,
        formSubmitting,
        dirty

        //city_id
    } = props;

    return (
        <div className="row">
            <div className="decoration"></div>
            <div className="page_container">
                <div className="card general pt-0">
                    <article className="card-body" style={{ backgroundColor: "#FAFAFA" }}>
                        {showModal ? (
                            <Modal
                                modal_title={modalTitle}
                                modal_text={modalText}
                                modal_btn={modalBtn}
                                modalReset={modalReset}
                                path={path}
                                redirect={redirect}
                            />
                        ) : (
                            ""
                        )}
                        <h4 className="card-title text-center">
                            <Link to={"/Login"}>
                                <FontAwesomeIcon
                                    className="arrow_back"
                                    icon={faArrowLeft}
                                    size="1x"
                                />
                            </Link>
                            Empresa - Crear cuenta
                            <FontAwesomeIcon
                                className="arrow_back"
                                icon={faArrowLeft}
                                size="1x"
                                style={{ visibility: "hidden" }}
                            />
                        </h4>


                        <Form>
                            <div className="row">
                                <div
                                    className="tit_black"

                                >
                                    Datos de la Empresa
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Nombre de Fantasía</label>
                                        <input
                                            name="fantasyName"
                                            className="form-control"
                                            type={"fantasyName"}
                                            onChange={handleChange}
                                        />
                                        {errors.fantasyName && touched.fantasyName ? (
                                            <div class="frm_error_c">{errors.fantasyName}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Razón social</label>
                                        <input
                                            name="legalName"
                                            className="form-control"
                                            type={"legalName"}
                                            onChange={handleChange}
                                        />

                                        {errors.legalName && touched.legalName ? (
                                            <div class="frm_error_c">{errors.legalName}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">CUIT</label>
                                        <input
                                            name="cuit"
                                            className="form-control"
                                            type={"cuit"}
                                            onChange={handleChange}
                                        />
                                        {errors.cuit && touched.cuit ? (
                                            <div class="frm_error_c">{errors.cuit}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Logo</label>
                                        <div className="custom-file">
                                            <div className="form-group">
                                                <div>
                                                    <div className="browse_c">
                                                        <ImageCrop
                                                            saveImage={saveImage}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    </div>

                                                    {errors.imageLogo && touched.imageLogo ? (
                                                        <div class="frm_error_c">{errors.imageLogo}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div
                                    className="tit_black"

                                >
                                    Dirección
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Provincia</label>
                                        <select
                                            //value={provinceID}
                                            name="province"
                                            className="form-control"
                                            onChange={e => {
                                                ProvinceHandleChange(e, setFieldValue);
                                            }}
                                            type="select"
                                        >
                                            <option value="">Provincia</option>
                                            {Object.keys(province).map((keyName, i) => (
                                                <option key={i} value={province[keyName].id}>
                                                    {province[keyName].name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.province && touched.province ? (
                                            <div class="frm_error_c">{errors.province}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Ciudad / Barrio</label>
                                        <select
                                            name="city"
                                            className="form-control"
                                            type="select"
                                            disabled={cityDisabled}
                                            onChange={handleChange}
                                        >
                                            <option value="">Ciudad / Barrio</option>
                                            {Object.keys(city).map((keyName, i) => (
                                                <option key={i} value={city[keyName].id}>
                                                    {city[keyName].name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.city && touched.city ? (
                                            <div class="frm_error_c">{errors.city}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Calle</label>
                                        <input
                                            name="addressStreet"
                                            className="form-control"
                                            type={"addressStreet"}
                                            onChange={handleChange}
                                            placeholder="Dirección"
                                        />
                                        {errors.addressStreet && touched.addressStreet ? (
                                            <div class="frm_error_c">{errors.addressStreet}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Altura</label>
                                        <input
                                            name="addressNumber"
                                            className="form-control"
                                            type={"addressNumber"}
                                            onChange={handleChange}
                                            placeholder="Número"
                                        />
                                        {errors.addressNumber && touched.addressNumber ? (
                                            <div class="frm_error_c">{errors.addressNumber}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Piso/Depto</label>
                                        <input
                                            name="addressFloor"
                                            className="form-control"
                                            type={"text"}
                                            onChange={handleChange}
                                            placeholder="Piso/Depto"
                                        />
                                        {errors.addressFloor && touched.addressFloor ? (
                                            <div class="frm_error_c">{errors.addressFloor}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div
                                    className="tit_black"

                                >
                                    Datos Personales
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Nombre</label>
                                        <input
                                            name="responsableFirstName"
                                            className="form-control"
                                            type={"responsableFirstName"}
                                            onChange={handleChange}
                                            placeholder="Nombre"
                                        />
                                        {errors.responsableFirstName &&
                                            touched.responsableFirstName ? (
                                            <div class="frm_error_c">
                                                {errors.responsableFirstName}
                                            </div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Apellido</label>
                                        <input
                                            name="responsableLastName"
                                            className="form-control"
                                            type={"responsableLastName"}
                                            onChange={handleChange}
                                            placeholder="Apellido"
                                        />
                                        {errors.responsableLastName &&
                                            touched.responsableLastName ? (
                                            <div class="frm_error_c">
                                                {errors.responsableLastName}
                                            </div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div
                                    class="tit_black"

                                >
                                    Teléfono
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                                    <FormGroup>
                                        <label className="label_black">Área</label>
                                        <input
                                            name="phoneAreaCode"
                                            className="form-control"
                                            type={"phoneAreaCode"}
                                            onChange={handleChange}
                                            placeholder="Cod. Área"
                                        />
                                        {errors.phoneAreaCode && touched.phoneAreaCode ? (
                                            <div class="frm_error_c"> {errors.phoneAreaCode}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>

                                <div className="col-xs-8 col-sm-8 col-md-4 col-lg-4">
                                    <FormGroup>
                                        <label className="label_black">Número</label>
                                        <input
                                            name="phone"
                                            className="form-control"
                                            type={"phone"}
                                            onChange={handleChange}
                                            placeholder="Tel"
                                        />
                                        {errors.phone && touched.phone ? (
                                            <div class="frm_error_c"> {errors.phone}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Email</label>
                                        <input
                                            name="email"
                                            className="form-control"
                                            type={"email"}
                                            onChange={handleChange}
                                            placeholder="Email"
                                        />
                                        {errors.email && touched.email ? (
                                            <div class="frm_error_c">{errors.email}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Contraseña</label>
                                        <input
                                            name="password"
                                            className="form-control"
                                            type={"password"}
                                            onChange={handleChange}
                                        />
                                        {errors.password && touched.password ? (
                                            <div class="frm_error_c">{errors.password}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Repetir contraseña</label>
                                        <input
                                            name="passwordRepeat"
                                            className="form-control"
                                            type={"password"}
                                            onChange={handleChange}
                                            placeholder="Repetir Contraseña*"
                                        />
                                        {errors.passwordRepeat && touched.passwordRepeat ? (
                                            <div class="frm_error_c">{errors.passwordRepeat}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                name="terms"
                                                className="custom-control-input"
                                                type="checkbox"
                                                id="customCheck"
                                                onChange={handleChange}
                                            />
                                            <label class="custom-control-label" for="customCheck">
                                                <Link to="/Terms" target="_blank">
                                                    <small> Acepto los Términos y Condiciones.</small>
                                                </Link>
                                            </label>
                                        </div>
                                        {errors.terms && touched.terms ? (
                                            <div class="frm_error_c">{errors.terms}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="form-group">
                                <button
                                    //onSubmit={handleSubmit}
                                    type="submit"
                                    className="btn btn-blue btn-block"
                                    style={{ width: "100%" }}
                                    disabled={formSubmitting || !dirty}
                                >
                                    {" "}
                                    REGISTRARME{" "}
                                </button>
                            </div>
                        </Form>
                    </article>
                </div>
            </div>
        </div>
    );
};

const EnhancedForm = formikWrapper(RegistroEmpresaForm);
export default EnhancedForm;
