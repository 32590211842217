import React, { Component } from "react";
import DatosPersonalesText from "./DatosEmpresaScreen";
import axios from "axios";
import { url_base } from "../../../../api_url";
import moment from "moment";

class DatosEmpresa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // data: []
            imageCompany: "",
            responsableFirstName: "",
            responsableLastName: "",
            fantasyName: "",
            legalName: "",
            responsableEmail: "",
            province: [],
            cityState: [],
            cityDisabled: "",
            phoneAreaCode: "",
            id: "",
            password: "",
            passwordRepeat: "",
            city: "",
            cityList: "",
            provinceList: "",
            userEmail: "",
            birthDate: "",
            docTypeList: "",
            docType: "",
            docNumber: "",
            address_street: "",
            address_floor: "",
            gender: "",
            showModal: false,
            modalTitle: "",
            modalText: "",
            modalBtn: "",

            showModalNoSaving: false,
            modalTitleNoSaving: "",
            modalTextNoSaving: "",
            modalBtnNoSaving: "",

            pathNoSaving: "",
            path: ""
        };
        //  this.nameHandleChange = this.nameHandleChange.bind(this);
        this.ProvinceHandleChange = this.ProvinceHandleChange.bind(this);
        this.select = this.select.bind(this);
        this.cityHandleChange = this.cityHandleChange.bind(this);
        //  this.docTypeHandleChange = this.docTypeHandleChange.bind(this);
        this.datepickerHandleChange = this.datepickerHandleChange.bind(this);
        this.saveImage = this.saveImage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.modalReset = this.modalReset.bind(this);
        this.redirectNoSaving = this.redirectNoSaving.bind(this);
        this.modalResetNoSaving = this.modalResetNoSaving.bind(this);
        this.modalCancel = this.modalCancel.bind(this);
        this.updateKey = this.updateKey.bind(this);
    }

    modalReset() {
        this.setState({ showModal: false, redirect: false });
    }

    modalResetNoSaving() {
        this.setState({ showModalNoSaving: false, redirect: false });
    }

    modalCancel() {
        this.setState({ showModalNoSaving: false });
    }

    select(idx) {
        if (this.state.id === idx) {
            let select = "selected";
            return select;
        }
    }

    datepickerHandleChange(e, setFieldValue) {
        setFieldValue(e.target.name, e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(values) {
        const access_token_data = localStorage.getItem("token");
        axios
            .patch(url_base + "/companies/profile/update", values, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {
                let message = response.data.message;
                let path = "/BusquedasActivas/";

                this.setState({
                    showModal: true,
                    modalTitle: "",
                    modalText: message,
                    modalBtn: "Aceptar",
                    path: path,
                    redirect: true
                });
            })
            .catch(error => {
                let message = error.data.message;
                let path = "/BusquedasActivas/";

                this.setState({
                    showModal: true,
                    modalTitle: "",
                    modalText: message,
                    modalBtn: "Aceptar",
                    path: path,
                    redirect: true
                });
            });
    }
    redirectNoSaving() {
        let message =
            "Si modificaste datos, asegurate de haberlos guardado antes de abandonar esta sección.";
        let path = "/BusquedasActivas/";

        this.setState({
            showModalNoSaving: true,
            modalTitleNoSaving: "Atención",
            modalTextNoSaving: message,
            modalBtnNoSaving: "Aceptar",
            pathNoSaving: path,
            //   modalCancel: modalCancel,
            redirectNoSaving: true
        });
    }

    cityHandleChange(e, setFieldValue) {
        setFieldValue("city", e.target.value);
        this.setState({
            city: e.target[e.target.selectedIndex].value
        });
    }
    saveImage(imagenToPost, setFieldValue) {
        this.setState({ imagenTo: imagenToPost });
        setFieldValue("imageLogo", imagenToPost);
    }

    updateKey(imageLogo) {
        const dataKey = Math.random();

        this.setState({ dataKey: dataKey, imageCompany: imageLogo });
    }

    ProvinceHandleChange(e, setFieldValue) {
        setFieldValue("province", e.target.value);
        this.setState({
            province: e.target[e.target.selectedIndex].value
        });

        if (e.target[e.target.selectedIndex].value != "") {
            this.setState({
                cityDisabled: ""
            });
        } else {
            this.setState({
                cityDisabled: "disabled"
            });
        }
        const idProvince = e.target[e.target.selectedIndex].value;

        axios
            .get(
                url_base + "/province-cities/" + e.target[e.target.selectedIndex].value
            )
            .then(response => {
                this.setState({
                    cityList: response.data.data.cities,
                    city: response.data.data.cities[0].id
                });
            })
            .catch(error => { });
    }
    componentDidMount() {
        const access_token_data = localStorage.getItem("token");

        axios
            .get(url_base + "/companies/profile", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = response.data.data.company;

                    this.setState({
                        responsableFirstName: data.responsable_first_name,
                        responsableLastName: data.responsable_last_name,
                        fantasyName: data.fantasy_name,
                        province: data.provinceId,
                        legalName: data.legal_name,
                        responsableEmail: data.responsable_email,
                        phoneAreaCode: data.phone_area_code,
                        phone: data.phone,
                        userEmail: data.userEmail,
                        city: data.city_id,
                        addressStreet: data.address_street,
                        addressNumber: data.address_number,
                        addressFloor: data.address_floor,
                        cuit: data.cuit,
                        imageCompany: data.brand_image
                        // talentImage: data.photo,
                    });

                    axios
                        .get(url_base + "/province-cities/" + data.provinceId)
                        .then(response => {
                            this.setState({
                                cityList: response.data.data.cities
                            });
                        })
                        .catch(error => { });
                },
                error => {
                    var status = error.response;
                }
            );

        //cargo los tipo de documento
        axios
            .get(url_base + "/document-types")
            .then(response => {
                this.setState({
                    docTypeList: response.data.data.docTypes
                });
            })
            .catch(error => { });

        //cargo las provincias
        axios
            .get(url_base + "/provinces")
            .then(response => {
                //    console.log(response.data.data.provinces);
                this.setState({
                    provinceList: response.data.data.provinces
                });
            })
            .catch(error => { });

    }
    render() {
        return (
            <DatosPersonalesText
                ProvinceHandleChange={this.ProvinceHandleChange}
                categoryHandleChange={this.categoryHandleChange}
                responsableFirstName={this.state.responsableFirstName}
                responsableLastName={this.state.responsableLastName}
                responsableEmail={this.state.responsableEmail}
                cuit={this.state.cuit}
                fantasyName={this.state.fantasyName}
                legalName={this.state.legalName}
                phoneAreaCode={this.state.phoneAreaCode}
                //  nameHandleChange={this.nameHandleChange}
                phone={this.state.phone}
                provinceList={this.state.provinceList}
                // city={this.state.cityState}
                province={this.state.province}
                select={this.select}
                cityList={this.state.cityList}
                city={this.state.city}
                cityHandleChange={this.cityHandleChange}
                docNumber={this.state.docNumber}
                addressStreet={this.state.addressStreet}
                addressNumber={this.state.addressNumber}
                addressFloor={this.state.addressFloor}
                imageCompany={this.state.imageCompany}
                saveImage={this.saveImage}
                handleSubmit={this.handleSubmit}
                showModal={this.state.showModal}
                modalTitle={this.state.modalTitle}
                modalText={this.state.modalText}
                modalBtn={this.state.modalBtn}
                modalReset={this.modalReset}
                path={this.state.path}
                redirect={this.state.redirect}
                redirectNoSaving={this.redirectNoSaving}
                showModalNoSaving={this.state.showModalNoSaving}
                modalTitleNoSaving={this.state.modalTitleNoSaving}
                modalTextNoSaving={this.state.modalTextNoSaving}
                modalBtnNoSaving={this.state.modalBtnNoSaving}
                pathNoSaving={this.state.pathNoSaving}
                modalResetNoSaving={this.modalResetNoSaving}
                modalCancel={this.modalCancel}
                updateKey={this.updateKey}
            //redirectNoSaving={this.state.redirectNoSaving}
            />
        );
    }
}

export default DatosEmpresa;
