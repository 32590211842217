import React, { Component } from "react";
import { Field } from "formik";
import { Input, FormFeedback, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const customInputForm = ({ field, form: { touched, errors }, ...props }) => (
    <div>
        <Input
            invalid={!!(touched[field.name] && errors[field.name])}
            {...field}
            {...props}
        />
        {touched[field.name] && errors[field.name] && (
            <FormFeedback>{errors[field.name]}</FormFeedback>
        )}
    </div>
);

class AddItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayData: [],
            showdata: this.displayData,
            cont: 1
        };
        this.add = this.add.bind(this);
    }
    add(e, props) {
        e.preventDefault();

        if (this.state.cantidad + this.props.numRows < 3) {
            this.setState({
                cont: this.state.cont + 1,
                cantidad: Number(this.state.cantidad) + 1
            });

            this.state.displayData.push(
                <div className="idioma_set_frm">
                    <FormGroup>
                        <Field
                            id="languages"
                            name={"languages_" + this.state.cont}
                            className="form-control"
                            component={customInputForm}
                            type="select"
                        //value={loadedLanguages[keyName].language_id}
                        >
                            <option value="">Seleccionar</option>
                            {Object.keys(this.props.languages).map((keyName, i) => (
                                <option key={i} value={this.props.languages[keyName].id}>
                                    {this.props.languages[keyName].language}
                                </option>
                            ))}
                        </Field>
                    </FormGroup>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <label className="form-check-label align-self-end ">Nivel</label>
                        <FormGroup tag="fieldset">
                            <div className="form-check form-check-inline ">
                                <Field
                                    id="levelLanguage1"
                                    name={"levelLanguage_" + this.state.cont}
                                    className="form-check-input radio-inline radio align-items-center "
                                    type="radio"
                                    value="b"
                                    component={customInputForm}
                                />
                                <label className="form-check-label">básico</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <Field
                                    id="levelLanguage2"
                                    name={"levelLanguage_" + this.state.cont}
                                    className="form-check-input radio-inline radio align-items-center"
                                    type="radio"
                                    value="i"
                                    component={customInputForm}
                                />
                                <label className="form-check-label">intermedio</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <Field
                                    id="levelLanguage3"
                                    name={"levelLanguage_" + this.state.cont}
                                    className="form-check-input radio-inline radio align-items-center"
                                    type="radio"
                                    value="a"
                                    component={customInputForm}
                                />
                                <label className="form-check-label">avanzado</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <Field
                                    id="levelLanguage4"
                                    name={"levelLanguage_" + this.state.cont}
                                    className="form-check-input radio-inline radio align-items-center"
                                    type="radio"
                                    value="n"
                                    component={customInputForm}
                                />
                                <label className="form-check-label">nativo / bilingue</label>
                            </div>
                        </FormGroup>
                    </div>
                </div>
            );
            //  console.log("add idioma: " + this.state.displayData);
            this.setState({
                showdata: this.state.displayData
            });
            //   console.log(this.state.showdata);
        }
    }
    componentDidMount() {
        this.setState({
            cantidad: this.props.numRows
        });
        //  console.log("cantidad inicial: " + this.props.numRows);
    }

    render() {
        return (
            <div className="agregar_idioma">
                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="input-group">
                        {this.state.showdata &&
                            Object.keys(this.state.showdata).map((keyName, i) => (
                                <div className="idioma_set" key={i}>
                                    {this.state.showdata[keyName]}
                                </div>
                            ))}
                    </div>
                </div>
                <Link className="arrow_right" onClick={props => this.add(props)}>
                    <span className="txt" style={{ color: "#0D71B9" }}>Agregar idioma</span>
                    <FontAwesomeIcon icon={faPlus} size="1x" style={{ color: "#0D71B9" }} />
                </Link>
            </div>
        );
    }
}
export default AddItem;
