import React from "react";

import "./../../../style.css";
import * as Yup from "yup";
import { withFormik, ErrorMessage, Form, Field } from "formik";
import { FormFeedback, Input, FormGroup, Label, CustomInput } from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { url_base } from "./../../../../api_url";
import moment from "moment";
import { withRouter, Link } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
    faMapMarkerAlt,
    faUserFriends,
    faMoneyBillWave,
    faCalendarAlt,
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { parseISO } from "date-fns";

const numerics = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const customInputForm = ({ field, form: { touched, errors }, ...props }) => (
    <div>
        <Input
            invalid={!!(touched[field.name] && errors[field.name])}
            {...field}
            {...props}
        />
        {touched[field.name] && errors[field.name] && (
            <FormFeedback>{errors[field.name]}</FormFeedback>
        )}
    </div>
);

const DatePickerField = ({ name, value, onChange, placeholder }) => {
    return (
        <DatePicker
            name={name}
            selected={(value && new Date(value)) || null}
            //   selected={moment(value)}
            // selected={value}
            className="form-control"
            placeholderText={placeholder}
            dateFormat="MM/yyyy"
            autoComplete="off"
            showMonthYearPicker
            onChange={val => {
                onChange(name, val);
            }}
        />
    );
};

//let data = first_name;

const formikWrapper = withFormik({
    //enableReinitialize: false,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: props => ({
        company: "",
        description: "",
        position: "",
        startDate: "",
        finishDate: ""

        /* birthDate: props.birthDate
          ? moment(props.birthDate) //.format("DD-MM-YYYY")
          : "",*/
    }),
    handleSubmit: (values, { props }) => {
        //    console.log(values);
        const access_token_data = localStorage.getItem("token");
        axios

            .post(url_base + "/talents/experiences/add", values, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {
                //      console.log(response);
                let path = `/PerfilText`;
                props.history.push(path);
            })
            .catch();
    },

    validationSchema: Yup.object().shape({
        company: Yup.string().required("requerido"),
        position: Yup.string().required("requerido"),
        description: Yup.string().required("requerido"),
        startDate: Yup.date().required("requerido"),
        finishDate: Yup.date()
            .nullable()
            // .required("requerido")
            .min(
                Yup.ref("startDate"),
                ({ startDate }) => `la fecha debe ser mayor a la inicial`
            )
    })
});

const ExperienciaText = props => {
    const {
        data,
        handleChange,
        errors,
        touched,
        values,
        company,
        position,
        description,
        startDate,
        finishDate,
        setFieldValue
    } = props;

    return (
        <div>
            <div className="row justify-content-center  mr-0 ml-0 h-100">
                <div className="decoration"></div>
                <div className="page_container" >
                    <div className="card" style={{ backgroundColor: "#FAFAFA", minHeight: "100vh" }}>
                        <article className="card-body general" style={{ minHeight: "600px" }}>
                            <h4 className="card-title text-center">
                                <Link to={"/PerfilText"}>
                                    <FontAwesomeIcon
                                        className="arrow_back"
                                        icon={faArrowLeft}
                                        size="1x"
                                    />
                                </Link>
                                Agregar experiencia
                                <FontAwesomeIcon
                                    className="arrow_back"
                                    icon={faArrowLeft}
                                    size="1x"
                                    visibility="hidden"
                                />
                            </h4>


                            <Form>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" >
                                        <div className="form-group">
                                            <div className="input-group">
                                                <FormGroup className="w100_frm">
                                                    <label className="label_black">Empresa</label>
                                                    <input
                                                        name="company"
                                                        className="form-control text-primary"
                                                        type="text"
                                                        onChange={handleChange}
                                                    />
                                                    {errors.company && touched.company ? (
                                                        <div>
                                                            <div className="frm_error_c">
                                                                {errors.company}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <FormGroup className="w100_frm">
                                                    <label className="label_black">Puesto</label>
                                                    <input
                                                        name="position"
                                                        className="form-control text-primary"
                                                        type="text"
                                                        onChange={handleChange}
                                                    />
                                                    {errors.position && touched.position ? (
                                                        <div>
                                                            <div className="frm_error_c">
                                                                {errors.position}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group">
                                            <label className="label_black">Descripción</label>
                                            <FormGroup className="w100_frm">
                                                <input
                                                    name="description"
                                                    className="form-control"
                                                    type="textarea"
                                                    rows="3"
                                                    onChange={handleChange}
                                                />
                                                {errors.description && touched.description ? (
                                                    <div>
                                                        <div className="frm_error_c">
                                                            {errors.description}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <FormGroup className="w100_frm">
                                                    <label className="label_black">Fecha de ingreso</label>
                                                    <div className="customDatePickerWidth">
                                                        <DatePickerField
                                                            name="startDate"
                                                            value={values.startDate}
                                                            onChange={setFieldValue}
                                                            className="form-control"
                                                            dateFormat="MM/yyyy"
                                                            placeholder="MM/yyyy"
                                                        />
                                                        {errors.startDate && touched.startDate ? (
                                                            <div>
                                                                <div className="frm_error_c">
                                                                    {errors.startDate}
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <FormGroup className="w100_frm">
                                                    <label className="label_black">
                                                        Fecha de salida{" "}
                                                        <small>(En blanco si continúa)</small>
                                                    </label>
                                                    <div className="customDatePickerWidth">
                                                        <DatePickerField
                                                            name="finishDate"
                                                            value={values.finishDate}
                                                            onChange={setFieldValue}
                                                            className="form-control"
                                                            dateFormat="MM/yyyy"
                                                            placeholder="MM/yyyy"
                                                        />
                                                    </div>
                                                    {errors.finishDate && touched.finishDate ? (
                                                        <div>
                                                            <div className="frm_error_c">
                                                                {errors.finishDate}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row justify-content-end">
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <button
                                                type="submit"
                                                className="btn btn-blue btn-block"
                                                style={{ width: "100%" }}
                                            >
                                                {" "}
                                                Agregar{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
};
const EnhancedForm = formikWrapper(ExperienciaText);
//export default EnhancedForm;

export default withRouter(EnhancedForm);
