import React from "react";
import { Link, withRouter } from "react-router-dom";
import "../../script";
import "./../../../style.css";
import "./styles.css";
import { Form, withFormik } from "formik";
import { library } from "@fortawesome/fontawesome-svg-core";
import { imageTalentPath } from "./../../../common/constants";
import moment from "moment";

import ImageCrop from "./ImageCrop";

import {
    faUser,
    faUserGraduate,
    faPen,
    faChevronRight,
    faSuitcase,
    faHandPaper,
    faTimes,
    faPlus,
    faArrowLeft,
    faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(
    faUser,
    faUserGraduate,
    faPen,
    faChevronRight,
    faSuitcase,
    faHandPaper,
    faTimes,
    faPlus,
    faAngleDown
);


const formikWrapper = withFormik({
    //enableReinitialize: false,
    enableReinitialize: true,

    mapPropsToValues: props => ({
        emailNotifications: props.emailNotifications,
        appNotifications: props.appNotifications
    })
});

const PerfilText = props => {
    const {
        talentImage,
        firstName,
        lastName,
        userEmail,
        values,
        phone,
        address_number,
        address_street,
        city,
        address_floor,
        studies,
        experiences,
        experiencesLenght,
        skills,
        deleteSkill,
        deleteStudies,
        deleteLanguage,
        deleteExperience,
        languages,
        notification,
        emailNotifications,
        studiesLanguagesLength,
        appNotifications,
        saveImage,
        errors,
        touched,
        setFieldValue,
        updateKey
    } = props;

    return (
        <div>
            <div className="row justify-content-center mr-0 ml-0">
                <div className="decoration"></div>
                <div className="page_container">
                    <div className="card" style={{ backgroundColor: "#FAFAFA" }}>
                        <article className="card-body general align-content-center">
                            <h4 className="card-title text-center d-flex align-content-center ">
                                <Link to={"/Matchs"} className="d-flex align-content-center justify-content-center">
                                    <FontAwesomeIcon
                                        className="arrow_back d-flex  align-content-center justify-content-center"
                                        icon={faArrowLeft}
                                        size="1x"
                                    />
                                </Link>
                                Perfil <FontAwesomeIcon
                                    className="arrow_back"
                                    icon={faArrowLeft}
                                    size="1x"
                                    style={{ visibility: "hidden" }}
                                />
                            </h4>

                            <div className="row clean">
                                <div className="col-12">
                                    <div className="browse_c_noImage">
                                        <div className="row">
                                            <div className="col-md-6 col-6">
                                                <img
                                                    className="profile_pic"
                                                    src={
                                                        imageTalentPath && talentImage
                                                            ? imageTalentPath + talentImage
                                                            : ""
                                                    }
                                                    width="30%"
                                                />

                                                <ImageCrop
                                                    saveImage={saveImage}
                                                    setFieldValue={setFieldValue}
                                                    updateKey={updateKey}
                                                />
                                            </div>

                                            <div className="col-md-6 col-6">
                                                <div className="user_name talent_profile">
                                                    {firstName + " " + lastName}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {errors.file && touched.file ? (
                                        <div>
                                            <small> {errors.file} </small>
                                        </div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="row clean " >
                                <div className="card text-primary border-0 n1 perfil_text m-0 border-light shadow-sm rounded-0 p-3" style={{ backgroundColor: "#FFFFFF" }}>
                                    {" "}
                                    <p
                                        className="card-text text-primary m-0 pl-0"
                                        style={{ textAlign: "left" }}
                                    >

                                        <span className="tit_black ml-0 mb-0">Datos Personales</span>
                                    </p>
                                    <p align="right pl-0">
                                        <Link to="/DatosPersonalesText">
                                            <FontAwesomeIcon icon={faPen} size="1x" className="pen" />
                                        </Link>
                                    </p>
                                    <p className="card-text m-0 pl-0" style={{ textAlign: "left" }}>
                                        <small> Email </small>
                                    </p>
                                    <p className="card-text pl-0" style={{ textAlign: "left" }}>
                                        {userEmail}{" "}
                                    </p>
                                    <p className="card-text m-0 ml-0" style={{ textAlign: "left" }}>
                                        <small>Teléfono</small>
                                    </p>
                                    <p className="card-text ml-0" style={{ textAlign: "left" }}>
                                        {phone}
                                    </p>
                                    <p className="card-text m-0 ml-0" style={{ textAlign: "left" }}>
                                        <small>Dirección</small>
                                    </p>
                                    <p className="card-text ml-0" style={{ textAlign: "left" }}>
                                        {address_street +
                                            " " +
                                            address_number +
                                            ", " +
                                            city +
                                            " (" +
                                            address_floor +
                                            ")"}
                                    </p>
                                </div>

                                <div
                                    className="collapse multi-collapse"
                                    id="CollapsePersonalInformation"
                                >
                                    <div className="row p-0 m-0">
                                        <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <p>subcategoría</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="list-group list-group-flush w100 mr-0 ml-0 p-3 mt-3 border-light shadow-sm rounded-0" style={{ backgroundColor: "#FFFFFF" }} >
                                    <div className="row  mr-0 ml-0">
                                        <div className="col-12  pr-0 pl-0">
                                            <div className="row mr-0 ml-0">
                                                <div className="col-10 pl-0 pr-0 d-flex">
                                                    <a
                                                        className="list-group-item d-flex justify-content-start align-items-center list-group-item-action  borderless text-primary p-0 "
                                                        data-toggle="collapse"
                                                        href="#CollapseEducation"
                                                        role="button"
                                                        aria-expanded="false"
                                                        aria-controls="CollapseEducation"
                                                    >
                                                        {" "}
                                                        <div className="tit_black m-0 p-0 d-flex col-12 align-items-center justify-content-start">
                                                            Educación
                                                        </div>

                                                    </a>
                                                </div>
                                                <div className="col-2 pl-0 pr-0" style={{ backgroundColor: "#FFFFFF" }}>

                                                    {(Number(studiesLanguagesLength) < 6 || Number(studiesLanguagesLength) == 0) ?
                                                        <Link to="/EducacionText" className="icono_right m-0 pen" style={{ zIndex: 1000 }}>
                                                            <FontAwesomeIcon icon={faPen} size="1x" className="pen" />
                                                        </Link>
                                                        : <FontAwesomeIcon icon={faAngleDown} size="1x" className="icono_right m-0 pen" />}
                                                </div>
                                            </div>
                                        </div>





                                    </div>
                                    <div
                                        className="collapse multi-collapse"
                                        id="CollapseEducation"
                                        style={{ backgroundColor: "#FFFFFF" }}
                                    >
                                        <div className="form-group mb-0">
                                            <div className="input-group education pt-3 ">
                                                {studies &&
                                                    Object.keys(studies).map((keyName, i) => (
                                                        <div className="form-group aptitud" key={i} style={{ textAlign: "left" }}>
                                                            <div className="name  pl-0 ml-0 " style={{ textAlign: "left" }}>

                                                                {" "}
                                                                {studies[keyName].title}
                                                            </div>

                                                            <Link
                                                                className="faTimes mt-0"
                                                                onClick={() =>
                                                                    deleteStudies(
                                                                        studies[keyName].id,
                                                                        studies[keyName]
                                                                    )
                                                                }
                                                                to="/PerfilText/"
                                                            //to={"/AgregarAptitudes/" + values.skillsSubcategory}
                                                            >
                                                                <FontAwesomeIcon icon={faTimes} size="1x" style={{ color: "#0D71B9" }} />
                                                            </Link>
                                                            <div className="date">
                                                                {moment(studies[keyName].start_date).format(
                                                                    "MM/YYYY"
                                                                ) +
                                                                    " - " +
                                                                    (studies[keyName].finish_date
                                                                        ? moment(
                                                                            studies[keyName].finish_date
                                                                        ).format("MM/YYYY")
                                                                        : "actualmente")}
                                                            </div>

                                                            <div className="title ml-0">
                                                                {studies[keyName].institution}
                                                            </div>
                                                        </div>
                                                    ))}
                                                <small className="label_black ml-0">Idiomas: </small>
                                                {languages &&
                                                    Object.keys(languages).map((keyName, i) => (
                                                        <div className="form-group idioma w-100" style={{ width: "100%" }} key={i}>
                                                            <p className="name pl-0 ml-0 w-100" style={{ textAlign: "left", width: "100%" }}>
                                                                {" "}
                                                                {languages[keyName].language}
                                                                <small>{languages[keyName].level}</small>
                                                                <Link
                                                                    className="faTimes mt-0"
                                                                    onClick={() =>
                                                                        deleteLanguage(
                                                                            languages[keyName].id,
                                                                            languages[keyName]
                                                                        )
                                                                    }
                                                                //to={"/AgregarAptitudes/" + values.skillsSubcategory}
                                                                >
                                                                    <FontAwesomeIcon icon={faTimes} size="1x" style={{ color: "#0D71B9" }} />
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="list-group list-group-flush w100 list-group-flush w100 mr-0 ml-0 p-3 mt-3 border-light shadow-sm rounded-0" style={{ backgroundColor: "#FFFFFF" }}>
                                    <div className="row  mr-0 ml-0">
                                        <div className="col-12  pr-0 pl-0">
                                            <div className="row mr-0 ml-0">
                                                <div className="col-10 pl-0 pr-0 d-flex">
                                                    <a
                                                        className="list-group-item d-flex justify-content-start align-items-center list-group-item-action  borderless text-primary p-0 "
                                                        data-toggle="collapse"
                                                        href="#CollapseExperience"
                                                        role="button"
                                                        aria-expanded="false"
                                                        aria-controls="CollapseExperience"
                                                    >
                                                        {" "}
                                                        <div className="tit_black m-0 p-0 d-flex col-12 align-items-center justify-content-start">
                                                            Experiencia
                                                        </div>
                                                    </a>
                                                </div>

                                                <div className="col-2 pl-0 pr-0" style={{ backgroundColor: "#FFFFFF" }}>

                                                    {(Number(experiencesLenght) < 3 || Number(experiencesLenght) == 0) ?
                                                        <Link to="/ExperienciaText" className="icono_right m-0" style={{ zIndex: 1000 }} >
                                                            <FontAwesomeIcon icon={faPen} size="1x" />
                                                        </Link>
                                                        : <FontAwesomeIcon icon={faAngleDown} size="1x" className="text-primary icono_right m-0" />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="collapse multi-collapse"
                                        id="CollapseExperience"
                                    >  <div className="col-12  mt-2 mb-2 p-0" style={{ color: "#000000" }}>
                                            {"Cargá hasta tres experiencias"}
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group education">
                                                {experiences &&
                                                    Object.keys(experiences).map((keyName, i) => (
                                                        <div className="form-group aptitud ml-0 mr-0 pr-0" style={{ textAlign: "left" }} key={i}>
                                                            <div className="name pl-0 ml-0 " style={{ textAlign: "left" }}>
                                                                {" "}
                                                                {experiences[keyName].company}{" "}
                                                            </div>
                                                            <Link
                                                                className="faTimes"
                                                                onClick={() =>
                                                                    deleteExperience(
                                                                        experiences[keyName].id,
                                                                        experiences[keyName]
                                                                    )
                                                                }
                                                            //to={"/AgregarAptitudes/" + values.skillsSubcategory}
                                                            >
                                                                <FontAwesomeIcon icon={faTimes} size="1x" style={{ color: "#0D71B9" }} />
                                                            </Link>

                                                            <div className="date">
                                                                {moment(experiences[keyName].start_date).format(
                                                                    "MM/YYYY"
                                                                ) +
                                                                    " - " +
                                                                    (experiences[keyName].finish_date
                                                                        ? moment(
                                                                            experiences[keyName].finish_date
                                                                        ).format("MM/YYYY")
                                                                        : "actualmente")}
                                                            </div>
                                                            <div className="title ml-0">
                                                                {experiences[keyName].position}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="list-group list-group-flush w100 list-group-flush w100 mr-0 ml-0 p-3 mt-3 border-light shadow-sm rounded-0" style={{ backgroundColor: "#FFFFFF" }}>
                                    <div className="row  mr-0 ml-0">
                                        <div className="col-12  pr-0 pl-0">
                                            <div className="row mr-0 ml-0">
                                                <div className="col-10 pl-0 pr-0 d-flex">
                                                    <a
                                                        className="list-group-item d-flex justify-content-start align-items-center list-group-item-action  borderless text-primary p-0 "
                                                        data-toggle="collapse"
                                                        href="#CollapseSkills"
                                                        role="button"
                                                        aria-expanded="false"
                                                        aria-controls="CollapseSkills"
                                                    >
                                                        <div className="tit_black m-0 p-0 d-flex col-12 align-items-center justify-content-start">
                                                            Habilidades
                                                        </div>

                                                    </a>
                                                </div>
                                                {skills == undefined || skills == '' ?
                                                    <div className="col-12 text-primary" style={{ marginBottom: "1rem", paddingLeft: "2rem" }}>
                                                        {"Esta sección se va a completar después de tu entrevista"}
                                                    </div>
                                                    : ""}


                                                <div className="col-2 pl-0 pr-0 d-flex align-items-center justify-content-end" style={{ backgroundColor: "#FFFFFF" }}>


                                                    <FontAwesomeIcon icon={faAngleDown} size="1x" className="text-primary icono_right m-0" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="collapse multi-collapse" id="CollapseSkills">
                                        <div className="form-group w-100 mb-0">
                                            <div className="input-group mt-2 w-100">
                                                {" "}
                                                {skills &&
                                                    Object.keys(skills).map((keyName, i) => (
                                                        <div className="form-group habilidad ml-0" key={i}>
                                                            <Link
                                                                className="faTimes"
                                                                onClick={() =>
                                                                    deleteSkill(
                                                                        skills[keyName].id,
                                                                        skills[keyName]
                                                                    )
                                                                }
                                                            //to={"/AgregarAptitudes/" + values.skillsSubcategory}
                                                            >

                                                            </Link>

                                                            <div className="title">
                                                                {skills[keyName].category +
                                                                    "/" +
                                                                    skills[keyName].subcategory}
                                                            </div>

                                                            <div className="name">
                                                                {" "}
                                                                {skills[keyName].skill}{" "}
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Form className="match_detalle_top mt-3 bg-white shadow-sm border border-light  w-100 p-3">

                                    <div className="tit_black ml-0">
                                        Deseo ser notificado mediante:
                                    </div>
                                    <div className="swich_c">
                                        <div className="custom-control custom-switch  mt-2">
                                            <input
                                                id="emailNotifications"
                                                name="emailNotifications"
                                                type="checkbox"
                                                className="custom-control-input"

                                                onChange={(e, setFieldValue) => {
                                                    notification(
                                                        e,
                                                        values,
                                                        document.getElementById("appNotifications"),
                                                        document.getElementById("emailNotifications"),
                                                        setFieldValue
                                                    );
                                                }}
                                                checked={emailNotifications == "1" ? true : false}
                                            />

                                            <label
                                                className="custom-control-label"
                                                htmlFor="emailNotifications"

                                            >
                                                Notificaciones Mail
                                            </label>
                                        </div>
                                    </div>

                                    <div className="swich_c">
                                        <div className="custom-control custom-switch">
                                            <input
                                                id="appNotifications"
                                                name="appNotifications"
                                                type="checkbox"
                                                style={{ backgroundColor: "#0D71B9" }}
                                                className="custom-control-input"
                                                onChange={(e, setFieldValue) => {
                                                    notification(
                                                        e,
                                                        values,
                                                        document.getElementById("appNotifications"),
                                                        document.getElementById("emailNotifications"),
                                                        setFieldValue
                                                    );
                                                }}
                                                checked={appNotifications == "1" ? true : false}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="appNotifications"
                                            >
                                                Notificaciones Push
                                            </label>
                                        </div>
                                    </div>

                                </Form>
                            </div>
                        </article>
                    </div>
                </div>
            </div >
        </div >
    );
};
const EnhancedForm = formikWrapper(PerfilText);
export default withRouter(EnhancedForm);
