import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NuevaBusquedaScreen from "./NuevaBusquedaScreen";
import axios from "axios";
import { url_base } from "./../../../../api_url";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';
import * as Scroll from 'react-scroll';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

class NuevaBusqueda extends Component {
    constructor(props) {
        super(props);

        this.state = {
            //info: this.props.location.state,
            province: [],
            cityDisabled: "disabled",
            durationUnitQuant: "",
            cityState: [],
            selected: new Date(),
            skillsCategory: [],
            skillsSubcategory: [],
            skillsSubcategoryDisabled: "disabled",
            skillsSubcategoryID: "",
            skillsCategoryID: "",
            skills: "",
            minPaymentPercentage: [],
            provinceList: [],
            durationUnit: [],
            cityList: [],
            city: "",
            unit: "",
            unitDescription: "",
            province: "",
            jobEndDate: [],
            //  offerEndDate: [],
            jobStartDate: [],
            maxPayment: "",
            showModal: false,
            modalTitle: "",
            modalText: "",
            modalBtn: "",
            path: "",
            redirect: false,
            checks: [],
            arr: [],
            editorState: EditorState.createEmpty(),
            totalMinPayment: "",
            totalMaxPayment: "",
            idKey: ""
            //  title: this.title
        };
        this.modalReset = this.modalReset.bind(this);
        this.ProvinceHandleChange = this.ProvinceHandleChange.bind(this);
        this.categoryHandleChange = this.categoryHandleChange.bind(this);
        this.skillsSubcategoryHandleChange = this.skillsSubcategoryHandleChange.bind(
            this
        );
        this.disabledSkillsbutton = this.disabledSkillsbutton.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.calculateMaxPayment = this.calculateMaxPayment.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeWiziwig = this.onChangeWiziwig.bind(this);
        this.setDescValue = this.setDescValue.bind(this);
        this.handleInputChangeMin = this.handleInputChangeMin.bind(this);
        this.durationUnitHandleChange = this.durationUnitHandleChange.bind(this);
        this.handleInputUnitQuantity = this.handleInputUnitQuantity.bind(this);
        this.handleSave = this.handleSave.bind(this);

    }
    handleSave(values, setErrors, errors) {

        const valsplit = values.checks && JSON.parse(values.checks);

        var ids = "";
        valsplit && Object.keys(valsplit).map((keyName, i) => (
            <>
                {(valsplit[keyName].id !== undefined && valsplit[keyName].id !== "" && valsplit[keyName].id !== null) ?
                    <>
                        {
                            // ids.push(valsplit[keyName].id)

                            ids += valsplit[keyName].id + ","
                        }
                    </>
                    : ""}


            </>
        ))
        if (ids !== "") {
            values.skills = ids.substring(0, ids.length - 1);//JSON.stringify(ids);


        }

        var isError = false;
        var errorsMsgTitle = {};
        var errorsMsgCity = {};
        var errorsMsgSubCategory = {};
        if (values.title === "") {
            errors.title = "requerido";
            //  setErrors({ "title": "requerido" });
            errorsMsgTitle = { "title": "requerido" }
            isError = true
            //  window.scrollTo(0, 0)

        }

        if (((values.province === "" || values.province === null || values.province === undefined)
            && (values.city === "" || values.city === null || values.city === undefined) ||
            (values.province !== "" && values.province !== null && values.province !== undefined)
            && (values.city !== "" && values.city !== null && values.city !== undefined))
        ) {

            errors.city = "";

        } else {
            errors.city = "requerido";
            //  setErrors({ "city": "requerido" });
            errorsMsgCity = { "city": "requerido" }
            isError = true
        }

        if (values.skillsCategory && !values.skillsSubcategory && values.skillsCategory !== "" && values.skillsSubcategory === "") {

            errors.skillsSubcategory = "requerido";
            errorsMsgSubCategory = { "skillsSubcategory": "requerido" }
            isError = true;
        }

        if (isError === false) {
            setErrors({});
        } else {
            setErrors(errors);

        }


        if (isError === true) { scroll.scrollTo(0); } else {
            //   setErrors({});

            const access_token_data = localStorage.getItem("token");
            const valuesToApi = JSON.stringify(values).replace("null", "");

            axios
                .post(url_base + "/companies/job-searches/draft/add", JSON.parse(valuesToApi), {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                })
                .then(response => {

                    let message = "";
                    this.setState({
                        showModal: true,
                        modalTitle: "AVISO",
                        modalText:
                            "El borrador se creo correctamente!",
                        modalBtn: "Aceptar",
                        path: "/BusquedasActivas",
                        redirect: true
                    });

                    localStorage.removeItem("title", values.title);
                    localStorage.removeItem("description", values.description);
                    localStorage.removeItem("addressStreet", values.addressStreet);
                    localStorage.removeItem("addressNumber", values.addressNumber);
                    localStorage.removeItem("city", values.city);
                    localStorage.removeItem("jobEndDate", values.jobEndDate);
                    /*  localStorage.removeItem("offerEndDate", values.offerEndDate);*/
                    localStorage.removeItem("jobStartDate", values.jobStartDate);
                    localStorage.removeItem("maxPayment", values.maxPayment);
                    localStorage.removeItem("minPayment", values.minPayment);
                    localStorage.removeItem("province", values.province);
                    localStorage.removeItem("skillsCategory", values.skillsCategory);
                    localStorage.removeItem("skillsSubcategory", values.skillsSubcategory);
                    localStorage.removeItem("skills", values.skills);
                    localStorage.removeItem("checks", values.checks);
                    localStorage.removeItem("vacancies", values.vacancies);
                    localStorage.removeItem("totalHours", values.totalHours);
                    localStorage.removeItem(
                        "minPaymentPercentage",
                        values.minPaymentPercentage
                    );
                })
                .catch(error => {
                    this.setState({
                        showModal: true,
                        modalTitle: "HA OCURRIDO UN ERROR",
                        modalText:
                            "Ocurrio un error al crear el borrador. Por favor intentalo nuevamente.",
                        modalBtn: "Aceptar",
                        path: "/BusquedasActivas",
                        redirect: true
                    })
                }
                );
        }
    }

    modalReset() {
        this.setState({ showModal: false });
    }

    setDescValue(event, setFieldValue) {
        setFieldValue("description", event.target.value)
        return event.target.value
    }


    onChangeWiziwig(description, setFieldValue) { this.setState({ editorState: description }); setFieldValue("description", description) };
    handleSubmit(values, props) {
        const access_token_data = localStorage.getItem("token");

        axios
            .post(url_base + "/companies/job-searches/add", values, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {
                if (
                    Number(response.data.data.talentsMatching) <
                    Number(response.data.data.vacancies)
                ) {
                    let message = "";
                    this.setState({
                        showModal: true,
                        modalTitle: "AVISO",
                        modalText:
                            "La publicacion se creo correctamente! La cantidad de Talentos que hacen Match es inferior a la cantidad de vacantes. Si necesitas ayuda escribinos a hola@asignate.com",
                        modalBtn: "Aceptar",
                        path: "/BusquedasActivas",
                        redirect: true
                    });
                } else {
                    let message = response.data.message;
                    this.setState({
                        showModal: true,
                        modalTitle: "BÚSQUEDA CREADA",
                        modalText: message,
                        modalBtn: "Aceptar",
                        path: "/BusquedasActivas",
                        redirect: true
                    });
                }
                localStorage.removeItem("title", values.title);
                localStorage.removeItem("description", values.description);
                localStorage.removeItem("addressStreet", values.addressStreet);
                localStorage.removeItem("addressNumber", values.addressNumber);
                localStorage.removeItem("city", values.city);
                localStorage.removeItem("jobEndDate", values.jobEndDate);
                /*  localStorage.removeItem("offerEndDate", values.offerEndDate);*/
                localStorage.removeItem("jobStartDate", values.jobStartDate);
                localStorage.removeItem("maxPayment", values.maxPayment);
                localStorage.removeItem("minPayment", values.minPayment);
                localStorage.removeItem("province", values.province);
                localStorage.removeItem("skillsCategory", values.skillsCategory);
                localStorage.removeItem("skillsSubcategory", values.skillsSubcategory);
                localStorage.removeItem("skills", values.skills);
                localStorage.removeItem("checks", values.checks);
                localStorage.removeItem("vacancies", values.vacancies);
                localStorage.removeItem("totalHours", values.totalHours);
                localStorage.removeItem(
                    "minPaymentPercentage",
                    values.minPaymentPercentage
                );
                /*   localStorage.removeItem("totalMaxPayment");
                   localStorage.removeItem("totalMinPayment");*/
            })
            .catch(error => {

                this.setState({
                    showModal: true,
                    modalTitle: "HA OCURRIDO UN ERROR",
                    modalText:
                        "Ocurrio un error al crear la publicacion. Por favor intentalo nuevamente.",
                    modalBtn: "Aceptar",
                    path: "/BusquedasActivas",
                    redirect: true
                })
            }
            );
    }

    handleInputChangeMin(event, minPercentage, durationUnitQuant, setFieldValue) {

        this.setState({ minPayment: event.target.value });
        setFieldValue("minPayment", event.target.value);

        if (minPercentage !== undefined && minPercentage !== "") {
            const partial = Math.floor((event.target.value * minPercentage) / 100);
            const result = Number(event.target.value) + Number(partial);
            this.setState({ maxPayment: result });
            setFieldValue("maxPayment", result);

            if (durationUnitQuant !== undefined && durationUnitQuant !== "") {
                const totalMinPayment = event.target.value * durationUnitQuant;
                const totalMaxPayment = result * durationUnitQuant;

                this.setState({ totalMinPayment: totalMinPayment });
                this.setState({ totalMaxPayment: totalMaxPayment });

                setFieldValue("totalMinPayment", totalMinPayment);
                setFieldValue("totalMaxPayment", totalMaxPayment);

            }
        }
    }

    handleInputUnitQuantity(event, minPayment, maxPayment, setFieldValue) {

        this.setState({ durationUnitQuant: event.target.value });
        setFieldValue("durationUnitQuant", event.target.value);

        if (minPayment !== undefined && minPayment !== "" && maxPayment !== undefined && maxPayment !== "") {
            const totalMinPayment = minPayment * event.target.value;
            const totalMaxPayment = maxPayment * event.target.value;

            this.setState({ totalMinPayment: totalMinPayment });
            this.setState({ totalMaxPayment: totalMaxPayment });

            setFieldValue("totalMinPayment", totalMinPayment);
            setFieldValue("totalMaxPayment", totalMaxPayment);
        }
    }

    handleInputChange(event, minPayment, durationUnitQuant, setFieldValue) {
        event.target.checked = true;
        const partial = Math.floor((event.target.value * minPayment) / 100);
        const result = Number(minPayment) + Number(partial);

        this.setState({ maxPayment: result });
        setFieldValue("maxPayment", result);
        setFieldValue("minPaymentPercentage", event.target.value);

        event.target.checked = true;

        if (minPayment !== undefined && minPayment !== "" && durationUnitQuant !== undefined && durationUnitQuant !== "") {

            const totalMinPayment = minPayment * durationUnitQuant;
            const totalMaxPayment = result * durationUnitQuant;

            this.setState({ totalMinPayment: totalMinPayment });
            this.setState({ totalMaxPayment: totalMaxPayment });

            setFieldValue("totalMinPayment", totalMinPayment);
            setFieldValue("totalMaxPayment", totalMaxPayment);
        }
    }

    skillsSubcategoryHandleChange(e, setFieldValue) {
        setFieldValue("skillsSubcategory", e.target.value);

        this.setState({
            skillsSubcategoryID: e.target[e.target.selectedIndex].id
        });
    }

    calculateMaxPayment(value, minPayment) {
        const result = minPayment + (value * minPayment) / 100;

        this.setState({ maxPayment: result });
        this.setFieldValue("maxPayment", result);
    }



    saveformStorage(values) {

        localStorage.setItem("title", values.title);
        localStorage.setItem("description", values.description);
        localStorage.setItem("addressStreet", values.addressStreet);
        localStorage.setItem("addressNumber", values.addressNumber);
        localStorage.setItem("city", values.city);
        localStorage.setItem("jobEndDate", values.jobEndDate);
        /*  localStorage.setItem("offerEndDate", values.offerEndDate);*/
        localStorage.setItem("jobStartDate", values.jobStartDate);
        localStorage.setItem("maxPayment", values.maxPayment);
        localStorage.setItem("minPayment", values.minPayment);
        localStorage.setItem("province", values.province);
        localStorage.setItem("skillsCategory", values.skillsCategory);
        localStorage.setItem("skillsSubcategory", values.skillsSubcategory);
        localStorage.setItem("vacancies", values.vacancies);
        localStorage.setItem("totalHours", values.totalHours);
        localStorage.setItem("minPaymentPercentage", values.minPaymentPercentage);
        localStorage.setItem("durationUnit", values.durationUnit);
        values.totalMaxPayment && localStorage.setItem("totalMaxPayment", values.totalMaxPayment);
        values.totalMinPayment && localStorage.setItem("totalMinPayment", values.totalMinPayment);

    }

    categoryHandleChange(e, setFieldValue) {
        setFieldValue("skillsCategory", e.target.value);
        setFieldValue("skillsSubcategory", "");
        this.setState({
            skillsCategoryID: e.target[e.target.selectedIndex].value
        });

        if (e.target[e.target.selectedIndex].value != "") {
            this.setState({
                skillsSubcategoryDisabled: ""
            });
        } else {
            this.setState({
                skillsSubcategoryDisabled: "disabled"
            });
        }

        let skillsCategoryID = e.target[e.target.selectedIndex].value;

        const access_token_data = localStorage.getItem("token");

        axios

            .get(url_base + "/skills/subcategories/" + skillsCategoryID, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {
                this.setState({
                    skillsSubcategory: response.data.data.subcategories
                });
            })
            .catch(error => { });
    }

    disabledSkillsbutton = values => {
        let disabled = "none";
        values.skillsSubcategory || values.skillsSubcategory === "undefined"
            ? (disabled = "")
            : (disabled = "none");
        return disabled;
    };
    //


    ProvinceHandleChange(e, setFieldValue) {
        setFieldValue("province", e.target.value);

        this.setState({
            province: e.target[e.target.selectedIndex].value
        });

        if (e.target[e.target.selectedIndex].value != "") {
            this.setState({
                cityDisabled: "",
                cityList: ""
            });
        } else {
            this.setState({
                cityDisabled: "disabled",
                cityList: ""
            });
        }
        const idProvince = e.target[e.target.selectedIndex].value;

        axios
            .get(
                url_base + "/province-cities/" + e.target[e.target.selectedIndex].value
            )
            .then(response => {
                this.setState({
                    cityList: response.data.data.cities,
                    city: response.data.data.cities[0].id
                });
            })
            .catch(error => { });
    }

    durationUnitHandleChange(e, setFieldValue) {

        setFieldValue("durationUnit", e.target[e.target.selectedIndex].value);



        if (e.target[e.target.selectedIndex].value === "1") {

            setFieldValue("durationUnitQuant", "1");
            this.setState({ durationUnitQuant: "1" });

        } else {
            this.setState({
                durationUnitQuant: ""

            });
            setFieldValue("durationUnitQuant", "");

        }

        setFieldValue("maxPayment", "");
        setFieldValue("minPayment", "");
        setFieldValue("totalMinPayment", "");
        setFieldValue("totalMaxPayment", "");
        setFieldValue("minPaymentPercentage", "");

        this.setState({
            unit: e.target[e.target.selectedIndex].value,
            unitDescription: e.target[e.target.selectedIndex].innerHTML,
            maxPayment: "",
            minPayment: "",
            totalMinPayment: "",
            totalMaxPayment: "",
            minPaymentPercentage: ""
        });

    }
    deleteitems() {
        localStorage.removeItem("title");
        localStorage.removeItem("description");
        localStorage.removeItem("addressStreet");
        localStorage.removeItem("addressNumber");
        localStorage.removeItem("city");
        localStorage.removeItem("jobEndDate");
        //  localStorage.removeItem("offerEndDate", values.offerEndDate);
        localStorage.removeItem("jobStartDate");
        localStorage.removeItem("maxPayment");
        localStorage.removeItem("minPayment");
        localStorage.removeItem("province");
        localStorage.removeItem("skillsCategory");
        localStorage.removeItem("skillsSubcategory");
        localStorage.removeItem("skills");
        localStorage.removeItem("checks");
        localStorage.removeItem("vacancies");
        localStorage.removeItem("totalHours");
        localStorage.removeItem(
            "minPaymentPercentage"
        );
        localStorage.removeItem("totalMaxPayment");
        localStorage.removeItem("totalMinPayment");
        localStorage.removeItem("durationUnit");
        localStorage.removeItem("durationUnitQuant");
        return 0
    }

    componentWillMount(values, setFieldValue) {

        const key = this.props.location.pathname.split("/");
        //  const key = this.props.location.state && this.props.location.state.referedData;
        // console.log("key: " + JSON.stringify(key));
        const idKey = key[2];
        const keyPageNro = key[2];
        this.setState({ idKey: idKey })
        const access_token_data = localStorage.getItem("token");

        if (key[2] === "" || key[2] === null || key[2] === undefined) {

            this.deleteitems()
        } else {

            //
            const durationUnitQuant = localStorage.getItem("durationUnitQuant");
            const minPercentage = localStorage.getItem("minPaymentPercentage");

            const minPayment = localStorage.getItem("minPayment");

            if ((minPercentage !== undefined && minPercentage !== "") && (minPayment !== undefined && minPayment !== "")) {
                const partial = Math.floor((minPayment * minPercentage) / 100);
                const result = Number(minPayment) + Number(partial);

                localStorage.setItem("maxPayment", result);
                this.setState({ maxPayment: result });

                if (durationUnitQuant !== undefined && durationUnitQuant !== "") {
                    const totalMinPayment = minPayment * durationUnitQuant;
                    const totalMaxPayment = result * durationUnitQuant;

                    localStorage.setItem("totalMinPayment", totalMinPayment);
                    localStorage.setItem("totalMaxPayment", totalMaxPayment);

                    this.setState({
                        totalMinPayment: totalMinPayment,
                        totalMaxPayment: totalMaxPayment
                    })

                }
            }

        }

        //  //cargo las provincias
        axios
            .get(url_base + "/provinces")
            .then(response => {
                this.setState({
                    provinceList: response.data.data.provinces
                });
            })
            .catch(error => { });

        //selecciono la ciudad si ya fue cargada

        const province = localStorage.getItem("province");
        //const city = localStorage.getItem("city");
        const skills = localStorage.getItem("skills");
        const checks = JSON.parse(localStorage.getItem("checks"));

        let data = this.state.checks;

        this.setState({ skills: skills, checks: checks });

        if (province != "") {
            this.setState({
                cityDisabled: "",
                cityList: ""
            });
            //   localStorage.setItem("city", "");
        } else {
            this.setState({
                cityDisabled: "disabled",
                cityList: ""
            });
            //    localStorage.setItem("city", "");
        }
        axios
            .get(url_base + "/province-cities/" + province)
            .then(response => {
                this.setState({
                    // cityState: response.data.data.cities
                    cityList: response.data.data.cities
                });
            })
            .catch(error => { });

        axios
            .get(url_base + "/skills/categories-with-skills", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {
                this.setState({
                    skillsCategory: response.data.data.categories
                });

                //si se cargó la categoría ok cargo la subcategoría
                const subID = localStorage.getItem("skillsCategory");

                if (subID) {
                    this.setState({
                        skillsSubcategoryDisabled: ""
                    });
                } else {
                    this.setState({
                        skillsSubcategoryDisabled: "disabled"
                    });
                }

                axios
                    .get(url_base + "/skills/subcategories/" + subID, {
                        headers: {
                            ["Authorization"]: "Bearer " + access_token_data
                        }
                    })
                    .then(response => {
                        this.setState({
                            skillsSubcategory: response.data.data.subcategories
                        });
                    })
                    .catch(error => { });
            })
            .catch(error => { });

        //cargo las subcategorias si ya fueron cargadas



        // Recupero las unidades de duración

        axios
            .get(url_base + "/job-searches/duration-units", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {

                this.setState({
                    durationUnit: response.data.data,
                });
                const unitValue = localStorage.getItem("durationUnit");

                if (unitValue != "" && response.data.data[unitValue]) {
                    this.setState({
                        unit: unitValue,
                        unitDescription: response.data.data[unitValue].unit
                        // unitDescription:
                    });
                }
            })
    }


    render() {
        const handleSubmit = values => { };


        return (
            <div>
                <NuevaBusquedaScreen
                    id={this.state.id}
                    province={this.state.province}
                    provinceList={this.state.provinceList}
                    cityDisabled={this.state.cityDisabled}
                    city={this.state.cityState}
                    cityList={this.state.cityList}
                    unit={this.state.unit}
                    ProvinceHandleChange={this.ProvinceHandleChange}
                    durationUnitHandleChange={this.durationUnitHandleChange}
                    categoryHandleChange={this.categoryHandleChange}
                    skillsCategory={this.state.skillsCategory}
                    category={this.state.category}
                    unitDescription={this.state.unitDescription}
                    skillsSubcategory={this.state.skillsSubcategory}
                    skillsSubcategoryDisabled={this.state.skillsSubcategoryDisabled}
                    skillsSubcategoryID={this.state.skillsSubcategoryID}
                    skillsSubcategoryHandleChange={this.skillsSubcategoryHandleChange}
                    saveformStorage={this.saveformStorage}
                    disabledSkillsbutton={this.disabledSkillsbutton}
                    handleInputChange={this.handleInputChange}
                    calculateMaxPayment={this.calculateMaxPayment}
                    minPayment={this.state.minPayment}
                    maxPayment={this.state.maxPayment}
                    showModal={this.state.showModal}
                    modalTitle={this.state.modalTitle}
                    modalText={this.state.modalText}
                    modalBtn={this.state.modalBtn}
                    modalReset={this.modalReset}
                    handleSubmit={this.handleSubmit}
                    path={this.state.path}
                    redirect={this.state.redirect}
                    skills={this.state.skills}
                    checks={this.state.checks}
                    durationUnit={this.state.durationUnit}
                    arr={this.state.arr}
                    onChangeWiziwig={this.onChangeWiziwig}
                    editorState={this.state.editorState}
                    setDescValue={this.setDescValue}
                    handleInputChangeMin={this.handleInputChangeMin}
                    handleInputUnitQuantity={this.handleInputUnitQuantity}
                    handleSave={this.handleSave}
                    title={this.state.title}
                    description={this.state.description}
                    totalMinPayment={this.state.totalMinPayment}
                    totalMaxPayment={this.state.totalMaxPayment}
                    idKey={this.state.idKey}
                />
            </div>
        );
    }
}

export default withRouter(NuevaBusqueda);
