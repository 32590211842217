import React, { Component } from "react";
import BusquedasActivasScreen from "./BusquedasActivasScreen";
import axios from "axios";
import { url_base } from "../../../../api_url";
import auth from "../../../../auth";

class BusquedasActivas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            bySkills: [],
            cantJobOffers: [],
            cantPages: [],
            pages: [],
            divShow: false,
            pageSelected: "",
            optionSelected: ""
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeOptions = this.handleChangeOptions.bind(this);
        this.redirectToBusquedasActivas = this.redirectToBusquedasActivas.bind(this);
        this.deleteDraft = this.deleteDraft.bind(this);
    }
    redirectToBusquedasActivas(values) {

        const key = this.props.location.pathname.split("/");
        const access_token_data = localStorage.getItem("token");



        axios
            .post(url_base + "/companies/job-searches/" + values.id, "", {
                headers: { ["Authorization"]: "Bearer " + access_token_data }
            })

            .then(
                response => {

                    localStorage.setItem("title", response.data.data.jobOffer.title)
                    localStorage.setItem("description", response.data.data.jobOffer.description !== null ? response.data.data.jobOffer.description : "")
                    localStorage.setItem("vacancies", response.data.data.jobOffer.vacancies !== null ? response.data.data.jobOffer.vacancies : "")
                    localStorage.setItem("province", response.data.data.jobOffer.province_id !== "" ? response.data.data.jobOffer.province_id : "")

                    localStorage.setItem("addressStreet", response.data.data.jobOffer && response.data.data.jobOffer.address_street !== null ? response.data.data.jobOffer.address_street : "");
                    localStorage.setItem("addressNumber", response.data.data.jobOffer && response.data.data.jobOffer.address_number !== null ? response.data.data.jobOffer.address_number : "");
                    localStorage.setItem("city", response.data.data.jobOffer && response.data.data.jobOffer.city_id !== null ? response.data.data.jobOffer.city_id : "");
                    localStorage.setItem("jobEndDate", response.data.data.jobOffer.job_end_date !== null ? response.data.data.jobOffer.job_end_date : "");
                    /*  localStorage.setItem("offerEndDate", values.offerEndDate);*/
                    localStorage.setItem("jobStartDate", response.data.data.jobOffer.job_start_date !== null ? response.data.data.jobOffer.job_start_date : "");
                    localStorage.setItem("maxPayment", response.data.data.jobOffer.max_payment !== "" ? response.data.data.jobOffer.max_payment : "");
                    localStorage.setItem("minPayment", response.data.data.jobOffer.min_payment !== null ? response.data.data.jobOffer.min_payment : "");
                    localStorage.setItem("province", response.data.data.jobOffer.province_id !== null ? response.data.data.jobOffer.province_id : "");
                    localStorage.setItem("provinceList", response.data.data.jobOffer.province_id !== null ? response.data.data.jobOffer.province_id : "");

                    localStorage.setItem("skillsCategory", response.data.data.jobOffer.subcategory && response.data.data.jobOffer.subcategory.category_id !== "" ? response.data.data.jobOffer.subcategory.category_id : "");
                    localStorage.setItem("skillsSubcategory", response.data.data.jobOffer.subcategory && response.data.data.jobOffer.subcategory.id !== "" ? response.data.data.jobOffer.subcategory.id : "");
                    localStorage.setItem("totalHours", response.data.data.jobOffer.totalHours && response.data.data.jobOffer.totalHours !== "" ? response.data.data.jobOffer.totalHours : "");
                    localStorage.setItem("minPaymentPercentage", response.data.data.jobOffer.min_payment_percentage && response.data.data.jobOffer.min_payment_percentage !== "" ? response.data.data.jobOffer.min_payment_percentage : "");
                    localStorage.setItem("durationUnit", response.data.data.jobOffer.duration_unit_id && response.data.data.jobOffer.duration_unit_id !== "" ? response.data.data.jobOffer.duration_unit_id : "");
                    localStorage.setItem("durationUnitQuant", response.data.data.jobOffer.duration_unit_quantity && response.data.data.jobOffer.duration_unit_quantity !== "" ? response.data.data.jobOffer.duration_unit_quantity : "");

                    const skillsObj = response.data.data.jobOffer.skills;


                    let skills = [];
                    Object.keys(skillsObj).map((keyName, i) => {
                        skills.push({
                            id: skillsObj[keyName].id,
                            description: skillsObj[keyName].skill
                        })
                    })

                    const Datainfo = JSON.stringify(skills);

                    localStorage.setItem("checks", Datainfo);

                    this.props.history.push({ pathname: "/NuevaBusqueda/" + response.data.data.jobOffer.id });
                },
                error => {
                    var status = error.response;
                }
            )



    }

    handleChangeOptions(e) {

        let option = e.target[e.target.selectedIndex].value;
        const access_token_data = localStorage.getItem("token");
        const key = this.props.location.pathname.split("/");

        const datosStorage = JSON.parse(localStorage.getItem("BusquedasActivas"));

        let keyIdPage = "";

        if (datosStorage && datosStorage.keyPageNro != "" && datosStorage.keyPageNro !== undefined && datosStorage.keyPageNro !== null) {
            keyIdPage = datosStorage.keyPageNro;
        } else {
            keyIdPage = 1;
        }

        axios
            .get(url_base + "/companies/job-searches/list/", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }, params: {
                    state: String(option)
                }
            })
            .then(
                response => {

                    const data = response.data.data.jobOffers;
                    const cantJobOffers = response.data.data.cantJobOffers;
                    const cantPages = response.data.data.cantPages;

                    if (data == "") {
                        this.setState({
                            data: data,
                            cantJobOffers: cantJobOffers,
                            cantPages: cantPages,
                            divShow: true,
                            pageSelected: 1,
                            optionSelected: option
                        });
                    } else {
                        this.setState({
                            data: data,
                            cantJobOffers: cantJobOffers,
                            cantPages: cantPages,
                            divShow: false,
                            pageSelected: 1,
                            optionSelected: option
                        });
                    }
                },
                error => {
                    var status = error.response;
                    console.log(status);
                }
            );
    }
    deleteDraft(id, pageSelected, optionSelected) {
        const access_token_data = localStorage.getItem("token");
        const parameters = {
            state: String(optionSelected)
        }
        this.props.history.push("/BusquedasActivas/" + id);
        axios
            .delete(url_base + "/companies/job-searches/draft/" + id, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(

                res => {

                    const diferencia = this.state.cantJobOffers % 4;

                    if (diferencia === 1) {
                        if (pageSelected > 1) {
                            pageSelected = pageSelected - 1;

                        }
                    }


                    axios
                        .get(url_base + "/companies/job-searches/list/" + pageSelected, {
                            headers: {
                                ["Authorization"]: "Bearer " + access_token_data
                            }, params: {
                                state: String(optionSelected)
                            }
                        })
                        .then(
                            response => {

                                const data = response.data.data.jobOffers;
                                const cantJobOffers = response.data.data.cantJobOffers;
                                const cantPages = response.data.data.cantPages;

                                if (data === "") {

                                } else {
                                    this.setState({
                                        data: data,
                                        cantJobOffers: cantJobOffers,
                                        cantPages: cantPages,
                                        divShow: false,
                                        pageSelected: pageSelected,
                                        optionSelected: optionSelected
                                    });
                                }
                            },
                            error => {
                                var status = error.response;
                                console.log(status);
                            }
                        );
                },
                error => {
                    var status = error.response;
                }
            );

    }

    handleChangePage(id) {
        const access_token_data = localStorage.getItem("token");
        // const datosStorage = localStorage.getItem("BusquedasActivas") && JSON.parse(localStorage.getItem("BusquedasActivas"));
        this.props.history.push("/BusquedasActivas/" + id);
        /* let pageNro = "";
         if (datosStorage) {
             pageNro = datosStorage.keyPageNro;
         } else {
             pageNro = 1;
         }*/
        axios
            .get(url_base + "/companies/job-searches/list" + "/" + id, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }, params: {
                    state: String(this.state.optionSelected)
                }
            })
            .then(
                response => {
                    const data = response.data.data.jobOffers;
                    const cantJobOffers = response.data.data.cantJobOffers;
                    const cantPages = response.data.data.cantPages;

                    this.setState({
                        data: data,
                        cantJobOffers: cantJobOffers,
                        cantPages: cantPages,
                        pageSelected: id
                        //    bySkills: bySkills
                    });
                },
                error => {
                    var status = error.response;
                }
            );
    }

    componentDidMount() {
        const access_token_data = localStorage.getItem("token");
        const datosStorage = localStorage.getItem("BusquedasActivas") && JSON.parse(localStorage.getItem("BusquedasActivas"));
        const key = this.props.location.pathname.split("/");

        let keyIdPage = "";
        let state = "";

        if (datosStorage) {
            if (datosStorage.keyPageNro != "" && datosStorage.keyPageNro !== undefined && datosStorage.keyPageNro !== null) {
                keyIdPage = datosStorage.keyPageNro;
            } else {
                keyIdPage = 1;
            }
            if (datosStorage.optionSelected != "" && datosStorage.optionSelected !== undefined && datosStorage.optionSelected !== null) {
                // console.log("key[3]: " + key[3])
                state = datosStorage.optionSelected;
            } else {
                state = "activas";
            }
        } else {
            keyIdPage = 1;
            state = "activas";
        }

        axios
            .get(url_base + "/companies/job-searches/list/" + keyIdPage, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }, params: {
                    // state: "activas"
                    state: String(state)
                }
            })

            .then(
                response => {

                    const data = response.data.data.jobOffers;
                    const cantJobOffers = response.data.data.cantJobOffers;
                    const cantPages = response.data.data.cantPages;

                    if (data == "") {
                        this.setState({
                            data: data,
                            cantJobOffers: cantJobOffers,
                            cantPages: cantPages,
                            divShow: true,
                            pageSelected: keyIdPage,
                            optionSelected: state
                        });
                    } else {
                        this.setState({
                            data: data,
                            cantJobOffers: cantJobOffers,
                            cantPages: cantPages,
                            divShow: false,
                            pageSelected: keyIdPage,
                            optionSelected: state
                        });
                    }
                },
                error => {
                    var status = error.response;
                    console.log(status);
                }
            );
    }

    render() {
        return (
            <BusquedasActivasScreen
                data={this.state.data}
                cantJobOffers={this.state.cantJobOffers}
                cantPages={this.state.cantPages}
                pages={this.state.pages}
                handleChangePage={this.handleChangePage}
                handleChangeOptions={this.handleChangeOptions}
                divShow={this.state.divShow}
                pageSelected={this.state.pageSelected}
                optionSelected={this.state.optionSelected}
                redirectToBusquedasActivas={this.redirectToBusquedasActivas}
                deleteDraft={this.deleteDraft}
                durationUnitQuant={this.durationUnitQuant}
            />
        );
    }
}

export default BusquedasActivas;
