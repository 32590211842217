import React from "react";
import { FormFeedback, Input, FormGroup } from "reactstrap";

import "./../../../../Styles/general.css";



//const formikWrapper = withFormik({
/*mapPropsToValues: props => ({
  idname: props.idname,
  dataid: props.dataid
}),*/
/* validationSchema: Yup.object().shape({*/
/*radiouna: Yup.string()
 .label("gender")
 .test("is-true", "debe seleccionar una opción", value =>
   value !== "1" && value !== "0" ? false : true
 ),
 radiodos: Yup.string()
 .label("gender")
 .test("is-true", "debe seleccionar una opción", value =>
   value !== "1" && value !== "0" ? false : true
 ),*/
/*   city: Yup.string().required("requerido"),
 city: Yup.string().required("requerido"),*/
/* }),
 enableReinitialize: true,
 validateOnChange: false,
 validateOnBlur: false,
// handleSubmit: (values, bag) => bag.props.handleSubmit(values)
});*/

const ModalPreguntasScreen = props => {
    const {
        handleChangeRadio,
        data,
        idname,
        handleSubmit,
        setFieldValue,
        handleChange,
        values,
        showModalInfo,
        modalTitle,
        closeModal,
        modalText,
        modalBtn,
        modalOpen,
        isModalOpen,
        dataid,
        error
    } = props;

    return (
        <>
            <div //className="button btn btn-primary"
                className="match_preview_btn" style={{ cursor: "pointer" }} onClick={e => modalOpen(e)}>
                {" "}
                CALIFICAR{" "}
            </div>
            <div className={isModalOpen ? "modal_show" : "modal_hide"}>
                <div className="modal_background"></div>
                <div className="modal_content">
                    {!showModalInfo ? (
                        <form method="POST" name={"oForm_" + idname} onSubmit={e => handleSubmit(e, values, props)} className="col-12">
                            <>
                                <div className="row">
                                    <div
                                        className="col-12"
                                        style={{ textAlign: "right", marginBottom: "5px" }}
                                    >
                                        <a
                                            className="close"
                                            onClick={e => closeModal(e)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            &times;
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {data &&
                                            Object.keys(data).map((keyName, i) => (
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        {data[keyName].question}
                                                    </div>
                                                    <div
                                                        className="col-md-4 col-12"
                                                        style={{ textAlign: "right", marginTop: "5px" }}
                                                    >
                                                        <FormGroup tag="fieldset">
                                                            <div
                                                                className="form-check form-check-inline"
                                                                style={{ margin: "0 0 0 0.75rem", float: "left" }}
                                                            >
                                                                <input
                                                                    id={"radiouna_" + props.idname}
                                                                    name={"valueQuestion_" + data[keyName].id + "_" + props.idname}
                                                                    className="form-check-input radio-inline radio align-items-center "
                                                                    type="radio"
                                                                    value="1"
                                                                    onChange={e =>
                                                                        handleChangeRadio(
                                                                            e,
                                                                            document.getElementById(
                                                                                "descriptionQuestion_" +
                                                                                data[keyName].id + "_" + props.idname
                                                                            ),
                                                                            setFieldValue, dataid, props.idname
                                                                        )
                                                                    }
                                                                />
                                                                <label className="form-check-label">Si</label>
                                                            </div>
                                                            <div
                                                                className="form-check form-check-inline"
                                                                style={{ margin: "0 0 0 0.75rem", float: "left" }}
                                                            >
                                                                <input
                                                                    id={"radiodos_" + props.idname}
                                                                    name={"valueQuestion_" + data[keyName].id + "_" + props.idname}
                                                                    className="form-check-input radio-inline radio align-items-center"
                                                                    type="radio"
                                                                    value="0"
                                                                    onChange={e =>
                                                                        handleChangeRadio(
                                                                            e,
                                                                            document.getElementById(
                                                                                "descriptionQuestion_" +
                                                                                data[keyName].id + "_" + props.idname
                                                                            ),
                                                                            setFieldValue, dataid, props.idname
                                                                        )
                                                                    }
                                                                />
                                                                <label className="form-check-label">No</label>
                                                            </div>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="col-12">
                                                        <FormGroup>
                                                            <textarea
                                                                id={"descriptionQuestion_" + data[keyName].id + "_" + props.idname}
                                                                name={"descriptionQuestion_" + data[keyName].id + "_" + props.idname}
                                                                className="form-control"
                                                                style={{ display: "none", resize: "none" }}
                                                                type="textarea"
                                                                rows="3"
                                                                placeholder="Descripción"
                                                                onChange={handleChange}
                                                            />
                                                        </FormGroup>
                                                    </div>

                                                    <input type="hidden" name="idname" value={props.idname} />
                                                    <input type="hidden" name="dataid" value={props.dataid} />
                                                </div>
                                            ))}
                                    </div>
                                    {
                                        <div className="frm_error_c" style={{ margin: "1rem" }}>
                                            {error}
                                        </div>
                                    }
                                    <div className="col-12">
                                        <button
                                            className="btn btn-primary btn-block"
                                            //type="submit"
                                            style={{ width: "100%" }}

                                        >
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                            </>
                        </form>
                    ) : (
                        <>
                            {" "}
                            <div className="col-12">
                                <div className="row" style={{ justifyContent: "center" }}>
                                    <div className="modal_title">{modalTitle}</div>
                                    <div className="modal_text">{modalText}</div>
                                    <div style={{ width: "100%" }}>
                                        <button className="modal_btn" onClick={() => closeModal()}>
                                            {modalBtn}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

/*const EnhancedForm = formikWrapper(ModalPreguntasScreen);
export default EnhancedForm;*/
export default ModalPreguntasScreen;