import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import "./style.css";
import "./../../../style.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faMapMarkerAlt,
    faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";


function DifferenceInDays(firstDate) {
    var today = new Date();
    var todayDate = moment(today).format("YYYY-MM-DD");
    var endDate = moment(firstDate).format("YYYY-MM-DD");
    var difference = moment(endDate).diff(todayDate, "days");

    var day = difference > "1" || difference < "-1" ? "días" : "día";

    if (difference > "1" || difference < "-1") { day = "días" }
    else { day = "día" }

    if (Number(difference) > 0) {
        difference = "Finaliza en " + difference;
    }
    else if (Number(difference) == 0) {
        return "Finaliza hoy";
    }

    else {
        difference = "Finalizó hace " + Math.abs(difference);
    }

    return [difference + " " + day];
}


library.add(faMapMarkerAlt, faChevronRight);

class Paginator extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        //scroll.scrollToTop();
        window.scroll(0, 0);
        let data = [];
        let active = "";
        let arrowActiveForward = "";
        let arrowActiveBackward = "";

        this.props.optionSelected <= 1
            ? (arrowActiveForward = "page-item disabled")
            : (arrowActiveForward = "page-item");
        this.props.optionSelected >= this.props.cantPages
            ? (arrowActiveBackward = "page-item disabled")
            : (arrowActiveBackward = "page-item");

        for (let i = 1; i <= this.props.cantPages; i++) {
            if (this.props.cantPages > 1) {
                Number(this.props.optionSelected) === Number(i)
                    ? (active = "page-item active")
                    : (active = "page-item");

                data.push(
                    <li className={active}>
                        <Link
                            className="page-link"
                            onClick={() => this.props.handleChangePage(i)}
                        >
                            {i}
                        </Link>
                    </li>
                )
            }

        }
        return this.props.cantPages > 1 ? (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center" style={{
                    flexWrap: "wrap"
                }}>
                    {
                        < li className={arrowActiveForward} >
                            <a
                                className="page-link text-primary"
                                style={{ cursor: "pointer" }}

                                onClick={() =>
                                    this.props.handleChangePage(
                                        Number(this.props.optionSelected) - 1
                                    )
                                }
                                aria-label="Previous"
                            >
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Anterior</span>
                            </a>
                        </li>
                    }
                    {data}
                    {
                        <li className={arrowActiveBackward}>
                            <a
                                className={"page-link text-primary"}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    this.props.handleChangePage(
                                        Number(this.props.optionSelected) + 1
                                    )
                                }
                                aria-label="Next"
                            >
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Siguiente</span>
                            </a>
                        </li>
                    }
                </ul>
            </nav >
        ) : (
            ""
        )

    }

}

const BusquedasActivasScreen = ({
    data,
    cantPages,
    deleteDraft,
    handleChangePage,
    divShow,
    handleChangeOptions,
    redirectToBusquedasActivas,
    optionSelected,
    pageSelected
}) => (
    <div>
        <div className="row d-flex justify-content-center mr-0 ml-0" >
            <div className="decoration"></div>
            <div className="page_container">
                <div className="card" style={{ backgroundColor: "#FAFAFA", minHeight: "100vh" }}>
                    <article className="card-body general">
                        <h4 className="card-title text-center" style={{ justifyContent: "center" }}>
                            Tus búsquedas
                        </h4>
                        <div
                            className="col-12 col-sm-6 offset-sm-6 col-md-4 offset-md-8 selector"
                            style={{ marginBottom: "1rem", padding: "0" }}
                        >
                            <select
                                name="options"
                                className="form-control"
                                onChange={e => handleChangeOptions(e)}
                                type="select"
                                value={optionSelected}
                            >
                                {/* <option value="">Todos</option> */}
                                <option key={1} value="activas">
                                    Activas
                                </option>
                                <option key={2} value="trabajando">
                                    En Curso
                                </option>
                                <option key={3} value="finalizadas">
                                    Finalizadas
                                </option>
                                <option key={4} value="borradores">
                                    Borradores
                                </option>
                                <option key={5} value="cerradas">
                                    Canceladas
                                </option>
                            </select>
                        </div>
                        <div className={divShow === true ? "div_show" : "div_hide"}>
                            <strong>{"Aún no hay nada por aquí. "}</strong>
                            {
                                "Activa una nueva búsqueda y empezá a recibir ofertas de los Talentos."
                            }
                        </div>
                        <form>
                            {Object.keys(data).map((keyName, i) => (
                                <div className="card h-10 match_preview shadow-sm rounded-0" key={i} >
                                    <div className="card-body p-30 m-30">
                                        <div className="row">
                                            <div className="col">
                                                {/*this element should be iterated */}

                                                <div className="match_preview_top">
                                                    {optionSelected !== "borradores" ?
                                                        <div className="match_preview_date">
                                                            {
                                                                DifferenceInDays(data[keyName].offer_end_date)}
                                                        </div>
                                                        : ""}
                                                    {optionSelected !== "borradores" ?
                                                        <div className="match_preview_status">
                                                            {data[keyName].talentsHired +
                                                                "/" +
                                                                data[keyName].vacancies +
                                                                " " +
                                                                "vacantes"}{" "}
                                                        </div>
                                                        : <Link onClick={() => deleteDraft(data[keyName].id, pageSelected, optionSelected)}><div className="match_preview_status" style={{ textDecoration: "underline" }} >
                                                            Eliminar Borrador
                                                        </div>
                                                        </Link>}
                                                </div>
                                                {optionSelected !== "borradores" ?
                                                    <div >
                                                        <div
                                                            className="col-12"
                                                            style={{ paddingBottom: "5px", paddingLeft: "0", paddingRight: "0" }}
                                                        >

                                                            <div >

                                                                {optionSelected !== "cerradas" && optionSelected !== "trabajando" && optionSelected !== "finalizadas" ?
                                                                    <>
                                                                        <div className="col-12" style={{
                                                                            paddingLeft: "0",
                                                                            paddingRight: "0"
                                                                        }}>
                                                                            <div
                                                                                className="match_preview_status"
                                                                                style={{
                                                                                    textAlign: "left",
                                                                                    paddingBottom: "2px",
                                                                                    color: "#0D71B9",
                                                                                    fontSize: "15px"
                                                                                }}
                                                                            >
                                                                                {"Postulados: "}{" "}
                                                                                <strong>{data[keyName].talentApplied}</strong>{" "}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12" style={{
                                                                            paddingLeft: "0",
                                                                            paddingRight: "0"
                                                                        }} >
                                                                            <div
                                                                                className="match_preview_status"
                                                                                style={{
                                                                                    align: "left",
                                                                                    paddingBottom: "2px",
                                                                                    color: "#0D71B9",
                                                                                    fontSize: "15px"
                                                                                }}
                                                                            >
                                                                                {"Seleccionados: "}{" "}
                                                                                <strong>
                                                                                    {data[keyName].talentsSelected}
                                                                                </strong>{" "}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    : ""}

                                                                <div className="col-12" style={{
                                                                    paddingLeft: "0",
                                                                    paddingRight: "0"
                                                                }}>
                                                                    <div
                                                                        className="match_preview_status"
                                                                        style={{
                                                                            align: "left",
                                                                            paddingBottom: "2px",
                                                                            color: "#0D71B9",
                                                                            fontSize: "15px"
                                                                        }}
                                                                    >
                                                                        {"Contratados: "}{" "}
                                                                        <strong>{data[keyName].talentsHired}</strong>{" "}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    : ""}
                                                <div className="match_preview_info">
                                                    <div className="match_preview_job_name">
                                                        <span>{data[keyName].title}</span>
                                                    </div>

                                                    <div className="match_preview_description_title" >
                                                        Detalle:
                                                    </div>
                                                    <div className="match_preview_description special_text" style={{ maxHeight: "100px" }} dangerouslySetInnerHTML={{ __html: data[keyName].description === null || data[keyName].description === undefined || data[keyName].description === "" ? "Sin datos" : data[keyName].description }} />
                                                    <div className="match_preview_description_title">
                                                        <FontAwesomeIcon icon={faMapMarkerAlt} size="1x" />{" "}
                                                        Ubicación:
                                                    </div>
                                                    <div className="match_preview_location">
                                                        {data[keyName].province === null || data[keyName].province === undefined || data[keyName].province === "" ?
                                                            "Sin datos" : data[keyName].city + ", " + data[keyName].province}
                                                    </div>
                                                    {optionSelected !== "borradores" ?
                                                        <Link
                                                            className="match_preview_btn btn-blue"
                                                            // to={"/BusquedasDetalle/" + data[keyName].id + "/" + optionSelected} //, pageSelected,
                                                            to={{ pathname: "/BusquedasDetalle/" + data[keyName].id, query: "BusquedasActivas/" + pageSelected + "/" + optionSelected }}
                                                            replace
                                                        >
                                                            Ver más
                                                        </Link>
                                                        : <Link
                                                            className="match_preview_btn btn-blue"
                                                            onClick={() => redirectToBusquedasActivas(data[keyName])}
                                                        // to={"/BusquedasDetalle/" + data[keyName].id}
                                                        >
                                                            Ver borrador
                                                        </Link>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </form>
                        <div>
                            <Paginator
                                cantPages={cantPages}
                                handleChangePage={handleChangePage}
                                optionSelected={pageSelected}
                            />
                        </div>
                    </article>
                </div>
            </div >
        </div >
    </div >
)

//export default BusquedasActivasScreen;
export default withRouter(BusquedasActivasScreen)