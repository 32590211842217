import React from "react";
import PropTypes from "prop-types";
import "./hamburger.css";

const Hamburger = ({ onClick, scrolled, isActive }) => {
    const hamburgerClasses = `hamburger hamburger--spin ${!scrolled ? "not-scrolled" : ""
        } ${isActive ? "is-active" : ""}`;

    return (
        <button onClick={onClick} className={hamburgerClasses} style={{ outline: 0 }} type="button">
            <span className="hamburger-box">
                <span className="hamburger-inner" />
            </span>
        </button>
    );
};

Hamburger.defaultProps = {
    scrolled: false,
    isActive: false
};

Hamburger.propTypes = {
    onClick: PropTypes.func.isRequired,
    scrolled: PropTypes.bool.isRequired,
    isActive: PropTypes.bool
};

export default Hamburger;
