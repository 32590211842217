import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
//import "./style.css";

//import "./../../../style.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faMapMarkerAlt,
    faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "./../../../../Styles/general.css";
import Modal from "./../../../common/Modal";
import NoResultsImg from "../../../../images/clip-list-is-empty@3x.png";

class Paginator extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let data = [];
        let active = "";
        let arrowActiveForward = "";
        let arrowActiveBackward = "";
        //console.log("cantPages" + this.props.cantPages);
        this.props.optionSelected <= 1
            ? (arrowActiveForward = "page-item disabled")
            : (arrowActiveForward = "page-item");
        this.props.optionSelected >= this.props.cantPages
            ? (arrowActiveBackward = "page-item disabled")
            : (arrowActiveBackward = "page-item");

        for (let i = 1; i <= this.props.cantPages; i++) {
            if (Number(this.props.cantPages) > 1) {
                Number(this.props.optionSelected) === Number(i)
                    ? (active = "page-item active")
                    : (active = "page-item");

                data.push(
                    <li className={active} key={i}>
                        <Link
                            className="page-link"
                            onClick={() => this.props.handleChangePage(i)}
                        >
                            {i}
                        </Link>
                    </li>
                );
            }
        }
        return this.props.cantPages > 1 ? (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    {
                        <li className={arrowActiveForward}>
                            <a
                                className="page-link text-primary"
                                style={{ cursor: "pointer" }}
                                /// href="javascript:void();"
                                //  onClick={() => this.props.handleChangePage()}
                                onClick={() =>
                                    this.props.handleChangePage(
                                        Number(this.props.optionSelected) - 1
                                    )
                                }
                                aria-label="Previous"
                            >
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Anterior</span>
                            </a>
                        </li>
                    }
                    {data}
                    {
                        <li className={arrowActiveBackward}>
                            <a
                                className={"page-link text-primary"}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    this.props.handleChangePage(
                                        Number(this.props.optionSelected) + 1
                                    )
                                }
                                aria-label="Next"
                            >
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Siguiente</span>
                            </a>
                        </li>
                    }
                </ul>
            </nav>
        ) : (
            ""
        );
    }
}

const DivMessage = (
    <div className="d-flex flex-column justify-content-center">
        <div >
            <img src={NoResultsImg} className="image_matchs_no_result" />
        </div>
        <div className="title_matchs_no_results">Aún no hay nada por aquí.</div>
        <div >

            <div className="text_matchs_no_results">
                Pronto comenzarás a recibir
                oportunidades temporales.
                <br /> Recorda que cuanto mas completo este tu perfil, mejor posicionado vas
                a estar
            </div>
        </div>

    </div>

);

library.add(faMapMarkerAlt, faChevronRight);

const MatchsText = ({
    data,
    options,
    divShow,
    cantPages,
    handleChangePage,
    showModal,
    modalTitle,
    modalText,
    modalBtn,
    modalReset,
    path,
    redirect,
    handleChangeOptions,
    pageSelected,
    optionSelected,
    redirectToWithParams
}) => (
    <div className="h-100" >
        <div className="row h-100 mr-0 ml-0" >
            <div
                className="decoration h-100"
                style={{ height: window.innerHeight + "px" }}
            ></div>
            <div className="page_container h-100" style={{ backgroundColor: "#FAFAFA" }}>
                <div className="card h-100" style={{ minHeight: "100vh" }} >
                    <article className="card-body general h-100" style={{ backgroundColor: "#FAFAFA" }}>
                        {showModal ? (
                            <Modal
                                modal_title={modalTitle}
                                modal_text={modalText}
                                modal_btn={modalBtn}
                                modalReset={modalReset}
                                path={path}
                                redirect={redirect}
                            />
                        ) : (
                            ""
                        )}
                        <h4 className="card-title text-center " style={{ justifyContent: "center" }}>
                            Matchs</h4>
                        <div className="row">
                            <div
                                className="col-12 col-sm-6 offset-sm-6 col-md-4 offset-md-8"
                                style={{ marginBottom: "1rem" }}
                            >
                                <select
                                    name="options"
                                    className="form-control"
                                    onChange={e => handleChangeOptions(e)}
                                    type="select"
                                    value={optionSelected}
                                >
                                    <option value="">Todos</option>
                                    {Object.keys(options).map((keyName, i) => (
                                        <option key={i} value={options[keyName].id}>
                                            {options[keyName].state}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={divShow === true ? "div_show" : "div_hide"}>
                            {DivMessage}
                        </div>
                        <form>
                            {data &&
                                Object.keys(data).map((keyName, i) => (
                                    <div className="card h-10 border-light shadow-sm rounded-0 match_preview" key={i}>
                                        <div className="card-body p-30 m-30">
                                            <div className="match_preview_top">
                                                <div className="match_preview_date" style={{ fontSize: "0.8rem" }}>
                                                    Cierra el{" "}
                                                    {moment(data[keyName].offer_end_date).format(
                                                        "DD/MM/YYYY"
                                                    )}
                                                </div>

                                                <div className="match_preview_status" style={{ fontSize: "0.8rem" }}>
                                                    Estado:
                                                    {data[keyName].talent_offer_status === null ? (
                                                        <span className="activa"> Activa</span>
                                                    ) : data[keyName].talent_offer_status ===
                                                        "postulado" ? (
                                                        <span className="postulado"> Postulado</span>
                                                    ) : data[keyName].talent_offer_status ===
                                                        "seleccionado" ? (
                                                        <span className="seleccionado"> Seleccionado</span>
                                                    ) : data[keyName].talent_offer_status ===
                                                        "contratado" ? (
                                                        <span className="contratado"> Contratado</span>
                                                    ) : data[keyName].talent_offer_status ===
                                                        "rechazado" ? (
                                                        <span className="rechazado"> Rechazado</span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                            <div className="match_preview_info">
                                                <div className="company_name">
                                                    <strong>{data[keyName].fantasy_name}</strong> Busca:
                                                </div>

                                                <div className="match_preview_job_name">
                                                    {data[keyName].title}
                                                </div>

                                                <div className="match_preview_description_title">
                                                    Detalle:
                                                </div>
                                                <div className="match_preview_description htlm_text" dangerouslySetInnerHTML={{ __html: data[keyName].description }}>


                                                </div>

                                                <div className="match_preview_description_title">

                                                    Ubicación:
                                                </div>
                                                <div className="match_preview_location">
                                                    {data[keyName].city + ", " + data[keyName].province}
                                                </div>

                                                <Link
                                                    className="match_preview_btn btn-blue"
                                                    onClick={redirectToWithParams}
                                                    to={{
                                                        /*     "/MatchsDetalle/" + data[keyName].id*/
                                                        pathname:
                                                            "/MatchsDetalle/" +
                                                            data[keyName].id +
                                                            "/" +
                                                            pageSelected +
                                                            "/" +
                                                            optionSelected
                                                    }}
                                                >
                                                    Ver más
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </form>
                        <div>
                            <Paginator
                                cantPages={cantPages}
                                handleChangePage={handleChangePage}
                                optionSelected={pageSelected}
                            />
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>
);

//export default MatchsText;
export default withRouter(MatchsText);
