import React from "react";
//import "./script";
import "./../../../style.css";
//import $ from "jquery";

import { withRouter, Link } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faMapMarkerAlt,
  faUserFriends,
  faMoneyBillWave,
  faCalendarAlt,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AgregarHabilidadesScreen = () => (
  <div>
    <div className="row justify-content-center">
      <div className="decoration"></div>
      <div className="page_container">
        <div className="card">
          <article className="card-body">
            <h4 className="card-title text-center text-primary">
              <Link to={"/PerfilText"}>
                <FontAwesomeIcon
                  className="arrow_back"
                  icon={faArrowLeft}
                  size="1x"
                />
              </Link>
              Habilidades
            </h4>
            <h6 className="card-title text-center text-primary">
              Eilge hasta 5 subcategorías en total.
            </h6>
            <form>
              <div>
                <div className="row">
                  <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-primary">
                    <p className="text-left">
                      <span>Categoría</span>
                    </p>
                  </div>
                  <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-primary">
                    <p className="text-left">
                      <a href="" className="text-primary">
                        <span>Guardar</span>
                      </a>
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-primary">
                    <p>Nombre de Habilidad</p>
                  </div>
                  <hr />
                  <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="customRadio"
                        name="radioHabilidad"
                        value=""
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadio"
                      />
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </form>
          </article>
        </div>
      </div>
    </div>
  </div>
);
export default withRouter(AgregarHabilidadesScreen);
