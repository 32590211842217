//const api_key = "";

//PROD
export const url_base = "https://asignate.com.ar/admin/api";

//DEV
//export const url_base = "https://asignate.com.ar/development/api";

//LOCAL GUILLE
//export const url_base = "http://asignate-admin.local/api";

//const api_key = "cb71122a2b6bc662e5bb1f8529576f2e";

//const api_create = `${url_base}?email=${email}&password=${pass}`;

/*const authLogin = data_login => {
  const url_base = "http://api/v1/auth/login";
};

const authLogout = data_logoout => {
  const url_base = "http://api/v1/auth/logout";
};

const authFind = data_find => {
  const token = "";
  const url_base = "http://api/v1/auth/password/find/";
};

const authReset = data_reset => {
  const url_base = "http://api/v1/auth/reset";
};

const authSignup = data_signup => {
  const url_base = "http://api/v1/auth/create";
};

const authActivate = data_activate => {
  const token = "";
  const url_base = "http://api/v1/auth/signup/activate/";
};

const authUser = data_user => {
  const url_base = "http://api/v1/auth/user";
};

const addJob = data_job => {
  const url_base = "http://api/v1/job/add/job";
};

const addLanguage = data_language => {
  const url_base = "http://api/v1/person/add/langueage";
};

const addSkill = data_skill => {
  const url_base = "http://api/v1/person/add/skill";
};

const addStudy = data_study => {
  const url_base = "http://api/v1/person/add/study";
};

const addWork = data_work => {
  const url_base = "http://api/v1/person/add/work";
};

const deletelanguage = data_delete_language => {
  const url_base = "http://api/v1/person/delete/langueage";
};

const deleteSkill = data_delete_skill => {
  const url_base = "http://api/v1/person/delete/skill";
};

const deleteStudy = data_delete_study => {
  const url_base = "http://api/v1/person/delete/study";
};

const deleteWork = data_delete_work => {
  const url_base = "http://api/v1/person/delete/work";
};

const store = data_store => {
  const url_base = "http://api/v1/person/store";
};

const update = data_update => {
  const url_base = "http://api/v1/person/update";
};
*/
