import React, { Component } from "react";
import MatchDetalleText from "./MatchDetalleText";
import axios from "axios";
import { url_base } from "../../../../api_url";
import auth from "./../../../../auth";

class MatchDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            company: [],
            showModal: false,
            modalTitle: "",
            modalText: "",
            modalBtn: "",
            path: "",
            offerState: "",
            application: "",
            accountState: "",
            value: "",
            keyIdPage: ""
        };

        this.handleSubmitValue = this.handleSubmitValue.bind(this);
        this.handleSubmitReject = this.handleSubmitReject.bind(this);
        this.handleSubmitAcceptSelection = this.handleSubmitAcceptSelection.bind(
            this
        );
        this.handleSubmitRejectSelection = this.handleSubmitRejectSelection.bind(
            this
        );
        this.modalReset = this.modalReset.bind(this);
        this.pageRedirect = this.pageRedirect.bind(this);
    }
    pageRedirect() {
        this.props.history.goBack();
    }

    modalReset() {
        this.setState({ showModal: false, redirect: false });
    }

    handleSubmitValue = (event, value, id, props) => {
        event.preventDefault();

        const access_token_data = localStorage.getItem("token");
        axios
            .post(
                url_base + "/talents/apply-job/" + id,
                { ["paymentRequired"]: value },
                {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                }
            )
            .then(
                response => {
                    //   console.log(response.data);

                    let path = "/Matchs/" + this.state.keyIdPage;
                    // props.history.push(path);
                    this.setState({
                        showModal: true,
                        modalTitle: "",
                        modalText: "se ha postulado correctamente a la oferta",
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                },
                error => {
                    var message = error.response.data.message;
                    //      console.log(message);
                    let path = "/Matchs/" + this.state.keyIdPage;
                    this.setState({
                        showModal: true,
                        modalTitle: "HA OCURRIDO UN ERROR",
                        modalText: message,
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                }
            );
    };

    handleSubmitReject = (event, value, id) => {
        event.preventDefault();

        const access_token_data = localStorage.getItem("token");
        axios
            .post(
                url_base + "/talents/discard-job/" + id,
                { ["paymentRequired"]: value },
                {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                }
            )
            .then(
                response => {
                    //        console.log(response.data);

                    let path = "/Matchs/" + this.state.keyIdPage;
                    // props.history.push(path);
                    this.setState({
                        showModal: true,
                        modalTitle: "",
                        modalText: "Oferta descartada",
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                },
                error => {
                    var message = error.response.data.message;
                    //      console.log(message);
                    let path = "/Matchs/" + this.state.keyIdPage;
                    this.setState({
                        showModal: true,
                        modalTitle: "HA OCURRIDO UN ERROR",
                        modalText: message,
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                }
            );
    };

    handleSubmitAcceptSelection = (event, value, id) => {
        event.preventDefault();

        const access_token_data = localStorage.getItem("token");
        axios
            .post(
                url_base + "/talents/accept-selection/" + id,
                { ["paymentRequired"]: value },
                {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                }
            )
            .then(
                response => {
                    //       console.log(response.data);

                    let path = "/Matchs/" + this.state.keyIdPage;
                    // props.history.push(path);
                    this.setState({
                        showModal: true,
                        modalTitle: "",
                        modalText: "Felicitaciones!! Te contrataron",
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                },
                error => {
                    var message = error.response.data.message;
                    //     console.log(message);
                    let path = "/Matchs/" + this.state.keyIdPage;
                    this.setState({
                        showModal: true,
                        modalTitle: "HA OCURRIDO UN ERROR",
                        modalText: message,
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                }
            );
    };

    handleSubmitRejectSelection = (event, value, id) => {
        event.preventDefault();

        const access_token_data = localStorage.getItem("token");
        axios
            .post(
                url_base + "/talents/reject-selection/" + id,
                { ["paymentRequired"]: value },
                {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                }
            )
            .then(
                response => {
                    //   console.log(response.data);

                    let path = "/Matchs/" + this.state.keyIdPage;
                    // props.history.push(path);
                    this.setState({
                        showModal: true,
                        modalTitle: "",
                        modalText: "Oferta rechazada",
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                },
                error => {
                    var message = error.response.data.message;
                    //     console.log(message);
                    let path = "/Matchs/" + this.state.keyIdPage;
                    this.setState({
                        showModal: true,
                        modalTitle: "HA OCURRIDO UN ERROR",
                        modalText: message,
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                }
            );
    };

    componentDidMount() {
        if (
            auth.isAuthenticated() === true &&
            localStorage.getItem("userType") == 3
        ) {
            const key = this.props.location.pathname.split("/");
            const keyIdPage = key[3];

            //  console.log("data " + this.props.location.state.data);

            //const key = this.props.location.state.data;

            const access_token_data = localStorage.getItem("token");
            const accountState = localStorage.getItem("accountState");

            this.setState({ accountState: accountState, keyIdPage: keyIdPage });

            axios
                .get(url_base + "/talents/job-search/" + key[2], {
                    headers: { ["Authorization"]: "Bearer " + access_token_data }
                })

                .then(
                    response => {

                        const detail = response.data.data;
                        const companyDetail = response.data.data.company;
                        let offerState = "";
                        let application = "";
                        if (response.data.data.application !== null) {
                            offerState = response.data.data.application.state;
                            application = response.data.data.application;
                        } else {
                            offerState = "activa";
                        }
                        this.setState({
                            data: detail,
                            company: companyDetail,
                            offerState: offerState,
                            application: application,
                            value: detail.min_payment
                        });
                        // console.log(response.data.data.application);
                    },
                    error => {
                        var status = error.response;
                        // console.log(status);
                    }
                );
        } else {
            this.props.history.push("/Login");
        }
    }

    render() {
        return (
            <MatchDetalleText
                data={this.state.data}
                company={this.state.company}
                handleSubmitValue={this.handleSubmitValue}
                handleSubmitReject={this.handleSubmitReject}
                handleSubmitAcceptSelection={this.handleSubmitAcceptSelection}
                showModal={this.state.showModal}
                modalTitle={this.state.modalTitle}
                modalText={this.state.modalText}
                modalBtn={this.state.modalBtn}
                modalReset={this.modalReset}
                path={this.state.path}
                redirect={this.state.redirect}
                offerState={this.state.offerState}
                handleSubmitRejectSelection={this.handleSubmitRejectSelection}
                application={this.state.application}
                accountState={this.state.accountState}
                valueMinPayment={this.state.value}
                pageRedirect={this.pageRedirect}
                keyPage={this.state.keyIdPage}
            />
        );
    }
}

export default MatchDetalle;
