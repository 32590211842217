//assets
import React, { Component } from "react";

import LoginForm from "./LoginForm";
import auth from "../../auth";
import { url_base } from "./../../api_url";
import axios from "axios";
import { withRouter } from "react-router-dom";

class LoginScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            modalTitle: "",
            modalText: "",
            modalBtn: "",
            path: "",
            redirect: ""
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.modalReset = this.modalReset.bind(this);
    }
    modalReset() {
        this.setState({ showModal: false });
    }

    handleSubmit(values, props) {
        //console.log(localStorage.getItem("token"));
        //console.log("asdasdas");
        axios
            .post(url_base + "/login", values)
            .then(response => {
                //  console.log("response.ddddata: "+JSON.stringify(response.data));

                if (response.data.result) {
                    if (response.data.result === "1") {
                        const access_token = response.data.data.access_token;
                        const userType = response.data.data.user.userprofile_id;
                        const userstateId = response.data.data.user.userstate_id;
                        localStorage.removeItem("token");
                        localStorage.setItem("token", access_token);
                        localStorage.setItem("userType", userType);
                        localStorage.setItem("accountState", userstateId);
                        auth.login(access_token, userType);

                        if (
                            auth.isAuthenticated() === true &&
                            localStorage.getItem("userType") == 3
                        ) {
                            //preguntar si el prefil está completo
                            axios
                                .get(url_base + "/talents/profile", {
                                    headers: {
                                        ["Authorization"]: "Bearer " + access_token
                                    }
                                })
                                .then(
                                    response => {
                                        const data = response.data.data.talent;

                                        if (
                                            data.skills == "" &&
                                            data.experiences == "" &&
                                            data.languages == "" &&
                                            data.studies == ""
                                        ) {
                                            //   localStorage.setItem("accountState", 2);
                                            localStorage.setItem("incompleteProfile", 1);

                                            let path = "/PerfilText";
                                            this.setState({
                                                showModal: true,
                                                modalTitle: "Perfil incompleto",
                                                modalText:
                                                    "Recorda que cuanto mas completo este tu perfil, mejor posicionado vas a estar",
                                                modalBtn: "Aceptar",
                                                path: path,
                                                redirect: true
                                            });
                                        } else {
                                            localStorage.setItem("accountState", 1);
                                            this.props.history.push("/Matchs");
                                        }
                                    },
                                    error => {
                                        var status = error.response;
                                    }
                                );
                        } else if (
                            auth.isAuthenticated() === true &&
                            localStorage.getItem("userType") == 2
                        ) {
                            localStorage.setItem("accountState", 1);
                            this.props.history.push("/BusquedasActivas");
                        }
                    } else if (response.data.result === "2") {
                        // const access_token = response.data.data.access_token;
                        // const userType = response.data.data.user.userprofile_id;
                        localStorage.removeItem("token");
                        // localStorage.setItem("token", access_token);
                        // localStorage.setItem("userType", userType);
                        //
                        // auth.login(access_token, userType);
                        localStorage.setItem("accountState", 2);

                        this.setState({
                            showModal: true,
                            modalTitle: "LA CUENTA AUN NO SE ENCUENTRA ACTIVA",
                            modalText:
                                "Aún no activaste tu cuenta, te enviamos mail con el proceso de activación. Si tuviste problemas para hacerlo por favor envianos un correo a hola@asignate.com",
                            modalBtn: "Aceptar"
                        });
                    } else if (response.data.result === "3") {
                        //const userType = response.data.data.user.userprofile_id;

                        if (
                            response.data.data.user &&
                            response.data.data.user.userprofile_id === 3
                        ) {
                            const access_token = response.data.data.access_token;
                            const userType = response.data.data.user.userprofile_id;
                            const userstateId = response.data.data.user.userstate_id;
                            localStorage.removeItem("token");
                            localStorage.setItem("token", access_token);
                            localStorage.setItem("userType", userType);
                            localStorage.setItem("accountState", userstateId);
                            auth.login(access_token, userType);

                            //preguntar si el prefil está completo
                            axios
                                .get(url_base + "/talents/profile", {
                                    headers: {
                                        ["Authorization"]: "Bearer " + access_token
                                    }
                                })
                                .then(
                                    response => {
                                        const data = response.data.data.talent;

                                        if (
                                            data.skills == "" &&
                                            data.experiences == "" &&
                                            data.languages == "" &&
                                            data.studies == ""
                                        ) {
                                            //  localStorage.setItem("accountState", 2);
                                            localStorage.setItem("incompleteProfile", 1);

                                            const message = (
                                                <text>
                                                    Esta pendiente tu entrevista. Nos vamos a comunicar
                                                    para agendarla <br />
                                                    Existen datos incompletos en su perfil
                                                </text>
                                            );
                                            let path = "/PerfilText";
                                            this.setState({
                                                showModal: true,
                                                //  modalTitle: "PERFIL INCOMPLETO",
                                                /*   modalText: "Existen datos incompletos en su perfil",*/
                                                modalTitle:
                                                    "Cuenta pendiente de habilitacion y perfil incompleto",
                                                modalText: message,
                                                modalBtn: "CONTINUAR EDITANDO",
                                                path: path,
                                                redirect: true
                                            });
                                        } else {
                                            auth.login(access_token, userType);
                                            let path = "/Matchs/";
                                            localStorage.setItem("accountState", 3);
                                            this.setState({
                                                showModal: true,
                                                modalTitle: "Cuenta pendiente de habilitacion",
                                                modalText:
                                                    "Tu entrevista esta pendiente. Nos vamos a comunicar para agendarla",
                                                modalBtn: "CONTINUAR EDITANDO",
                                                path: path,
                                                redirect: true
                                            });
                                        }
                                    },
                                    error => {
                                        var status = error.response;
                                    }
                                );
                        } else {

                            localStorage.removeItem("token");
                            localStorage.setItem("accountState", 3);

                            this.setState({
                                showModal: true,
                                modalTitle: "Cuenta pendiente de habilitacion",
                                modalText:
                                    "Tu entrevista esta pendiente. Nos vamos a comunicar para agendarla",
                                modalBtn: "CONTINUAR EDITANDO"
                            });
                        }
                    } else if (response.data.result === "4") {

                        localStorage.removeItem("token");

                        localStorage.setItem("accountState", 4);

                        this.setState({
                            showModal: true,
                            modalTitle: "Cuenta Inhabilitada",
                            modalText: "Para mas informacion escribinos a hola@asignate.com",
                            modalBtn: "Aceptar"
                            // modalBtn: "Continuar editando"
                        });
                    }
                }
            })
            .catch(() => {
                this.setState({
                    showModal: true,
                    modalTitle: "ERROR",
                    modalText: "El usuario y/o la contraseña son incorrecto/s",
                    modalBtn: "Aceptar"
                });
            });
    }
    componentDidMount() {
        const access_token_data = localStorage.getItem("token");

        if (access_token_data) {
            axios
                .get(url_base + "/users/logged", {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                })
                .then(
                    response => {
                        //verifico si es empresa o talento para redirigirlo si está logueado.
                        const userProfile = response.data.data.user.userprofile_id;
                        //si es empresa lo redirijo al home de empresa
                        if (userProfile === 2) {
                            this.props.history.push("/BusquedasActivas");
                            //si es talento lo redirijo al home de talento
                        } else if (userProfile === 3) {
                            this.props.history.push("/Matchs");
                        }
                    },
                    error => { }
                );
        }
    }
    render() {
        return (
            <LoginForm
                handleSubmit={this.handleSubmit}
                showModal={this.state.showModal}
                modalTitle={this.state.modalTitle}
                modalText={this.state.modalText}
                modalBtn={this.state.modalBtn}
                modalReset={this.modalReset}
                redirect={this.state.redirect}
                path={this.state.path}
            />
        );
    }
}

export default withRouter(LoginScreen);
