import React, { Component } from "react";

import axios from "axios";
import { url_base } from "../../../../api_url";

import ModalPreguntasScreen from "./ModalPreguntasScreen";

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            //  name:false
            //   showResults:""
            showModalPreguntas: "",
            showModalInfo: false,
            open: "false",
            isModalOpen: false,
            error: ""
        };
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.modalReset = this.modalReset.bind(this);
        this.onModalBtnClick = this.onModalBtnClick.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
    }

    modalOpen(e) {
        e.preventDefault();
        this.setState({ isModalOpen: true });
    }

    modalReset() {
        this.setState({ showModalInfo: false });
    }

    closeModal(e, setFieldValue, values) {
        this.setState({ isModalOpen: false, showModalInfo: false, error: "" });
        // this.props.modalReset();
        // console.log("valores:" + JSON.stringify(values));
        /*  if (values) {
          if (
            values.valueQuestion_3 !== "" &&
            values.valueQuestion_3 !== undefined
          ) {
            values.valueQuestion_3 = "";
          }
    
          if (
            values.valueQuestion_4 != "" &&
            values.valueQuestion_4 !== undefined
          ) {
            values.valueQuestion_4 = "";
          }
        }*/
    }

    onModalBtnClick() {
        this.setState({ classMsg: false });
        this.props.modalReset();
        if (this.props.redirect) {
            this.props.history.push(this.props.path);
        }
    }

    handleSubmit(e, reloadPage) {
        e.preventDefault();
        const data = new FormData(e.target);
        let message = "";
        let valueQuestion_3 = "";
        let valueQuestion_4 = "";

        //  let dataid = data.get("dataid");
        let idname = data.get("idname");
        // console.log("idname: " + idname);
        valueQuestion_3 = data.get("valueQuestion_3_" + idname);
        valueQuestion_4 = data.get("valueQuestion_4_" + idname);
        let descriptionQuestion_3 = data.get("descriptionQuestion_3_" + idname);
        let descriptionQuestion_4 = data.get("descriptionQuestion_4_" + idname);

        if (
            valueQuestion_3 === null ||
            valueQuestion_3 === "" ||
            typeof valueQuestion_3 === "undefined" ||
            valueQuestion_4 === null ||
            valueQuestion_4 === "" ||
            typeof valueQuestion_4 === "undefined"
        ) {
            message = "hay campos vacíos en el formulario.";

            this.setState({ error: message });
        } else {
            let values = {
                valueQuestion_3: valueQuestion_3,
                valueQuestion_4: valueQuestion_4,
                descriptionQuestion_3: descriptionQuestion_3,
                descriptionQuestion_4: descriptionQuestion_4
            };

            /*   alert("valueQuestion_3:" + valueQuestion_3);
               alert("valueQuestion_4:" + valueQuestion_4);*/
            // console.log(JSON.stringify(values));
            const access_token_data = localStorage.getItem("token");
            axios
                .post(url_base + "/talents/qualify-company/" + idname, values, {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                })
                .then(
                    response => {
                        /*values.valueQuestion_3 = "";
                        values.valueQuestion_4 = "";*/

                        this.setState({
                            showModalInfo: true,
                            modalTitle: "Calificado Ok",
                            modalText: response.data.message,
                            modalBtn: "Aceptar",
                            error: ""
                        });
                        this.props.reloadPage();
                    },
                    error => {
                        var status = error.response;
                        //console.log(status);
                    }
                );
        }
        //   closeModal();
    }

    handleChangeRadio(e, n) {
        //console.log("idname", idname);
        //console.log("n " + n);

        let fieldValue = "";
        // let optionId = e.target.id;
        //  console.log(e.target.value);
        if (e.target.value == 0) {
            fieldValue = e.target.name;
            //    setFieldValue(fieldValue, 0);
            n.style.display = "block";
        } else {
            fieldValue = e.target.name;
            //    setFieldValue(fieldValue, 1);
            n.style.display = "none";
        }
    }

    componentDidMount() {
        const access_token_data = localStorage.getItem("token");

        axios
            .get(url_base + "/talents/qualification-questions", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    this.setState({
                        data: response.data.data,
                        error: ""
                    });
                },
                error => {
                    var status = error.response;

                    this.setState({
                        error: ""
                    });
                }
            );
    }
    render() {
        return (
            <ModalPreguntasScreen
                handleChangeRadio={this.handleChangeRadio}
                handleSubmit={this.handleSubmit}
                data={this.state.data}
                showModalPreguntas={this.state.Modal}
                idname={this.props.idname}
                closeModal={this.closeModal}
                modalTitle={this.state.modalTitle}
                modalText={this.state.modalText}
                modalBtn={this.state.modalBtn}
                showModalInfo={this.state.showModalInfo}
                modalReset={this.modalReset}
                onModalBtnClick={this.onModalBtnClick}
                modalOpen={this.modalOpen}
                isModalOpen={this.state.isModalOpen}
                error={this.state.error}
            />
        );
    }
}

export default Modal;
