import { css } from "styled-components";

const sizes = {
    xxxl: 1920,
    xxl: 1400,
    xl: 1200,
    lg: 992,
    md: 768,
    xsmd: 627,
    sm: 576,
    xs: 420,
};

const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
    return acc;
}, {});

export default media;
