//assets
import React, { Component } from "react";
import RegistroTalentoForm from "./RegistroTalentoForm";
import axios from "axios";
import { url_base } from "./../../../../api_url";
import { trackPromise } from "react-promise-tracker";

class RegistroTalentoScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            province: [],
            docType: [],
            cityDisabled: "disabled",
            cityState: [],
            birthDate: [],
            selected: new Date(),
            city_id: "",
            showModal: false,
            modalTitle: "",
            modalText: "",
            modalBtn: "",
            path: "",
            redirect: "",
            formSubmitting: false
        };
        this.ProvinceHandleChange = this.ProvinceHandleChange.bind(this);
        this.modalReset = this.modalReset.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    modalReset() {
        this.setState({ showModal: false });
    }

    handleSubmit(values, bag, props) {
        this.setState({ formSubmitting: true });
        /*console.log("values " + JSON.stringify(values));
        console.log("props: " + JSON.stringify(props));*/
        axios
            .post(url_base + "/talents/register", values)
            .then(response => {
                //   console.log(response);
                //    this.props.history.push("/Login");
                let path = "/Login";
                this.setState({
                    showModal: true,
                    modalTitle: "LA CUENTA AUN NO SE ENCUENTRA ACTIVA",
                    modalText:
                        "Te enviamos un mail para activar tu cuenta! Si no lo recibiste escribinos a hola@asignate.com",
                    modalBtn: "Aceptar",
                    path: path,
                    redirect: true,
                    formSubmitting: false
                });

            })
            /*    console.log(response.data.data.provinces);
              this.props.dispatch({ type: "INCREMENT", data: response.data.data.provinces });*/
            //  setSubmitting(false)

            .catch(error => {
                this.setState({
                    showModal: true,
                    modalTitle: "HA OCURRIDO UN ERROR",
                    modalText: error.response.data.message,
                    modalBtn: "Aceptar",
                    formSubmitting: false
                });
                //    props.setSubmitting(false);

                //     console.log(error.response);
            });
    }

    ProvinceHandleChange(e, setFieldValue) {
        setFieldValue("province", e.target.value);
        this.setState({
            //    provinceState: e.target.value,
            provinceID: e.target[e.target.selectedIndex].value
        });

        if (e.target[e.target.selectedIndex].value != "") {
            this.setState({
                cityDisabled: "",
                city_id: ""
            });
        } else {
            // setFieldValue("city_id", e.target.value);
            this.setState({
                cityDisabled: "disabled"
                //   city_id: ""
            });
        }
        let idProvince = e.target[e.target.selectedIndex].value;
        axios
            .get(url_base + "/province-cities/" + idProvince)
            .then(response => {
                //console.log(response.data.data.cities);
                this.setState({
                    cityState: response.data.data.cities
                });
            })
            .catch();
    }
    componentDidMount() {
        //cargo los tipo de documento

        axios
            .get(url_base + "/document-types")
            .then(response => {
                //    console.log(response.data.data.docTypes);
                this.setState({
                    docType: response.data.data.docTypes
                });
                /*    console.log(response.data.data.provinces);
                    this.props.dispatch({ type: "INCREMENT", data: response.data.data.provinces });*/
                //  console.log(JSON.stringify(response.data.data.docTypes));
            })
            .catch();

        trackPromise(
            //cargo las provincias
            axios
                .get(url_base + "/provinces")
                .then(response => {
                    //  console.log(response.data.data.provinces);
                    this.setState({
                        province: response.data.data.provinces
                    });
                    /*    console.log(response.data.data.provinces);
                      this.props.dispatch({ type: "INCREMENT", data: response.data.data.provinces });*/
                })
                .catch()
        );
    }

    render() {
        const handleSubmit = values => { };

        return (
            <RegistroTalentoForm
                handleSubmit={this.handleSubmit}
                province={this.state.province}
                docType={this.state.docType}
                cityDisabled={this.state.cityDisabled}
                city={this.state.cityState}
                ProvinceHandleChange={this.ProvinceHandleChange}
                birthDate={this.birthDate}
                showModal={this.state.showModal}
                modalTitle={this.state.modalTitle}
                modalText={this.state.modalText}
                modalBtn={this.state.modalBtn}
                modalReset={this.modalReset}
                redirect={this.state.redirect}
                path={this.state.path}
                formSubmitting={this.state.formSubmitting}
            // city_id={this.state.city_id}
            />
        );
    }
}

export default RegistroTalentoScreen;
