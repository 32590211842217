import React, { Component } from "react";
import ExperienciaText from "./ExperienciaText";

import auth from "../../../../auth";

class Experiencia extends Component {

  render() {

    if (!auth.isAuthenticated() === true || localStorage.getItem("userType") != 3) {

     this.props.history.push("/Login")
    }
    return (<ExperienciaText />);


  }
}
export default Experiencia;
