import React, { Component } from "react";
import ReactCrop, { makeAspectCrop } from "react-image-crop";
import { FlatButton, Dialog } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import "react-image-crop/dist/ReactCrop.css";
import { url_base } from "../../../../api_url";

import {
    base64StringtoFile,
    downloadBase64File,
    extractImageFileExtensionFromBase64,
    image64toCanvasRef
} from "./../../ReusableUtils";

class ImageCrop extends Component {
    state = {
        showImageCropper: false,
        selectedImageURL: "",
        crop: {
            unit: "px",
            width: 30,
            // height: 30,
            aspect: 1 / 1
            /* width: 30,
            aspect: 16 / 9*/
            // x: 0,
            // y: 0

            // aspect: 16 / 9,
        },
        selectedFile: null,
        croppedImage: "",
        fileURl: "",
        imagenTo: "",
        croppedImageUrl: ""
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result, selectedImageURL: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }

        this.setState({ showImageCropper: true });
    };

    onImageLoaded = image => {
        // this.setState({
        // crop: makeAspectCrop(
        //   {
        //     x: 0,
        //     y: 0,
        //     aspect: 1
        //     /*maxWidth: 10,
        //     maxHeight: 10*/
        //   },
        //   image.naturalWidth / image.naturalHeight
        // ),
        // image
        // });
        // return <div>{console.log(this.state.croppedImage)}</div>;
        this.imageRef = image;
    };

    onCropComplete = (crop, percentCrop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    // makeClientCrop(crop) {
    //   if (this.imageRef && crop.width && crop.height) {
    //     this.getCroppedImg(
    //       this.imageRef,
    //       crop,
    //       "newFile.jpeg"
    //     ).then(croppedImage => this.setState({ croppedImage }));
    //   }

    // }

    getCroppedImg(image, crop, fileName) {
        /* the parameters: - the image element - the new width - the new height - the x point we start taking pixels - the y point we start taking pixels - the ratio */
        // Set up canvas for thumbnail
        // console.log(imgObj);
        // let img = new Image();
        // img.src = this.state.selectedImageURL;
        // let tempCanvas = document.createElement('canvas');
        // let tnCanvas = tempCanvas;
        // tnCanvas.width = newWidth;
        // tnCanvas.height = newHeight;
        // tnCanvas.getContext('2d').drawImage(img, startX, startY, newWidth, newHeight);
        // return tnCanvas;

        // let img = new Image();
        // img.src = this.state.selectedImageURL;

        //console.log(this.state.selectedImageURL);
        /*
        const targetX = (srcImage.width * pixelCrop.x) / 100;
        const targetY = (srcImage.height * pixelCrop.y) / 100;
        const targetWidth = (srcImage.width * pixelCrop.width) / 100;
        const targetHeight = (srcImage.height * pixelCrop.height) / 100;
    */

        // const targetX = crop.x;
        // const targetY = crop.y;
        // const targetWidth = crop.width;
        // const targetHeight = crop.height;

        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        let ctx = canvas.getContext("2d");

        canvas
            .getContext("2d")
            .drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );

        ctx = "";

        //canvas.getContext("2d").drawImage(img, 0, 0, 100, 100, 0, 0, 100, 100);

        let imagenToPost = canvas.toDataURL();
        this.props.saveImage(imagenToPost, this.props.setFieldValue);
        this.setState({ imagenToPost: imagenToPost });

        // data.target.value = "";

        return new Promise(resolve => {
            canvas.toBlob(blob => {
                blob.name = fileName; // eslint-disable-line no-param-reassign
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);

                resolve(this.fileUrl);
            }, "image/jpeg");
        });

        canvas.toDataURL("image/jpeg");
    }

    handleCropClose = () => {
        this.setState({ showImageCropper: false });
        //   let { crop } = this.state;

        //   // console.log("selectedFile", selectedFile);
        //   //console.log("crop",crop);

        //   const croppedImg = this.getCroppedImg(
        //     this.refImageCrop,
        //     crop,
        //     "newFile.jpeg"
        //   );
        //   this.setState({ showImageCropper: false, croppedImage: croppedImg });

        //   console.log(this.state.showImageCropper);
    };

    handleOpen = () => {
        this.setState({ showImageCropper: true });
    };

    handleCancel = () => {
        this.setState({ showImageCropper: false });
    };

    showCropImageModal() {
        const actions = [
            <FlatButton label="Cerrar" primary={true} onClick={this.handleCancel} />,
            <FlatButton
                label="Recortar"
                primary={true}
                keyboardFocused={true}
                onClick={this.handleCropClose}
            />
        ];

        if (this.state.showImageCropper) {
            return (
                <div>
                    <Dialog
                        title="Recortar imagen"
                        actions={actions}
                        modal={true}
                        open={this.state.showImageCropper}
                        autoScrollBodyContent={true}
                    >
                        <div className="row">
                            <div className="col-md-8 col-12" style={{ marginBottom: "20px" }}>
                                {this.state.src && (
                                    <ReactCrop
                                        src={this.state.src}
                                        crop={this.state.crop}
                                        // ruleOfThirds
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                )}
                                {/* <ReactCrop
              src={this.state.selectedImageURL}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              maxHeight={60}
              maxWidth={60}
              minHeight={30}
              minWidth={30}
            /> */}
                            </div>
                            <div className="col-md-4 col-12" style={{ textAlign: "center" }}>
                                {!this.state.croppedImageUrl ? (
                                    <img
                                        alt="Crop"
                                        style={{ maxWidth: "100%" }}
                                        src={this.state.src}
                                    />
                                ) : (
                                    this.state.croppedImageUrl && (
                                        <img
                                            alt="Crop"
                                            style={{ maxWidth: "100%" }}
                                            src={this.state.croppedImageUrl}
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </Dialog>
                </div>
            );
        }
    }

    /*if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg').then(croppedImageUrl =>
        this.setState({ croppedImageUrl })
      );
    }*/

    render(handleSubmit, errors, touched, data) {
        // setFieldValue("imageLogo", this.state.imagenTo);
        return (
            <MuiThemeProvider>
                <div className="App">
                    <input
                        id="imageLogo"
                        name="imageLogo"
                        className="form-control transparent"
                        type="file"
                        /* onChange={e => {
                          console.log(e.target.name + " data " + data);
                          setFieldValue(e.target.name, data);
                        }}*/
                        onClick={e => (e.target.value != "" ? (e.target.value = "") : null)}
                        onChange={this.onSelectFile}
                    />
                    {this.showCropImageModal()}
                    <img
                        src={this.state.selectedImageURL}
                        style={{ display: "none" }}
                        ref={img => {
                            this.refImageCrop = img;
                        }}
                        alt=""
                    />
                </div>
            </MuiThemeProvider>
        );
    }
}

export default ImageCrop;
