import React, { Component } from "react";
import BusquedasDetalleScreen from "./BusquedasDetalleScreen";
import axios from "axios";
import { url_base } from "../../../../api_url";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class BusquedasDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            skills: [],
            hired: [],
            appliedTalents: [],
            subcategory: [],
            selectedTalents: [],
            showModal: false,
            modalTitle: "",
            modalText: "",
            modalBtn: "",
            path: "",
            keyPageNro: "",
            dataTalents: [],
            status: "",
            optionSelected: "",
            checkMonotributoChecked: false,
            optionSelectedBusquedasActivas: ""
        };
        this.handleSubmitValue = this.handleSubmitValue.bind(this);
        this.modalReset = this.modalReset.bind(this);
        this.showOfferClosingModal = this.showOfferClosingModal.bind(this);
        this.modalResetNoSaving = this.modalResetNoSaving.bind(this);
        this.modalCancel = this.modalCancel.bind(this);
        this.ModifyModalState = this.ModifyModalState.bind(this);
        this.pageRedirect = this.pageRedirect.bind(this);
        this.qualifications = this.qualifications.bind(this);
        this.reloadPage = this.reloadPage.bind(this);
        this.updateDescription = this.updateDescription.bind(this);
        this.setTalentsListStatus = this.setTalentsListStatus.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.deleteApplying = this.deleteApplying.bind(this);
        this.handleChangeOptions = this.handleChangeOptions.bind(this);
        this.selectTalent = this.selectTalent.bind(this);


    }
    selectTalent(event, idOffer, idTalent) {
        event.preventDefault();
        const access_token_data = localStorage.getItem("token");

        axios
            .patch(
                url_base +
                "/companies/job-searches/select-talent/" +
                idOffer +
                "/" +
                idTalent,
                "",
                {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                }
            )
            .then(
                response => {
                    const data = response.data;

                    let message = response.data.message;
                    let path = "/BusquedasActivas/";

                    this.setState({
                        showModal: true,
                        modalTitle: "",
                        modalText: message,
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                },
                error => {
                    var status = error.response;
                }
            );
    }
    handleChangeOptions(e, checkMonotributoChecked) {

        let option = e.target[e.target.selectedIndex].value;
        this.setState({ optionSelected: option })
        const access_token_data = localStorage.getItem("token");
        const key = this.props.location.pathname.split("/");
        let dataTalents = "";
        let order_param = "";
        let order_direction = "";

        if (option === "1") {
            order_param = "3";
            order_direction = "a";
        } else if (option === "2") {
            order_param = "3";
            order_direction = "d";
        } else if (option === "3") {
            order_param = "2";
            order_direction = "a";
        } else if (option === "4") {
            order_param = "2";
            order_direction = "d";
        } else if (option === "5") {
            order_param = "1";
            order_direction = "a";
        } else if (option === "6") {
            order_param = "1";
            order_direction = "d";
        }

        const parameters = {
            "order_param": String(order_param),
            "order_direction": String(order_direction),
            "filters": [
                {
                    "param": "monotributista",
                    "value": checkMonotributoChecked === true ? "1" : "0"
                }
            ]
        }

        axios
            .post(url_base + "/companies/job-searches/" + key[2], parameters, {
                headers: { ["Authorization"]: "Bearer " + access_token_data }

            })

            .then(
                response => {

                    if (response.data.result === "1") {

                        if (String(this.state.talentsListStatus) === "1") {
                            dataTalents = response.data.data.appliedTalents;
                        } else if (String(this.state.talentsListStatus) === "2") {
                            dataTalents = response.data.data.selectedTalents;
                        } else {
                            dataTalents = response.data.data.hiredTalents;
                        }

                        const skills = response.data.data.jobOffer.skills;
                        const jobOffer = response.data.data.jobOffer;

                        const appliedTalents = response.data.data.appliedTalents;
                        const hired = response.data.data.hiredTalents;
                        const selectedTalents = response.data.data.selectedTalents;
                        const subcategory = response.data.data.jobOffer.subcategory;

                        this.setState({
                            data: jobOffer,
                            skills: skills,
                            subcategory: subcategory,
                            appliedTalents: appliedTalents,
                            hired: hired,
                            selectedTalents: selectedTalents,
                            //    status: status,
                            talentsListStatus: this.state.talentsListStatus,
                            dataTalents: dataTalents
                        });
                    }
                },
                error => {
                    var status = error.response;
                }
            );
    }

    deleteApplying = (idSelected) => {
        const access_token_data = localStorage.getItem("token");
        const key = this.props.location.pathname.split("/");
        const id = key[2];

        let checkMonotributoChecked = "";
        let optionSelected = key[5];
        var talentsListStatus = key[3];


        if (key[4]) {
            checkMonotributoChecked = String(key[4]);
        }
        if (checkMonotributoChecked === "" || checkMonotributoChecked === undefined || checkMonotributoChecked === null) {
            checkMonotributoChecked = "false";
        }
        if (key[3]) {
            talentsListStatus = key[3];
        }

        if (talentsListStatus === "" || talentsListStatus === undefined || talentsListStatus === null) {
            talentsListStatus = 1;
        }

        if (key[5]) {
            optionSelected = String(key[5]);
        }
        if (optionSelected === "" || optionSelected === undefined || optionSelected === null) {
            optionSelected = "1";
        }

        axios
            .delete(url_base + "/companies/job-application/" + idSelected, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {
                //   this.state.dataTalents.filter(i => i.id !== id);
                this.setState({ checkMonotributoChecked: checkMonotributoChecked === "true" ? true : false, talentsListStatus: talentsListStatus })
                this.filterData(id, optionSelected, checkMonotributoChecked, talentsListStatus)
            }
                ,
                error => {
                    console.log(error.response);

                });
    }

    reloadPage() {


        const key = this.props.location.pathname.split("/");

        const id = key[2];
        let checkMonotributoChecked = "";
        let optionSelected = key[5];
        var talentsListStatus = key[3];


        if (key[4]) {
            checkMonotributoChecked = String(key[4]);
        }
        if (checkMonotributoChecked === "" || checkMonotributoChecked === undefined || checkMonotributoChecked === null) {
            checkMonotributoChecked = "false";
        }
        if (key[3]) {
            talentsListStatus = key[3];
        }

        if (talentsListStatus === "" || talentsListStatus === undefined || talentsListStatus === null) {
            talentsListStatus = 1;
        }

        if (key[5]) {
            optionSelected = String(key[5]);
        }
        if (optionSelected === "" || optionSelected === undefined || optionSelected === null) {
            optionSelected = "1";
        }

        this.setState({ checkMonotributoChecked: checkMonotributoChecked === "true" ? true : false, talentsListStatus: talentsListStatus })

        this.filterData(id, optionSelected, checkMonotributoChecked, talentsListStatus)

    }

    qualifications(qualifications) {
        let result = "";
        let value_1 = "";
        let value_2 = "";
        let noQualifications = "";

        if (qualifications[0] !== undefined && qualifications[1] !== undefined) {
            let qualificationsResult =
                Number(qualifications[0].value) + Number(qualifications[1].value);

            if (Number(qualificationsResult) == 2) {
                value_1 = faThumbsUp;
                value_2 = faThumbsUp;
            } else if (Number(qualificationsResult) == 1) {
                value_1 = faThumbsUp;
                value_2 = faThumbsDown;
            } else if (Number(qualificationsResult) == 0) {
                value_1 = faThumbsDown;
                value_2 = faThumbsDown;
            }
        }
        else {
            noQualifications = 1;
        }
        result = (
            <>
                {Number(noQualifications) !== Number(1) ?
                    <>
                        {"Calificación: "}
                        <FontAwesomeIcon
                            icon={value_1}
                            className="text-primary"
                            size="1x"
                            style={{ paddingLeft: "3px" }}
                        />
                        <FontAwesomeIcon
                            icon={value_2}
                            className="text-primary"
                            size="1x"
                            style={{ paddingLeft: "3px" }}
                        />
                    </> : <> {""} </>}
            </>
        );

        return result;
    }

    pageRedirect() {

        this.props.history.push("/BusquedasActivas/");
    }
    modalReset() {
        this.setState({ showModal: false, redirect: false });
    }

    modalResetNoSaving() {
        this.setState({ showModalNoSaving: false, redirect: false });
    }
    modalCancel() {
        this.setState({ showModalNoSaving: false });
    }
    ModifyModalState(
        showModal,
        modalTitle,
        modalText,
        modalBtn,
        path,
        redirect,
        message
    ) {
        this.setState({
            showModal: true,
            modalTitle: "",
            modalText: message,
            modalBtn: "Aceptar",
            path: path,
            redirect: true
        });
    }

    showOfferClosingModal(event, id) {
        let message = "¿Estás seguro que queres cerrar la oferta?";
        let path = "/BusquedasActivas/";

        this.setState({
            showModalNoSaving: true,
            modalTitleNoSaving: "ATENCIÓN",
            modalTextNoSaving: message,
            modalBtnNoSaving: "Aceptar",
            pathNoSaving: path,
            //   modalCancel: modalCancel,
            redirectNoSaving: true,
            e: event,
            id: id
        });
    }

    handleSubmitValue = (event, id) => {
        event.preventDefault();

        const access_token_data = localStorage.getItem("token");
        axios
            .patch(url_base + "/companies/job-searches/close/" + id, "", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    let message = response.data.message;
                    let path = "/BusquedasActivas";

                    this.setState({
                        showModal: true,
                        modalTitle: "",
                        modalText: message,
                        modalBtn: "Aceptar",
                        path: path,
                        redirect: true
                    });
                },
                error => {
                    var status = error.response;
                }
            );
    };
    setTalentsListStatus(status, check, optionSelected) {
        this.setState({ talentsListStatus: status });
        const key = this.props.location.pathname.split("/");
        const id = key[2];

        this.filterData(id, this.state.optionSelected, check, status)

    }
    updateDescription() {
        const key = this.props.location.pathname.split("/");
        const keyPageNro = key[3];
        const access_token_data = localStorage.getItem("token");
        //    this.setState({ talentsListStatus: 1 })

        const parameters = {
            "order_param": "1",
            "order_direction": "a",
            "filters": [
                {
                    "param": "monotributista",
                    "value": "0"
                }
            ]
        }

        axios
            .post(url_base + "/companies/job-searches/" + key[2], parameters, {
                headers: { ["Authorization"]: "Bearer " + access_token_data }

            })

            .then(
                response => {

                    if (response.data.result === "1") {

                        const skills = response.data.data.jobOffer.skills;
                        const jobOffer = response.data.data.jobOffer;
                        const appliedTalents = response.data.data.appliedTalents;
                        const hired = response.data.data.hiredTalents;
                        const subcategory = response.data.data.jobOffer.subcategory;
                        const selectedTalents = response.data.data.selectedTalents;

                        this.setState({
                            data: jobOffer,
                            skills: skills,
                            subcategory: subcategory,
                            appliedTalents: appliedTalents,
                            hired: hired,
                            selectedTalents: selectedTalents,
                            keyPageNro: keyPageNro
                        });
                    }
                },
                error => {
                    var status = error.response;
                }
            );
    }

    handleCheckboxChange(event, status) {

        this.setState({ checkMonotributoChecked: event.target.checked })

        const key = this.props.location.pathname.split("/");
        this.setState({ talentsListStatus: status });
        const id = key[2];
        const access_token_data = localStorage.getItem("token");
        let dataTalents = [];
        let optionSelected = "";

        this.filterData(id, this.state.optionSelected, event.target.checked, this.state.talentsListStatus)
    }

    filterData(id, optionSelected, checkMonotributoChecked, talentsListStatus) {

        const access_token_data = localStorage.getItem("token");

        let dataTalents = [];
        let order_param = "";
        let order_direction = "";

        if (optionSelected === "1") {
            order_param = "3";
            order_direction = "a";
        } else if (optionSelected === "2") {
            order_param = "3";
            order_direction = "d";
        } else if (optionSelected === "3") {
            order_param = "2";
            order_direction = "a";
        } else if (optionSelected === "4") {
            order_param = "2";
            order_direction = "d";
        } else if (optionSelected === "5") {
            order_param = "1";
            order_direction = "a";
        } else if (optionSelected === "6") {
            order_param = "1";
            order_direction = "d";
        }

        const parameters = {
            "order_param": String(order_param),
            "order_direction": String(order_direction),
            "filters": [
                {
                    "param": "monotributista",
                    "value": String(checkMonotributoChecked) === "true" ? "1" : "0"
                }
            ]
        }

        axios
            .post(url_base + "/companies/job-searches/" + id, parameters, {
                headers: { ["Authorization"]: "Bearer " + access_token_data }

            })

            .then(
                response => {

                    if (response.data.result === "1") {

                        if (String(talentsListStatus) === "1") {
                            dataTalents = response.data.data.appliedTalents;
                        } else if (String(talentsListStatus) === "2") {
                            dataTalents = response.data.data.selectedTalents;
                        } else {
                            dataTalents = response.data.data.hiredTalents;
                        }

                        const skills = response.data.data.jobOffer.skills;
                        const jobOffer = response.data.data.jobOffer;

                        const appliedTalents = response.data.data.appliedTalents;
                        const hired = response.data.data.hiredTalents;
                        const selectedTalents = response.data.data.selectedTalents;
                        const subcategory = response.data.data.jobOffer.subcategory;

                        this.setState({
                            data: jobOffer,
                            skills: skills,
                            subcategory: subcategory,
                            appliedTalents: appliedTalents,
                            hired: hired,
                            selectedTalents: selectedTalents,
                            //   keyPageNro: keyPageNro,
                            dataTalents: dataTalents,
                            data: response.data.data.jobOffer,
                            optionSelected: optionSelected,
                            talentsListStatus: talentsListStatus
                        });

                    }

                },
                error => {
                    var status = error.response;
                }
            );
        this.props.history.push("/BusquedasDetalle/" + id + "/" + talentsListStatus + "/" + checkMonotributoChecked + "/" + optionSelected);
    }


    componentDidMount() {

        const key = this.props.location.pathname.split("/");
        const keyPrevPage = String(this.props.location.query).split("/");

        const datosStorage = JSON.parse(localStorage.getItem("BusquedasActivas"));

        if (keyPrevPage && keyPrevPage[0] === "BusquedasActivas") {

            this.setState({ keyPageNro: keyPrevPage[1], optionSelectedBusquedasActivas: keyPrevPage[2] })
            const BusquedasActivas = { keyPageNro: keyPrevPage[1], optionSelected: keyPrevPage[2] };
            localStorage.setItem("BusquedasActivas", JSON.stringify(BusquedasActivas));

        }

        const id = key[2];
        let checkMonotributoChecked = "";
        let optionSelected = key[5];
        var talentsListStatus = key[3];


        if (key[4]) {
            checkMonotributoChecked = String(key[4]);
        }
        if (checkMonotributoChecked === "" || checkMonotributoChecked === undefined || checkMonotributoChecked === null) {
            checkMonotributoChecked = "false";
        }
        if (key[3]) {
            talentsListStatus = key[3];
        }

        if (talentsListStatus === "" || talentsListStatus === undefined || talentsListStatus === null) {
            talentsListStatus = 1;
        }

        if (key[5]) {
            optionSelected = String(key[5]);
        }
        if (optionSelected === "" || optionSelected === undefined || optionSelected === null) {
            optionSelected = "3";
        }

        this.setState({ checkMonotributoChecked: checkMonotributoChecked === "true" ? true : false, talentsListStatus: talentsListStatus })

        this.filterData(id, optionSelected, checkMonotributoChecked, talentsListStatus)

    }

    render() {
        return (
            <BusquedasDetalleScreen
                data={this.state.data}
                skills={this.state.skills}
                hired={this.state.hired}
                subcategory={this.state.subcategory}
                appliedTalents={this.state.appliedTalents}
                selectedTalents={this.state.selectedTalents}
                showModal={this.state.showModal}
                modalTitle={this.state.modalTitle}
                modalText={this.state.modalText}
                modalBtn={this.state.modalBtn}
                path={this.state.path}
                handleSubmitValue={this.handleSubmitValue}
                modalReset={this.modalReset}
                redirect={this.state.redirect}
                showOfferClosingModal={this.showOfferClosingModal}
                redirectNoSaving={this.redirectNoSaving}
                showModalNoSaving={this.state.showModalNoSaving}
                modalTitleNoSaving={this.state.modalTitleNoSaving}
                modalTextNoSaving={this.state.modalTextNoSaving}
                modalBtnNoSaving={this.state.modalBtnNoSaving}
                pathNoSaving={this.state.pathNoSaving}
                modalResetNoSaving={this.modalResetNoSaving}
                modalCancel={this.modalCancel}
                modalResetNoSaving={this.modalResetNoSaving}
                ModifyModalState={this.ModifyModalState}
                pageRedirect={this.pageRedirect}
                keyPageNro={this.state.keyPageNro}
                qualifications={this.qualifications}
                reloadPage={this.reloadPage}
                updateDescription={this.updateDescription}
                setTalentsListStatus={this.setTalentsListStatus}
                dataTalents={this.state.dataTalents}
                checkMonotributoChecked={this.state.checkMonotributoChecked}
                handleCheckboxChange={this.handleCheckboxChange}
                talentsListStatus={this.state.talentsListStatus}
                status={this.state.status}
                deleteApplying={this.deleteApplying}
                handleChangeOptions={this.handleChangeOptions}
                optionSelected={this.state.optionSelected}
                selectTalent={this.selectTalent}
                optionSelectedBusquedasActivas={this.state.optionSelectedBusquedasActivas}

            />
        );
    }
}

export default BusquedasDetalle;
