import React, { Component } from "react";
import EducacionText from "./EducacionText";
import axios from "axios";
import { url_base } from "./../../../../api_url";
import moment from "moment";
import { date } from "yup";

class Educacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateStartSuperior: "",
            phone: "",
            address_number: "",
            address_street: "",
            city: "",
            address_apartment: "",
            address_floor: "",
            studies: [],
            experiences: [],
            skills: [],
            primary: [],
            highSchool: [],
            university: [],
            languages: [],
            loadedLanguages: [],
            numRows: "",
            displayData: [],
            showdata: [],
            disableHighschool: false,
            disablePrimary: false,
            disableUniversity: false,
            fieldsObject: [],
            errorTitle_1: "",
            errorInstitution_1: "",
            errorStartDate_1: "",
            errorEndDate_1: "",

            errorTitle_2: "",
            errorInstitution_2: "",
            errorStartDate_2: "",
            errorEndDate_2: "",

            errorTitle_3: "",
            errorInstitution_3: "",
            errorStartDate_3: "",
            errorEndDate_3: "",

            validateFieldsPrimaria: false,
            validateFieldsSecundaria: false,
            validateFieldsSuperior: false
            //this.displayData
        };
        //  this.rOnly = this.rOnly.bind(this);
        this.languagesHandleChange = this.languagesHandleChange.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.conditionalValidation = this.conditionalValidation.bind(this);
        // this.addIdioma = this.addIdioma.bind(this);
    }

    conditionalValidation(value_1, value_2, value_3, setFieldValue) {
        let validationShape = {};

        let ariaValue_1 = value_1.getAttribute("aria-expanded");
        let ariaValue_2 = value_2.getAttribute("aria-expanded");
        let ariaValue_3 = value_3.getAttribute("aria-expanded");

        let ariaNameValue_1 = value_1.id;
        let ariaNameValue_2 = value_2.id;
        let ariaNameValue_3 = value_3.id;

        // let ariaValue = value.getAttribute("aria-expanded");
        //  let ariaName = value.id;
        var validateFieldsPrimaria = "";
        var validateFieldsSecundaria = "";
        var validateFieldsSuperior = "";

        if (
            this.state.disablePrimary === false &&
            ariaNameValue_1 == "primary" &&
            ariaValue_1 == "true"
        ) {
            // this.setState({ validateFieldsPrimaria: false });
            //console.log("entra a primary TRUE");
            validateFieldsPrimaria = true;
            setFieldValue("validateFieldsPrimaria", true);
        } else {
            //console.log("entra a primary FALSE");
            //  this.setState({ validateFieldsPrimaria: true });
            validateFieldsPrimaria = false;
            setFieldValue("validateFieldsPrimaria", false);
        }

        if (
            this.state.disableHighschool === false &&
            ariaNameValue_2 == "highSchool" &&
            ariaValue_2 == "true"
        ) {
            validateFieldsSecundaria = true;
            setFieldValue("validateFieldsSecundaria", true);
            //  this.setState({ validateFieldsSecundaria: false });
        } else {
            //  this.setState({ validateFieldsSecundaria: true });
            validateFieldsSecundaria = false;
            setFieldValue("validateFieldsSecundaria", false);
        }

        if (
            this.state.disableUniversity === false &&
            ariaNameValue_3 === "superior" &&
            ariaValue_3 === "true"
        ) {
            //  this.setState({ validateFieldsSuperior: false });
            validateFieldsSuperior = true;
            setFieldValue("validateFieldsSuperior", true);
        } else {
            validateFieldsSuperior = false;
            setFieldValue("validateFieldsSuperior", false);
            //  this.setState({ validateFieldsSuperior: true });
        }
    }

    handleSubmitForm(values) {
        let error = false;

        if (values.validateFieldsPrimaria === true) {
            if (values.title_1 === "" || values.title_1 === undefined) {
                this.setState({ errorTitle_1: "requerido" });
                error = true;
            } else {
                this.setState({ errorTitle_1: " " });
            }

            if (values.institution_1 === "" || values.institution_1 === undefined) {
                this.setState({ errorInstitution_1: "requerido" });
                error = true;
            } else {
                this.setState({ errorInstitution_1: " " });
            }

            //fecha finalización

            if (
                values.finishDate_1 === "" ||
                values.finishDate_1 === undefined ||
                values.finishDate_1 === null
            ) {
                this.setState({ errorFinishDate_1: "requerido" });
                error = true;
            } else {
                if (moment(values.finishDate_1, "DD/YYYY").isValid()) {
                    if (
                        Number(
                            moment(values.finishDate_1).diff(
                                moment(values.startDate_1, "days")
                            )
                        ) <= Number(0)
                    ) {
                        this.setState({
                            errorFinishDate_1:
                                "la fecha de finalización debe ser mayor a la de inicio"
                        });
                        error = true;
                    } else {
                        this.setState({ errorFinishDate_1: "", error: false });
                    }
                } else {
                    this.setState({
                        errorFinishDate_1: "el campo es inválido"
                    });
                    error = true;
                }
            }

            //Fecha de inicio
            if (
                values.startDate_1 === "" ||
                values.startDate_1 === undefined ||
                values.startDate_1 === null
            ) {
                this.setState({ errorStartDate_1: "requerido" });
                error = true;
            } else {
                if (moment(values.startDate_1, "DD/YYYY").isValid()) {
                    if (
                        Number(
                            moment(values.finishDate_1).diff(
                                moment(values.startDate_1, "days")
                            )
                        ) <= Number(0)
                    ) {
                        this.setState({
                            errorStartDate_1:
                                "la fecha de inicio debe ser menor a la de finalización"
                        });
                        error = true;
                    } else {
                        this.setState({ errorStartDate_1: "", error: false });
                    }
                } else {
                    this.setState({
                        errorStartDate_1: "el campo es inválido"
                    });
                    error = true;
                }
            }
        }

        // Validación Secundario

        if (values.validateFieldsSecundaria === true) {
            if (values.title_2 === "" || values.title_2 === undefined) {
                this.setState({ errorTitle_2: "requerido" });
                error = true;
            } else {
                this.setState({ errorTitle_2: " " });
                //   error = false;
            }

            if (values.institution_2 === "" || values.institution_2 === undefined) {
                this.setState({ errorInstitution_2: "requerido" });
                error = true;
            } else {
                this.setState({ errorInstitution_2: " " });
                //  error = false;
            }

            //fecha finalización

            if (
                values.finishDate_2 === "" ||
                values.finishDate_2 === undefined ||
                values.finishDate_2 === null
            ) {
                this.setState({ errorFinishDate_2: "requerido" });
                error = true;
            } else {
                if (moment(values.finishDate_2, "DD/YYYY").isValid()) {
                    if (
                        Number(
                            moment(values.finishDate_2).diff(
                                moment(values.startDate_2, "days")
                            )
                        ) <= Number(0)
                    ) {
                        this.setState({
                            errorFinishDate_2:
                                "la fecha de finalización debe ser mayor a la de inicio"
                        });
                    } else {
                        this.setState({ errorFinishDate_2: "", error: false });
                    }
                } else {
                    this.setState({
                        errorFinishDate_2: "el campo es inválido"
                    });
                    error = true;
                }
            }

            //Fecha de inicio
            if (
                values.startDate_2 === "" ||
                values.startDate_2 === undefined ||
                values.startDate_2 === null
            ) {
                this.setState({ errorStartDate_2: "requerido" });
                error = true;
            } else {
                if (moment(values.startDate_2, "DD/YYYY").isValid()) {
                    if (
                        Number(
                            moment(values.finishDate_2).diff(
                                moment(values.startDate_2, "days")
                            )
                        ) <= Number(0)
                    ) {
                        this.setState({
                            errorStartDate_2:
                                "la fecha de inicio debe ser menor a la de finalización"
                        });
                        error = true;
                    } else {
                        this.setState({ errorStartDate_2: "", error: false });
                    }
                } else {
                    this.setState({
                        errorStartDate_2: "el campo es inválido"
                    });
                    error = true;
                }
            }
        }

        // Validación Terciario/Universidad

        if (values.validateFieldsSuperior === true) {
            if (values.title_3 === "" || values.title_3 === undefined) {
                this.setState({ errorTitle_3: "requerido" });
                error = true;
            } else {
                this.setState({ errorTitle_3: " " });
                //    error = false;
            }

            if (values.institution_3 === "" || values.institution_3 === undefined) {
                this.setState({ errorInstitution_3: "requerido" });
                error = true;
            } else {
                this.setState({ errorInstitution_3: " " });
                //    error = false;
            }

            //fecha finalización

            if (
                values.finishDate_3 === "" ||
                values.finishDate_3 === undefined ||
                values.finishDate_3 === null
            ) {
                this.setState({ errorFinishDate_3: "requerido" });
                error = true;
            } else {
                if (moment(values.finishDate_3, "DD/YYYY").isValid()) {
                    if (
                        Number(
                            moment(values.finishDate_3).diff(
                                moment(values.startDate_3, "days")
                            )
                        ) <= Number(0)
                    ) {
                        this.setState({
                            errorFinishDate_3:
                                "la fecha de finalización debe ser mayor a la de inicio"
                        });
                        error = true;
                    } else {
                        this.setState({ errorFinishDate_3: "", error: false });
                    }
                } else {
                    this.setState({
                        errorFinishDate_3: "el campo es inválido"
                    });
                    error = true;
                }
            }

            //Fecha de inicio
            if (
                values.startDate_3 === "" ||
                values.startDate_3 === undefined ||
                values.startDate_3 === null
            ) {
                this.setState({ errorStartDate_3: "requerido" });
                error = true;
            } else {
                if (moment(values.startDate_3, "DD/YYYY").isValid()) {
                    if (
                        Number(
                            moment(values.finishDate_3).diff(
                                moment(values.startDate_3, "days")
                            )
                        ) <= Number(0)
                    ) {
                        this.setState({
                            errorStartDate_3:
                                "la fecha de inicio debe ser menor a la de finalización"
                        });
                        error = true;
                    } else {
                        this.setState({ errorStartDate_3: "", error: false });
                    }
                } else {
                    this.setState({
                        errorStartDate_3: "el campo es inválido"
                    });
                    error = true;
                }
            }
        }

        if (error == false && error != null && error != undefined) {
            let formData = { studies: [], languages: [] };

            if (values.title_1 != "") {
                formData.studies.push({
                    typeId: 1,
                    title: values.title_1,
                    institution: values.institution_1,
                    startDate: values.startDate_1,
                    finishDate: values.finishDate_1,
                    state: values.state_1
                });
            }
            if (values.title_2 != "") {
                formData.studies.push({
                    typeId: 2,
                    title: values.title_2,
                    institution: values.institution_2,
                    startDate: values.startDate_2,
                    finishDate: values.finishDate_2,
                    state: values.state_2
                });
            }

            if (values.title_3 != "") {
                formData.studies.push({
                    typeId: 3,
                    title: values.title_3,
                    institution: values.institution_3,
                    startDate: values.startDate_3,
                    finishDate: values.finishDate_3,
                    state: values.state_3
                });
            }

            if (values.languages_1 > 0) {
                formData.languages.push({
                    language: values.languages_1,
                    levelLanguage: values.levelLanguage_1
                });
            }
            if (values.languages_2 > 0) {
                formData.languages.push({
                    language: values.languages_2,
                    levelLanguage: values.levelLanguage_2
                });
            }
            if (values.languages_3 > 0) {
                formData.languages.push({
                    language: values.languages_3,
                    levelLanguage: values.levelLanguage_3
                });
                if (values.languages_4 > 0) {
                    formData.languages.push({
                        language: values.languages_4,
                        levelLanguage: values.levelLanguage_4
                    });
                }
            }

            //console.log(JSON.stringify(formData));

            const access_token_data = localStorage.getItem("token");
            axios
                .post(url_base + "/talents/studies/add", formData, {
                    headers: {
                        ["Authorization"]: "Bearer " + access_token_data
                    }
                })
                .then(response => {
                    //  console.log(response);
                    let path = `/PerfilText/`;
                    this.props.history.push(path);
                })
                .catch();
        }
    }

    languagesHandleChange(e, setFieldValue) {
        setFieldValue("docType", e.target.value);
        this.setState({
            docType: e.target[e.target.selectedIndex].value
        });
    }

    componentDidMount() {
        const access_token_data = localStorage.getItem("token");

        axios
            .get(url_base + "/talents/profile", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = response.data.data.talent.studies;

                    Object.keys(data && data).map((keyName, i) => {
                        if (data[keyName].studiestype_id == "1") {
                            this.setState({ primary: data[keyName], disablePrimary: true });
                        } else if (data[keyName].studiestype_id == "2") {
                            this.setState({
                                highSchool: data[keyName],
                                disableHighschool: true
                            });
                        } else if (data[keyName].studiestype_id == "3") {
                            this.setState({
                                university: data[keyName],
                                disableUniversity: true
                            });
                        }
                        //  console.log("data" + data[keyName]);
                    });
                    const loadedLanguages = response.data.data.talent.languages;
                    const numRows = response.data.data.talent.languages.length;
                    // console.log("numRows: " + numRows);

                    this.setState({ loadedLanguages: loadedLanguages, numRows: numRows });

                    // console.log(this.state.loadedLanguages);
                },
                error => {
                    var status = error.response;
                    //  console.log(status);
                }
            );

        axios
            .get(url_base + "/languages", {
                headers: { ["Authorization"]: "Bearer " + access_token_data }
            })
            .then(
                response => {
                    const languages = response.data.data.languages;
                    this.setState({
                        languages: languages
                    });
                },
                error => {
                    var status = error.response;
                }
            );
    }

    render() {
        return (
            <EducacionText
                primary={this.state.primary}
                highSchool={this.state.highSchool}
                university={this.state.university}
                languages={this.state.languages}
                loadedLanguages={this.state.loadedLanguages}
                languagesHandleChange={this.languagesHandleChange}
                numRows={this.state.numRows}
                //      addIdioma={this.addIdioma}
                displayData={this.state.displayData}
                showdata={this.state.showdata}
                disableUniversity={this.state.disableUniversity}
                disableHighschool={this.state.disableHighschool}
                disablePrimary={this.state.disablePrimary}
                validationShapeVar={this.state.validationShapeVar}
                conditionalValidation={this.conditionalValidation}
                validateFieldsPrimaria={this.state.validateFieldsPrimaria}
                validateFieldsSecundaria={this.state.validateFieldsSecundaria}
                validateFieldsSuperior={this.state.validateFieldsSuperior}
                handleSubmitForm={this.handleSubmitForm}
                errorTitle_1={this.state.errorTitle_1}
                errorInstitution_1={this.state.errorInstitution_1}
                errorStartDate_1={this.state.errorStartDate_1}
                errorFinishDate_1={this.state.errorFinishDate_1}
                errorTitle_2={this.state.errorTitle_2}
                errorInstitution_2={this.state.errorInstitution_2}
                errorStartDate_2={this.state.errorStartDate_2}
                errorFinishDate_2={this.state.errorFinishDate_2}
                errorTitle_3={this.state.errorTitle_3}
                errorInstitution_3={this.state.errorInstitution_3}
                errorStartDate_3={this.state.errorStartDate_3}
                errorFinishDate_3={this.state.errorFinishDate_3}

            //      rOnly={this.state.rOnly}
            />
        );
    }
}
export default Educacion;
