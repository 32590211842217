import React from "react";

import "./../../../style.css";
import * as Yup from "yup";
import { withFormik, Form } from "formik";
import { FormFeedback, Input, FormGroup } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { withRouter, Link } from "react-router-dom";
import { imageCompanyPath } from "./../../../common/constants";
import ImageCrop from "./ImageCrop";
import Modal from "./../../../common/Modal";
import ModalNoSaving from "../../../common/ModalNoSaving";

import {
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const numerics = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const customInputForm = ({ field, form: { touched, errors }, ...props }) => (
    <div>
        <Input
            invalid={!!(touched[field.name] && errors[field.name])}
            {...field}
            {...props}
        />
        {touched[field.name] && errors[field.name] && (
            <FormFeedback>{errors[field.name]}</FormFeedback>
        )}
    </div>
);

const formikWrapper = withFormik({
    enableReinitialize: true,

    mapPropsToValues: props => ({
        imageCompany: props.imageCompany || "",
        responsableFirstName: props.responsableFirstName || "",
        responsableLastName: props.responsableLastName || "",
        responsableEmail: props.responsableEmail || "",
        cuit: props.cuit || "",
        fantasyName: props.fantasyName || "",
        legalName: props.legalName || "",
        phoneAreaCode: props.phoneAreaCode || "",
        phone: props.phone || "",
        provinceList: props.province || "",
        cityDisabled: props.cityDisabled || "",
        city: props.city || "",
        cityList: props.cityList || "",
        province: props.province || "",
        addressStreet: props.addressStreet || "",
        addressNumber: props.addressNumber || "",
        addressFloor: props.addressFloor || ""
    }),
    handleSubmit: (values, bag) => bag.props.handleSubmit(values),

    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
        fantasyName: Yup.string().required("requerido"),
        legalName: Yup.string().required("requerido"),
        responsableFirstName: Yup.string().required("requerido"),
        responsableLastName: Yup.string().required("requerido"),
        //   responsableLastName:Yup.string().required("requerido"),
        responsableEmail: Yup.string().required("requerido"),
        //   cuit:Yup.string().required("requerido"),
        phoneAreaCode: Yup.number()
            .integer()
            .required("requerido"),
        phone: Yup.number().required("requerido"),
        addressStreet: Yup.string().required("requerido"),
        addressNumber: Yup.string().required("requerido")
    })
});

const DatosEmpresaScreen = props => {
    const {
        setFieldValue,
        cuit,
        legalName,
        responsableEmail,
        provinceList,
        cityHandleChange,
        province,
        ProvinceHandleChange,
        cityList,
        city,
        handleChange,
        redirectNoSaving,
        values,
        errors,
        touched,
        saveImage,
        showModal,
        modalTitle,
        modalText,
        modalBtn,
        path,
        modalReset,
        redirect,
        modalTitleNoSaving,
        modalTextNoSaving,
        modalBtnNoSaving,
        showModalNoSaving,
        modalResetNoSaving,
        pathNoSaving,
        modalCancel,
        updateKey
    } = props;

    return (
        <div className="row justify-content-center mr-0 ml-0">
            <div className="decoration"></div>
            <div className="page_container">
                <div className="card" style={{ backgroundColor: "#FAFAFA" }}>
                    <article className="card-body general">
                        <h4 className="card-title text-center mt-2">
                            <Link onClick={() => redirectNoSaving(modalCancel)}>
                                <FontAwesomeIcon
                                    className="arrow_back"
                                    icon={faArrowLeft}
                                    size="1x"
                                />
                            </Link>
                            Modificar Perfil
                            <FontAwesomeIcon
                                className="arrow_back"
                                icon={faArrowLeft}
                                size="1x"
                                style={{ visibility: "hidden" }}
                            />
                        </h4>


                        {showModalNoSaving ? (
                            <ModalNoSaving
                                modal_title={modalTitleNoSaving}
                                modal_text={modalTextNoSaving}
                                modal_btn={modalBtnNoSaving}
                                modalResetNoSaving={modalResetNoSaving}
                                path={pathNoSaving}
                                modalCancel={modalCancel}
                                redirect={redirectNoSaving}
                            />
                        ) : (
                            ""
                        )}
                        {showModal ? (
                            <Modal
                                modal_title={modalTitle}
                                modal_text={modalText}
                                modal_btn={modalBtn}
                                modalReset={modalReset}
                                path={path}
                                redirect={redirect}
                            />
                        ) : (
                            ""
                        )}
                        <Form>
                            <div className="row empresa_profile">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 empresa_profile_pic">
                                    <div className="custom-file">
                                        <div className="form-group">
                                            <div>
                                                <div className="browse_c_noImage">
                                                    <img
                                                        className="profile_pic"
                                                        src={imageCompanyPath + "/" + values.imageCompany}
                                                        width="40%"
                                                    />
                                                    <ImageCrop
                                                        saveImage={saveImage}
                                                        setFieldValue={setFieldValue}
                                                        updateKey={updateKey}
                                                    />
                                                </div>

                                                {errors.file && touched.file ? (
                                                    <div>
                                                        <small> {errors.file} </small>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div
                                    className="tit_black"

                                >
                                    Datos de la empresa
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Nombre de fantasía</label>
                                        <input
                                            name="fantasyName"
                                            className="form-control"
                                            type={"fantasyName"}
                                            //component={customInputForm}
                                            onChange={handleChange}
                                            value={values.fantasyName}
                                            placeholder="Empresa*"
                                        />
                                        {errors.fantasyName && touched.fantasyName ? (
                                            <div className="frm_error_c">{errors.fantasyName}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <div className="form-group">
                                            <label className="label_black">Razón social</label>
                                            <div className="input-group">{legalName}</div>
                                        </div>
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="label_black">CUIT</label>
                                        <div className="input-group">{cuit}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div
                                    className="tit_black"

                                >
                                    Dirección
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Provincia</label>
                                        <select
                                            name="province"
                                            className="form-control"
                                            component={customInputForm}
                                            onChange={e => {
                                                ProvinceHandleChange(e, setFieldValue);
                                            }}
                                            value={province}
                                            // value={id}
                                            type="select"
                                        >
                                            {Object.keys(provinceList).map((keyName, i) => (
                                                <option key={i} value={provinceList[keyName].id}>
                                                    {provinceList[keyName].name}
                                                </option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup tag="fieldset">
                                        <label className="label_black">Localidad / Barrio</label>
                                        <select
                                            name="city"
                                            className="form-control"
                                            type="select"
                                            component={customInputForm}
                                            value={city}
                                            onChange={e => {
                                                cityHandleChange(e, setFieldValue);
                                            }}
                                        >
                                            {Object.keys(cityList).map((keyName, i) => (
                                                <option key={i} value={cityList[keyName].id}>
                                                    {cityList[keyName].name}
                                                </option>
                                            ))}
                                        </select>
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Calle</label>
                                        <input
                                            name="addressStreet"
                                            className="form-control"
                                            type={"addressStreet"}
                                            //component={customInputForm}
                                            onChange={handleChange}
                                            value={values.addressStreet}
                                            placeholder="Dirección"
                                        />
                                        {errors.addressStreet && touched.addressStreet ? (
                                            <div className="frm_error_c">{errors.addressStreet}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Altura</label>
                                        <input
                                            name="addressNumber"
                                            className="form-control"
                                            type={"addressNumber"}
                                            //component={customInputForm}
                                            onChange={handleChange}
                                            value={values.addressNumber}
                                            placeholder="Número"
                                        />
                                        {errors.addressNumber && touched.addressNumber ? (
                                            <div className="frm_error_c">{errors.addressNumber}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Piso / Depto</label>
                                        <input
                                            name="addressFloor"
                                            className="form-control"
                                            type={"text"}
                                            // component={customInputForm}
                                            onChange={handleChange}
                                            value={values.addressFloor}
                                            placeholder="Piso/Depto"
                                        />
                                        {errors.addressFloor && touched.addressFloor ? (
                                            <div className="frm_error_c">{errors.addressFloor}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            </div>

                            <div className="row">
                                <div
                                    className="tit_black"

                                >
                                    Datos personales
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Nombre</label>
                                        <input
                                            name="responsableFirstName"
                                            className="form-control"
                                            type={"responsableFirstName"}
                                            //component={customInputForm}
                                            onChange={handleChange}
                                            value={values.responsableFirstName}
                                            placeholder="Nombre responsable"
                                        />
                                        {errors.responsableFirstName &&
                                            touched.responsableFirstName ? (
                                            <div className="frm_error_c">
                                                {errors.responsableFirstName}
                                            </div>
                                        ) : null}
                                    </FormGroup>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                    <FormGroup>
                                        <label className="label_black">Apellido</label>
                                        <input
                                            name="responsableLastName"
                                            className="form-control"
                                            type={"responsableLastName"}
                                            //component={customInputForm}
                                            onChange={handleChange}
                                            value={values.responsableLastName}
                                            placeholder="Apellido responsable"
                                        />
                                        {errors.responsableLastName &&
                                            touched.responsableLastName ? (
                                            <div className="frm_error_c">
                                                {errors.responsableLastName}
                                            </div>
                                        ) : null}
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="row">
                                <div
                                    className="tit_black"

                                >
                                    Teléfono
                                </div>
                                <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                                    <FormGroup>
                                        <label className="label_black">Área</label>
                                        <input
                                            name="phoneAreaCode"
                                            className="form-control"
                                            type={"phoneAreaCode"}
                                            //component={customInputForm}
                                            onChange={handleChange}
                                            value={values.phoneAreaCode}
                                            placeholder="Cod. Área"
                                        />
                                        {errors.phoneAreaCode && touched.phoneAreaCode ? (
                                            <div className="frm_error_c">{errors.phoneAreaCode}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>

                                <div className="col-xs-8 col-sm-8 col-md-4 col-lg-4">
                                    <FormGroup>
                                        <label className="label_black">Número</label>
                                        <input
                                            name="phone"
                                            className="form-control"
                                            type={"phone"}
                                            onChange={handleChange}
                                            value={values.phone}
                                            placeholder="Tel"
                                        />
                                        {errors.phone && touched.phone ? (
                                            <div className="frm_error_c">{errors.phone}</div>
                                        ) : null}
                                    </FormGroup>
                                </div>

                                <div className="col-xs-8 col-sm-8 col-md-4 col-lg-4">
                                    <label className="label_black">Email</label>
                                    <div
                                        className="col-xs-12 col-sm-12 col-md-6 col-lg-6"
                                        style={{
                                            paddingLeft: "0",
                                            textAlign: "left",
                                            paddingBottom: "20px"
                                        }}
                                    >
                                        <div className="input-group">{responsableEmail}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <button
                                                type="submit"
                                                className="btn btn-blue btn-block"
                                                style={{ width: "100%" }}
                                            >
                                                {" "}
                                                Guardar{" "}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </article>
                </div>
            </div>
        </div>
    );
};
const EnhancedForm = formikWrapper(DatosEmpresaScreen);
//export default EnhancedForm;

export default withRouter(EnhancedForm);
