import React from "react";
import { Route, Redirect, Link } from "react-router-dom";
import auth from "../auth";
import Navbar from "./Navbar";
import NavbarEmpresa from "./NavbarEmpresa.js";
import logoImg from "../images/logo.png";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const options =
        [

            {
                name: "Perfil",
                link: "PerfilText"
            },
            {
                name: "Historial Matcheos",
                link: "HistorialMatcheos"
            },
            {
                name: "Mis Matcheos",
                link: "Matchs"
            },
            {
                name: "Logout",
                link: "Logout"
            }

        ];

    const optionsEmpresa =
        [

            {
                name: "Nueva Búsqueda",
                link: "NuevaBusqueda"
            },
            {
                name: "Perfil",
                link: "DatosEmpresa"
            },
            {
                name: "Búsquedas",
                link: "BusquedasActivas"
            },
            {
                name: "Logout",
                link: "Logout"
            }

        ]
    return (
        <Route
            {...rest}
            render={props => {
                if (auth.isAuthenticated() && localStorage.getItem("userType") == 3) {
                    //if  (1==1){
                    return (
                        <data>

                            <Navbar options={options} logoImg={logoImg} />
                            <Component {...props} />
                        </data>)


                } else if (auth.isAuthenticated() && localStorage.getItem("userType") == 2) {
                    return (

                        <data>

                            <NavbarEmpresa options={optionsEmpresa} logoImg={logoImg} />
                            <Component {...props} />
                        </data>)
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/Login",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                }
            }}
        />
    );
};
export default ProtectedRoute;
