import React, { Component } from "react";

import axios from "axios";
import { url_base } from "../../../../api_url";

import ModalPreguntasScreen from "./ModalPreguntasScreen";

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],

            showModal: "",
            open: "false",
            showModalInfo: false,
            isModalOpen: false,
            error: ""
        };
        this.handleChangeRadio = this.handleChangeRadio.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.modalReset = this.modalReset.bind(this);
        this.onModalBtnClick = this.onModalBtnClick.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
    }
    onModalBtnClick() {
        this.setState({ classMsg: false });
        this.props.modalReset();
        if (this.props.redirect) {
            this.props.history.push(this.props.path);
        }
    }

    modalOpen(e) {
        e.preventDefault();
        this.setState({ isModalOpen: true });
    }

    modalReset() {
        this.setState({ showModalInfo: false });
    }

    closeModal(e) {
        this.setState({ isModalOpen: false, showModalInfo: false, error: "" });
        /* const data = new FormData(e.target);
        //  console.log(values);
        let idname = data.get("idname");
        let valueQuestion_1 = data.get("valueQuestion_1_" + idname);
        let valueQuestion_2 = data.get("valueQuestion_2_" + idname);
        
    
        if (valueQuestion_1 ||valueQuestion_2) {
          if (
            valueQuestion_1 !== "" &&
            valueQuestion_1  !== undefined
          ) {
            data.set(valueQuestion_1, "") ;
          }
    
          if (
            valueQuestion_2 != "" &&
            valueQuestion_2 !== undefined
          ) {
            data.set(valueQuestion_2, "") ;
          }
        }*/
    }

    handleSubmit(e, oForm, props) {
        e.preventDefault();
        const data = new FormData(e.target);
        let message = "";

        let dataid = data.get("dataid");
        let idname = data.get("idname");
        let valueQuestion_1 = data.get("valueQuestion_1_" + idname);
        let valueQuestion_2 = data.get("valueQuestion_2_" + idname);
        let descriptionQuestion_1 = data.get("descriptionQuestion_1_" + idname);
        let descriptionQuestion_2 = data.get("descriptionQuestion_2_" + idname);

        if (
            valueQuestion_1 === null ||
            valueQuestion_1 === "" ||
            typeof valueQuestion_1 === "undefined" ||
            valueQuestion_2 === null ||
            valueQuestion_2 === "" ||
            typeof valueQuestion_2 === "undefined"
        ) {
            message = "hay campos vacíos en el formulario.";

            this.setState({ error: message });
        } else {
            let values = {
                valueQuestion_1: valueQuestion_1,
                valueQuestion_2: valueQuestion_2,
                descriptionQuestion_1: descriptionQuestion_1,
                descriptionQuestion_2: descriptionQuestion_2
            };

            const access_token_data = localStorage.getItem("token");

            axios
                .post(
                    url_base + "/companies/qualify-talent/" + dataid + "/" + idname,
                    values,
                    {
                        headers: {
                            ["Authorization"]: "Bearer " + access_token_data
                        }
                    }
                )
                .then(
                    response => {
                        /*  data.set("valueQuestion_1") = "";
                        data.set("valueQuestion_2") = "";*/
                        this.setState({
                            response: response.data.data.message,
                            showModalInfo: true,
                            modalTitle: "Calificación registrada",
                            modalText: response.data.message,
                            modalBtn: "Aceptar",
                            error: ""
                        });
                        this.props.reloadPage();

                    },
                    error => {
                        var status = error.response;
                        //console.log(status);
                    }
                );
            //   closeModal();
        }
    }
    handleChangeRadio(e, n, setFieldValue) {
        //console.log("idname", idname);
        //console.log("n " + n);

        let fieldValue = "";
        // let optionId = e.target.id;
        //  console.log(e.target.value);
        if (e.target.value == 0) {
            fieldValue = e.target.name;
            //   setFieldValue(fieldValue, 0);
            n.style.display = "block";
        } else {
            fieldValue = e.target.name;
            //   setFieldValue(fieldValue, 1);
            n.style.display = "none";
        }
    }

    componentDidMount() {
        const access_token_data = localStorage.getItem("token");

        //console.log(this.props.idname);

        axios
            .get(url_base + "/companies/qualification-questions", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    //    console.log(response.data.data);
                    this.setState({ data: response.data.data });
                },
                error => {
                    var status = error.response;
                    //    console.log(status);
                }
            );
    }
    render() {
        return (
            <ModalPreguntasScreen
                handleChangeRadio={this.handleChangeRadio}
                handleSubmit={this.handleSubmit}
                data={this.state.data}
                showModal={this.state.Modal}
                idname={this.props.idname}
                dataid={this.props.dataid}
                closeModal={this.closeModal}
                showModalInfo={this.state.showModalInfo}
                modalReset={this.modalReset}
                onModalBtnClick={this.onModalBtnClick}
                modalOpen={this.modalOpen}
                isModalOpen={this.state.isModalOpen}
                modalTitle={this.state.modalTitle}
                modalText={this.state.modalText}
                modalBtn={this.state.modalBtn}
                error={this.state.error}
            />
        );
    }
}

export default Modal;
