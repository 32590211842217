import React, { Component } from "react";
import PerfilText from "./PerfilText";
import axios from "axios";
import { url_base } from "../../../../api_url";
import auth from "../../../../auth";
import { date } from "yup";
import { trackPromise } from "react-promise-tracker";

class PerfilTextScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            userEmail: "",
            phone: "",
            address_number: "",
            address_street: "",
            city: "",
            //  address_apartment: "",
            address_floor: "",
            emailNotifications: false,
            appNotifications: false,
            studies: [],
            experiences: [],
            skills: [],
            languages: [],
            checked: "",
            dataKey: "",
            experiencesLenght: "",
            studiesLanguagesLength: ""
        };
        this.deleteSkill = this.deleteSkill.bind(this);
        this.deleteStudies = this.deleteStudies.bind(this);
        this.deleteLanguage = this.deleteLanguage.bind(this);
        this.deleteExperience = this.deleteExperience.bind(this);
        this.notification = this.notification.bind(this);
        this.saveImage = this.saveImage.bind(this);
        this.updateKey = this.updateKey.bind(this);
    }

    saveImage(imagenToPost, setFieldValue) {
        // this.setState({ imagenTo: imagenToPost });
        setFieldValue("photo", imagenToPost);
    }

    notification(e, values, appNotifications, emailNotifications, setFieldValue) {
        let data = {};

        if (appNotifications.checked) {
            data.appNotifications = 1;
        } else {
            data.appNotifications = 0;
        }

        this.setState({ appNotifications: data.appNotifications });

        if (emailNotifications.checked) {
            data.emailNotifications = 1;
        } else {
            data.emailNotifications = 0;
        }

        const access_token_data = localStorage.getItem("token");

        axios({
            method: 'patch',
            url: url_base + "/talents/profile/update-notifications/",
            data: data,
            headers: {
                "Access-Control-Allow-Origin": "true",
                'Authorization': "Bearer " + access_token_data
            }
        })


            .then(
                response => {
                    this.setState({
                        appNotifications: response.data.data.appNotifications,
                        emailNotifications: response.data.data.emailNotifications
                    });
                },
                error => {
                    let appNotificationsValue = "";
                    let emailNotificationsValue = "";

                    this.state.appNotifications == 1
                        ? (appNotificationsValue = 0)
                        : (appNotificationsValue = 1);

                    this.state.emailNotificationsValue == 1
                        ? (emailNotificationsValue = 0)
                        : (emailNotificationsValue = 1);

                    this.setState({
                        appNotifications: appNotificationsValue,
                        emailNotifications: emailNotificationsValue
                    });
                }
            );

    }

    updateKey(talentImage) {
        const dataKey = Math.random();

        this.setState({ dataKey: dataKey, talentImage: talentImage });
    }

    deleteStudies(id, study) {
        //  console.log(id);
        const access_token_data = localStorage.getItem("token");
        axios
            .post(url_base + "/talents/studies/delete/" + id, "", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = this.state.studies.filter(i => i.id !== study.id);
                    this.setState({
                        studies: data,
                        studiesLanguagesLength: this.state.studiesLanguagesLength - 1
                    });

                    //  const data = this.state.data.filter(i => i.id !== item.id);
                    //  const data = response.data.result;
                    //      console.log(data);
                },
                error => {
                    var status = error.response;
                    //      console.log(status);
                }
            );
    }

    deleteLanguage(id, language) {
        //console.log(id);
        // const data = this.state.languages.filter(i => i.id !== item.id);
        const access_token_data = localStorage.getItem("token");
        axios
            .post(url_base + "/talents/languages/delete/" + id, "", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = this.state.languages.filter(i => i.id !== language.id);
                    this.setState({
                        languages: data,
                        studiesLanguagesLength: this.state.studiesLanguagesLength - 1
                    });
                    //  const data = response.data.result;
                    //     console.log(data);
                },
                error => {
                    var status = error.response;
                    //     console.log(status);
                }
            );
    }

    deleteExperience(id, experience) {
        const access_token_data = localStorage.getItem("token");
        axios
            .post(url_base + "/talents/experiences/delete/" + id, "", {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = this.state.experiences.filter(
                        i => i.id !== experience.id
                    );
                    this.setState({
                        experiences: data,
                        experiencesLenght: this.state.experiencesLenght - 1
                    });
                    //     console.log(response.data);
                },
                error => {
                    var status = error.response;
                    //     console.log(status);
                }
            );
    }

    deleteSkill(id, skills) {
        //  console.log(id);
        const access_token_data = localStorage.getItem("token");
        axios
            .post(url_base + "/talents/skills/delete/" + id, "", {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = this.state.skills.filter(i => i.id !== skills.id);
                    this.setState({ skills: data });
                    //    const data = response.data.result;
                    //       console.log(data);
                },
                error => {
                    var status = error.response;
                    //       console.log(status);
                }
            );
    }

    componentDidMount() {
        if (
            auth.isAuthenticated() === true &&
            localStorage.getItem("userType") == 3
        ) {
            const access_token_data = localStorage.getItem("token");

            trackPromise(
                axios
                    .get(url_base + "/talents/profile", {
                        headers: {
                            ["Authorization"]: "Bearer " + access_token_data
                        }
                    })
                    .then(
                        response => {
                            const data = response.data.data.talent;
                            const studiesLanguagesLength = data.studies.length + data.languages.length;

                            this.setState({
                                talentImage: data.photo,
                                userEmail: data.userEmail,
                                firstName: data.first_name,
                                lastName: data.last_name,
                                phone: data.phone,
                                address_number: data.address_number,
                                address_street: data.address_street,
                                city: data.city,
                                //    address_apartment: data.address_apartment,
                                address_floor: data.address_floor,
                                studies: data.studies,
                                experiences: data.experiences,
                                skills: data.skills,
                                languages: data.languages,
                                emailNotifications: data.email_notifications,
                                appNotifications: data.app_notifications,
                                checked: data.app_notifications,
                                experiencesLenght: data.experiences.length,
                                studiesLanguagesLength: studiesLanguagesLength

                            });
                        },
                        error => {
                            var status = error.response;
                            //      console.log(status);
                        }
                    )
            );
        } else {
            this.props.history.push("/Login");
        }
    }

    render() {
        return (
            <PerfilText
                userEmail={this.state.userEmail}
                phone={this.state.phone}
                address_number={this.state.address_number}
                address_street={this.state.address_street}
                city={this.state.city}
                // address_apartment={this.state.address_apartment}
                address_floor={this.state.address_floor}
                studies={this.state.studies}
                experiences={this.state.experiences}
                languages={this.state.languages}
                skills={this.state.skills}
                deleteSkill={this.deleteSkill}
                deleteLanguage={this.deleteLanguage}
                deleteStudies={this.deleteStudies}
                deleteExperience={this.deleteExperience}
                notification={this.notification}
                emailNotifications={this.state.emailNotifications}
                appNotifications={this.state.appNotifications}
                talentImage={this.state.talentImage}
                checked={this.state.checked}
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                saveImage={this.saveImage}
                updateKey={this.updateKey}
                dataKey={this.state.dataKey}
                experiencesLenght={this.state.experiencesLenght}
                studiesLanguagesLength={this.state.studiesLanguagesLength}
            />
        );
    }
}
export default PerfilTextScreen;
