import React from "react";

//Dependencies
import { Link } from "react-router-dom";
import { withFormik, ErrorMessage, Form, Field, Formik, setErrors } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { FormFeedback, Input, FormGroup, Label, CustomInput } from "reactstrap";
import Modal from "./../../../common/Modal";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import "./styleQuill.css";


const DatePickerField = ({ name, value, onChange, placeholder }) => {
    return (
        <DatePicker
            name={name}
            selected={(value && new Date(value)) || null}
            // selected={value}
            //   selected={value ? moment(value, "DD-MM-YYYY") : moment()}
            autoComplete="off"
            className="form-control"
            placeholderText={placeholder}
            dateFormat="dd-MM-yyyy"
            showMonthDropdown
            showYearDropdown
            onChange={val => {
                onChange(name, val);
            }}
        />
    );
};

const data = localStorage;

const formikWrapper = withFormik({
    mapPropsToValues: (values, title) => (
        {

            title: data.title || "",
            description: data.description || "",
            jobEndDate: data.jobEndDate ? moment(data.jobEndDate) : "",
            jobStartDate: data.jobStartDate ? moment(data.jobStartDate) : "",
            /*  offerEndDate: data.offerEndDate ? moment(data.offerEndDate) : "",*/
            addressStreet: data.addressStreet || "",
            addressNumber: data.addressNumber || "",
            //addressFloor: data.addressFloor || "",
            durationUnit: data.durationUnit || "",
            unit: data.unit || "",
            maxPayment: data.maxPayment || "",
            minPayment: data.minPayment || "",
            skillsCategory: data.skillsCategory || "",
            skillsSubcategory: data.skillsSubcategory || "",
            skills: data.skills || "",
            checks: data.checks || "",
            durationUnitQuant: data.durationUnitQuant || "",
            city: data.city || "",
            // totalMaxPayment: data.totalMaxPayment || "",
            //  totalMinPayment: data.totalMinPayment || "",
            provinceList: data.province || "",
            province: data.province || "",
            vacancies: data.vacancies || "",
            totalHours: data.totalHours || "",
            cityList: data.cityList || "",
            minPaymentPercentage: data.minPaymentPercentage || ""
            //  minPaymentPercentage: "15"
        }),

    handleSubmit: (values, bag) => bag.props.handleSubmit(values),

    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
        title: Yup.string().required("requerido"),
        description: Yup.string().required("requerido"),
        /* maxPayment: Yup.number()
          .integer()
          .required("requerido"),*/
        minPayment: Yup.number()
            .integer()
            .required("requerido"),
        durationUnitQuant: Yup.number().required("requerido"),
        // province: Yup.string().required("requerido"),
        province: Yup.string().required("requerido"),
        durationUnit: Yup.number().required("requerido"),
        skillsCategory: Yup.string().required("requerido"),
        skillsSubcategory: Yup.string().required("requerido"),
        city: Yup.string().required("requerido"),
        addressStreet: Yup.string().required("requerido"),
        addressNumber: Yup.number().required("requerido"),
        vacancies: Yup.number().required("requerido"),
        jobStartDate: Yup.date()
            .required("requerido")
            .test("DOB", "la fecha debe ser mayor a la actual", value => {
                return moment(value) - moment(new Date()) > -86400000;
            }),
        jobEndDate: Yup.date()
            .required("requerido")
            .min(
                Yup.ref("jobStartDate"),
                ({ jobStartDate }) => `la fecha debe ser mayor a la inicial`
            )
            .test("DOB", "la fecha debe ser mayor a la actual", value => {
                return moment(value) - moment(new Date()) > -86400000;
            }),

        /* totalHours: Yup.string()
             // .transform(v => (v === null ? "" : v))
             .required("requerido"),*/

        minPaymentPercentage: Yup.string()
            .label("minPaymentPercentage")
            .test("is-true", "debe seleccionar una opción", value =>
                Number(value) !== 5 &&
                    Number(value) !== 10 &&
                    Number(value) !== 15 &&
                    Number(value) !== 20
                    ? false
                    : true
            )
    })
});

const NuevaBusquedaScreen = props => {
    const noTruncarDecimales = { maximumFractionDigits: 20 };
    const {
        values,
        touched,
        errors,
        setFieldValue,
        handleSave,
        ProvinceHandleChange,
        cityDisabled,
        skillsCategory,
        skillsSubcategoryDisabled,
        skillsSubcategory,
        categoryHandleChange,
        skills,
        setErrors,
        handleInputChangeMin,
        handleInputUnitQuantity,
        provinceList,
        durationUnitHandleChange,
        checks,
        skillsSubcategoryHandleChange,
        saveformStorage,
        TotalMaxPayment,
        TotalMinPayment,
        disabledSkillsbutton,
        handleChange,
        cityList,
        handleInputChange,
        durationUnit,
        unit,
        unitDescription,
        showModal,
        modalTitle,
        modalText,
        modalBtn,
        modalReset,
        path,
        redirect,
        onChangeWiziwig,
        editorState,
        setDescValue,
        title,
        totalMaxPayment,
        totalMinPayment,
        maxPayment,
        idKey
    } = props;
    let a,
        b = "";
    return (
        <div>
            <div className="row justify-content-center mr-0 ml-0" >
                <div className="decoration"></div>
                <div className="page_container">
                    <div className="card" style={{ backgroundColor: "#FAFAFA" }}>
                        <article className="card-body general">
                            <h4 className="card-title text-center" style={{ justifyContent: "center" }}>Generar Publicación</h4>
                            {showModal ? (
                                <Modal
                                    modal_title={modalTitle}
                                    modal_text={modalText}
                                    modal_btn={modalBtn}
                                    modalReset={modalReset}
                                    path={path}
                                    redirect={redirect}
                                />
                            ) : (
                                ""
                            )}
                            <Form>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Título</label>
                                            <input
                                                name="title"
                                                className="form-control"
                                                type={"title"}
                                                value={values.title}
                                                onChange={handleChange}
                                            />
                                            {errors.title ? (
                                                <div className="frm_error_c">{errors.title}</div>
                                            ) : <div style={{ height: "15px" }} />}

                                        </FormGroup>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <FormGroup>
                                                    <label className="label_black">Vacantes</label>
                                                    <input
                                                        name="vacancies"
                                                        className="form-control"
                                                        //type={"vacancies"}
                                                        type="number"
                                                        onChange={handleChange}
                                                        placeholder="vacancies"
                                                        value={values.vacancies}
                                                    />
                                                    {errors.vacancies && touched.vacancies ? (
                                                        <div className="frm_error_c">{errors.vacancies}</div>
                                                    ) : <div style={{ height: "15px" }} />}
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" style={{ height: "13rem", alignItems: "flex-start" }}>
                                        <FormGroup>
                                            <label className="label_black">Descripción</label>
                                            <div className="customDatePickerWidth" style={{ height: "8rem" }}>
                                                <ReactQuill value={values.description}

                                                    style={{ height: "4.7rem", backgroundColor: "#FFFFFF" }}
                                                    modules={{
                                                        toolbar: {
                                                            container: [
                                                                ["bold", "italic", "underline"],

                                                                [
                                                                    { list: "bullet" },

                                                                ],

                                                            ],

                                                        },
                                                    }}
                                                    onChange={(e) => {
                                                        if (e != "<p><br></p>") { setFieldValue("description", e); } else { setFieldValue("description", "") }
                                                    }
                                                    } />
                                            </div>

                                        </FormGroup>
                                        {errors.description && touched.description ? (
                                            <div className="frm_error_c" style={{ marginTop: "0" }}>
                                                {errors.description}
                                            </div>
                                        ) : <div style={{ height: "15px" }} />}
                                    </div>



                                </div>



                                <div className="row">
                                    <div
                                        className="tit_black"

                                    >
                                        <strong>Dirección</strong>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Provincia</label>
                                            <select
                                                name="province"
                                                className="form-control"
                                                //    component={customInputForm}
                                                onChange={e => {
                                                    ProvinceHandleChange(e, setFieldValue);
                                                }}
                                                //value={province}
                                                value={values.province}
                                                type="select"
                                            >
                                                <option value="">Provincia</option>
                                                {Object.keys(provinceList).map((keyName, i) => (
                                                    <option key={i} value={provinceList[keyName].id}>
                                                        {provinceList[keyName].name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.province && touched.province ? (
                                                <div className="frm_error_c">{errors.province}</div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup tag="fieldset">
                                            <label className="label_black">Ciudad / Barrio</label>
                                            <select
                                                id="city"
                                                name="city"
                                                className="form-control"
                                                type="select"
                                                disabled={cityDisabled}
                                                onChange={handleChange}
                                                value={values.city}
                                            >
                                                <option value="">Ciudad / Barrio</option>
                                                {Object.keys(cityList).map((keyName, i) => (
                                                    <option key={i} value={cityList[keyName].id}>
                                                        {cityList[keyName].name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.city ? (
                                                <div className="frm_error_c">{errors.city}</div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Calle</label>
                                            <input
                                                name="addressStreet"
                                                className="form-control"
                                                type={"addressStreet"}
                                                onChange={handleChange}
                                                value={values.addressStreet}
                                            />
                                            {errors.addressStreet && touched.addressStreet ? (
                                                <div className="frm_error_c">
                                                    {errors.addressStreet}
                                                </div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Altura</label>
                                            <input
                                                name="addressNumber"
                                                className="form-control"
                                                type={"addressNumber"}
                                                onChange={handleChange}
                                                placeholder="Número"
                                                value={values.addressNumber}
                                            />
                                            {errors.addressNumber && touched.addressNumber ? (
                                                <div className="frm_error_c">
                                                    {errors.addressNumber}
                                                </div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="tit_black"

                                    >
                                        Fechas
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <div className="customDatePickerWidth">
                                                <label className="label_black">Inicio asignación</label>
                                                <DatePickerField
                                                    name="jobStartDate"
                                                    value={
                                                        !isNaN(values.jobStartDate)
                                                            ? values.jobStartDate
                                                            : null
                                                    }
                                                    onChange={setFieldValue}
                                                    className="form-control"
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholder="desde"
                                                />
                                                {errors.jobStartDate && touched.jobStartDate ? (
                                                    <div className="frm_error_c">
                                                        {errors.jobStartDate}
                                                    </div>
                                                ) : <div style={{ height: "15px" }} />}
                                            </div>
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <div className="customDatePickerWidth">
                                                <label className="label_black">Fin asignación</label>
                                                <DatePickerField
                                                    name="jobEndDate"
                                                    value={
                                                        !isNaN(values.jobEndDate) ? values.jobEndDate : null
                                                    }
                                                    onChange={setFieldValue}
                                                    className="form-control"
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholder="hasta"
                                                />
                                                {errors.jobEndDate && touched.jobEndDate ? (
                                                    <div className="frm_error_c">{errors.jobEndDate}</div>
                                                ) : <div style={{ height: "15px" }} />}
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="tit_black"

                                    >
                                        Aptitudes
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup tag="fieldset">
                                            <label className="label_black">Categoría</label>
                                            <select
                                                name="skillsCategory"
                                                className="form-control"
                                                type="select"
                                                //component={customInputForm}
                                                value={values.skillsCategory}
                                                onChange={e => {
                                                    categoryHandleChange(e, setFieldValue);
                                                }}
                                            >
                                                <option value="">Seleccionar</option>
                                                {Object.keys(skillsCategory).map((keyName, i) => (
                                                    <option key={i} value={skillsCategory[keyName].id}>
                                                        {skillsCategory[keyName].category}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.skillsCategory ? (
                                                <div className="frm_error_c">
                                                    {errors.skillsCategory}
                                                </div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup tag="fieldset">
                                            <label className="label_black">Sub-categoría</label>
                                            <select
                                                name="skillsSubcategory"
                                                className="form-control"
                                                type="select"
                                                //value={skillsSubcategoryID}
                                                value={values.skillsSubcategory}
                                                onChange={e => {
                                                    skillsSubcategoryHandleChange(e, setFieldValue);
                                                }}
                                                //  component={customInputForm}
                                                disabled={skillsSubcategoryDisabled}
                                            >
                                                <option value="" defaultValue>
                                                    seleccionar
                                                </option>
                                                {Object.keys(skillsSubcategory).map((keyName, i) => (
                                                    <option key={i} value={skillsSubcategory[keyName].id}>
                                                        {skillsSubcategory[keyName].subcategory}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.skillsSubcategory ? (
                                                <div className="frm_error_c">
                                                    {errors.skillsSubcategory}
                                                </div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">


                                        {checks &&
                                            Object.keys(checks).map((keyName, i) => (
                                                checks[keyName] && checks[keyName].description ?
                                                    <div className="form-group habilidad" key={i}>
                                                        {checks[keyName].description}
                                                    </div> : ""
                                            ))}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <FormGroup>
                                            {values.skillsSubcategory > 0 ? (
                                                <p>
                                                    Agregar Habilidad
                                                    <Link
                                                        style={{
                                                            pointerEvents: disabledSkillsbutton
                                                        }}
                                                        className="arrow_right"
                                                        onClick={() => saveformStorage(values)}
                                                        to={"/AgregarAptitudes/" + values.skillsSubcategory + "/" + idKey}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} size="1x" className="blue" />
                                                    </Link>
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="tit_black"

                                    >
                                        Remuneración
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <FormGroup tag="fieldset">
                                            <label className="label_black">Para indicar la remuneración, selecciona la unidad de medida correspondiente (hora, día, semana o mes). Luego, completá el resto de los campos teniendo en cuenta la unidad seleccionada. </label>
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup tag="fieldset">
                                            <label className="label_black">Unidad de Medida</label>
                                            <select
                                                name="durationUnit"
                                                className="form-control"
                                                //    component={customInputForm}
                                                onChange={e => {
                                                    durationUnitHandleChange(e, setFieldValue);
                                                }}
                                                //value={province}
                                                value={unit}
                                                type="select"
                                            >
                                                <option value="">Seleccionar</option>
                                                {Object.keys(durationUnit).map((keyName, i) => (
                                                    <option key={i} value={durationUnit[keyName].id}>
                                                        {durationUnit[keyName].unit}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.durationUnit && touched.durationUnit ? (
                                                <div className="frm_error_c">{errors.durationUnit}</div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Cantidad de {unitDescription !== "Seleccionar" ? unitDescription === "día" || unitDescription === "semana" || unitDescription === "hora" ? unitDescription + "s" : unitDescription === "mes" ? unitDescription + "es" : unitDescription : ""}</label>
                                            <input

                                                name="durationUnitQuant"
                                                className="form-control"
                                                //type={"maxPayment"}
                                                type="number"
                                                onChange={e =>
                                                    handleInputUnitQuantity(
                                                        e,
                                                        values.minPayment,
                                                        values.maxPayment,
                                                        setFieldValue
                                                    )
                                                }
                                                placeholder="Cantidad de Unidades"
                                                value={values.durationUnitQuant}
                                                disabled={unit === "1" ? "disabled" : ""}

                                            />
                                            {errors.durationUnitQuant && touched.durationUnitQuant ? (
                                                <div className="frm_error_c">{errors.durationUnitQuant}</div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>




                                    <div className="col-12 col-md-3 col-lg-4">
                                        <FormGroup>
                                            <label className="label_black remuneracion" >Remuneración Mínima por {unitDescription !== "Seleccionar" ? unitDescription : ""}</label>
                                            <input
                                                name="minPayment"
                                                className="form-control"
                                                // type={"minPayment"}
                                                type="number"
                                                //  onChange={handleChange}
                                                //onChange={handleInputChange}
                                                placeholder="Mínima"
                                                value={values.minPayment}
                                                onChange={e =>
                                                    handleInputChangeMin(
                                                        e,
                                                        values.minPaymentPercentage,
                                                        values.durationUnitQuant,
                                                        setFieldValue
                                                    )
                                                }
                                            />
                                            {errors.minPayment && touched.minPayment ? (
                                                <div className="frm_error_c">{errors.minPayment}</div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <FormGroup tag="fieldset">
                                            <label className="label_black remuneracion" >Ajuste (%)</label>
                                            <div className="form-check form-check-inline ">
                                                <input
                                                    id="option1"
                                                    name="minPaymentPercentage"
                                                    className="form-check-input radio-inline radio align-items-center "
                                                    type="radio"
                                                    value="5"
                                                    onChange={e =>
                                                        handleInputChange(
                                                            e,
                                                            values.minPayment,
                                                            values.durationUnitQuant,
                                                            setFieldValue
                                                        )
                                                    }
                                                    checked={
                                                        values.minPaymentPercentage === "5" ? true : ""
                                                    }
                                                />

                                                <label className="form-check-label">5</label>
                                            </div>
                                            <div className="form-check form-check-inline ">
                                                <input
                                                    id="option1"
                                                    name="minPaymentPercentage"
                                                    className="form-check-input radio-inline radio align-items-center "
                                                    type="radio"
                                                    value="10"
                                                    onChange={e =>
                                                        handleInputChange(
                                                            e,
                                                            values.minPayment,
                                                            values.durationUnitQuant,
                                                            setFieldValue
                                                        )
                                                    }
                                                    checked={
                                                        values.minPaymentPercentage === "10" ? true : ""
                                                    }
                                                />

                                                <label className="form-check-label">10</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    id="option2"
                                                    name="minPaymentPercentage"
                                                    className="form-check-input radio-inline radio align-items-center"
                                                    type="radio"
                                                    value="15"
                                                    onChange={e =>
                                                        handleInputChange(
                                                            e,
                                                            values.minPayment,
                                                            values.durationUnitQuant,
                                                            setFieldValue
                                                        )
                                                    }
                                                    checked={
                                                        values.minPaymentPercentage === "15" ? true : ""
                                                    }
                                                />

                                                <label className="form-check-label">15</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    id="option3"
                                                    name="minPaymentPercentage"
                                                    className="form-check-input radio-inline radio align-items-center"
                                                    type="radio"
                                                    value="20"
                                                    onChange={e =>
                                                        handleInputChange(
                                                            e,
                                                            values.minPayment,
                                                            values.durationUnitQuant,
                                                            setFieldValue
                                                        )
                                                    }
                                                    checked={
                                                        values.minPaymentPercentage === "20" ? true : ""
                                                    }
                                                />
                                                <label className="form-check-label">20</label>
                                            </div>
                                            {errors.minPaymentPercentage &&
                                                touched.minPaymentPercentage ? (
                                                <div className="frm_error_c">
                                                    {errors.minPaymentPercentage}
                                                </div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <FormGroup>
                                            <label className="label_black remuneracion" >Remuneración Máxima por {unitDescription !== "Seleccionar" ? unitDescription : ""}</label>
                                            <input
                                                disabled
                                                name="maxPayment"
                                                className="form-control"
                                                //type={"maxPayment"}
                                                type="number"
                                                // onChange={handleChange}
                                                placeholder="Máxima"
                                                value={maxPayment}
                                            />
                                            {errors.maxPayment && touched.maxPayment ? (
                                                <div className="frm_error_c">{errors.maxPayment}</div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Remuneración Total Mínima</label>
                                            <input
                                                disabled
                                                name="totalMinPayment"
                                                className="form-control"
                                                //type={"maxPayment"}
                                                type="number"
                                                //  onChange={handleChange}
                                                placeholder="Máxima"
                                                value={totalMinPayment}
                                            />
                                            {errors.totalMinPayment && touched.totalMinPayment ? (
                                                <div className="frm_error_c">{errors.totalMinPayment}</div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Remuneración Total Máxima</label>
                                            <input
                                                disabled
                                                name="totalMaxPayment"
                                                className="form-control"
                                                //type={"maxPayment"}
                                                type="number"
                                                // onChange={handleChange}
                                                placeholder="Máxima"
                                                value={totalMaxPayment}
                                            />
                                            {errors.totalMaxPayment && touched.totalMaxPayment ? (
                                                <div className="frm_error_c">{errors.totalMaxPayment}</div>
                                            ) : <div style={{ height: "15px" }} />}
                                        </FormGroup>
                                    </div>

                                </div>
                                <div className="form-group">
                                    <button
                                        className="btn btn-blue btn-block"
                                        type="submit"
                                        // disabled={isSubmitting}
                                        style={{ width: "100%" }}
                                    >
                                        Publicar
                                    </button>
                                </div>
                                <div className="form-group">
                                    <div
                                        className="btn btn-blue btn-block"
                                        variant="contained"
                                        // disabled={isSubmitting}
                                        onClick={() => { handleSave(props.values, setErrors, errors) }}
                                        style={{ width: "100%", cursor: "pointer" }}
                                    >
                                        Guardar en borrador
                                    </div>
                                </div>
                            </Form>
                        </article>
                    </div>
                </div>
            </div>
        </div >
    );
};
const EnhancedForm = formikWrapper(NuevaBusquedaScreen);
export default EnhancedForm;
