import React, { useState } from "react";
import "rc-slider/assets/index.css";
import "./../../../../Styles/general.css";
import { withRouter, Link } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';

import ModalUpdateFields from "./modalUpdateFields";

import "./../../../style.css";
import "./style.css";

//Assets
import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faMapMarkerAlt,
    faUserFriends,
    faArrowLeft,
    faArrowRight,
    faPen,
    faMoneyBillWave,
    faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment";
import { TabPanel, Tab, Tabs, TabList } from "react-tabs";
import Modal from "../../../common/Modal";
import ModalPreguntas from "./ModalPreguntas";
import ModalNoSaving from "./ModalNoSaving";
import * as Yup from "yup";
library.add(faMapMarkerAlt, faUserFriends);

function onSubmit() {

}

function DifferenceInDays(firstDate) {
    var today = new Date();
    var todayDate = moment(today).format("YYYY-MM-DD");
    var endDate = moment(firstDate).format("YYYY-MM-DD");
    var difference = moment(endDate).diff(todayDate, "days");

    var day = difference > "1" ? "días" : "día";

    if (difference > "0") {
    }

    return [difference + " " + day];
}

function getAge(dateString) {
    let hoy = new Date()
    let fechaNacimiento = new Date(dateString)
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
    if (
        diferenciaMeses < 0 ||
        (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
        edad--
    }
    return edad
}
function DifferenceInDaysEndingNumber(firstDate) {
    var today = new Date();
    var todayDate = moment(today).format("YYYY-MM-DD");
    var endDate = moment(firstDate).format("YYYY-MM-DD");
    // var difference = moment(endDate).diff(todayDate, "days");
    var difference = moment(endDate).diff(todayDate, "days");
    ///var day = difference > "1" || difference < "-1" ? "días" : "día";

    return difference;
}


function DifferenceInDaysEnding(firstDate) {
    var today = new Date();
    var todayDate = moment(today).format("YYYY-MM-DD");
    var endDate = moment(firstDate).format("YYYY-MM-DD");
    // var difference = moment(endDate).diff(todayDate, "days");
    var difference = moment(endDate).diff(todayDate, "days");
    var day = difference > "1" || difference < "-1" ? "días" : "día";


    if (Number(difference) > 0) {
        difference = "Finaliza en " + difference;
    }
    else if (Number(difference) == 0) {
        return "Finaliza hoy";
    }

    else {
        difference = "Finalizó hace " + Math.abs(difference);
    }

    return [difference + " " + day];
}


const BusquedasDetalleScreen = props => {
    const {
        min,
        data,
        skills,
        hired,

        deleteApplying,
        appliedTalents,
        selectedTalents,
        showModal,
        modalTitle,
        modalText,
        modalBtn,
        path,
        modalReset,
        dataTalents,
        showOfferClosingModal,
        optionSelected,
        handleChangeOptions,
        redirect,
        modalTitleNoSaving,
        modalTextNoSaving,
        modalBtnNoSaving,
        showModalNoSaving,
        modalResetNoSaving,
        pathNoSaving,
        redirectNoSaving,
        modalCancel,
        ModifyModalState,
        pageRedirect,
        qualifications,
        setTalentsListStatus,
        reloadPage,
        handleChange,
        values,
        setUpdateValues,
        updateDescription,
        pointerEvent,
        checkMonotributoChecked,
        handleCheckboxChange,
        talentsListStatus,
        selectTalent
    } = props;

    const [updateActive, setupdateActive] = useState(false);
    function closeModal() {
        setupdateActive(false)
    }


    return (
        <div style={{ pointerEvents: updateActive ? "none" : "" }}>

            <div className="row justify-content-center mr-0 ml-0">
                <div className="decoration"></div>
                <div className="page_container" >
                    <div className="card" style={{ backgroundColor: "#FAFAFA" }}>
                        <article className="card-body general" style={{ minHeight: "100px" }}>
                            <h4 className="card-title text-center">
                                <a onClick={pageRedirect}>
                                    <FontAwesomeIcon
                                        className="arrow_back"
                                        icon={faArrowLeft}
                                        size="1x"
                                    />
                                </a>
                                Busquedas Detalle
                                <FontAwesomeIcon
                                    className="arrow_back"
                                    icon={faArrowLeft}
                                    size="1x"
                                    style={{ visibility: "hidden" }}
                                />
                            </h4>
                            {showModalNoSaving ? (
                                <ModalNoSaving
                                    modal_title={modalTitleNoSaving}
                                    modal_text={modalTextNoSaving}
                                    modal_btn={modalBtnNoSaving}
                                    modalResetNoSaving={modalResetNoSaving}
                                    path={pathNoSaving}
                                    modalCancel={modalCancel}
                                    redirect={redirectNoSaving}
                                    ModifyModalState={ModifyModalState}
                                    id={data.id}
                                />
                            ) : (
                                ""
                            )}
                            {showModal ? (
                                <Modal
                                    modal_title={modalTitle}
                                    modal_text={modalText}
                                    modal_btn={modalBtn}
                                    modalReset={modalReset}
                                    path={path}
                                    redirect={redirect}
                                />
                            ) : (
                                ""
                            )}
                            <hr />

                            <form>
                                <div className="match_detalle">
                                    <div className="match_detalle_top">
                                        <div className="match_detalle_date">
                                            {DifferenceInDaysEnding(data.offer_end_date)}
                                        </div>

                                        <div className="match_detalle_status">
                                            {hired.length + " / " + data.vacancies + " " + "vacantes"}
                                        </div>
                                    </div>

                                    {/*this element should be iterated */}


                                    <div className="match_detalle_info">
                                        <div className="match_detalle_icon"  >
                                            <FontAwesomeIcon
                                                className="arrow_back"

                                                icon={faPen}
                                                size="1.5x"
                                                onClick={() => setupdateActive(true)}></FontAwesomeIcon>
                                        </div>
                                        <div className="match_detalle_job_name">{data.title}</div>
                                        {updateActive === true ? <ModalUpdateFields closeModal={closeModal} updateDescription={updateDescription} /> : ""}


                                        <label className="match_detalle_description_title " style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                            Detalle:
                                        </label>

                                        <div className="match_detalle_description" dangerouslySetInnerHTML={{ __html: data.description }} />

                                        {skills[0] ?
                                            (<div className="aptitudes_requeridas">
                                                <div className="match_detalle_description_title">
                                                    Aptitudes requeridas:
                                                </div>
                                                {skills &&
                                                    Object.keys(skills).map((keyName, i) => (
                                                        <span className="badge badge-pill badge-primary m-1 busqueda_activa_aptitud">
                                                            {skills[keyName].skill}
                                                        </span>
                                                    ))}
                                            </div>) : ""}


                                        <div className="match_detalle_description_title">

                                            Ubicación:
                                        </div>
                                        <div className="match_detalle_location">
                                            {data.city +
                                                ", " +
                                                data.province +
                                                "/" +
                                                data.address_street +
                                                " " +
                                                data.address_number}{" "}
                                        </div>

                                        <div className="match_detalle_description_title">

                                            Remuneración:

                                        </div>

                                        <div className="match_detalle_txt">
                                            {"Desde $ " +
                                                Math.round(data.min_payment * data.duration_unit_quantity) +
                                                " - " +
                                                "Hasta $ " +
                                                Math.round(data.min_payment +
                                                    (data.min_payment * data.min_payment_percentage) /
                                                    100) * data.duration_unit_quantity}
                                        </div>

                                        <div className="match_detalle_description_title">
                                            Fechas
                                            y horarios:
                                        </div>

                                        <div className="match_detalle_txt">
                                            Fecha de inicio:{" "}
                                            <strong>
                                                {moment(data.job_start_date).format("DD/MM/YYYY")}
                                            </strong>
                                            <br />
                                            Fecha de finalización:{" "}
                                            <strong>
                                                {moment(data.job_end_date).format("DD/MM/YYYY")}
                                            </strong>
                                            <br />
                                            {/*    Cantidad de horas: <strong>{data.total_hours}</strong> */}
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </article>

                        <div className="card">
                            <article className="card-body" style={{ backgroundColor: "#FAFAFA" }}>
                                <div className="row" style={{ marginRight: "0", marginLeft: "0", backgroundColor: "#FFFFFF" }}>
                                    <div className={`col-4 statusButton ${String(talentsListStatus) === "1" ? " buttonActive" : ""}`} onClick={() => setTalentsListStatus(1, checkMonotributoChecked, optionSelected)}>
                                        Postulados{" "}
                                        {appliedTalents && appliedTalents != undefined ? "(" + appliedTalents.length + ")" : "(" + 0 + ")"}
                                    </div>
                                    <div className={`col-4 statusButton ${String(talentsListStatus) === "2" ? " buttonActive" : ""}`} onClick={() => setTalentsListStatus(2, checkMonotributoChecked, optionSelected)}>
                                        Seleccionados{" "}
                                        {selectedTalents && selectedTalents != undefined ? "(" + selectedTalents.length + ")" : "(" + 0 + ")"}
                                    </div>
                                    <div className={`col-4 statusButton ${String(talentsListStatus) === "3" ? " buttonActive" : ""}`} onClick={() => setTalentsListStatus(3, checkMonotributoChecked, optionSelected)}>
                                        Contratados {hired && hired != undefined ? "(" + hired.length + ")" : "(" + 0 + ")"}
                                    </div>
                                </div>


                                <div className="row" style={{ marginTop: "10px" }}>
                                    <div
                                        className="col-12 col-sm-6  col-md-6 inputOrder"
                                        style={{ marginBottom: "1rem", paddingLeft: "1rem", paddingRight: "0", paddingTop: "0.7rem" }}
                                    >

                                        <input
                                            name="checkMonotributo"
                                            // className="checkbox"
                                            style={{ marginLeft: "0.5 rem" }}
                                            type="checkbox"
                                            checked={checkMonotributoChecked}
                                            onChange={(e) => handleCheckboxChange(e, talentsListStatus)}
                                        />
                                        {" "}Tiene Monotributo
                                    </div>
                                    <div
                                        className="col-12 col-sm-6  col-md-6 inputOrder mb-3"

                                    >
                                        <select
                                            name="options"
                                            className="form-control"
                                            onChange={e => handleChangeOptions(e, checkMonotributoChecked)}
                                            type="select"
                                            value={optionSelected}
                                        >
                                            {/* <option value="">Todos</option> */}
                                            <option key={1} value="1">
                                                Ordenar por : Calificación ascendente
                                            </option>
                                            <option key={2} value="2">
                                                Ordenar por : Calificación descendente
                                            </option>
                                            <option key={3} value="3">

                                                Ordenar por : Fecha de postulación ascendente
                                            </option>
                                            <option key={4} value="4">

                                                Ordenar por : Fecha de postulación descendente
                                            </option>
                                            <option key={5} value="5">

                                                Ordenar por : Remuneración ascendente
                                            </option>
                                            <option key={6} value="6">
                                                Ordenar por : Remuneración descendente

                                            </option>

                                            {/*   <option key={4} value="cerradas">
                                                Cerradas
                                                </option>*
                                                                                
                                                Activas, En curso, Finalizadas, Borradores, Canceladas */}

                                        </select>
                                    </div>
                                </div>

                                {dataTalents.length === 0 ? (
                                    <div style={{ textAlign: "center", width: "100% !important" }}>
                                        <span className="text-primary" >

                                            {`No hay ${talentsListStatus === 1 ? "postulados" : talentsListStatus === 2 ? "seleccionados" : "contratados"} `}
                                        </span>
                                    </div>) : (

                                    Object.keys(dataTalents).map(
                                        (keyName, i) => (
                                            <form>

                                                <div className="card h-10 match_preview border-light shadow-sm rounded-0" >
                                                    <div className="card-body p-30 m-30">
                                                        <div className="row" style={{ marginRight: "0", marginLeft: "0" }}>
                                                            <div className="col" style={{ paddingRight: "0", paddingLeft: "0" }}>
                                                                {/*this element should be iterated */}

                                                                <div className="match_preview_top">

                                                                    <div className="match_preview_date">


                                                                    </div>

                                                                    {(String(talentsListStatus) === "1" || String(talentsListStatus) === "2") && Number(DifferenceInDaysEndingNumber(data.offer_end_date)) > 0 ?
                                                                        <div className="match_preview_status">
                                                                            <Link onClick={() => deleteApplying(dataTalents[keyName].jobApplicationId)}><div className="match_preview_status" style={{ textDecoration: "underline" }} >
                                                                                {`Eliminar ${String(talentsListStatus) === "1" ? "Postulado" : String(talentsListStatus) === "2" ? "Seleccionado" : ""} `}
                                                                            </div>
                                                                            </Link>
                                                                        </div> : ""}

                                                                </div>

                                                            </div>

                                                            <div className="row">
                                                                <div
                                                                    className="col-12"
                                                                    style={{ paddingBottom: "5px" }}
                                                                >

                                                                    <div className="row">

                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="match_preview_info">
                                                                <div >

                                                                    <span>
                                                                        <div className="match_preview_description_title" >
                                                                            Detalle:

                                                                            {dataTalents[
                                                                                keyName
                                                                            ].first_name
                                                                                .charAt(0)
                                                                                .toUpperCase() +
                                                                                dataTalents[
                                                                                    keyName
                                                                                ].first_name.slice(1) +
                                                                                " " +
                                                                                dataTalents[
                                                                                    keyName
                                                                                ].last_name
                                                                                    .charAt(0)
                                                                                    .toUpperCase()}
                                                                        </div>
                                                                    </span>

                                                                </div>

                                                                <div className="match_preview_description_title" >
                                                                    Remuneración pretendida:
                                                                    <strong>
                                                                        {" $" +
                                                                            dataTalents[keyName]
                                                                                .payment_required}
                                                                    </strong>
                                                                </div>

                                                                <div className="match_preview_description_title">
                                                                    Calificaciones:
                                                                    <strong>
                                                                        {Number(dataTalents[keyName]
                                                                            .qualifications_quant) != 0 ?

                                                                            " " +
                                                                            Math.round((dataTalents[keyName]
                                                                                .qualifications_sum * 100
                                                                            ) / dataTalents[keyName]
                                                                                    .qualifications_quant) + "%"

                                                                            : "aún sin calificaciones"}
                                                                    </strong>
                                                                </div>
                                                                <div className="match_preview_description_title">
                                                                    Barrio:


                                                                    {" "}{dataTalents[keyName].city +
                                                                        ", " +
                                                                        dataTalents[keyName].province}{" "}

                                                                </div>
                                                                <div className="match_preview_description_title">
                                                                    Edad:


                                                                    {" "}{getAge(dataTalents[keyName].birthdate)}
                                                                </div>
                                                                <div className="match_preview_description_title">
                                                                    Monotributo Activo:
                                                                    {" "}{dataTalents[keyName].monotributista && dataTalents[keyName].monotributista === 1 ? "Sí" : "No"}
                                                                </div>

                                                                <Link
                                                                    to={
                                                                        "/DetalleTalentoOferta/" +
                                                                        data.id +
                                                                        "/" +
                                                                        dataTalents[keyName].id +
                                                                        "/" +
                                                                        talentsListStatus +
                                                                        "/" +
                                                                        checkMonotributoChecked + "/" +
                                                                        optionSelected

                                                                    }
                                                                    replace
                                                                    className="match_preview_btn btn-blue"

                                                                // className="list-group-item  list-group-item-light"
                                                                >
                                                                    Ver más
                                                                </Link>
                                                                {Number(talentsListStatus) === 1 && Number(DifferenceInDaysEndingNumber(data.offer_end_date)) > 0 ?
                                                                    (<Link
                                                                        className="match_preview_btn btn-blue"
                                                                        style={{ backgroundColor: "#2973B5" }}
                                                                        //  onClick={() => redirectToBusquedasActivas(data[keyName])}
                                                                        // to={"/BusquedasDetalle/" + data[keyName].id}
                                                                        onClick={e => selectTalent(e, data.id, dataTalents[keyName].id)}
                                                                    >
                                                                        Seleccionar
                                                                    </Link>)
                                                                    : ("")}
                                                                <div>
                                                                    {console.log("dataTalents[keyName].is_qualified: " + dataTalents[keyName].is_qualified)}
                                                                    {String(talentsListStatus) === "3" && DifferenceInDays(
                                                                        data.offer_end_date
                                                                    ) < "0" &&
                                                                        Number(dataTalents[keyName].is_qualified) != 1 ? (
                                                                        <div>
                                                                            <ModalPreguntas
                                                                                idname={dataTalents[keyName].id}
                                                                                dataid={data.id}
                                                                                reloadPage={reloadPage}

                                                                            />
                                                                        </div>
                                                                    ) : ""
                                                                    }

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>


                                            </form>
                                        )))// : ""
                                }
                                < div >
                                    {/*    <Paginator
                                                    cantPages={cantPages}
                                                    handleChangePage={handleChangePage}
                                                    optionSelected={pageSelected}
                                            /> */}
                                </div>
                            </article>
                        </div>

                        {/*end iteration */}

                        {data.fullState != "cerrada" && Number(DifferenceInDaysEndingNumber(data.offer_end_date)) >= 0 ? (
                            <div className="row justify-content-center mr-1 ml-1">
                                <div className="col-12 col-xs-12 col-sm-12  col-md-12 col-lg-12 col-xl-12 text-left text-primary ">
                                    <div className="form-group">
                                        <button
                                            type="submit"
                                            className="btn btn-blue btn-block"
                                            // onClick={e => handleSubmitValue(e, data.id)}
                                            onClick={e => showOfferClosingModal(e, data.id)}
                                            style={{ width: "100%" }}
                                        >
                                            {" "}
                                            Finalizar búsqueda{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}


                    </div>
                </div>
            </div>

        </div >
    );
};

//export default withRouter(BusquedasDetalleScreen);
const EnhancedForm = BusquedasDetalleScreen;
export default withRouter(EnhancedForm);
