//assets
import React, { Component } from "react";

//import { Formik, withFormik, Field, Form } from "formik";
//import api_services from "../../api_services";
import RegistroEmpresaForm from "./RegistroEmpresaForm";
//import auth from "../../auth";
import axios from "axios";
import { url_base } from "./../../../../api_url";
import "./../../../../../node_modules/react-image-crop/dist/ReactCrop.css";


class RegistroEmpresaScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            province: [],
            docType: [],
            cityDisabled: "disabled",
            cityState: [],
            birthDate: [],
            showModal: false,
            modalTitle: "",
            modalText: "",
            modalBtn: "",
            path: "",
            redirect: "",
            open: false,
            src: null,
            province: [],
            provinceID: [],
            city: [],
            formSubmitting: false
        };

        this.ProvinceHandleChange = this.ProvinceHandleChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.saveImage = this.saveImage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.modalReset = this.modalReset.bind(this);
    }

    modalReset() {
        this.setState({ showModal: false });
    }

    handleSubmit(values, props) {
        this.setState({ formSubmitting: true });
        axios
            .post(url_base + "/companies/register", values)
            .then(response => {
                let path = "/Login";
                this.setState({
                    showModal: true,
                    modalTitle: "LA CUENTA AÚN NO SE ENCUENTRA ACTIVA",
                    modalText:
                        "Te enviamos un mail para activar tu cuenta! Si no lo recibiste escribinos a hola@asignate.com",
                    modalBtn: "Aceptar",
                    path: path,
                    redirect: true,
                    formSubmitting: false
                });

            })

            .catch(error => {
                this.setState({
                    showModal: true,
                    modalTitle: "HA OCURRIDO UN ERROR",
                    modalText: error.response.data.message,
                    modalBtn: "Aceptar",
                    formSubmitting: false
                });

            });
    }

    handleChange = (e, setFieldValue) => {
        setFieldValue("province", e.target.value);

        this.setState({
            provinceID: e.target[e.target.selectedIndex].value
        });

        if (e.target[e.target.selectedIndex].value != "") {
            this.setState({
                cityDisabled: ""
            });
        } else {
            // setFieldValue("city_id", e.target.value);
            this.setState({
                cityDisabled: "disabled"
            });
        }
        let idProvince = e.target[e.target.selectedIndex].value;
        axios
            .get(url_base + "/province-cities/" + idProvince)
            .then(response => {
                this.setState({
                    city: response.data.data.cities
                });
            })
            .catch(error => { });
    };

    saveImage(imagenToPost, setFieldValue) {
        this.setState({ imagenTo: imagenToPost });
        setFieldValue("imageLogo", imagenToPost);
    }

    ProvinceHandleChange(e, setFieldValue) {
        setFieldValue("province", e.target.value);
        this.setState({
            //    provinceState: e.target.value,
            provinceID: e.target[e.target.selectedIndex].value
        });

        if (e.target[e.target.selectedIndex].value != "") {
            this.setState({
                cityDisabled: "",
                city: ""
            });
        } else {
            // setFieldValue("city_id", e.target.value);
            this.setState({
                cityDisabled: "disabled",
                city: ""
            });
        }

        setFieldValue("city", "");
        let idProvince = e.target[e.target.selectedIndex].value;

        axios
            .get(url_base + "/province-cities/" + idProvince)
            .then(response => {
                this.setState({
                    city: response.data.data.cities
                });
            })
            .catch(error => { });
    }
    componentDidMount() {
        const idProvince = "";
        //cargo las provincias
        axios
            .get(url_base + "/provinces")
            .then(response => {
                this.setState({
                    province: response.data.data.provinces
                });
                idProvince = response.data.data.provinces.id;
            })
            .catch(error => { });

        axios
            .get(url_base + "/province-cities/" + idProvince)
            .then(response => {
                this.setState({
                    city: response.data.data.cities
                });
            })
            .catch(error => { });
    }

    render() {
        const handleSubmit = values => { };

        return (
            <RegistroEmpresaForm
                //province: [],
                provinceID={this.state.provinceID}
                // city: []
                province={this.state.province}
                cityDisabled={this.state.cityDisabled}
                city={this.state.city}
                ProvinceHandleChange={this.ProvinceHandleChange}
                provinceID={this.state.provinceID}
                src={this.src}
                imagenToPost={this.state.imagenToPost}
                saveImage={this.saveImage}
                handleChange={this.handleChange}
                showModal={this.state.showModal}
                modalTitle={this.state.modalTitle}
                modalText={this.state.modalText}
                modalBtn={this.state.modalBtn}
                modalReset={this.modalReset}
                redirect={this.state.redirect}
                path={this.state.path}
                handleSubmit={this.handleSubmit}
                formSubmitting={this.state.formSubmitting}
            />
        );
    }
}
export default RegistroEmpresaScreen;
