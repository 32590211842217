import React from "react";

import { Form, withFormik } from "formik";
import * as Yup from "yup";
import { FormGroup } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import { url_base } from "./../../api_url";
import {
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "./../common/Modal";

const formikWrapper = withFormik({
    mapPropsToValues: props => ({
        email: ""
    }),

    initialValues: { password: "", password_confirmation: "" },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .email("email inválido")
            .min(6, "el email es demasiado corto")
            .required("requerido")
    }),

    // validationSchema: validationSchema,
    handleSubmit: (values, { props }) => {
        axios
            .post(url_base + "/recover-password", values, {
                headers: {
                    ["Authorization"]: "Bearer "
                }
            })
            .then(response => {
                let path = `/Login/`;
                props.history.push(path);
            })
            .catch();
    }
});
const RecuperarPassword = props => {
    const {
        handleChange,
        errors,
        touched,
        showModal,
        modalTitle,
        modalText,
        modalBtn,
        modalReset,
        path,
        redirect
    } = props;
    return (
        <div className="forgot_c" style={{ backgroundColor: "#FAFAFA" }}>
            <div className="row justify-content-center m-3" style={{ backgroundColor: "#FAFAFA" }}>
                <div className="col-xs-6 col-sm-6 col-md-5 col-lg-5  align-self-center" >
                    <div className="card " style={{ backgroundColor: "#FAFAFA", border: "none" }}>
                        <article className="card-body general">
                            <h4 className="card-title text-center ">
                                <Link to={"/Login"}>
                                    <FontAwesomeIcon
                                        className="arrow_back"
                                        icon={faArrowLeft}
                                        size="1x"
                                    />
                                </Link>

                                Recuperar contraseña
                                <FontAwesomeIcon
                                    className="arrow_back"
                                    icon={faArrowLeft}
                                    size="1x"
                                    style={{ visibility: "hidden" }}
                                />
                            </h4>
                            <p>
                                Ingresa tu email y te enviaremos un link de recupero de clave
                            </p>

                            {showModal ? (
                                <Modal
                                    modal_title={modalTitle}
                                    modal_text={modalText}
                                    modal_btn={modalBtn}
                                    modalReset={modalReset}
                                    path={path}
                                    redirect={redirect}
                                />
                            ) : (
                                ""
                            )}
                            <Form>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <FormGroup>
                                            <div className="label_black">Email</div>
                                            <input
                                                name="email"
                                                className="form-control"
                                                type={"mail"}
                                                onChange={handleChange}
                                                placeholder="email*"
                                            />
                                            {errors.email && touched.email ? (
                                                <div className="frm_error_c">{errors.email}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <button
                                        className="btn btn-blue btn-block"
                                        style={{ width: "100%" }}
                                        type="submit"
                                    >
                                        Enviar
                                    </button>
                                </div>
                            </Form>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
};

const EnhancedForm = formikWrapper(RecuperarPassword);

export default withRouter(EnhancedForm);
