import React, { Component } from "react";
import {
    HashRouter,
    Route,
    Switch,
} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";

//components
import LoginScreen from "./components/privateRoutes/LoginScreen";
import RegistroTalentoScreen from "./components/privateRoutes/talento/formRegistroTalento/RegistroTalentoScreen";
import RegistroEmpresaScreen from "./components/privateRoutes/empresa/formRegistroEmpresa/RegistroEmpresaScreen";
import DatosPersonalesText from "./components/privateRoutes/talento/formDatosPersonales/DatosPersonales";
import EducacionText from "./components/privateRoutes/talento/educacion/Educacion";
import ExperienciaText from "./components/privateRoutes/talento/formExperiencia/Experiencia";
//import AptitudesMenuText from "./components/privateRoutes/AptitudesMenuText";
//import AptitudesHabilidadText from "./components/privateRoutes/AptitudesHabilidadText";
import PerfilText from "./components/privateRoutes/talento/formMenuEditarPerfil/PerfilTextScreen";
//import PerfilEditarText from "./components/privateRoutes/empresa/formPerfilEditar/PerfilEditarText";
import Matchs from "./components/privateRoutes/talento/listadoMatchs/Matchs";
import MatchsDetalle from "./components/privateRoutes/talento/matchDetalle/MatchDetalle";
import RegistryVerification from "./RegistryVerification";
import NuevaBusqueda from "./components/privateRoutes/empresa/formNuevaBusqueda/NuevaBusqueda";
import AgregarAptitudes from "./components/privateRoutes/empresa/formAgregarAptitudes/AgregarAptitudes";
import DatosEmpresa from "./components/privateRoutes/empresa/formDatosEmpresa/DatosEmpresa";
import BusquedasActivas from "./components/privateRoutes/empresa/busquedasActivas/BusquedasActivas.js";
import BusquedasDetalle from "./components/privateRoutes/empresa/BusquedasDetalle/BusquedasDetalle";
import DetalleTalentoOferta from "./components/privateRoutes/empresa/formDetalleTalentoOferta/DetalleTalentoOferta";
import HistorialMatcheos from "./components/privateRoutes/talento/historialMatcheos/HistorialMatcheos";
import AgregarCategoria from "./components/privateRoutes/talento/formAgregarCategoria/AgregarCategoria";
import AgregarHabilidades from "./components/privateRoutes/talento/formAgregarHabilidades/AgregarHabilidades";
import AgregarAptitudesTalento from "./components/privateRoutes/talento/formAgregarCategoria/AgregarAptitudesTalento";
import CambiarPassword from "./components/publicRoutes/CambiarPassword";
import RecuperarPassword from "./components/publicRoutes/RecuperarPassword";
import HistorialBusquedas from "./components/privateRoutes/empresa/historialBusquedas/HistorialBusquedas";
import RestaurarPassword from "./components/publicRoutes/RestaurarPassword";
import Logout from "./components/publicRoutes/Logout";
import Terms from "./components/publicRoutes/Terms";
import Landing from "./components/asignateLanding";

import Modal from "./components/common/Modal";


const AppRoutes = () => (
    <HashRouter basename="/">
        <Switch>
            <Route path="/Login" component={LoginScreen} />
            <Route path exact="/" component={Landing} />

            <Route path="/RegistroTalento" component={RegistroTalentoScreen} />
            <Route path="/RegistroEmpresa" component={RegistroEmpresaScreen} />
            <Route path="/Terms" component={Terms} />
            <Route path="/Landing" component={Landing} />
            <ProtectedRoute
                path="/DatosPersonalesText"
                component={DatosPersonalesText}
            />
            <ProtectedRoute path="/EducacionText" component={EducacionText} />
            <ProtectedRoute path="/ExperienciaText" component={ExperienciaText} />
            <ProtectedRoute path="/Logout" component={Logout} />

            <Route path="/registry-verification" component={RegistryVerification} />
            <Route path="/RecuperarPassword" component={RecuperarPassword} />
            <ProtectedRoute
                path="/HistorialBusquedas"
                component={HistorialBusquedas}
            />

            <Route path="/Modal" component={Modal} />

            <Route path="/restore-password" component={RestaurarPassword} />
            <ProtectedRoute path="/CambiarPassword" component={CambiarPassword} />

            <ProtectedRoute
                path="/HistorialMatcheos/"
                component={HistorialMatcheos}
            />
            <ProtectedRoute path="/NuevaBusqueda" component={NuevaBusqueda} />
            <ProtectedRoute path="/AgregarAptitudes" component={AgregarAptitudes} />
            <ProtectedRoute path="/DatosEmpresa" component={DatosEmpresa} />
            <ProtectedRoute path="/BusquedasActivas" component={BusquedasActivas} />
            <ProtectedRoute
                path="/BusquedasDetalle"
                // path="/BusquedasDetalle"
                component={BusquedasDetalle}
            />
            <ProtectedRoute
                path="/AgregarHabilidades"
                component={AgregarHabilidades}
            />

            <ProtectedRoute
                path="/DetalleTalentoOferta/:id/:data/:type"
                component={DetalleTalentoOferta}
            />
            <ProtectedRoute path="/AgregarCategoria" component={AgregarCategoria} />
            <ProtectedRoute
                path="/AgregarAptitudesTalento"
                component={AgregarAptitudesTalento}
            />

            <ProtectedRoute path="/PerfilText" component={PerfilText} />
            <ProtectedRoute path="/Matchs" component={Matchs} />
            <ProtectedRoute path="/MatchsDetalle" component={MatchsDetalle} />
        </Switch>
    </HashRouter>
);
export default AppRoutes;
