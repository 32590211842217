import React, { Component } from "react";
import axios from "axios";
import { url_base } from "../../../../api_url";
import auth from "../../../../auth";
import HistorialBusquedasScreen from "./HistorialBusquedasScreen";
import Popup from "reactjs-popup";

class HistorialBusquedas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idOffer: "",
            idTalent: "",
            studies: [],
            experiences: [],
            skills: [],
            languages: [],
            data: [],
            dateFrom: [],
            dateUntil: [],
            cantPages: "",
            divShow: false,
            pageSelected: ""
        };

        this.handleChangePage = this.handleChangePage.bind(this);
        //  this.modalPreguntas = this.modalPreguntas.bind(this);
    }

    handleChangePage(id) {
        const access_token_data = localStorage.getItem("token");
        this.props.history.push("/HistorialBusquedas/" + id);
        axios
            .get(url_base + "/companies/closed-job-searches/" + id, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = response.data.data.jobOffers;
                    const cantPages = response.data.data.cantPages;
                    //   console.log(data);
                    this.setState({
                        data: data,
                        cantPages: cantPages,
                        pageSelected: id
                    });
                },
                error => {
                    var status = error.response;
                }
            );
    }

    componentDidMount() {
        const access_token_data = localStorage.getItem("token");

        const key = this.props.location.pathname.split("/");

        let keyIdPage = "";
        if (key[2] != "" && key[2] !== undefined && key[2] !== null) {
            keyIdPage = key[2];
        } else {
            keyIdPage = 1;
        }

        axios
            .get(url_base + "/companies/closed-job-searches/" + keyIdPage, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(
                response => {
                    const data = response.data.data.jobOffers;
                    const cantPages = response.data.data.cantPages;
                    //console.log("cantPages" + cantPages);

                    if (data == "" || data == undefined) {
                        this.setState({
                            divShow: true
                        });
                    } else {
                        this.setState({
                            data: data,
                            cantPages: cantPages,
                            divShow: false,
                            pageSelected: keyIdPage
                        });
                    }
                },
                error => {
                    var status = error.response;
                }
            );
    }

    render() {
        return (
            <HistorialBusquedasScreen
                data={this.state.data}
                cantPages={this.state.cantPages}
                handleChangePage={this.handleChangePage}
                divShow={this.state.divShow}
                pageSelected={this.state.pageSelected}
            />
        );
    }
}
export default HistorialBusquedas;
