import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../../script";
import "./../../../style.css";
import moment from "moment";
import ModalPreguntas from "./ModalPreguntas";
import Modal from "./../../../common/Modal";

class Paginator extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        window.scroll(0, 0);
        let data = [];
        let active = "";
        let arrowActiveForward = "";
        let arrowActiveBackward = "";
        //console.log("cantPages" + this.props.cantPages);
        this.props.optionSelected <= 1
            ? (arrowActiveForward = "page-item disabled")
            : (arrowActiveForward = "page-item");
        this.props.optionSelected >= this.props.cantPages
            ? (arrowActiveBackward = "page-item disabled")
            : (arrowActiveBackward = "page-item");

        for (let i = 1; i <= this.props.cantPages; i++) {
            if (this.props.cantPages > 1) {
                Number(this.props.optionSelected) === Number(i)
                    ? (active = "page-item active")
                    : (active = "page-item");

                data.push(
                    <li className={active} style={{ color: "#0D71B9" }}>
                        <Link
                            className="page-link"

                            onClick={() => this.props.handleChangePage(i)}
                        >
                            {i}
                        </Link>
                    </li>
                );
            }
        }
        return this.props.cantPages > 1 ? (
            <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                    {
                        <li className={arrowActiveForward}>
                            <a
                                className="page-link "

                                style={{ cursor: "pointer", color: "#0D71B9" }}
                                /// href="javascript:void();"
                                //  onClick={() => this.props.handleChangePage()}
                                onClick={() =>
                                    this.props.handleChangePage(
                                        Number(this.props.optionSelected) - 1
                                    )
                                }
                                aria-label="Previous"
                            >
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Anterior</span>
                            </a>
                        </li>
                    }
                    {data}
                    {
                        <li className={arrowActiveBackward}>
                            <a
                                className={"page-link "}
                                style={{ cursor: "pointer", color: "#0D71B9" }}
                                onClick={() =>
                                    this.props.handleChangePage(
                                        Number(this.props.optionSelected) + 1
                                    )
                                }

                                aria-label="Next"
                            >
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Siguiente</span>
                            </a>
                        </li>
                    }
                </ul>
            </nav>
        ) : (
            ""
        );
    }
}
const DivMessage = (
    <text>
        <strong>Aún no prestaste ningún servicio</strong>
    </text>
);

const HistorialMatcheosScreen = ({
    data,
    showModal,
    divShow,
    modalTitle,
    modalText,
    modalBtn,
    modalReset,
    path,
    redirect,
    pageSelected,
    cantPages,
    handleChangePage,
    reloadPage,
    qualifications
}) => (
    <div>
        <div className="row" style={{ marginRight: "0", marginLeft: "0" }}>
            <div
                className="decoration"
                style={{ height: window.innerHeight + "px" }}

            ></div>
            <div className="page_container" >
                <div className="card" style={{ backgroundColor: "#FAFAFA", minHeight: "100vh" }}>
                    <article className="card-body general" >
                        {showModal ? (
                            <Modal
                                modal_title={modalTitle}
                                modal_text={modalText}
                                modal_btn={modalBtn}
                                modalReset={modalReset}
                                path={path}
                                redirect={redirect}
                            />
                        ) : (
                            ""
                        )}

                        <h4 className="card-title text-center" style={{ justifyContent: "center" }}>
                            Historial</h4>

                        <div className={divShow === true ? "div_show" : "div_hide"}>
                            {DivMessage}
                        </div>
                        <form>
                            {data &&
                                Object.keys(data).map((keyName, i) => (
                                    <div key={i} className="card h-10 match_preview border-light shadow-sm rounded-0">
                                        <div className="card-body p-30 m-30" >
                                            <div className="row historial">
                                                <a
                                                    className="h_titulo"
                                                    href={
                                                        "#/MatchsDetalle" + "/" + data[keyName].job_offer_id
                                                    }
                                                >
                                                    <strong style={{ color: "#0D71B9" }}>{data[keyName].fantasy_name}</strong>
                                                </a>

                                                <div className="h_job_name">{data[keyName].title}</div>

                                                <div className="h_fecha">
                                                    {moment(data[keyName].offer_end_date).format(
                                                        "DD/MM/YYYY"
                                                    )}
                                                </div>
                                                {Number(data[keyName].qualifications_quant) === 0 && data[keyName].state === "contratado" ? (
                                                    <ModalPreguntas
                                                        idname={data[keyName].job_offer_id}
                                                        reloadPage={reloadPage}
                                                    />
                                                ) : (
                                                    <div
                                                        className="row justify-content-center"
                                                        style={{ width: "100%" }}
                                                    >
                                                        <div
                                                            className="col-12"
                                                            style={{ textAlign: "center" }}
                                                        >
                                                            {qualifications(data[keyName].qualifications)}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </form>
                        <>
                            <Paginator
                                cantPages={cantPages}
                                handleChangePage={handleChangePage}
                                optionSelected={pageSelected}
                            />
                        </>
                    </article>
                </div>
            </div>
        </div>
    </div >
);

export default HistorialMatcheosScreen;
