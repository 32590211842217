import React from "react";

import "./../../../style.css";
import * as Yup from "yup";
import { withFormik, ErrorMessage, Form, Field } from "formik";
import { FormFeedback, Input, FormGroup, Label, CustomInput } from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { url_base } from "./../../../../api_url";
import moment from "moment";

import "./../../../../Styles/general.css";

import { withRouter, Link } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
    faMapMarkerAlt,
    faUserFriends,
    faMoneyBillWave,
    faCalendarAlt,
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parseISO } from "date-fns";

const numerics = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const customInputForm = ({ field, form: { touched, errors }, ...props }) => (
    <div>
        {touched[field.name] && errors[field.name] && (
            <div className="frm_error_c">{errors[field.name]}</div>
        )}
    </div>
);

/*const handleChange = (event) => {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  event.persist()
  this.setState(prevState => ({
    values: {
      ...prevState.values,
      [name]: value
    },
  }));
};*/

const DatePickerField = ({ name, value, onChange }) => {
    return (
        <DatePicker
            name={name}
            selected={(value && new Date(value)) || null}
            //   selected={moment(value)}
            // selected={value}
            className="form-control"
            placeholderText="Fecha de Nacimiento"
            dateFormat="dd-MM-yyyy"
            autoComplete="off"
            showMonthDropdown
            showYearDropdown
            onChange={val => {
                onChange(name, val);
            }}
        />
    );
};

//let data = first_name;

const formikWrapper = withFormik({
    //enableReinitialize: false,
    enableReinitialize: true,

    mapPropsToValues: props => ({
        talentImage: props.talentImage || "",
        firstName: props.firstName || "",
        lastName: props.lastName || "",
        phoneAreaCode: props.phoneAreaCode || "",
        phone: props.phone || "",
        provinceList: props.province || "",
        cityDisabled: props.cityDisabled || "",
        city: props.city || "",
        cityList: props.cityList || "",
        province: props.province || "",
        birthDate: props.birthDate
            ? moment(props.birthDate) //.format("DD-MM-YYYY")
            : "",
        // birthdate: props.birthdate ? moment(props.birthdate, "DD-MM-YYYY") : "",
        // cityID: props.cityID,
        password: props.password || "",
        passwordRepeat: props.passwordRepeat || "",
        docTypeList: props.docTypeList || "",
        gender: props.gender || "",
        docType: props.docType || "",
        docNumber: props.docNumber || "",
        addressStreet: props.addressStreet || "",
        addressNumber: props.addressNumber || "",
        addressFloor: props.addressFloor || ""
    }),
    handleSubmit: (values, { props }) => {
        const access_token_data = localStorage.getItem("token");
        axios
            .patch(url_base + "/talents/profile/update", values, {
                headers: {
                    ["Authorization"]: "Bearer " + access_token_data
                }
            })
            .then(response => {
                //console.log(response);

                /*  setTimeout(() => {
                  let path = `/PerfilText/`;
                  props.history.push(path);
                }, 30000);*/
                let path = `/PerfilText/`;
                props.history.push(path);
                /*setTimeout(props => {
                  redirect(props)
                }, 2000);*/
            })
            .catch();
    },
    validateOnChange: false,
    validateOnBlur: true,

    validationSchema: Yup.object().shape({
        firstName: Yup.string().required("requerido"),
        lastName: Yup.string().required("requerido"),
        phoneAreaCode: Yup.number()
            .integer()
            .required("requerido"),
        phone: Yup.number().required("requerido"),
        //  docType: Yup.number().required("requerido"),
        docNumber: Yup.string()
            .matches(numerics, "documento inválido")
            .required("requerido"),
        addressStreet: Yup.string().required("requerido"),
        addressNumber: Yup.string().required("requerido")
        /*    birthDate: Yup.date()
              .max(new Date(), "Fecha de nacimiento incorrecta")
              .required("requerido")
              .test("DOB", "error message", value => {
                return new Date() - value >= 18;
              })*/
        /*  genderGroup: Yup.boolean()
          .label("genderGroup")
          .test(
            "is-true",
            "debe seleccionar una opción",
            value => value === true
          )*/
    })
});

const DatosPersonalesText = props => {
    const {
        values,
        errors,
        touched,
        userEmail,
        docTypeList,
        setFieldValue,
        provinceList,
        cityHandleChange,
        docTypeHandleChange,
        province,
        docType,
        ProvinceHandleChange,
        cityList,
        city,
        handleChange
    } = props;

    return (
        <div>
            <div className="row justify-content-center mr-0 ml-0">
                <div className="decoration"></div>
                <div className="page_container">
                    <div className="card" style={{ backgroundColor: "#FAFAFA" }}>
                        <article className="card-body general">
                            <h4 className="card-title text-center">
                                <Link to={"/PerfilText"}>
                                    <FontAwesomeIcon
                                        className="arrow_back"
                                        icon={faArrowLeft}
                                        size="1x"
                                    />
                                </Link>
                                Datos Personales
                                <FontAwesomeIcon
                                    className="arrow_back"
                                    icon={faArrowLeft}
                                    size="1x"
                                    style={{ visibility: "hidden" }}
                                />
                            </h4>

                            <Form>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Nombre</label>
                                            <input
                                                name="firstName"
                                                type={"firstName"}
                                                className="form-control"
                                                onChange={handleChange}
                                                value={values.firstName}
                                            />
                                            {errors.firstName && touched.firstName ? (
                                                <div className="frm_error_c">{errors.firstName}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Apellido</label>
                                            <input
                                                name="lastName"
                                                type={"lastName"}
                                                className="form-control"
                                                onChange={handleChange}
                                                value={
                                                    values.lastName
                                                } /*
                             onChange={e => {
                              nameHandleChange(e, setFieldValue);
                            }}*/
                                            /*  component={
                                            customInputForm
                                          } */
                                            />
                                            {errors.lastName && touched.lastName ? (
                                                <div className="frm_error_c">{errors.lastName}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="tit_black"
                                        style={{
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Teléfono
                                    </div>
                                    <div className="col-xs-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                                        <label className="label_black">Código de área</label>
                                        <FormGroup>
                                            <input
                                                name="phoneAreaCode"
                                                className="form-control"
                                                type={"phoneAreaCode"}
                                                // component={customInputForm}
                                                onChange={handleChange}
                                                value={values.phoneAreaCode}
                                            />
                                            {errors.phoneAreaCode && touched.phoneAreaCode ? (
                                                <div className="frm_error_c">
                                                    {errors.phoneAreaCode}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-8 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                                        <FormGroup>
                                            <label className="label_black">Número</label>
                                            <input
                                                name="phone"
                                                className="form-control"
                                                placeholder="Tel"
                                                type={"phone"}
                                                // component={customInputForm}
                                                onChange={handleChange}
                                                value={values.phone}
                                            />
                                            {errors.phone && touched.phone ? (
                                                <div className="frm_error_c">{errors.phone}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="tit_black"
                                        style={{
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Documento
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Tipo</label>
                                            <select
                                                id="docType"
                                                name="docType"
                                                className="form-control"
                                                component={customInputForm}
                                                type="select"
                                                value={docType}
                                                onChange={e => {
                                                    docTypeHandleChange(e, setFieldValue);
                                                }}
                                            >
                                                <option value="">Seleccionar</option>
                                                {Object.keys(docTypeList).map((keyName, i) => (
                                                    <option key={i} value={docTypeList[keyName].id}>
                                                        {docTypeList[keyName].doc_type}
                                                    </option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Número</label>
                                            <input
                                                id="docNumber"
                                                name="docNumber"
                                                className="form-control"
                                                placeholder="número de documento"
                                                //component={customInputForm}
                                                onChange={handleChange}
                                                value={values.docNumber}
                                                type={"docNumber"}
                                            />
                                            {errors.docNumber && touched.docNumber ? (
                                                <div className="frm_error_c">{errors.docNumber}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 profile_user_email mb-3">
                                        <label className="label_black">
                                            Email <sup>(no puedes modificar este dato)</sup>
                                        </label>
                                        {userEmail}
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Fecha de nacimiento</label>
                                            <div className="customDatePickerWidth">
                                                <DatePickerField
                                                    name="birthDate"
                                                    value={values.birthDate}
                                                    onChange={setFieldValue}
                                                    className="form-control"
                                                    dateFormat="dd-MM-yyyy"
                                                    placeholder="DD/MM/AAAA"
                                                />
                                                {errors.birthDate && touched.birthDate ? (
                                                    <div className="frm_error_c">{errors.birthDate}</div>
                                                ) : null}
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="tit_black"

                                    >
                                        Dirección
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Provincia</label>
                                            <select
                                                name="province"
                                                className="form-control"
                                                component={customInputForm}
                                                onChange={e => {
                                                    ProvinceHandleChange(e, setFieldValue);
                                                }}
                                                value={province}
                                                // value={id}
                                                type="select"
                                            >
                                                {Object.keys(provinceList).map((keyName, i) => (
                                                    <option key={i} value={provinceList[keyName].id}>
                                                        {provinceList[keyName].name}
                                                    </option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Ciudad / Localidad</label>
                                            <select
                                                name="city"
                                                className="form-control"
                                                type="select"
                                                component={customInputForm}
                                                value={city}
                                                onChange={e => {
                                                    cityHandleChange(e, setFieldValue);
                                                }}
                                            >
                                                {Object.keys(cityList).map((keyName, i) => (
                                                    <option key={i} value={cityList[keyName].id}>
                                                        {cityList[keyName].name}
                                                    </option>
                                                ))}
                                            </select>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Calle</label>
                                            <input
                                                name="addressStreet"
                                                className="form-control"
                                                //component={customInputForm}
                                                onChange={handleChange}
                                                value={values.addressStreet}
                                                type={"addressStreet"}
                                            />
                                            {errors.addressStreet && touched.addressStreet ? (
                                                <div className="frm_error_c">
                                                    {errors.addressStreet}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">Altura</label>
                                            <input
                                                name="addressNumber"
                                                className="form-control text-primary"
                                                placeholder="Dirección Número"
                                                // component={customInputForm}
                                                onChange={handleChange}
                                                value={values.addressNumber}
                                                type={"addressNumber"}
                                            />
                                            {errors.addressNumber && touched.addressNumber ? (
                                                <div className="frm_error_c">
                                                    {errors.addressNumber}
                                                </div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                        <FormGroup>
                                            <label className="label_black">
                                                Dirección Piso/Depto <sup>(Opcional)</sup>
                                            </label>
                                            <input
                                                name="addressFloor"
                                                className="form-control"
                                                placeholder=""
                                                // component={customInputForm}
                                                onChange={handleChange}
                                                value={values.addressFloor}
                                                type={"addressFloor"}
                                            />
                                            {errors.addressFloor && touched.addressFloor ? (
                                                <div className="frm_error_c">{errors.addressFloor}</div>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left radio_gruop_fix">
                                        <FormGroup>
                                            <label className="label_genero">Género</label>
                                            <div className="custom-control custom-radio custom-control-inline fix">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    onChange={handleChange}
                                                    id="radioGender1"
                                                    name="gender"
                                                    value="m"
                                                    checked={values.gender === "m" ? true : ""}
                                                />
                                                <label
                                                    className="custom-control-label black"
                                                    for="radioGender1"
                                                >
                                                    M
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline fix">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    onChange={handleChange}
                                                    id="radioGender2"
                                                    name="gender"
                                                    value="f"
                                                    checked={values.gender === "f" ? true : ""}
                                                />
                                                <label
                                                    className="custom-control-label black"
                                                    for="radioGender2"
                                                >
                                                    F
                                                </label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline fix">
                                                <input
                                                    type="radio"
                                                    className="custom-control-input"
                                                    onChange={handleChange}
                                                    id="radioGender3"
                                                    name="gender"
                                                    value="o"
                                                    checked={values.gender === "o" ? true : ""}
                                                />
                                                <label
                                                    className="custom-control-label blue_last"
                                                    for="radioGender3"
                                                >
                                                    O
                                                </label>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-blue btn-block"
                                        style={{ width: "100%" }}
                                    >
                                        {" "}
                                        Guardar{" "}
                                    </button>
                                </div>
                            </Form>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
};
const EnhancedForm = formikWrapper(DatosPersonalesText);
//export default EnhancedForm;

export default withRouter(EnhancedForm);
